<template>
  <section>
    <RuleSection
      title="Reply SMS"
      index="1"
      type="success"
      info="What should the user receive upon sending a text to this endpoint?"
      v-if="inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel'"
    >
      <label class="form-check mb-4">
        <input class="form-check-input" type="checkbox" v-model="inbox.ruleset.replyWithMessage" id="autoreply" />
        <label class="form-check-label" for="autoreply">Send reply?</label>
      </label>
      <div class="form-group row mb-0" v-if="inbox.ruleset.replyWithMessage">
        <SmsMessage v-model="inbox.ruleset.replyContent" placeholder="Sender content" label="Reply content:"></SmsMessage>
      </div>
    </RuleSection>
    <RulesetAutoreplyMultiple v-if="inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel'" />
    <span v-if="inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel' || inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel'">
      <PaymentInitial />
      <PaymentConfirmation v-if="inbox.ruleset.requireConfirmation" />
    </span>
  </section>
</template>

<script setup lang="ts">
  import { inject, watchEffect } from 'vue';
  import RuleSection from '../ruleset/shared/RuleSection.vue';
  import PaymentInitial from './../elements/PaymentInitial.vue';
  import PaymentConfirmation from './../elements/PaymentConfirmation.vue';
  import SmsMessage from '@/components/shared/SmsMessage.vue';
  import RulesetAutoreplyMultiple from '../ruleset/RulesetAutoreplyMultiple.vue';

  const inbox = inject('inbox') as any;

  watchEffect(() => {
    if (inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel' && !inbox.value.ruleset.replyWithMessage) {
      inbox.value.ruleset.replyContent = '';
    }
  });
</script>
