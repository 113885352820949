<template>
  <div>
    <Notification>
      Below are all of your sub-keywords. When users send an SMS to your keyword + the sub-keyword, it will trigger an autoreply. Example:
      <span class="fw-bold">{{ inbox.keyword }} HELLO</span> will trigger the autoreply that is linked to the sub-keyword “HELLO”.
    </Notification>
    <div v-if="inbox.ruleset.keywords && inbox.ruleset.keywords?.length > 0">
      <RuleSection
        v-for="(keyword, idx) in inbox.ruleset.keywords"
        :key="idx"
        :title="`Sub-keyword${keyword.keyword ? `: ${keyword.keyword}` : ''}`"
        :index="`${idx + 1}`"
        type="success"
        closeable
        @close="removeKeyword(idx)"
      >
        <div class="form-group">
          <label class="mb-2" for="keyword">Sub-keyword:</label>
          <input type="text" class="form-control" id="keyword" placeholder="Sub-keyword" v-model="keyword.keyword" @keydown.space.prevent />
        </div>
        <SmsMessage v-model="keyword.replyContent" label="Reply content:"></SmsMessage>
      </RuleSection>
      <div class="text-center d-flex items-center justify-content-center">
        <button class="btn btn-outline-primary d-flex items-center" @click="addNewKeyword"><i class="fe fe-plus me-1"></i>Add keyword</button>
      </div>
    </div>
    <EmptyState v-else text="No keywords added.">
      <button class="btn btn-primary" @click="addNewKeyword">Add keyword</button>
    </EmptyState>
  </div>
</template>

<script setup lang="ts">
  import { inject, Ref } from 'vue';
  import { SmsInboxRulesetAutoreplyMultipleModel } from '@/dto/graphql';
  import RuleSection from './shared/RuleSection.vue';
  import SmsMessage from '@/components/shared/SmsMessage.vue';
  import EmptyState from '@/components/shared/EmptyState.vue';
  import Notification from '@/components/shared/Notification.vue';

  type TInbox = {
    keyword: string;
    ruleset: SmsInboxRulesetAutoreplyMultipleModel;
  };
  const inbox = inject('inbox') as Ref<TInbox>;

  const addNewKeyword = () => {
    inbox.value.ruleset.keywords?.push({ keyword: '', replyContent: '' });
  };

  const removeKeyword = (index: number) => {
    inbox.value.ruleset.keywords?.splice(index, 1);
  };
</script>
