<template>
  <section>
    <MultipleNoKeywordError v-if="inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel'" :margin="false" />
    <PaymentChargeError
      v-if="inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel' || inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel'"
      :margin="false"
    />
    <PaymentInvalidContentError
      v-if="inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel' || inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel'"
      :margin="false"
    />
  </section>
</template>

<script setup lang="ts">
  import { Ref, inject } from 'vue';
  import {
    SmsInboxRulesetAutoreplyModel,
    SmsInboxRulesetAutoreplyMultipleModel,
    SmsInboxRulesetPaymentDynamicModel,
    SmsInboxRulesetPaymentFixedModel,
  } from '@/dto/graphql';
  import MultipleNoKeywordError from '../elements/MultipleNoKeywordError.vue';
  import PaymentChargeError from '../elements/PaymentChargeError.vue';
  import PaymentInvalidContentError from '../elements/PaymentInvalidContentError.vue';

  type TInbox = {
    keyword: string;
    ruleset:
      | SmsInboxRulesetAutoreplyModel
      | SmsInboxRulesetAutoreplyMultipleModel
      | SmsInboxRulesetPaymentDynamicModel
      | SmsInboxRulesetPaymentFixedModel;
  };

  const inbox = inject('inbox') as Ref<TInbox>;
</script>
