<template>
  <div class="container-fluid vh-100 d-flex flex-column">
    <div class="header">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h6 class="header-pretitle">SMS Inbox</h6>
            <h1 class="header-title py-2">Inboxes and messages</h1>
          </div>

          <div class="col-auto">
            <button @click="router.push({ name: 'SmsInboxWizard' })" class="btn btn-primary btn-lg d-flex align-items-center px-4">
              <i class="fe fe-edit-2 me-3"></i>
              Create inbox
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row flex-fill h-100">
      <div class="col-12 col-xl-3">
        <InboxList @reload="updateInboxesList" />
      </div>
      <div class="col-12" :class="{ 'col-12 col-xl-4': currentThread, 'col-12 col-xl-9': !currentThread }">
        <MessageList
          :messages="messages"
          :loading-messages="loadingMessages"
          :current-inbox="currentInbox"
          v-model:current-page="currentPage"
          :total-count="totalCount"
          :total-pages="totalPages"
          :items-per-page="itemsPerPage"
          @select-message="selectMessage($event)"
          :selected-message="selectedMessage?.id || ''"
        />
      </div>
      <div v-if="currentThread" class="col-xl-5">
        <Thread
          :thread="currentThread"
          :current-inbox="currentInbox"
          :message-id="selectedMessage?.id || ''"
          @close-thread="currentThread = null"
          @update-thread="getThread($event, true)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { provide, Ref, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { SmsInboxModelResult, SmsIncomingModelResult, ThreadModelResult } from '@/dto/graphql';
  import { SmsInboxList, SmsIncomingList, ThreadGet } from '@/services/GraphqlApi';
  import InboxList from '@/components/sms-inbox-list/InboxList.vue';
  import MessageList from '@/components/sms-inbox-list/MessageList.vue';
  import Thread from '@/components/threads/Thread.vue';
  import { useToast } from 'vue-toastification';

  const router = useRouter();
  const toast = useToast();
  const loadingMessages = ref(true) as Ref<boolean>;

  const currentPage = ref(0) as Ref<number>;
  const totalCount = ref(0) as Ref<number>;
  const totalPages = ref(0) as Ref<number>;
  const itemsPerPage = 100;

  const currentInbox = ref() as Ref<SmsInboxModelResult | null>;
  const inboxes = ref([]) as Ref<SmsInboxModelResult[]>;
  const messages = ref([]) as Ref<SmsIncomingModelResult[]>;

  const selectedMessage = ref(null) as Ref<{ id: string; MSISDN: string } | null>;
  const currentThread = ref() as Ref<ThreadModelResult | null>;

  provide('currentInbox', currentInbox);
  provide('inboxes', inboxes);
  provide('itemsPerPage', itemsPerPage);

  function updateInboxesList() {
    SmsInboxList(itemsPerPage).then((result) => {
      inboxes.value = result.results;
    });
  }

  watch(currentInbox, (inbox) => {
    if (inbox) {
      currentThread.value = null;
      selectedMessage.value = null;
      updateMessagesList(inbox.id);
    } else {
      messages.value = [];
      loadingMessages.value = false;
    }
  });

  watch(currentPage, () => {
    if (currentInbox.value) updateMessagesList(currentInbox.value.id);
  });

  function updateMessagesList(inbox: string) {
    SmsIncomingList(inbox, itemsPerPage, currentPage.value)
      .then((result) => {
        messages.value = result.results;
        totalCount.value = result.totalCount;
        totalPages.value = Math.ceil(result.totalCount / itemsPerPage);
      })
      .catch(() => {
        messages.value = [];
        loadingMessages.value = false;
      })
      .finally(() => {
        loadingMessages.value = false;
      });
  }

  const selectMessage = (message: { id: string; MSISDN: string }) => {
    selectedMessage.value = message;
    getThread(message.MSISDN);
  };

  const getThread = (MSISDN: string, newMessage?: boolean) => {
    if (newMessage) {
      selectedMessage.value = null;
    }

    ThreadGet(MSISDN)
      .then((result) => {
        currentThread.value = result;
      })
      .catch(() => {
        currentThread.value = null;
        toast.error('Failed to load thread', { timeout: 3000 });
      });
  };
</script>

<style scoped></style>
