<template>
  <label class="form-check mb-4">
    <input class="form-check-input" type="checkbox" v-model="inbox.ruleset.sendErrorMessage" id="send-error" />
    <label class="form-check-label" for="send-error">Send error message on no sub-keyword found?</label>
  </label>
  <RuleSection
    v-if="inbox.ruleset.sendErrorMessage"
    title="Error: Sub-keyword not found"
    index="A"
    type="danger"
    info="The specified sub-keyword was not found."
    :margin="margin"
  >
    <div class="form-group row mb-0">
      <SmsMessage v-model="inbox.ruleset.errorMessage" placeholder="Example: We did not understand your message." label="Reply content:"></SmsMessage>
    </div>
  </RuleSection>
</template>

<script setup lang="ts">
  import { Ref, inject } from 'vue';
  import RuleSection from './../ruleset/shared/RuleSection.vue';
  import SmsMessage from '@/components/shared/SmsMessage.vue';
  import { SmsInboxRulesetAutoreplyMultipleModel } from '@/dto/graphql';

  defineProps({
    margin: {
      type: Boolean,
      default: true,
    },
  });

  type TInbox = {
    keyword: string;
    ruleset: SmsInboxRulesetAutoreplyMultipleModel;
  };
  const inbox = inject('inbox') as Ref<TInbox>;
</script>
