<template>
  <section class="mb-4 clearfix">
    <p class="text-center text-muted">{{ formattedDate }}</p>
    <ThreadMessage v-for="message in messages" :key="message.id" :message="message" />
  </section>
</template>

<script setup lang="ts">
  import { MessageModel } from '@/dto/graphql';
  import { format } from 'date-fns';
  import { computed } from 'vue';
  import ThreadMessage from './ThreadMessage.vue';

  const props = defineProps<{
    messages: MessageModel[];
    date: string;
  }>();

  const diff = computed(() => {
    const diff = new Date().getTime() - new Date(props.date).getTime();
    return diff / (1000 * 3600 * 24);
  });

  const formattedDate = computed(() => {
    if (diff.value < 1) {
      return 'Today';
    }
    if (diff.value < 2) {
      return 'Yesterday';
    }
    return format(new Date(props.date), 'd. MMMM yyyy');
  });
</script>

<style scoped></style>
