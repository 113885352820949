<template>
  <div v-if="!hasConnection" class="alert d-flex justify-content-between align-items-center" :class="props.alertClass">
    <div class="me-auto">
      <slot> You cannot use this feature, until you have added a GatewayAPI Token on the "Settings" &gt; "SMS Configuration page". </slot>
    </div>
    <router-link class="btn btn-light text-nowrap ms-3 d-flex align-items-center px-3" :to="{ name: 'SMSConfiguration' }"
      ><i class="fe fe-settings me-2"></i> Fix now</router-link
    >
  </div>
</template>

<script lang="ts" setup>
  import { inject, Ref } from 'vue';

  const props = defineProps({
    alertClass: {
      type: String,
      default: 'alert-danger',
    },
  });
  const hasConnection = inject('hasConnection') as Ref<boolean>;
</script>
