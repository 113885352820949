<template>
  <form @submit.prevent="submit">
    <label for="addPhoneNumber" class="mb-2"
      >Add recipient to {{ type === 'CAMPAIGN' ? 'campaign' : 'list' }}
      <InfoTooltip
        tooltip='Enter the MSISDN of a recipient, ie. country code and national number. Then click the "+"-button to add the recipient to the campaign.'
      ></InfoTooltip
    ></label>

    <div class="input-group">
      <span class="input-group-text">
        <div class="flag overflow-hidden border rounded-circle">
          <img
            :src="phoneFlag"
            alt=""
            v-tooltip="
              phoneDetails
                ? phoneDetails.country
                  ? `Country ${phoneDetails.country} (+${phoneDetails.countryCallingCode}) detected.`
                  : `Invalid number for +${phoneDetails.countryCallingCode}.`
                : 'Enter a phone number, starting with country calling code.'
            "
          />
        </div>
      </span>

      <input
        v-model="phoneNumber"
        class="form-control"
        type="text"
        name="phonenumber"
        inputmode="numeric"
        placeholder="Enter phone number"
        id="addPhoneNumber"
      />
      <button class="btn btn-outline-secondary" type="submit" :disabled="!phoneDetails">
        <span class="fe fe-plus"></span>
      </button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
  .flag {
    overflow: hidden;
    box-sizing: content-box;

    &,
    img {
      height: 32px;
      width: 32px;
    }
  }
</style>

<script lang="ts" setup>
  import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
  import { PropType, Ref, ref, watch, watchEffect } from 'vue';
  import vTooltip from '@/directives/vTooltip';
  import { getFlagByPhoneNumber } from '@/services/PhoneNumber';
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';

  const emit = defineEmits(['newNumber']);
  const phoneNumber = ref('') as Ref<string>;
  const phoneDetails = ref(parsePhoneNumberFromString('')) as Ref<PhoneNumber | undefined>;
  const phoneFlag = ref('');

  defineProps({
    type: {
      type: String as PropType<'CAMPAIGN' | 'QUICKSEND'>,
      default: 'CAMPAIGN',
    },
  });

  watchEffect((e) => {
    const phoneParseable = phoneNumber.value.replace(/\D+/, '');
    phoneDetails.value = parsePhoneNumberFromString(`+${phoneParseable}`);
    phoneFlag.value = getFlagByPhoneNumber(phoneParseable);
  });

  const submit = () => {
    if (!phoneDetails.value || !phoneDetails.value.country) return;

    emit('newNumber', phoneDetails.value.number);

    phoneNumber.value = '';
  };
</script>
