<template>
  <h3 class="mb-4 text-center">Legal</h3>
  <p>As we will handle information about your clients/contacts, it is important that you sign a Data Processing Agreement
    (DPA) with us.</p>
  <p>Your may start out without one for testing purposes.</p>
  <p>
    <a target="_blank" class="btn btn-link mx-n3 my-n2" href="RCDPA0.1.pdf" @click="emit('update:agreementRead', true)">
      Read Data Processing Agreement (DPA)
    </a>
  </p>
  <div class="form-check mt-3">
    <input class="form-check-input" type="radio" name="signingStatus" :value="false" v-model="signingStatus"
      id="signingStatusFalse" />
    <label class="form-check-label" for="signingStatusFalse">Do not sign DPA-agreement yet</label>
  </div>
  <Notification v-if="!signingStatus" type="warning" class="mt-2">You can continue without signing, but before production
    use, this must be signed.</Notification>
  <div class="form-check mt-2">
    <input class="form-check-input" type="radio" name="signingStatus" :value="true" v-model="signingStatus"
      id="signingStatusTrue" />
    <label class="form-check-label" for="signingStatusTrue">Accept and sign the DPA-agreement</label>
  </div>
  <small class="d-block text-danger" v-if="signingStatus && !agreementRead">
    You must read the agreement first. Please click the link above to read the DPA.
  </small>
  <div class="mt-3" v-else-if="signingStatus && agreementRead">
    <hr />
    <div class="form-group">
      <label for="signeeCompanyName">Company name</label>
      <input type="text" class="form-control" id="signeeCompanyName" :value="signeeCompanyName"
        @change="emit('update:signeeCompanyName', ($event.target as HTMLInputElement).value)" />
      <small v-if="signeeCompanyNameErrorMessage" class="text-danger">{{ signeeCompanyNameErrorMessage }}</small>
    </div>
    <div class="form-group">
      <label for="signeeVatNo">VAT ID</label>
      <input type="text" class="form-control" id="signeeVatNo" :value="signeeVatNo"
        @change="emit('update:signeeVatNo', ($event.target as HTMLInputElement).value)" />
      <small v-if="signeeVatNoErrorMessage" class="text-danger">{{ signeeVatNoErrorMessage }}</small>
    </div>
    <div class="form-group">
      <label for="signeeName">Contact name</label>
      <input type="text" class="form-control" id="signeeName" :value="signeeName"
        @change="emit('update:signeeName', ($event.target as HTMLInputElement).value)" />
      <small v-if="signeeNameErrorMessage" class="text-danger">{{ signeeNameErrorMessage }}</small>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" name="confirmation" id="confirmationCheck" :value="confirmation"
        @change="emit('update:confirmation', ($event.target as HTMLInputElement).checked)" />
      <label class="form-check-label" for="confirmationCheck">I confirm that I am allowed to legally sign on behalf of
        above company.</label>
      <small v-if="confirmationErrorMessage" class="text-danger">{{ confirmationErrorMessage }}</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import Notification from '../shared/Notification.vue';

defineProps<{
  signeeCompanyName: string;
  signeeCompanyNameErrorMessage?: string | undefined;
  signeeVatNo: string;
  signeeVatNoErrorMessage?: string | undefined;
  signeeName: string;
  signeeNameErrorMessage?: string | undefined;
  confirmation: boolean;
  confirmationErrorMessage?: string | undefined;
  signingStatus: boolean;
  agreementRead: boolean;
}>();

const signingStatus = ref(false);
const emit = defineEmits([
  'update:signingStatus',
  'update:agreementRead',
  'update:signeeCompanyName',
  'update:signeeVatNo',
  'update:signeeName',
  'update:confirmation',
]);

watch(signingStatus, (value) => {
  emit('update:signingStatus', value);
});
</script>
