<template>
  <RuleSection
    title="Error: Charge not possible"
    index="A"
    type="danger"
    info="We tried to charge, but the charge was not possible (usually disabled or not enough credit left)."
    :margin="margin"
  >
    <div class="form-group row mb-0">
      <SmsMessage
        v-model="inbox.ruleset.errChargeContent"
        placeholder="Example: We're sorry, but we were not able to charge your phone."
        label="Reply content:"
      ></SmsMessage>
    </div>
  </RuleSection>
</template>

<script setup lang="ts">
  import { inject } from 'vue';
  import RuleSection from './../ruleset/shared/RuleSection.vue';
  import SmsMessage from '@/components/shared/SmsMessage.vue';

  const props = defineProps({
    margin: {
      type: Boolean,
      default: true,
    },
  });

  const inbox = inject('inbox') as any;
</script>
