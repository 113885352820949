<template>
  <div class="container-fluid">
    <!-- HEADER -->
    <div class="header">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h6 class="header-pretitle">SMS Quick Send</h6>
            <h1 class="header-title py-2">Quick Send</h1>
          </div>

          <div class="col-auto">
            <SendButton></SendButton>
          </div>
        </div>
      </div>
    </div>
    <!-- HEADER -->

    <MissingGatewayapiTokenAlert />

    <!-- BODY -->
    <div class="row">
      <!-- LEFT SIDEBAR -->
      <div class="col-lg-6 col-xxl-5">
        <!-- RECIPIENTS -->
        <Recipients></Recipients>
        <!-- RECIPIENTS -->

        <!-- GATEWAYAPI SETTINGS -->
        <MessageClass></MessageClass>
        <!-- GATEWAYAPI SETTINGS -->

        <!-- STATUS -->
        <Status></Status>
        <!-- STATUS -->
      </div>
      <!-- LEFT SIDEBAR -->

      <!-- RIGHT - MAIN CONTENT -->
      <div class="col-lg-6 col-xxl-7">
        <!-- MESSAGE -->
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">Message</h4>
          </div>
          <div class="card-body">
            <SmsMessage v-model="quickSend.message.value"></SmsMessage>
          </div>
        </div>
        <!-- MESSAGE -->

        <!-- SENDER NAME -->
        <SmsSenderName v-model="quickSend.senderName.value"></SmsSenderName>
        <!-- SENDER NAME -->
      </div>
      <!-- RIGHT - MAIN CONTENT -->
    </div>
    <!-- BODY -->
  </div>
</template>

<script lang="ts" setup>
  import useQuickSend from '@/services/QuickSend';
  import SmsMessage from '@/components/shared/SmsMessage.vue';
  import Status from '@/components/sms-quick-send/Status.vue';
  import MessageClass from '@/components/sms-quick-send/MessageClass.vue';
  import { provide } from 'vue';
  import Recipients from '@/components/sms-quick-send/Recipients.vue';
  import SmsSenderName from '@/components/shared/SmsSenderName.vue';
  import SendButton from '@/components/sms-quick-send/SendButton.vue';
  import MissingGatewayapiTokenAlert from '@/components/shared/MissingGatewayapiTokenAlert.vue';

  const quickSend = useQuickSend();

  provide('quickSend', quickSend);
</script>
