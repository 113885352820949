import { AccountSettingsModel } from '@/dto/graphql';
import { accountSettingsGet, accountSettingsUpdate } from '@/services/GraphqlApi';
import { defineStore } from 'pinia';

export const useSettingsStore = defineStore('settings', {
  state: () => {
    return {
      settings: null as AccountSettingsModel | null,
    };
  },
  actions: {
    async getSettings(): Promise<AccountSettingsModel | null> {
      await accountSettingsGet()
        .then((response) => {
          this.settings = response;
        })
        .catch((error) => {
          console.error(error);
        });

      return this.settings;
    },
    async updateSettings(settings: AccountSettingsModel) {
      await accountSettingsUpdate(settings)
        .then(async () => {
          await this.getSettings();
          return this.settings;
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    },
  },
});
