<template>
  <Loading v-if="loadingTemplate" />
  <div v-else-if="template" class="container-fluid">
    <!-- HEADER -->
    <div class="header">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h6 class="header-pretitle">SMS Templates</h6>
            <input
              ref="refCampaignName"
              required
              type="text"
              class="form-control form-control-flush h1 my-0 py-0 d-none d-sm-block"
              placeholder="Enter name of template"
              v-model.trim="campaignName"
            />
          </div>

          <div class="col-auto">
            <div class="dropdown">
              <button
                class="btn btn-primary btn-lg dropdown-toggle d-flex align-items-center"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fe fe-send me-3"></i>
                <span class="pe-2">Actions</span>
              </button>
              <ul class="dropdown-menu">
                <li v-if="templateId !== 'new'">
                  <button class="dropdown-item" :class="{ disabled: !hasMessage }" @click.prevent="submitTemplate(false, false)">Save</button>
                </li>
                <li>
                  <button class="dropdown-item" :class="{ disabled: !hasMessage }" @click.prevent="submitTemplate(true, false)">
                    Save {{ templateId === 'new' ? '' : 'as new' }}
                  </button>
                </li>
                <li>
                  <button class="dropdown-item" :class="{ disabled: !hasMessage }" @click.prevent="submitTemplate(false, true)">
                    Create campaign
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- LEFT SIDEBAR -->
      <div class="col-lg-6 col-xxl-5">
        <div class="mb-4 d-sm-none">
          <label for="refCampaignNameMobile" class="form-label">Template name</label>
          <input
            ref="refCampaignNameMobile"
            id="refCampaignNameMobile"
            required
            type="text"
            class="form-control"
            placeholder="Enter name of template"
            v-model.trim="campaignName"
          />
        </div>

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title me-auto">Settings</h4>
          </div>
          <div class="card-body">
            <MessageClassComponent class="mb-5" />
            <SendSpeed class="my-5" v-model="throttle" />
            <AllowedHours class="mt-5" />
          </div>
        </div>
      </div>
      <!-- LEFT SIDEBAR -->

      <!-- RIGHT - MAIN CONTENT -->
      <div class="col-lg-6 col-xxl-7">
        <!-- MESSAGE -->
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">Message</h4>
          </div>
          <div class="card-body">
            <SmsMessage v-model="message" tags-selector></SmsMessage>
          </div>
        </div>
        <!-- MESSAGE -->

        <!-- SENDER NAME -->
        <SmsSenderName v-model="senderName"></SmsSenderName>
        <!-- SENDER NAME -->
      </div>
      <!-- RIGHT - MAIN CONTENT -->
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, onMounted, provide, ref, Ref } from 'vue';
  import { CampaignAllowedHoursModel, CampaignTemplateModel, ConnectionModelResult, CreateSendStatus, MessageClass } from '@/dto/graphql';
  import { CampaignTemplateGet, CampaignTemplateUpsert, CampaignUpsert, ConnectionGatewayapi } from '@/services/GraphqlApi';

  import { useRouter, useRoute } from 'vue-router';
  import { useToast } from 'vue-toastification';

  import SmsMessage from '@/components/shared/SmsMessage.vue';
  import SmsSenderName from '@/components/shared/SmsSenderName.vue';
  import MessageClassComponent from '@/components/sms-campaign-editor/settings/MessageClass.vue';
  import AllowedHours from '@/components/sms-campaign-editor/settings/AllowedHours.vue';
  import SendSpeed from '@/components/sms-campaign-editor/settings/SendSpeed.vue';
  import Loading from '@/components/shared/Loading.vue';
  import { setTitle } from '@/services/SetTitle';

  const toast = useToast();
  const route = useRoute();
  const router = useRouter();
  const connectionDetails = ref<ConnectionModelResult>();

  const hasMessage = computed(() => !!message.value.length);
  const templateId = computed(() => {
    const parseId = (id: string | string[]) => (Array.isArray(id) ? id[0] : id);
    return parseId(route.params.id);
  });

  const template = ref({}) as Ref<CampaignTemplateModel>;
  const loadingTemplate = ref(true) as Ref<boolean>;

  const refCampaignName = ref() as Ref<HTMLInputElement>;
  const refCampaignNameMobile = ref() as Ref<HTMLInputElement>;

  const campaignName = ref('') as Ref<string>;
  const messageClass = ref(MessageClass.Standard) as Ref<MessageClass>;
  const allowedHours = ref([]) as Ref<CampaignAllowedHoursModel[]>;
  const message = ref('') as Ref<string>;
  const throttle = ref(10) as Ref<number>;
  const senderName = ref('') as Ref<string>;

  provide('messageClass', messageClass);
  provide('allowedHours', allowedHours);
  provide('message', message);

  onMounted(async () => {
    if (templateId.value && templateId.value !== 'new') {
      CampaignTemplateGet(templateId.value)
        .then((t) => {
          template.value = t;
          campaignName.value = t.campaignName;
          messageClass.value = t.messageClass;
          allowedHours.value = t.allowedHours || [];
          message.value = t.message;
          throttle.value = t.throttle || 10;
          senderName.value = t.sender;
          setTitle(t.campaignName);
        })
        .catch(() => {
          toast.error('Campaign not found');
          router.push({ name: 'SmsTemplatesList' });
        })
        .finally(() => {
          loadingTemplate.value = false;
        });
    } else {
      loadingTemplate.value = false;
      connectionDetails.value = await ConnectionGatewayapi();
      if (connectionDetails.value?.defaultSenderId) senderName.value = connectionDetails.value.defaultSenderId;
    }
  });

  const submitTemplate = async (isNew = false, createCampaign = false) => {
    if (templateId.value === 'new') {
      isNew = true;
    }

    try {
      if (!campaignName.value) {
        if (refCampaignName.value.offsetParent) refCampaignName.value.focus();
        if (refCampaignNameMobile.value.offsetParent) refCampaignNameMobile.value.focus();
        throw new Error('Your template must have a title. The title is not sent to recipients, but used within this system.');
      }

      if (throttle.value < 1 || throttle.value > 100 || isNaN(throttle.value)) {
        throw new Error('Your template must have a send speed and must be a number between 1 and 100.');
      }

      if (!senderName.value || senderName.value.length > 11) {
        throw new Error('Your template must have a sender name that is 11 characters or less.');
      }

      await CampaignTemplateUpsert(
        {
          campaignName: campaignName.value,
          message: message.value,
          sender: senderName.value,
          messageClass: messageClass.value,
          allowedHours: allowedHours.value,
          throttle: throttle.value,
          title: campaignName.value, // TODO: Remove this when backend is fixed
        },
        isNew ? undefined : templateId.value ? templateId.value : undefined,
      );
      if (!createCampaign) {
        toast.success('Template saved', { timeout: 2000 });
        await router.push({ name: 'SmsTemplatesList' });
      } else {
        try {
          const campaign = await CampaignUpsert({
            campaignName: campaignName.value,
            message: message.value,
            sendStatus: CreateSendStatus.Predraft,
            sendTime: new Date(),
            sender: senderName.value,
            throttle: throttle.value,
            allowedHours: allowedHours.value,
            messageClass: messageClass.value,
          });
          await router.push({ name: 'SmsCampaignEditor', params: { id: campaign } });
        } catch (error) {
          toast.error(`Campaign could not be created from template}`);
        }
      }
    } catch (e) {
      toast.error((e as Error).message, { timeout: 2000 });
    }
  };
</script>

<style scoped></style>
