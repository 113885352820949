<template>
  <RuleSection
    v-if="inbox.ruleset.requireConfirmation"
    title="Error: No confirmation from user"
    index="D"
    type="danger"
    info="The user has only sent initial SMS, but has not sent the confirmation."
    :margin="margin"
  >
    <div class="form-group row">
      <label for="inactivityPeriod" class="col-sm-3 col-form-label">Send after inactivity period of (minutes):</label>
      <div class="col-sm-9">
        <input
          type="number"
          class="form-control"
          id="inactivityPeriod"
          placeholder="Confirmation word"
          v-model.number="inbox.ruleset.errNoConfirmationTime"
          @keypress="isNumber($event)"
          @keypress.space.prevent
        />
      </div>
    </div>
    <div class="form-group row mb-0">
      <SmsMessage
        v-model="inbox.ruleset.errNoConfirmationContent"
        :placeholder="`Example: We haven't received your confirmation of your donation.${
          inbox.ruleset.paymentConfirmWord && `Please reply with ${inbox.ruleset.paymentConfirmWord.toUpperCase()} to confirm the donation.`
        }`"
        label="Reply content:"
      ></SmsMessage>
    </div>
  </RuleSection>
</template>

<script setup lang="ts">
  import { inject } from 'vue';
  import RuleSection from './../ruleset/shared/RuleSection.vue';
  import SmsMessage from '@/components/shared/SmsMessage.vue';

  const props = defineProps({
    margin: {
      type: Boolean,
      default: true,
    },
  });

  const isNumber = (event: KeyboardEvent) => {
    if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
  };

  const inbox = inject('inbox') as any;
</script>
