<template>
  <tr>
    <td>
      <p class="m-0">{{ recipient.recipient }}</p>
      <small class="text-muted">{{ recipientType[recipient.type] }}</small>
    </td>
    <td>
      <p class="m-0">{{ format(recipient.blockedAt, 'd. MMMM yyyy') }}</p>
    </td>
    <td>
      <p class="m-0" v-if="recipient.note">{{ recipient.note }}</p>
      <p class="m-0 text-muted" v-else>No note</p>
    </td>
    <td>
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle rounded-pill" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" href="#" @click="showBlockRecipientModal = true">
              <i class="fe fe-edit me-3"></i>
              <span>Edit</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item text-success" href="#" @click="unblock(recipient._id)">
              <i class="fe fe-check-circle me-3"></i>
              <span>Unblock</span>
            </a>
          </li>
        </ul>
      </div>
    </td>
  </tr>
  <BlockRecipientModal v-if="showBlockRecipientModal" :recipient-prop="recipient" @close="showBlockRecipientModal = false" @success="onEditSuccess" />
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import { BlacklistModelResult } from '@/dto/graphql';
  import { BlacklistDelete } from '@/services/GraphqlApi';
  import { format } from 'date-fns';
  import { useToast } from 'vue-toastification';
  import BlockRecipientModal from './BlockRecipientModal.vue';

  const toast = useToast();
  const emit = defineEmits(['success']);

  const showBlockRecipientModal = ref(false);

  interface recipientTypeInterface {
    [key: string]: string;
  }
  const recipientType: recipientTypeInterface = {
    PHONE_NUMBER: 'Phone number',
    EMAIL: 'Email address',
  };

  defineProps<{
    recipient: BlacklistModelResult;
  }>();

  const unblock = async (id: string) => {
    await BlacklistDelete(id)
      .then(() => {
        emit('success');
        toast.success('Recipient unblocked', { timeout: 3000 });
      })
      .catch((error) => {
        console.error('Failed to unblock recipient', error);
        toast.error('Failed to unblock recipient', { timeout: 3000 });
      });
  };

  const onEditSuccess = () => {
    showBlockRecipientModal.value = false;
    emit('success');
  };
</script>
