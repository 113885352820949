<template>
  <div class="dropdown">
    <button
      class="btn btn-primary btn-lg dropdown-toggle d-flex align-items-center"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :disabled="!hasConnection"
    >
      <i class="fe fe-send me-3"></i>
      <span class="pe-2">Actions</span>
    </button>
    <ul class="dropdown-menu">
      <li>
        <button class="dropdown-item" :class="{ disabled: !hasMessage }" @click.prevent="openTestModal()">Send test SMS</button>
      </li>
      <li>
        <button class="dropdown-item" :class="{ disabled: !hasMessage || !recipientCount }" @click.prevent="emit('send-campaign')">
          Send Campaign
          <span
            class="ms-2 badge"
            :class="{
              'bg-danger': hasMessage && recipientCount,
              'bg-light text-muted': !hasMessage || !recipientCount,
            }"
          >
            {{ recipientCount }} recipients
          </span>
        </button>
      </li>
      <hr class="mx-4" />
      <li>
        <button class="dropdown-item" :class="{ disabled: !hasMessage }" @click.prevent="emit('save-draft')">Save as draft</button>
      </li>
      <li>
        <button class="dropdown-item" :class="{ disabled: !hasMessage }" @click.prevent="emit('save-as-template')">Save as template</button>
      </li>
    </ul>
  </div>

  <SendTestModal v-if="showTestModal" @close="showTestModal = false"></SendTestModal>
</template>

<script lang="ts" setup>
  import { computed, inject, Ref, ref } from 'vue';
  import SendTestModal from '@/components/sms-campaign-editor/SendTestModal.vue';
  import { useToast } from 'vue-toastification';
  import { useTagsValidation } from '@/services/TagsValidation';

  const toast = useToast();
  const { validateTags } = useTagsValidation();

  const emit = defineEmits(['send-campaign', 'save-draft', 'save-as-template']);

  const hasConnection = inject('hasConnection') as Ref<boolean>;
  const message = inject('message') as Ref<string>;
  const recipientCount = inject('recipientCount') as Ref<number>;

  const hasMessage = computed(() => !!message.value.length);

  const showTestModal = ref(false);

  const openTestModal = () => {
    try {
      validateTags(message.value);
      showTestModal.value = true;
    } catch (e: any) {
      toast.warning((e as Error).message ?? 'Something went wrong.');
    }
  };
</script>
