<template>
  <div class="dropdown">
    <button
      class="btn btn-primary btn-lg dropdown-toggle d-flex align-items-center"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :disabled="!hasConnection || quickSendStatus !== QuickSendStatus.Draft"
    >
      <i class="fe fe-send me-3"></i>
      <span v-if="quickSendStatus === QuickSendStatus.Draft" class="pe-2">Quick Send</span>
      <span v-if="quickSendStatus === QuickSendStatus.Sending">Sending...</span>
      <span v-if="quickSendStatus === QuickSendStatus.Done">Campaign sent</span>
    </button>
    <ul class="dropdown-menu">
      <li>
        <button class="dropdown-item" :class="{ disabled: !hasMessage }" @click.prevent="showTestModal = true">Send test SMS</button>
      </li>
      <li>
        <button class="dropdown-item" :class="{ disabled: !hasMessage || !hasRecipients }" @click.prevent="doSend">
          Send SMS
          <span class="ms-2 badge" :class="{ 'bg-success': hasMessage && hasRecipients, 'bg-light text-muted': !hasMessage || !hasRecipients }"
            >{{ quickSend.recipients.value.length }} recipients</span
          >
        </button>
      </li>
    </ul>
  </div>

  <SendTestModal v-if="showTestModal" @close="showTestModal = false"></SendTestModal>
  <SendingModal v-if="showSendingModal" @close="showSendingModal = false"></SendingModal>
</template>

<script lang="ts" setup>
  import useQuickSend, { sendNow, QuickSendStatus, QuickSend } from '@/services/QuickSend';
  import { computed, inject, Ref, ref } from 'vue';
  import SendTestModal from '@/components/sms-quick-send/SendTestModal.vue';
  import SendingModal from '@/components/sms-quick-send/SendingModal.vue';
  import { useDialog } from '@/services/Dialog';

  const createDialog = useDialog();

  const quickSend = inject('quickSend') as QuickSend;
  const hasConnection = inject('hasConnection') as Ref<boolean>;
  const quickSendStatus = quickSend.status;

  const hasRecipients = computed(() => !!quickSend.recipients.value.length);
  const hasMessage = computed(() => !!quickSend.message.value.length);
  const showSendingModal = ref(false);
  const showTestModal = ref(false);

  const doSend = async () => {
    if (!hasRecipients.value) return;

    const res = await createDialog({
      title: 'About to send campaign',
      content: `Your campaign is about to be sent to <strong>${quickSend.recipients.value.length}</strong> recipients.<br><br>Are you sure that you are ready to continue?`,
      confirmText: 'Confirm & Send',
    });

    if (!res) return;

    showSendingModal.value = true;
    await sendNow(quickSend);
  };
</script>
