import { ref } from 'vue';

export enum Theme {
  Dark = 'Dark',
  Light = 'Light',
}

let darkModeLink: HTMLLinkElement | null = null;

export const currentTheme = ref((localStorage.getItem('userTheme') ?? Theme.Light) as Theme);

export function setTheme(newTheme?: Theme) {
  document.body.setAttribute('data-theme', (newTheme ?? Theme.Light).toLowerCase());

  if (newTheme) {
    localStorage.setItem('userTheme', newTheme);
    currentTheme.value = newTheme;
  }

  if (currentTheme.value === Theme.Dark) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `/dashkit/css/theme-dark.bundle.css`;
    document.head.appendChild(link);
    darkModeLink = link;
  } else if (darkModeLink) {
    darkModeLink.remove();
    darkModeLink = null;
  }
}
