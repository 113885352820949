<template>
  <div class="dropdown mb-3 mt-1 mx-n3">
    <button
      class="btn btn-dark dropdown-toggle text-start w-100 d-flex align-items-center"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span class="flex-fill" style="max-width: 90%">
        <small class="text-muted">Account:</small><br />
        <span class="text-truncate d-inline-block w-100 pe-2 mb-n2">
          {{ currentAccount?.name ?? 'No account' }}
        </span>
      </span>
    </button>
    <div class="dropdown-menu w-100">
      <div class="mx-n3">
        <h6 class="dropdown-header">All accounts:</h6>
        <a
          href="#!"
          class="dropdown-item text-truncate"
          :class="account.id === currentAccount?.id ?? 'bg-success'"
          v-for="account in userAccounts"
          :key="account.id"
          :title="account.name"
          @click="switchAccount(account.id)"
          >{{ account.name }}</a
        >
        <hr class="my-3 mx-3" />
        <a type="button" @click="createAccountModal = true" class="dropdown-item d-flex justify-content-between">
          Add new account
          <i class="fe fe-plus"></i>
        </a>
      </div>
    </div>
  </div>

  <CreateAccountModal v-if="createAccountModal" @close="createAccountModal = false" />
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { userAccounts, currentAccount, selectAccount, getCurrentUser } from '@/services/Authentication';
  import CreateAccountModal from '../account/CreateAccountModal.vue';

  import useSplash from '@/composables/useSplash';
  const { showSplash } = useSplash();

  const router = useRouter();
  const createAccountModal = ref(false);

  const switchAccount = async (id: string) => {
    await selectAccount(id);
    showSplash();
    setTimeout(() => router.go(0), 300);
  };
</script>
