import { inject, InjectionKey } from 'vue';

export interface CreateDialogOptions {
  title: string;
  content: string;
  showInput?: boolean;
  inputLabel?: string;
  inputPlaceholder?: string;
  defaultInputValue?: string;
  inputValidator?: (input: string) => Promise<string | null>;
  cancelText?: string;
  confirmText?: string;
  showCancel?: boolean;
  showConfirm?: boolean;
}

export type CreateDialog = (options: CreateDialogOptions) => Promise<boolean | string>;
export const CreateDialogKey: InjectionKey<CreateDialog> = Symbol('CreateDialogKey');

export function useDialog() {
  const dialog = inject(CreateDialogKey);

  if (!dialog) {
    throw new Error('Could not resolve provider');
  }

  return dialog;
}
