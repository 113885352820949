<template>
  <RuleSection
    title="Donation SMS"
    index="1"
    type="success"
    :info="`${
      inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel'
        ? `When the user sends ${inbox.keyword ? `${inbox.keyword}` : 'the specified keyword,'} ${
            inbox.ruleset.requireConfirmation ? 'a confirmation SMS is sent' : 'a charged SMS is sent, which confirms the charge'
          } `
        : `When the user sends ${inbox.keyword ? `${inbox.keyword} X` : 'KEYWORD X'} ${
            inbox.ruleset.requireConfirmation
              ? 'where X is the amount to charge, the user then receives a reply to confirm the charge'
              : 'where X is the amount to charge, the user is then overcharged'
          }`
    }`"
  >
    <div v-if="inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel' && !inbox.ruleset.requireConfirmation" class="form-group row">
      <label for="price" class="col-sm-3 col-form-label">Price:</label>
      <div class="col-sm-9">
        <input type="number" class="form-control" id="price" placeholder="Price" v-model="inbox.ruleset.paymentPrice" />
      </div>
    </div>
    <div v-if="inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel'" class="form-group row">
      <label for="price" class="col-sm-3 col-form-label">Price range:</label>
      <div class="col-sm-9 d-flex align-items-center">
        <input type="number" class="form-control" id="price1" placeholder="Min" v-model="inbox.ruleset.paymentPriceMin" />
        <p class="mx-3 mb-0 text-muted">and</p>
        <input type="number" class="form-control" id="price2" placeholder="Max" v-model="inbox.ruleset.paymentPriceMax" />
      </div>
    </div>
    <div class="form-group row mb-0">
      <SmsMessage
        v-model="initialReplyContent"
        :placeholder="
          inbox.ruleset.requireConfirmation
            ? `Example: Thank you for your support! Please confirm by replying with ${inbox.keyword || 'KEYWORD'} ${
                inbox.ruleset.paymentConfirmWord || 'CONFIRMATION_WORD'
              }`
            : 'Example: Thank you for your donation to x! We really appreciate your support.'
        "
        label="Reply content:"
      ></SmsMessage>
    </div>
  </RuleSection>
</template>

<script setup lang="ts">
  import { inject, computed } from 'vue';
  import RuleSection from './../ruleset/shared/RuleSection.vue';
  import SmsMessage from '@/components/shared/SmsMessage.vue';

  const inbox = inject('inbox') as any;
  const initialReplyContent = computed({
    get(): string {
      return inbox.value.ruleset.requireConfirmation
        ? (inbox.value.ruleset.initialContent as string)
        : (inbox.value.ruleset.paymentContent as string);
    },
    set(value): void {
      if (inbox.value.ruleset.requireConfirmation) {
        inbox.value.ruleset.initialContent = value;
      } else {
        inbox.value.ruleset.paymentContent = value;
      }
    },
  });
</script>
