<template>
  <div class="card">
    <div class="card-body">
      <h6 class="text-uppercase text-muted mb-2">Last 5 campaigns</h6>
      <div class="d-flex flex-column h-100" v-if="campaigns.length > 0">
        <ul class="list-group-flush p-0">
          <div v-for="(campaign, index) in campaigns" :key="index" class="list-group-item d-flex align-items-center justify-content-between">
            <p class="m-0 text-truncate">{{ campaign.campaignName }}</p>
            <div>
              <span class="badge bg-success-soft me-2" v-tooltip="'Successful'">{{ campaign.statusSuccess }}</span>
              <span class="badge bg-danger-soft me-2" v-tooltip="'Failed'">{{ campaign.statusFailure }}</span>
            </div>
          </div>
        </ul>
        <a href="/campaigns" class="mt-3 text-muted mt-auto mb-3 d-flex items-align-center" @click.prevent="router.push({ name: 'SmsCampaignList' })"
          >View all campaigns <i class="fe fe-arrow-right ms-1"
        /></a>
      </div>
      <Loading v-else-if="loadingCampaigns" />
      <EmptyState v-else text="No statistics yet." />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { DataCampaigns } from '@/dto/graphql';
  import { StatisticsCampaigns } from '@/services/GraphqlApi';
  import { onMounted, Ref, ref } from 'vue';
  import Loading from '../shared/Loading.vue';
  import vTooltip from '@/directives/vTooltip';
  import { useRouter } from 'vue-router';
  import EmptyState from '../shared/EmptyState.vue';

  const campaigns = ref([]) as Ref<DataCampaigns[]>;
  const loadingCampaigns = ref(true);
  const router = useRouter();

  onMounted(async () => {
    const response = await StatisticsCampaigns(5);
    campaigns.value = response.data;
    loadingCampaigns.value = false;
  });
</script>

<style scoped></style>
