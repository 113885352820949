<template>
  <Teleport to="body">
    <div class="modal" ref="createAccountModalEl" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="staticBackdropLabel">Create Account</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body">
            <CreateAccount class="content" @step-change="(step) => changeStep(step)" @close="emit('close')" />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
  import { onMounted, onBeforeUnmount, ref, Ref } from 'vue';
  import CreateAccount from '@/components/account/CreateAccount.vue';
  import { Modal } from 'bootstrap';

  const currentStep = ref(1);
  const emit = defineEmits(['close']);

  const changeStep = (step: number) => {
    currentStep.value = step;
  };

  const createAccountModalEl = ref<HTMLElement>() as Ref<HTMLElement>;

  onMounted(async () => {
    const modal = new Modal(createAccountModalEl.value);
    modal.show();
    onBeforeUnmount(async () => {
      modal.hide();
    });
    createAccountModalEl.value.addEventListener('hidden.bs.modal', () => {
      emit('close');
      modal.dispose();
    });
  });
</script>

<style scoped lang="scss">
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(5px);
    z-index: 5000;

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--bs-body-bg);
      border-radius: 0.5rem;
      padding: 1rem 0;
      width: 50%;
      max-width: 500px;
    }
  }
</style>
