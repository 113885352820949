<template>
  <RuleSection
    title="Error: Invalid content"
    index="B"
    type="danger"
    info="The user entered invalid content or a number outside the range."
    :margin="margin"
  >
    <div class="form-group row mb-0">
      <SmsMessage
        v-model="inbox.ruleset.errInvalidContent"
        placeholder="Example: You entered an invalid amount or unknown keyword. Please try again."
        label="Reply content:"
      ></SmsMessage>
    </div>
  </RuleSection>
</template>

<script setup lang="ts">
  import { inject } from 'vue';
  import RuleSection from './../ruleset/shared/RuleSection.vue';
  import SmsMessage from '@/components/shared/SmsMessage.vue';

  const props = defineProps({
    margin: {
      type: Boolean,
      default: true,
    },
  });

  const inbox = inject('inbox') as any;
</script>
