<template>
  <Teleport to="body">
    <div class="modal" ref="senderIdModalEl" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="staticBackdropLabel">Change Sender ID</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body">
            <SmsSenderName v-model="sender" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button @click="saveSender" type="button" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
  import { ref, Ref, onMounted, onBeforeUnmount } from 'vue';
  import { Modal } from 'bootstrap';
  import SmsSenderName from '../shared/SmsSenderName.vue';

  const senderIdModalEl = ref(null) as any as Ref<HTMLDivElement>;

  const emit = defineEmits(['close', 'saveSender']);
  const props = defineProps<{
    senderName: string | undefined;
  }>();

  const sender = ref(props.senderName || '');

  onMounted(async () => {
    const senderIdModal = new Modal(senderIdModalEl.value);
    senderIdModal.show();

    onBeforeUnmount(async () => {
      senderIdModal.hide();
    });

    senderIdModalEl.value.addEventListener('hidden.bs.modal', () => {
      emit('close');
      senderIdModal.dispose();
    });
  });

  const saveSender = () => {
    emit('saveSender', sender.value);
  };
</script>
