<template>
  <div class="card card-fill-lg">
    <div class="card-header">
      <h4 class="card-header-title d-flex align-items-center">
        Contact Tags
        <InfoTooltip
          tooltip="You can associate tags with contacts to help grouping/organizing. A contact may have multiple tags. When sending campaigns, you can send to contacts, based on tags."
        />
      </h4>
    </div>
    <div class="card-body tags-list-body position-relative mt-0 overflow-auto flex-grow-1 d-flex">
      <div class="inner position-absolute overflow-auto top-0 start-0 h-100 w-100" v-if="contactTags.length">
        <ul class="list-group list-group-flush border-bottom border-light">
          <li
            class="list-group-item list-group-item-action py-3 px-4 d-flex justify-content-between align-items-start"
            :class="{ active: !currentTag }"
            @mousedown.prevent="currentTag = ''"
          >
            <div class="me-auto" :class="{ 'text-focus': currentTag }">- All contacts -</div>
          </li>

          <li
            v-for="tag of contactTagsSorted"
            class="list-group-item list-group-item-action py-3 px-4 d-flex justify-content-between align-items-center"
            :class="{ active: tag.id === currentTag }"
            :key="tag.id"
            @mousedown.prevent="currentTag = tag.id"
          >
            <div class="me-auto">
              <p class="d-block my-0">
                {{ tag.name }}
              </p>
              <span v-if="tag.totalCount > 0">
                <span
                  class="badge bg-success rounded-pill me-2"
                  v-tooltip="'Active contacts, ie. the contacts we have marked active. They are included when using \'Send campaign\'.'"
                  >{{ tag.activeCount.toLocaleString() }}</span
                >
                <span
                  class="badge bg-secondary rounded-pill"
                  v-tooltip="'Inactive contacts, ie. contacts we have not marked active. They will be skipped when using \'Send campaign\'.'"
                  >{{ tag.totalCount - tag.activeCount }}</span
                >
              </span>
            </div>
            <button
              aria-haspopup="true"
              class="btn btn-sm btn-rounded-circle btn-white"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fe fe-more-vertical"></i>
            </button>
            <div role="menu" class="dropdown-menu">
              <button @click.prevent="renameTag(tag.id)" role="menuitem" class="dropdown-item list-group-item-action d-flex py-3 text-secondary">
                <i class="fe fe-edit-3 me-3"></i>
                Rename tag
              </button>
              <button role="menuitem" @click.prevent="deleteTag(tag.id)" class="dropdown-item list-group-item-action d-flex py-3 text-danger">
                <i class="fe fe-trash me-3"></i>
                Delete tag
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div v-else class="align-self-center w-100">
        <div class="alert alert-light text-muted d-flex align-items-center mb-0" role="alert">
          <div>
            You have not created any tags yet. You create tags directly on a contact.<br /><br />
            Tags help organize and group contacts.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .list-group-item {
    cursor: pointer;
  }
  @media (max-width: 991px) {
    .tags-list-body {
      padding: 0 !important;

      .inner {
        position: relative !important;
        height: auto;
        max-height: 75vh;
      }
    }
  }
</style>

<script lang="ts" setup>
  import { computed, inject, Ref } from 'vue';
  import { ContactTagModelResult } from '@/dto/graphql';
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';
  import vTooltip from '@/directives/vTooltip';
  import _ from 'lodash';
  import { useToast } from 'vue-toastification';
  import { ContactTagDelete, ContactTagUpsert, ContactTagList } from '@/services/GraphqlApi';
  import { useDialog } from '@/services/Dialog';

  const createDialog = useDialog();

  const toast = useToast();

  const contactTags = inject('contactTags') as Ref<ContactTagModelResult[]>;
  const currentTag = inject('currentTag') as Ref<string>;

  const emit = defineEmits(['reload']);

  ContactTagList().then((tags) => {
    contactTags.value = tags;
  });

  // Sorted by totalCount (DESC) then name (ASC)
  const contactTagsSorted = computed(() => {
    const withLowerName = _.map(contactTags.value, (t: ContactTagModelResult) => {
      return { ...t, nameLower: t.name.toLocaleLowerCase() };
    });
    const sortedA = _.reverse(_.sortBy(withLowerName, ['nameLower']));
    const sortedB = _.sortBy(sortedA, ['totalCount']);
    return _.reverse(sortedB);
  }) as Ref<ContactTagModelResult[]>;

  // return the next contact in the list, after the currently selected one
  const contactNextInList = computed(() => {
    let returnNext = false;
    for (const c of contactTagsSorted.value) {
      if (returnNext) return c;
      if (c.id === currentTag.value) returnNext = true;
    }
    return null;
  }) as Ref<ContactTagModelResult | null>;

  const renameTag = async (tagId: string) => {
    // find tag
    const tag = contactTags.value.find((t) => t.id === tagId);
    if (!tag) return toast.error('The tag does not seem to exist.');

    const res = await createDialog({
      title: 'Rename tag',
      content: 'Enter a new name for the tag',
      defaultInputValue: tag.name,
      showInput: true,
      inputLabel: 'Name',
      inputPlaceholder: 'Enter a tag name',
      inputValidator(value: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
          if (!value) {
            reject('Tag name cannot be empty.');
          }
          resolve(null);
        });
      },
    });

    if (!res) return;

    //If OK, fire rename mutation
    try {
      await ContactTagUpsert(
        {
          name: res as unknown as string,
        },
        tag.id,
      );
      emit('reload');
    } catch (e) {
      return toast.error(`An error occured: ${(e as Error).message}`);
    }
  };

  const deleteTag = async (tagId: string) => {
    // find tag
    const tag = contactTags.value.find((t) => t.id === tagId);
    if (!tag) return toast.error('The tag does not seem to exist.');

    const deleteContent = computed(() => {
      return `You are about to delete the tag "${_.escape(tag.name)}" which is associated with ${
        tag.totalCount
      } contact(s).<br><br>Do you want all associated contacts to be deleted as well?<br><br><input type="checkbox" id="deleteContactsToo" class="form-check-input" /> <label for="deleteContactsToo">Delete contacts as well</label>`;
    });

    // totalCount 0, then just delete
    if (tag.totalCount) {
      const res = await createDialog({
        title: `The tag '${_.escape(tag.name)}' has contacts`,
        content: deleteContent.value,
        cancelText: 'Cancel',
        confirmText: 'Delete tag',
      });

      if (!res) return;
    }

    // Doing it the old skool way, because v-model isn't reactive in template literals for some reason.
    const deleteContactsToo = document.querySelector('#deleteContactsToo:checked') ? true : false;

    // delete!
    try {
      await ContactTagDelete(tagId, deleteContactsToo);
      currentTag.value = contactNextInList.value?.id ?? '';
      _.remove(contactTags.value, (t) => t.id === tagId);
      emit('reload');
      toast.success(`The tag "${_.escape(tag.name)}" was deleted.`);
    } catch (e) {
      return toast.error(`An error occured: ${(e as Error).message}`);
    }
  };
</script>
