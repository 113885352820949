<template>
  <section>
    <label>
      <p class="mb-1">Status</p>
      <div class="form-check form-check-inline">
        <label class="form-check-label" for="inboxActive1">
          <input
            :value="true"
            v-model="inbox.ruleset.active"
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inboxActive1"
            aria-label="Active"
          />
          Active
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label" for="inboxActive2">
          <input
            :value="false"
            v-model="inbox.ruleset.active"
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inboxActive2"
            aria-label="Inactive"
          />
          Inactive
        </label>
      </div>
    </label>
    <div class="form-group">
      <label class="w-100 mt-4">
        <p class="mb-1">Active from/to</p>
        <Datepicker
          v-model="dateRange"
          range
          :timezone="accountSettings?.timezone"
          :dark="currentTheme === Theme.Dark"
          placeholder="Select date"
          :month-change-on-scroll="false"
          aria-label="Active date range"
        />
      </label>
    </div>
    <div class="mb-4">
      <div class="input-group input-group-merge input-group-reverse">
        <label for="receivingNumber" class="w-100">
          Receiving number
          <InfoTooltip tooltip="The number which you receive messages via. Must start with country code without symbols (451204 etc)." />
        </label>
        <input
          v-model.number="inbox.number"
          @keypress="isNumber($event)"
          id="receivingNumber"
          type="phone"
          class="form-control"
          placeholder="451204"
          aria-label="Receiving number"
          @keydown.space.prevent
        />
        <div class="input-group-text">
          <span class="fe fe-hash"></span>
        </div>
      </div>
      <small class="text-danger" v-if="inbox.ruleset.__typename == 'SmsInboxRulesetAutoreplyModel' && keyword === '*' && inbox.number == '451204'">
        You cannot use the catch-all inbox with the default number. Please use a virtual number.
      </small>
      <small class="text-danger" v-if="catchAllInboxExists">A catch-all inbox already exists with the specified phone number.</small>
    </div>
    <div class="form-group">
      <label for="keyword" class="w-100">
        Keyword
        <InfoTooltip
          tooltip="The keyword that messages to this inbox must start with (DONATE or SURVEY etc.). You can also use * to create a
                      catch-all inbox (requires a virtual number)"
        />
        <input type="text" class="form-control" id="keyword" placeholder="Keyword" v-model="keyword" aria-label="Keyword" @keydown.space.prevent />
      </label>
    </div>
    <div class="form-group">
      <label for="replySender" class="w-100">
        Reply sender
        <InfoTooltip tooltip="The name that will show up on the recipients phone when you send a reply." />
        <input
          type="text"
          class="form-control"
          id="replySender"
          placeholder="Reply sender"
          v-model="inbox.ruleset.sender"
          aria-label="Reply sender"
        />
      </label>
      <small class="text-warning" v-if="inbox.ruleset.__typename == 'SmsInboxRulesetAutoreplyModel' && keyword === '*' && !checkSender">
        The receiving number and reply sender is not the same. Your user will receive the reply and thread messages from this sender id. If using a
        phone number, country code should be prefixed.
      </small>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { inject, computed, Ref } from 'vue';
  import Datepicker from '@vuepic/vue-datepicker';
  import { currentTheme, Theme } from '@/services/Theme';

  import InfoTooltip from '@/components/shared/InfoTooltip.vue';
  import {
    AccountSettingsModel,
    SmsInboxModelResult,
    SmsInboxRulesetAutoreplyModel,
    SmsInboxRulesetPaymentDynamicModel,
    SmsInboxRulesetPaymentFixedModel,
  } from '@/dto/graphql';

  type TInbox = SmsInboxModelResult & {
    ruleset: SmsInboxRulesetAutoreplyModel | SmsInboxRulesetPaymentDynamicModel | SmsInboxRulesetPaymentFixedModel;
  };

  const inbox = inject('inbox') as Ref<TInbox>;
  const inboxes = inject('inboxes') as Ref<TInbox[]>;
  const accountSettings = inject('accountSettings') as Ref<AccountSettingsModel | null>;

  const isNumber = (event: KeyboardEvent) => {
    if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
  };

  const keyword = computed({
    get: () => inbox.value.keyword,
    set: (value: string) => {
      inbox.value.keyword = value.replace(/\s/g, '').toLocaleUpperCase();
    },
  });

  const dateRange = computed({
    get() {
      return [inbox.value.ruleset.activeFrom, inbox.value.ruleset.activeTo];
    },
    set(value) {
      if (!value) {
        inbox.value.ruleset.activeFrom = undefined;
        inbox.value.ruleset.activeTo = undefined;
        return;
      }

      inbox.value.ruleset.activeFrom = value[0];
      inbox.value.ruleset.activeTo = value[1];
    },
  });

  const checkSender = computed(() => {
    return inbox.value.ruleset.sender && inbox.value.ruleset.sender === `${inbox.value.number}`;
  });

  const catchAllInboxExists = computed(() => {
    if (keyword.value !== '*') return false;
    return inboxes.value.find(
      (i) => inbox.value.ruleset.__typename == 'SmsInboxRulesetAutoreplyModel' && i.keyword === '*' && i.number == inbox.value.number,
    );
  });
</script>

<style scoped></style>
