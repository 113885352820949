<template>
  <td>
    <Datepicker
      v-model="modelValue.start"
      :min-date="new Date()"
      :timezone="timezone"
      :dark="isDarkMode"
      placeholder="Select date"
      :month-change-on-scroll="false"
      :enable-time-picker="false"
      :clearable="false"
      :disabled-dates="disabledDates"
    ></Datepicker>
  </td>
  <td>
    <select class="form-control" v-model="modelValue.start">
      <option v-for="time in times('before')" :key="time.text" :value="time.value">
        {{ time.text }}
      </option>
    </select>
  </td>
  <td>
    <select class="form-control" v-model="modelValue.end">
      <option v-for="time in times('after')" :key="time.text" :value="time.value">
        {{ time.text }}
      </option>
    </select>
  </td>
  <td class="text-center">
    <button class="col-1 btn btn-rounded-circle btn-outline-danger" type="button" @click="emit('remove')">
      <i class="fe fe-trash" />
    </button>
  </td>
</template>

<script setup lang="ts">
  import { computed, watchEffect, watch, Ref, PropType, onMounted, inject } from 'vue';
  import { set, getMinutes, getHours, isBefore, isAfter } from 'date-fns';
  import Datepicker from '@vuepic/vue-datepicker';
  import { Theme, currentTheme } from '@/services/Theme';
  import { CampaignAllowedHoursModel } from '@/dto/graphql';
  import { utcToZonedTime } from 'date-fns-tz';

  const isDarkMode = computed(() => currentTheme.value === Theme.Dark);
  const disabledDates = inject('disabledDates') as Ref<Date[]>;

  const emit = defineEmits(['update:modelValue', 'remove']);

  const props = defineProps({
    modelValue: {
      type: Object as PropType<CampaignAllowedHoursModel>,
      default: () => ({
        start: new Date(),
        end: new Date(),
      }),
    },
    timezone: {
      type: String,
      required: true,
    },
  });

  // Convert datestring to Date
  onMounted(() => {
    props.modelValue.start = utcToZonedTime(new Date(props.modelValue.start), props.timezone);
    props.modelValue.end = utcToZonedTime(new Date(props.modelValue.end), props.timezone);
  });

  // Update the end time value when the start value (main date) changes
  watchEffect(() => {
    if (props.modelValue.start) {
      props.modelValue.end = set(new Date(props.modelValue.start), {
        hours: getHours(new Date(props.modelValue.end)),
        minutes: getMinutes(new Date(props.modelValue.end)),
      });
    }
  });

  const times = computed(() => {
    return function (beforeOrAfter: 'before' | 'after') {
      const times = [];
      for (let i = 0; i < 24 * 4; i++) {
        const hour = Math.floor(i / 4);
        const minute = (i % 4) * 15;
        const time = set(new Date(props.modelValue.start), { hours: hour, minutes: minute, seconds: 0 });

        if (isBefore(time, new Date(props.modelValue.end)) && beforeOrAfter === 'before') {
          times.push({
            text: `${hour < 10 ? '0' + hour : hour}:${minute === 0 ? '00' : minute}`,
            value: time,
          });
        } else if (isAfter(time, new Date(props.modelValue.start)) && beforeOrAfter === 'after') {
          times.push({
            text: `${hour < 10 ? '0' + hour : hour}:${minute === 0 ? '00' : minute}`,
            value: time,
          });
        }
      }
      return times;
    };
  });
</script>

<style scoped></style>
