<template>
  <div class="card">
    <div class="card-body">
      <div class="row align-items-center gx-0">
        <div class="col">
          <h6 class="text-uppercase text-muted mb-2">Contacts</h6>
          <div v-if="_.isEmpty(statisticsContactsCountries)" class="h2 mb-0">
            <span class="spinner-border spinner-border-sm text-muted"></span> &nbsp;
          </div>
          <div v-else-if="statisticsContactsCountries.contactTotalCount">
            <span class="h2 mb-0">
              {{ statisticsContactsCountries.contactTotalCount.toLocaleString() }}
            </span>
            <span class="badge bg-info-soft mt-n1 ms-3">
              In
              {{ statisticsContactsCountries.countryTotalCount }}
              {{ statisticsContactsCountries.countryTotalCount === 1 ? 'country' : 'countries' }}
            </span>
          </div>
          <div v-else>
            <span class="h2 mb-0 text-warning">None yet</span>
          </div>
        </div>
        <div class="col-auto">
          <span class="h2 fe fe-users text-muted mb-0"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { StatisticsContactsCountries } from '@/services/GraphqlApi';
  import { StatisticContactCountriesModel } from '@/dto/graphql';
  import { Ref, ref } from 'vue';
  import _ from 'lodash';

  const statisticsContactsCountries = ref({}) as Ref<StatisticContactCountriesModel>;
  StatisticsContactsCountries().then((res) => (statisticsContactsCountries.value = res));
</script>
