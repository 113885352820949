<template>
  <MapboxMap class="map" :accessToken="accessToken" :mapStyle="theme" height="500px" />
</template>

<script lang="ts" setup>
  import { ref, computed } from 'vue';
  import { Theme, currentTheme } from '@/services/Theme';
  import { MapboxMap } from 'vue-mapbox-ts';

  const theme = computed(() => (currentTheme.value === Theme.Dark ? 'dark-v10' : 'light-v10'));

  const accessToken = ref(import.meta.env.VITE_MAPBOX_ACCESS_TOKEN);
</script>

<style lang="scss">
  .map {
    min-height: 500px;
    height: 100%;
    width: 100%;
  }
</style>
