<template>
  <div class="container pt-7">
    <div class="d-flex align-items-center justify-content-center gap-2" ref="steps">
      <i
        v-for="(step, index) in stepAmount"
        :key="index"
        class="fe"
        :class="currentStep > step ? 'fe-check-circle text-success' : currentStep === step ? 'fe-arrow-down-circle' : 'fe-circle text-muted'"
      />
    </div>
    <div class="row pt-3" ref="columns">
      <SetupWizard class="col-12 col-xl-8" :step="currentStep" @next="nextStep()" @previous="currentStep--" />
      <MessageExample class="d-none d-xl-block col-4 col-xl" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { provide, ref, computed } from 'vue';
  import SetupWizard from '@/components/sms-inbox-editor/SetupWizard.vue';
  import MessageExample from '@/components/sms-inbox-editor/setup/MessageExample.vue';
  import { useToast } from 'vue-toastification';
  import { useRouter } from 'vue-router';
  import { reactiveOmit } from '@vueuse/core';
  import {
    AccountSettingsModel,
    SmsInboxModel,
    SmsInboxModelResult,
    SmsInboxRulesetAutoreplyModel,
    SmsInboxRulesetAutoreplyMultipleModel,
    SmsInboxRulesetPaymentDynamicModel,
    SmsInboxRulesetPaymentFixedModel,
  } from '@/dto/graphql';
  import {
    SmsInboxRulesetAutoreplyUpsert,
    SmsInboxRulesetPaymentFixedUpsert,
    SmsInboxRulesetPaymentDynamicUpsert,
    SmsInboxUpsert,
    SmsInboxList,
    accountSettingsGet,
    SmsInboxRulesetAutoreplyMultipleUpsert,
  } from '@/services/GraphqlApi';
  import { useAutoAnimate } from '@formkit/auto-animate/vue';

  const [columns] = useAutoAnimate();
  const [steps] = useAutoAnimate();
  const toast = useToast();
  const router = useRouter();

  type SmsInboxPayment = SmsInboxRulesetPaymentDynamicModel | SmsInboxRulesetPaymentFixedModel;

  const currentStep = ref(1);
  const stepAmount = computed(() =>
    inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel'
      ? 3
      : (inbox.value.ruleset as SmsInboxPayment).requireConfirmation || inbox.value.ruleset.__typename !== 'SmsInboxRulesetAutoreplyMultipleModel'
      ? 5
      : 4,
  );

  const accountSettings = ref<AccountSettingsModel | null>(null);
  provide('accountSettings', accountSettings);

  const nextStep = () => {
    if (
      ((inbox.value.ruleset as SmsInboxPayment).requireConfirmation && currentStep.value === 5) ||
      (!(inbox.value.ruleset as SmsInboxPayment).requireConfirmation && currentStep.value === 4) ||
      (inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel' && currentStep.value === 3) ||
      (inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel' && currentStep.value === 4)
    ) {
      submitInbox();
    } else {
      currentStep.value++;
    }
  };

  const submitInbox = async () => {
    let savedRuleset;

    try {
      if (inbox.value.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel') {
        savedRuleset = await SmsInboxRulesetPaymentDynamicUpsert(
          reactiveOmit(inbox.value.ruleset, 'id', '__typename') as SmsInboxRulesetPaymentDynamicModel,
        );
      } else if (inbox.value.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel') {
        savedRuleset = await SmsInboxRulesetPaymentFixedUpsert(
          reactiveOmit(inbox.value.ruleset, 'id', '__typename') as SmsInboxRulesetPaymentFixedModel,
        );
      } else if (inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel') {
        savedRuleset = await SmsInboxRulesetAutoreplyUpsert(reactiveOmit(inbox.value.ruleset, 'id', '__typename') as SmsInboxRulesetAutoreplyModel);
      } else if (inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel') {
        savedRuleset = await SmsInboxRulesetAutoreplyMultipleUpsert(
          reactiveOmit(inbox.value.ruleset, 'id', '__typename') as SmsInboxRulesetAutoreplyMultipleModel,
        );
      }
    } catch (error) {
      toast.error('Error saving ruleset');
      console.error(error);
    } finally {
      if (savedRuleset) {
        await SmsInboxUpsert({
          keyword: inbox.value.keyword,
          number: `${inbox.value.number}`,
          ruleset: savedRuleset,
        } as SmsInboxModel)
          .then((inbox) => {
            toast.success('Inbox saved', { timeout: 3000 });
            router.push({ name: 'SmsInboxEditor', params: { id: inbox } });
          })
          .catch((e) => {
            toast.error(`Error saving inbox: ${e.message}`, { timeout: 5000 });
            return console.error(e);
          });
      }
    }
  };

  type TInbox = SmsInboxModelResult & {
    ruleset:
      | SmsInboxRulesetAutoreplyModel
      | SmsInboxRulesetAutoreplyMultipleModel
      | SmsInboxRulesetPaymentDynamicModel
      | SmsInboxRulesetPaymentFixedModel;
  };

  const inbox = ref<TInbox>({
    id: '',
    gatewayapiToken: '',
    number: '451204',
    keyword: '',
    ruleset: {
      id: '',
      __typename: 'SmsInboxRulesetAutoreplyModel',
      active: true,
      activeFrom: undefined,
      activeTo: undefined,
      sender: '',
      replyWithMessage: true,
      replyContent: '',
    },
  });

  const inboxes = ref<SmsInboxModelResult[]>([]);
  SmsInboxList().then((result) => {
    inboxes.value = result.results;
  });

  accountSettingsGet().then((res) => {
    accountSettings.value = res;
  });

  provide('inbox', inbox);
  provide('inboxes', inboxes);
  provide('accountSettings', accountSettings);
</script>
