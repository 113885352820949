import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { getAccessToken } from '@/services/Authentication';
import { createPinia } from 'pinia';
import client from '@/services/GraphqlApi';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

// Sentry
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || '';

// bootstrap
import 'bootstrap/js/dist/button';

// Vue Toastification CSS
import 'vue-toastification/dist/index.css';
import Toast from 'vue-toastification';

// let's ensure that we're ready to go
const app = createApp(App);

if (SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    release: import.meta.env.VITE_SENTRY_RELEASE || undefined,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'local',
    tracesSampleRate: 1.0, // for now, RelationCity is not THAT popular, so let's just get a lot of metrics
  });
}

app
  .use(router)
  .use(client)
  .use(createPinia())
  .use(Toast, { hideProgressBar: true, timeout: 8000, closeButton: false, draggable: false })
  .mount('#app');
