<template>
  <Teleport to="body">
    <div class="modal fade" tabindex="-1" ref="testSmsModalEl">
      <div class="modal-dialog">
        <form @submit.prevent="doSend">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Send Test SMS</h4>
            </div>
            <div class="modal-body">
              <div
                v-if="statusMessage"
                class="alert alert-dismissible fade show"
                :class="{ 'alert-danger': !statusSuccess, 'alert-success': statusSuccess }"
                role="alert"
              >
                <div v-html="statusMessage"></div>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div v-if="statusSending" class="alert alert-info">Your SMS is being sent...</div>

              <PhoneNumberInput v-model="phoneNumber"></PhoneNumberInput>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" @click="emit('close')">Close</button>
              <button type="submit" :disabled="!phoneNumber" class="btn btn-primary">Send Test SMS</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
  import { inject, onBeforeUnmount, onMounted, Ref, ref, unref } from 'vue';
  import { QuickSend } from '@/services/QuickSend';
  import { Modal } from 'bootstrap';
  import PhoneNumberInput from '@/components/shared/PhoneNumberInput.vue';
  import { SmsSend } from '@/services/GraphqlApi';
  import { getAccessToken } from '@/services/Authentication';
  import { getPhoneParts } from '@/services/PhoneNumber';

  const quickSend = inject('quickSend') as QuickSend;
  let testSmsModal: Modal;
  const testSmsModalEl = ref(null) as any as Ref<HTMLDivElement>;

  const emit = defineEmits(['close']);

  const phoneNumber = ref('');

  const statusMessage = ref('');
  const statusSuccess = ref(true);
  const statusSending = ref(false);

  onMounted(() => {
    testSmsModal = new Modal(testSmsModalEl.value);
    testSmsModal.show();

    testSmsModalEl.value.addEventListener('hidden.bs.modal', () => {
      emit('close');
    });

    onBeforeUnmount(async () => {
      testSmsModal.hide();
      await new Promise((r) => setTimeout(r, 500));
      testSmsModal.dispose();
    });
  });

  const doSend = async () => {
    const accessToken = await getAccessToken();
    if (!accessToken) throw new Error('Access token missing.');

    const recipient = phoneNumber.value;
    phoneNumber.value = '';
    const [cc, no] = getPhoneParts(recipient);

    try {
      statusSending.value = true;
      statusMessage.value = '';

      const sendStatus = await SmsSend(
        recipient,
        quickSend.senderName.value,
        quickSend.message.value,
        accessToken,
        quickSend.encoding.value,
        quickSend.senderClass.value,
      );

      statusMessage.value = `Your message to <strong>(+${cc}) ${no}</strong> was sent succesfully.<br>Total cost: ${sendStatus.creditUsed} ${sendStatus.creditUnit}.`;
      statusSuccess.value = true;
    } catch (e) {
      const ex = e as Error;

      statusMessage.value = `Your message to <strong>(+${cc}) ${no}</strong> could not be sent.<br>Error: ${ex.message}.`;
      statusSuccess.value = false;
    } finally {
      statusSending.value = false;
    }
  };
</script>
