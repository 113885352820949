<template>
  <div class="dropdown-menu-card rounded border shadow m-3 w" ref="rootEl">
    <div class="card-header align-items-center">
      <h4 class="card-header-title">{{ isEditing ? 'Edit' : 'Add' }} Field</h4>
      <button type="button" class="btn-close p-4 m-n4" @click="emit('close')"></button>
    </div>
    <div v-if="!isEditing && step === 1" class="card-body">
      <button class="btn btn-white w-100 text-start" href="#" @click.prevent="handleSelectType('ContactFieldTextModel')">
        <i class="fe fe-type me-2"></i>
        Text
      </button>
      <button class="btn btn-white w-100 text-start mt-3" href="#" @click.prevent="handleSelectType('ContactFieldDateModel')">
        <i class="fe fe-calendar me-2"></i>
        Date
      </button>
    </div>
    <div v-if="isEditing || step === 2">
      <form
        @submit.prevent="upsertCustomField(fieldName, fieldPlaceholder, field?.id)"
        :id="`field-form-${field ? field.id : 'new'}`"
        class="form-group card-body"
      >
        <label class="form-label mb-1" for="name">Name</label>
        <input v-model="fieldName" id="name" type="text" class="form-control mb-3" placeholder="Name of field" />
        <span v-if="fieldType === 'ContactFieldTextModel'">
          <label class="form-label mb-1" for="name">Placeholder</label>
          <input v-model="fieldPlaceholder" id="name" type="text" class="form-control" placeholder="Placeholder of field" />
        </span>
      </form>
      <div class="card-footer d-flex align-items-center justify-content-between">
        <button type="button" v-if="field" @click.prevent="field && deleteCustomField(field.id)" class="btn btn-outline-danger">
          <i class="fe fe-trash me-2"></i>
          Delete field
        </button>
        <button type="button" v-else @click.prevent="step = 1" class="btn btn-outline-secondary d-flex align-items-center justify-content-between">
          <i class="fe fe-chevron-left me-2"></i>
          Back
        </button>
        <button
          type="submit"
          :form="`field-form-${field ? field.id : 'new'}`"
          class="btn btn-primary d-flex align-items-center justify-content-between"
        >
          <i class="fe me-2" :class="[isEditing ? 'fe-edit-2' : 'fe-plus']"></i>
          {{ isEditing ? 'Save changes' : 'Save field' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, PropType, Ref } from 'vue';
  import { useToast } from 'vue-toastification';

  import { ContactFieldTextUpsert, ContactFieldDateUpsert, ContactFieldDelete } from '@/services/GraphqlApi';
  import { ContactFieldTextModel, ContactFieldDateModel } from '@/dto/graphql';
  import { useContactsStore } from '@/stores/contacts';
  import { onClickOutside } from '@vueuse/core';
  import { useDialog } from '@/services/Dialog';

  const createDialog = useDialog();

  const contactStore = useContactsStore();
  const toast = useToast();
  const props = defineProps({
    isEditing: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object as PropType<ContactFieldTextModel | ContactFieldDateModel>,
    },
  });

  const emit = defineEmits(['close']);
  const rootEl = ref({}) as Ref<HTMLDivElement>;

  const fieldType = ref<string | undefined>(props.field ? props.field.__typename : '');
  const fieldName = ref<string>(props.field ? props.field.name : '');
  const fieldPlaceholder = ref<string>(props.field ? ((props.field as ContactFieldTextModel).placeholder as string) ?? '' : '');

  const step = ref(props.field ? 2 : 1);

  onClickOutside(rootEl, (event) => emit('close'));

  const handleSelectType = (type: string) => {
    fieldType.value = type;
    step.value = 2;
  };

  const upsertCustomField = async (name: string, placeholder: string, fieldId?: string) => {
    if (!name || name === '') {
      toast.error('Name is required');
      return;
    }

    try {
      if (fieldType.value === 'ContactFieldTextModel') {
        await ContactFieldTextUpsert(
          {
            name,
            placeholder,
          },
          fieldId,
        );
      } else if (fieldType.value === 'ContactFieldDateModel') {
        await ContactFieldDateUpsert(
          {
            name,
          },
          fieldId,
        );
      }

      await contactStore.fetchContactFields();
      emit('close');
      toast.success(`Field has been ${props.isEditing ? 'edited' : 'added'}`);
    } catch (e) {
      toast.error(`An error happened: ${(e as Error).message}`);
    }
  };

  const deleteCustomField = async (fieldId: string) => {
    const res = await createDialog({
      title: `Delete "${props.field?.name || 'field'}"?`,
      content: `You are about to delete the field "${props.field?.name}". The field and all data in this field on ALL contacts, will be deleted immediately.<br><br>This action is destructible and irreversible.`,
      confirmText: 'Delete field from all contacts',
    });

    if (!res) return;

    try {
      await ContactFieldDelete(fieldId);
      await contactStore.fetchContactFields();
      emit('close');
      toast.success(`Field has been deleted`);
    } catch (e) {
      toast.error(`Deletion of field failed: ${(e as Error).message}`);
    }
  };
</script>
