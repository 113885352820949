<template>
  <div v-if="campaign">
    <div class="container-fluid">
      <div class="header">
        <div class="header-body">
          <div class="row align-items-end">
            <div class="col">
              <h6 class="header-pretitle">Campaign statistics</h6>
              <h1 class="header-title py-2">{{ campaign.campaignName }}</h1>
            </div>

            <div class="col-auto d-flex">
              <button class="btn btn-secondary btn-lg me-3" @click="copyPublicLink">
                <i class="fe fe-link"></i>
              </button>
              <button
                class="btn btn-primary btn-lg dropdown-toggle d-flex align-items-center"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="pe-2">Settings</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row g-3">
        <div class="col-12 col-lg-6 col-xl">
          <div class="card">
            <div class="card-body">
              <h6 class="text-uppercase text-muted mb-2">Recipients</h6>
              <div class="d-flex align-items-center justify-content-between gx-0">
                <p class="h1 mb-2 mt-3">357.142</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <div class="card">
            <div class="card-body">
              <h6 class="text-uppercase text-muted mb-2">Delivery</h6>
              <div class="d-flex align-items-center justify-content-between gx-0">
                <p class="h1 mb-2 mt-3">97.3%</p>
                <div class="badge bg-success">+3.5%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <div class="card">
            <div class="card-body">
              <h6 class="text-uppercase text-muted mb-2">Unsuccessful</h6>
              <div class="d-flex align-items-center justify-content-between gx-0">
                <p class="h1 mb-2 mt-3">2.4%</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <div class="card">
            <div class="card-body">
              <h6 class="text-uppercase text-muted mb-2">Enroute</h6>
              <div class="d-flex align-items-center justify-content-between gx-0">
                <p class="h1 mb-2 mt-3">0.3%</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row g-3">
        <div class="col-3">
          <div class="card">
            <div class="card-body">
              <h6 class="text-uppercase text-muted">Send speed</h6>
              <div class="d-flex align-items-center justify-content-between gx-0">
                <p class="h1 mb-2 mt-3">2hr 26m</p>
                <div class="badge bg-success">47 SMS/s</div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h6 class="text-uppercase text-muted">Total cost</h6>
              <div class="d-flex align-items-center justify-content-between gx-0">
                <p class="h1 mb-2 mt-3">€ 11.423</p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h6 class="text-uppercase text-muted">Top countries</h6>
              <ul class="countries">
                <li class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <img src="/flags-48/dk.svg" class="me-2" alt="" />
                    <p class="m-0">Denmark</p>
                  </div>
                  <p class="bold m-0">82.2%</p>
                </li>
                <li class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <img src="/flags-48/no.svg" class="me-2" alt="" />
                    <p class="m-0">Norway</p>
                  </div>
                  <p class="bold m-0">11.4%</p>
                </li>
                <li class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <img src="/flags-48/se.svg" class="me-2" alt="" />
                    <p class="m-0">Sweden</p>
                  </div>
                  <p class="bold m-0">4.2%</p>
                </li>
                <li class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <img src="/flags-48/us.svg" class="me-2" alt="" />
                    <p class="m-0">U.S.A</p>
                  </div>
                  <p class="bold m-0">2.2%</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="card">
            <div class="card-header">
              <h6 class="text-uppercase text-muted mb-0">Top countries</h6>
            </div>
            <div class="card-body p-0">
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EmptyState v-else text="Campaign not found." />
</template>

<script setup lang="ts">
  import { ref, Ref, onMounted } from 'vue';
  import { useToast } from 'vue-toastification';
  import { useRoute } from 'vue-router';
  import { CampaignModelResult } from '@/dto/graphql';
  import { CampaignGet } from '@/services/GraphqlApi';
  import Map from '@/components/sms-campaign-statistics/Map.vue';
  import EmptyState from '@/components/shared/EmptyState.vue';

  const toast = useToast();
  const route = useRoute();

  const campaign = ref(null) as Ref<CampaignModelResult | null>;

  onMounted(() => {
    const parseId = (id: string | string[]) => (Array.isArray(id) ? id[0] : id);
    const campaignId: string = parseId(route.params.id);

    if (campaignId) {
      CampaignGet(campaignId).then((result) => {
        campaign.value = result;
      });
    } else {
      toast.error('Campaign not found');
    }
  });

  const copyPublicLink = () => {
    toast.info('Public link copied to clipboard', { timeout: 1500 });
  };
</script>

<style lang="scss" scoped>
  ul.countries {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin: 1.2em 0;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
</style>
