<template>
  <SingleMessage type="to" :text="`${inbox?.keyword || 'DONATE'}`" />
  <SingleMessage
    v-if="inbox?.ruleset.requireConfirmation"
    type="from"
    :text="`${
      inbox?.ruleset.initialContent ||
      `Thank you for your support! Please, confirm your donation of ${inbox?.ruleset.paymentPrice || '100'} DKK by replying with ${
        inbox?.keyword || 'DONATE'
      } ${inbox?.ruleset.paymentConfirmWord || 'CONFIRM'}`
    }`"
  />
  <SingleMessage
    v-if="inbox?.ruleset.requireConfirmation"
    type="to"
    :text="`${inbox?.keyword || 'DONATE'} ${inbox?.ruleset.paymentConfirmWord || 'CONFIRM'}`"
  />
  <SingleMessage
    type="from"
    :text="`${
      inbox?.ruleset.paymentContent ||
      `Thank you for your donation of ${inbox?.ruleset.paymentPrice || '100'} DKK! We really appreciate your support.`
    }`"
  />
</template>

<script setup lang="ts">
  import { inject } from 'vue';
  import { SmsInboxModelResult, SmsInboxRulesetPaymentFixedModel } from '@/dto/graphql';
  import SingleMessage from './SingleMessage.vue';

  type TInbox = SmsInboxModelResult & {
    ruleset: SmsInboxRulesetPaymentFixedModel;
  };

  const inbox = inject<TInbox>('inbox');
</script>
