import { parsePhoneNumberFromString } from 'libphonenumber-js';
import countries from './Countries';

export function getFlagByPhoneNumber(phoneNumber: string) {
  const parsed = parsePhoneNumberFromString(`+${phoneNumber.replace(/\D+/, '')}`);
  if (!parsed || !parsed.country) {
    return '/flags-48/flag-default.svg';
  }

  return `/flags-48/${parsed.country.toLowerCase()}.svg`;
}

export function getFlagByNationalNumber(nationalNumber: number) {
  const foundCountry = countries.find((country) => country.prefix === nationalNumber);
  if (!foundCountry) {
    return '/flags-48/flag-default.svg';
  }

  return `/flags-48/${foundCountry.id.toLowerCase()}.svg`;
}

export function getCountryByNationalNumber(nationalNumber: number) {
  const foundCountry = countries.find((country) => country.prefix === nationalNumber);
  if (!foundCountry) {
    return null;
  }

  return foundCountry;
}

export function getPhoneParts(phoneNumber: string) {
  const parsed = parsePhoneNumberFromString(`+${phoneNumber.replace(/\D+/, '')}`);
  if (!parsed) return ['', ''];

  return [parsed?.countryCallingCode, parsed?.nationalNumber];
}
