<template>
  <div class="card card-fill">
    <div class="card-header">
      <h4 class="card-header-title d-flex align-items-center">Incoming messages</h4>
      <div class="dropdown">
        <button
          class="btn btn-outline-secondary btn-rounded-circle dropdown-toggle me-n2"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        ></button>
        <ul class="dropdown-menu">
          <li>
            <a @click="exportMessages()" class="dropdown-item list-group-item-action d-flex py-3 text-secondary" href="#">
              <i class="fe fe-download-cloud me-3"></i>Export to spreadsheet
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="position-relative mt-0 overflow-auto flex-grow-1 d-flex">
      <div class="inner position-absolute overflow-auto top-0 start-0 h-100 w-100">
        <div class="table-responsive-sm table-responsive-md">
          <table class="table">
            <thead>
              <tr>
                <th style="width: 20%; min-width: 200px">Sender number</th>
                <th style="width: 80%; min-width: 140px">Message</th>
              </tr>
            </thead>
            <tbody v-if="messages && messages.length > 0">
              <MessageListRow
                v-for="message in messages"
                :key="message.id"
                :message="message"
                @click="
                  $emit('selectMessage', {
                    id: message.id,
                    MSISDN: message.senderNumber,
                  })
                "
                :selected="selectedMessage === message.id"
              />
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4" class="text-center">
                  <Loading v-if="loadingMessages" />
                  <EmptyState v-else-if="!currentInbox" text="No inbox selected." />
                  <EmptyState v-else-if="messages.length === 0" text="No messages in selected inbox." />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="totalCount > itemsPerPage" class="card-footer card-footer-boxed">
      <nav aria-label="Campaign pagination">
        <ul class="pagination justify-content-center m-0">
          <li class="page-item" :class="{ disabled: currentPage === 0 }">
            <a @click="changePage(currentPage - 1)" class="page-link" href="#" :aria-disabled="currentPage === 0">Previous</a>
          </li>
          <li v-for="(page, index) in totalPages" :key="index" class="page-item" :class="{ active: currentPage === index }">
            <a @click="changePage(index)" class="page-link" href="#">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage + 1 === totalPages }">
            <a @click="changePage(currentPage + 1)" class="page-link" :aria-disabled="currentPage + 1 === totalPages" href="#">Next</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
  import MessageListRow from '@/components/sms-inbox-list/MessageListRow.vue';
  import EmptyState from '@/components/shared/EmptyState.vue';
  import Loading from '@/components/shared/Loading.vue';
  import { SmsInboxModelResult, SmsIncomingModelResult } from '@/dto/graphql';
  import exportFromJSON from 'export-from-json';
  import { SmsIncomingList } from '@/services/GraphqlApi';

  const props = defineProps<{
    messages: SmsIncomingModelResult[];
    loadingMessages: boolean;
    currentInbox: SmsInboxModelResult | null;
    currentPage: number;
    totalCount: number;
    totalPages: number;
    itemsPerPage: number;
    selectedMessage: string;
  }>();
  const emit = defineEmits(['update:currentPage', 'selectMessage']);

  const changePage = (page: number) => {
    emit('update:currentPage', page);
  };

  const getInboxMessages = async () => {
    return SmsIncomingList(props.currentInbox?.id || '', 10000);
  };

  const exportMessages = async () => {
    const { results: allMessages } = await getInboxMessages();
    const data = allMessages.map((message) => ({
      senderNumber: message.senderNumber,
      message: message.message,
      createdAt: message.createdAt,
    }));

    exportFromJSON({
      data,
      fileName: `${props.currentInbox?.number}_${props.currentInbox?.keyword === '*' ? 'catchall' : props.currentInbox?.keyword}_messages`,
      exportType: 'csv',
    });
  };
</script>

<style scoped></style>
