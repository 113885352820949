<template>
  <div>
    <h4>Scheduling</h4>
    <p>Here you can define a future date for the campaign to start sending.</p>
    <div v-if="sendStatus !== SendStatus.Sending">
      <div class="form-check form-switch">
        <input class="form-check-input" v-model="sendNow" type="checkbox" role="switch" id="sendTime" />
        <label class="form-check-label" for="sendTime">
          {{ sendNow ? 'Send immediately' : 'Send in the future' }}
        </label>
      </div>

      <div class="mt-4" v-if="!sendNow">
        <label class="form-control-label mb-1">Send at</label>
        <Datepicker
          id="sendTimeEl"
          v-model="sendTime"
          :timezone="timezone"
          :min-date="new Date()"
          :dark="isDarkMode"
          placeholder="Select future date"
          :month-change-on-scroll="false"
        ></Datepicker>
      </div>
    </div>
    <div v-else class="alert alert-warning" role="alert">You cannot change the send time while your campaign is currently running.</div>
  </div>
</template>

<style>
  .dp__button:after {
    content: 'Pick time';
    display: block;
    margin-left: 15px;
  }
</style>

<script lang="ts" setup>
  import { computed, inject, Ref, ref, watch } from 'vue';
  import Datepicker from '@vuepic/vue-datepicker';
  import { Theme, currentTheme } from '@/services/Theme';
  import { SendStatus } from '@/dto/graphql';
  import { isPast } from 'date-fns';

  const isDarkMode = computed(() => currentTheme.value === Theme.Dark);
  const sendStatus = inject('sendStatus') as Ref<string>;

  const emit = defineEmits(['update:modelValue']);

  const props = defineProps({
    modelValue: {
      type: Date,
      required: true,
    },
    timezone: {
      type: String,
      required: false,
      default: 'UTC',
    },
  });
  const sendTime = ref(new Date(props.modelValue));

  const sendNow = ref(isPast(sendTime.value)) as Ref<boolean>;

  watch(sendTime, (newValue) => {
    if (newValue && isPast(newValue)) {
      sendNow.value = true;
    }

    emit('update:modelValue', newValue);
  });

  watch(sendNow, () => {
    sendTime.value = new Date();
  });
</script>
