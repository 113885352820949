<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title me-auto">Campaign Tags</h4>
      <InfoTooltip tooltip="You can associate tags to help you organize your campaigns. A campaign may have multiple tags."></InfoTooltip>
    </div>

    <div class="card-body">
      <form @submit.prevent="addTag">
        <div class="form-control d-flex flex-wrap align-items-center p-2">
          <a
            v-for="tag of allTagsSorted"
            :key="tag.id"
            href="#"
            class="badge m-1 py-2"
            :class="{ 'bg-light': !tagSelected(tag), 'bg-secondary': tagSelected(tag) }"
            @click="toggleSelected(tag)"
          >
            <i class="me-1 fe" :class="{ 'fe-square': !tagSelected(tag), 'fe-check-square': tagSelected(tag) }"></i>
            {{ tag.name }}
          </a>

          <span class="flex-fill w-auto mx-1 my-0 d-flex" style="min-width: 155px">
            <input
              v-model.trim="inputNew"
              maxlength="25"
              class="form-control form-control-flush form-control-sm me-2 tag-editor"
              placeholder="Add new tag"
              @focus="focusAddNewTag = true"
              @blur="focusAddNewTag = false"
              :readonly="submitting"
            />
            <button v-if="(focusAddNewTag || inputNew) && inputNew.length > 0" class="btn btn-outline-secondary btn-sm" :disabled="submitting">
              <i class="fe fe-plus"></i>
            </button>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .tag-editor {
    font-size: 0.9375rem;
    color: #b1c2d9;
    @media screen and (max-width: 767px) {
      font-size: 17px;
    }
  }
</style>

<script lang="ts" setup>
  import { computed, inject, ref, Ref } from 'vue';
  import { CampaignTagModelResult } from '@/dto/graphql';
  import _ from 'lodash';
  import { CampaignTagUpsert } from '@/services/GraphqlApi';
  import { useToast } from 'vue-toastification';
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';

  const toast = useToast();

  const allTags = inject('allCampaignTags') as Ref<CampaignTagModelResult[]>;
  const selectedTags = inject('selectedTags') as Ref<string[]>;
  const focusAddNewTag = ref(false);

  const inputNew = ref('');
  const submitting = ref(false);

  // Sorted by name (ASC)
  const allTagsSorted = computed(() => {
    const withLowerName = _.map(allTags.value, (t: CampaignTagModelResult) => {
      return { ...t, nameLower: t.name.toLocaleLowerCase() };
    });
    return _.sortBy(withLowerName, ['nameLower']);
  }) as Ref<CampaignTagModelResult[]>;

  const addTag = async () => {
    const name = inputNew.value;
    submitting.value = true;

    class Warning extends Error {}
    try {
      if (!inputNew.value) throw new Warning('The field is blank. The tag name cannot be empty.');
      const existingTag = allTags.value.find((t) => t.name === name);
      if (existingTag) throw new Warning('A tag with the same name already exists. Please pick a unique name.');

      const id = await CampaignTagUpsert({
        name,
      });
      const newTagObj = { id, name, totalCount: 0, totalDraftCount: 0, totalScheduledCount: 0, totalSentCount: 0 };
      allTags.value.push(newTagObj);
      toggleSelected(newTagObj);
      inputNew.value = '';
    } catch (e) {
      if (e instanceof Warning) {
        toast.warning(e.message);
      } else {
        toast.error((e as Error).message);
      }
    }

    submitting.value = false;
  };

  const tagSelected = (tag: CampaignTagModelResult) => {
    return selectedTags.value.includes(tag.id);
  };

  const toggleSelected = (tag: CampaignTagModelResult) => {
    const globalTag = allTags.value.find((t) => t.id === tag.id);
    if (!globalTag) return;

    if (tagSelected(tag)) {
      _.remove(selectedTags.value, (t) => t === tag.id);
      globalTag.totalCount--;
    } else {
      selectedTags.value.push(tag.id);
      globalTag.totalCount++;
    }
  };
</script>
