<template>
  <div>
    <h4>Send speed</h4>
    <p>
      It is usually a good idea to spread especially large campaigns. This can help ensure that eg. your website does not crash because of a sudden
      surge in traffic.
    </p>
    <label class="mb-2" for="sendSpeed">
      Recipients per second
      <InfoTooltip
        tooltip="How many recipients will receive a complete SMS per second?<br><br>Note that send speed must be a number between 1 and 100."
      ></InfoTooltip>
    </label>
    <input
      class="form-control"
      :class="{ 'is-invalid': error }"
      @input="checkSendSpeed"
      v-model.number="sendSpeed"
      type="number"
      step="1"
      inputmode="numeric"
      min="1"
      max="100"
      id="sendSpeed"
    />
    <small v-if="error" class="form-text text-danger mt-2">Send speed must be a number between 1 and 100.</small>
    <div v-if="!calculation && sendSpeed && sendSpeed !== 0" class="bg-info text-white px-2 py-4 mx-0 mt-3 rounded text-lg row align-items-center">
      <i class="fe fe-info h2 col-1 m-0" />
      <h3 class="col m-0">ETA is {{ calculatedEta }}, based on your current recipients list of {{ recipientCount }} contacts.</h3>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, Ref, ref, ComputedRef, inject } from 'vue';
  import { getMessageStats, SmsStats } from '@/services/SmsStats';
  import { addSeconds, formatDistanceToNow } from 'date-fns';

  import InfoTooltip from '@/components/shared/InfoTooltip.vue';

  const recipientCount = inject('recipientCount') as Ref<number>;
  const message = inject('message') as Ref<string>;

  const stats = computed(() => getMessageStats(message.value)) as ComputedRef<SmsStats>;

  const props = defineProps({
    modelValue: {
      type: Number,
      required: true,
    },
    calculation: {
      type: Boolean,
      default: true,
    },
  });
  const emit = defineEmits(['update:modelValue']);
  const error = ref(false);

  const checkSendSpeed = (v: any) => {
    const value = parseInt(v.target.value);
    if (value < 1 || value > 100 || isNaN(value)) {
      error.value = true;
    } else {
      error.value = false;
    }
  };

  const sendSpeed = computed({
    get: () => props.modelValue,
    set: (value: number) => emit('update:modelValue', Math.ceil(value)),
  });

  const calculatedEta = computed(() => {
    // Calculate ETA
    const seconds = Math.ceil(recipientCount.value / sendSpeed.value);
    return formatDistanceToNow(addSeconds(new Date(), seconds));
  });
</script>

<style scoped></style>
