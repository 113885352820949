export function getOcidUrl(): string {
  switch (window.location.hostname) {
    case 'eu.relationcity.io':
      return 'https://auth.onlinecity.io/relationcity';

    case 'relationcity.ms.ocx.dev':
      return 'https://ocid.ms.ocx.dev/relationcity';

    case 'relationcity.nc.ocx.dev':
      return 'https://ocid.nc.ocx.dev/relationcity';

    case 'thor.relationcity.dev':
      return 'https://auth.tl.ocx.dev/relationcity';

    default:
      return 'https://auth.onlinecity.dev/relationcity';
  }
}

export function getOcidAccountUrl(accountId?: string): string {
  const url = (url: string) => `${url}/relationcity/account${accountId ? '?id=' + accountId : ''}`;
  switch (window.location.hostname) {
    case 'eu.relationcity.io':
      return url('https://auth.onlinecity.io');

    case 'relationcity.ms.ocx.dev':
      return url('https://ocid.ms.ocx.dev');

    case 'relationcity.nc.ocx.dev':
      return url('https://ocid.nc.ocx.dev');

    case 'thor.relationcity.dev':
      return url('https://auth.tl.ocx.dev');

    default:
      return url('https://auth.onlinecity.dev');
  }
}
