<template>
  <div class="card card-fill">
    <div class="card-header">
      <h4 class="card-header-title d-flex align-items-center">
        Inboxes
        <InfoTooltip tooltip="Click to select which inbox you wish to see messages for." />
      </h4>
    </div>
    <div class="card-body tags-list-body position-relative mt-0 overflow-auto flex-grow-1 d-flex">
      <div v-if="inboxes && inboxes.length > 0" class="inner position-absolute overflow-auto top-0 start-0 h-100 w-100">
        <ul class="list-group list-group-flush border-bottom border-light">
          <li
            v-for="inbox of inboxes"
            class="list-group-item list-group-item-action py-3 px-4 d-flex justify-content-between align-items-center"
            :class="{ active: inbox.id === currentInbox?.id }"
            :key="inbox.id"
            @mousedown.prevent="currentInbox = inbox"
          >
            <div class="me-auto">
              <p class="d-block my-0">{{ inbox.number }} - {{ inbox.keyword == '*' ? 'Catch-all' : inbox.keyword }}</p>
              <span class="badge bg-info rounded-pill me-2" v-tooltip="inbox.ruleset.__typename && typeTooltip[inbox.ruleset.__typename]">
                {{ inbox.ruleset.__typename && typeNames[inbox.ruleset.__typename] }}
              </span>
              <span
                class="badge rounded-pill me-2"
                :class="inbox.ruleset.active ? 'bg-success' : 'bg-warning'"
                v-tooltip="
                  inbox.ruleset.active ? 'The inbox is active and can receive messages.' : 'The inbox is inactive and cannot receive messages.'
                "
              >
                {{ inbox.ruleset.active ? 'Active' : 'Inactive' }}
              </span>
            </div>
            <button
              aria-haspopup="true"
              class="btn btn-sm btn-rounded-circle btn-white"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fe fe-more-vertical"></i>
            </button>
            <div role="menu" class="dropdown-menu">
              <button
                @click="router.push({ name: 'SmsInboxEditor', params: { id: inbox.id } })"
                role="menuitem"
                class="dropdown-item list-group-item-action d-flex py-3 text-secondary"
              >
                <i class="fe fe-edit-3 me-3"></i>
                Edit
              </button>
              <button
                @click="toggleActivate(inbox.ruleset)"
                role="menuitem"
                class="dropdown-item list-group-item-action d-flex py-3"
                :class="inbox.ruleset.active ? 'text-danger' : 'text-success'"
              >
                <i class="fe me-3" :class="inbox.ruleset.active ? 'fe-x' : 'fe-check'"></i>
                {{ inbox.ruleset.active ? 'Deactivate' : 'Activate' }}
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div v-else class="align-self-center w-100">
        <EmptyState text="No inboxes found." />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .list-group-item {
    cursor: pointer;
  }
  @media (max-width: 991px) {
    .tags-list-body {
      padding: 0 !important;

      .inner {
        position: relative !important;
        height: auto;
        max-height: 75vh;
      }
    }
  }
</style>

<script lang="ts" setup>
  import { inject, ref } from 'vue';
  import {
    SmsInboxModelResult,
    SmsInboxRulesetAutoreplyModel,
    SmsInboxRulesetAutoreplyMultipleModel,
    SmsInboxRulesetPaymentDynamicModel,
    SmsInboxRulesetPaymentFixedModel,
  } from '@/dto/graphql';
  import {
    SmsInboxList,
    SmsInboxRulesetAutoreplyUpsert,
    SmsInboxRulesetPaymentFixedUpsert,
    SmsInboxRulesetPaymentDynamicUpsert,
    SmsInboxRulesetAutoreplyMultipleUpsert,
  } from '@/services/GraphqlApi';
  import { useRouter } from 'vue-router';

  import EmptyState from '@/components/shared/EmptyState.vue';
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';
  import vTooltip from '@/directives/vTooltip';

  const router = useRouter();

  const currentInbox = inject('currentInbox', ref(null as SmsInboxModelResult | null));
  const inboxes = inject('inboxes', ref([] as TInbox[]));
  const itemsPerPage = inject('itemsPerPage') as number;

  const emit = defineEmits(['reload']);

  const typeNames = {
    SmsInboxRulesetAutoreplyModel: 'Simple',
    SmsInboxRulesetAutoreplyMultipleModel: 'Multiple',
    SmsInboxRulesetPaymentDynamicModel: 'Payment/Donation',
    SmsInboxRulesetPaymentFixedModel: 'Payment/Donation',
  };

  const typeTooltip = {
    SmsInboxRulesetAutoreplyModel: 'Replies with a message to the sender.',
    SmsInboxRulesetPaymentDynamicModel: 'Allows the sender to pay or donate.',
    SmsInboxRulesetPaymentFixedModel: 'Allows the sender to pay or donate.',
  };

  type TInbox = SmsInboxModelResult & {
    ruleset: SmsInboxRulesetAutoreplyModel | SmsInboxRulesetPaymentDynamicModel | SmsInboxRulesetPaymentFixedModel;
  };

  SmsInboxList(itemsPerPage)
    .then((res) => {
      inboxes.value = res.results as TInbox[];
      currentInbox.value = res.results[0] ?? null;
    })
    .catch((err) => {
      console.error(err);
    });

  const toggleActivate = (
    ruleset:
      | SmsInboxRulesetAutoreplyModel
      | SmsInboxRulesetAutoreplyMultipleModel
      | SmsInboxRulesetPaymentDynamicModel
      | SmsInboxRulesetPaymentFixedModel,
  ) => {
    if (ruleset.__typename === 'SmsInboxRulesetAutoreplyModel') {
      SmsInboxRulesetAutoreplyUpsert(
        {
          activeFrom: ruleset.activeFrom,
          activeTo: ruleset.activeTo,
          sender: ruleset.sender,
          replyWithMessage: ruleset.replyWithMessage,
          replyContent: ruleset.replyContent,
          active: !ruleset.active,
        },
        ruleset.id,
      )
        .then(() => {
          emit('reload');
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel') {
      SmsInboxRulesetAutoreplyMultipleUpsert(
        {
          activeFrom: ruleset.activeFrom,
          activeTo: ruleset.activeTo,
          sender: ruleset.sender,
          keywords: ruleset.keywords,
          errorMessage: ruleset.errorMessage,
          sendErrorMessage: ruleset.sendErrorMessage,
          active: !ruleset.active,
        },
        ruleset.id,
      )
        .then(() => {
          emit('reload');
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel') {
      SmsInboxRulesetPaymentFixedUpsert(
        {
          activeFrom: ruleset.activeFrom,
          activeTo: ruleset.activeTo,
          sender: ruleset.sender,
          active: !ruleset.active,
          errChargeContent: ruleset.errChargeContent,
          errInvalidContent: ruleset.errInvalidContent,
          paymentContent: ruleset.paymentContent,
          paymentPrice: ruleset.paymentPrice,
          requireConfirmation: ruleset.requireConfirmation,
        },
        ruleset.id,
      )
        .then(() => {
          emit('reload');
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel') {
      SmsInboxRulesetPaymentDynamicUpsert(
        {
          activeFrom: ruleset.activeFrom,
          activeTo: ruleset.activeTo,
          sender: ruleset.sender,
          active: !ruleset.active,
          errChargeContent: ruleset.errChargeContent,
          errInvalidContent: ruleset.errInvalidContent,
          paymentContent: ruleset.paymentContent,
          requireConfirmation: ruleset.requireConfirmation,
          paymentPriceMax: ruleset.paymentPriceMax,
          paymentPriceMin: ruleset.paymentPriceMin,
        },
        ruleset.id,
      )
        .then(() => {
          emit('reload');
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
</script>
