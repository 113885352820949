<template>
  <div class="card card-fill-lg">
    <div class="card-header">
      <h4 class="card-header-title d-flex align-items-center">
        Campaign Tags
        <InfoTooltip tooltip="Click to select which tags you wish to see campaigns connected to." />
      </h4>
    </div>
    <div class="card-body tags-list-body position-relative mt-0 overflow-auto flex-grow-1 d-flex">
      <div class="inner position-absolute overflow-auto top-0 start-0 h-100 w-100" v-if="campaignTags.length">
        <ul class="list-group list-group-flush border-bottom border-light">
          <li
            class="list-group-item list-group-item-action py-3 px-4 d-flex justify-content-between align-items-start"
            :class="{ active: !currentTag }"
            @mousedown.prevent="currentTag = ''"
          >
            <div class="me-auto" :class="{ 'text-focus': currentTag }">- All campaigns -</div>
          </li>

          <li
            v-for="tag of campaignTagsSorted"
            class="list-group-item list-group-item-action py-3 px-4 d-flex justify-content-between align-items-center"
            :class="{ active: tag.id === currentTag }"
            :key="tag.id"
            @mousedown.prevent="currentTag = tag.id"
          >
            <div class="me-auto">
              <p class="d-block my-0">
                {{ tag.name }}
              </p>
              <span>
                <span class="badge bg-info rounded-pill me-2" v-tooltip="'Queued campaigns'">
                  {{ tag.totalScheduledCount }}
                </span>
                <span class="badge bg-light rounded-pill me-2" v-tooltip="'Draft campaigns'">
                  {{ tag.totalDraftCount }}
                </span>
                <span class="badge bg-success rounded-pill me-2" v-tooltip="'Completed campaigns'">
                  {{ tag.totalSentCount }}
                </span>
              </span>
            </div>
            <button
              aria-haspopup="true"
              class="btn btn-sm btn-rounded-circle btn-white"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fe fe-more-vertical"></i>
            </button>
            <div role="menu" class="dropdown-menu">
              <button @click.prevent="renameTag(tag.id)" role="menuitem" class="dropdown-item list-group-item-action d-flex py-3 text-secondary">
                <i class="fe fe-edit-3 me-3"></i>
                Rename tag
              </button>
              <button role="menuitem" @click.prevent="deleteTag(tag.id)" class="dropdown-item list-group-item-action d-flex py-3 text-danger">
                <i class="fe fe-trash me-3"></i>
                Delete tag
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div v-else class="align-self-center w-100">
        <div class="alert alert-light text-muted d-flex align-items-center mb-0" role="alert">
          <div>
            You have not created any tags yet. You create tags directly on a campaign.<br /><br />
            Tags help organize and group campaigns.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .list-group-item {
    cursor: pointer;
  }
  @media (max-width: 991px) {
    .tags-list-body {
      padding: 0 !important;

      .inner {
        position: relative !important;
        height: auto;
        max-height: 75vh;
      }
    }
  }
</style>

<script lang="ts" setup>
  import { computed, inject, Ref } from 'vue';
  import { CampaignTagModelResult } from '@/dto/graphql';
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';
  import vTooltip from '@/directives/vTooltip';
  import _ from 'lodash';
  import { useToast } from 'vue-toastification';
  import { CampaignTagDelete, CampaignTagList, CampaignTagUpsert } from '@/services/GraphqlApi';
  import { useDialog } from '@/services/Dialog';

  const createDialog = useDialog();

  const toast = useToast();

  const campaignTags = inject('campaignTags') as Ref<CampaignTagModelResult[]>;
  const currentTag = inject('currentTag') as Ref<string>;

  const emit = defineEmits(['reload']);

  CampaignTagList().then((tags) => {
    campaignTags.value = tags;
  });

  // Sorted by totalCount (DESC) then name (ASC)
  const campaignTagsSorted = computed(() => {
    const withLowerName = _.map(campaignTags.value, (t: CampaignTagModelResult) => {
      return { ...t, nameLower: t.name.toLocaleLowerCase() };
    });
    const sortedA = _.reverse(_.sortBy(withLowerName, ['nameLower']));
    const sortedB = _.sortBy(sortedA, ['totalCount']);
    return _.reverse(sortedB);
  }) as Ref<CampaignTagModelResult[]>;

  const renameTag = async (tagId: string) => {
    // find tag
    const tag = campaignTags.value.find((t) => t.id === tagId);
    if (!tag) return toast.error('The tag does not seem to exist.');

    const res = await createDialog({
      title: 'Rename tag',
      content: 'Please enter a new name for the tag.',
      showInput: true,
      defaultInputValue: tag.name,
      inputLabel: 'Tag name',
      inputPlaceholder: 'Tag name',
      inputValidator(value: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
          if (!value) {
            reject('Tag name cannot be empty.');
          }
          resolve(null);
        });
      },
    });

    //If OK, fire rename mutation
    if (res) {
      try {
        await CampaignTagUpsert(
          {
            name: res as string,
          },
          tag.id,
        );
        toast.success('The tag has been renamed.');
        emit('reload');
      } catch (e) {
        return toast.error(`An error occured: ${(e as Error).message}`);
      }
    }
  };

  const deleteTag = async (tagId: string) => {
    // find tag
    const tag = campaignTags.value.find((t) => t.id === tagId);
    if (!tag) return toast.error('The tag does not seem to exist.');

    const res = await createDialog({
      title: 'Delete tag',
      content: 'Are you sure you want to delete this tag?',
      confirmText: 'Delete',
    });

    if (!res) return;

    // delete!
    try {
      await CampaignTagDelete(tagId);
      currentTag.value = '';
      _.remove(campaignTags.value, (t) => t.id === tagId);
      emit('reload');
    } catch (e) {
      return toast.error(`An error occured: ${(e as Error).message}`);
    }
  };
</script>
