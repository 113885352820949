<template>
  <div class="bg-dark">
    <div class="container min-vh-100 d-flex justify-content-center align-items-center">
      <div class="row justify-content-center">
        <div class="welcome-container">
          <div class="text-center">
            <img src="@/assets/logo-RelationCity-white.svg" class="w-50 mb-5" alt="RelationCity" />
            <div class="d-flex align-items-center justify-content-center gap-2" ref="steps">
              <i
                v-for="(step, index) in 3"
                :key="index"
                class="fe"
                :class="
                  currentStep > step
                    ? 'fe-check-circle text-success'
                    : currentStep === step
                    ? 'fe-arrow-down-circle text-light'
                    : 'fe-circle text-muted'
                "
              />
            </div>
          </div>

          <div class="card mt-4">
            <CreateAccount @step-change="(step) => changeStep(step)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import CreateAccount from '@/components/account/CreateAccount.vue';

  const currentStep = ref(1);

  const changeStep = (step: number) => {
    currentStep.value = step;
  };

  /*
  onMounted(async () => {
    if (userAccounts.length > 0) {
      await router.push('/');
    }
  });
  */
</script>

<style scoped lang="scss">
  .welcome-container {
    width: 100%;
    max-width: 500px;
  }
</style>
