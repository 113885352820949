<template>
  <div class="d-flex justify-content-center">
    <button type="button" class="btn btn-link px-3" :disabled="currentPage == 0" @click="updatePage(0)">&laquo;</button>
    <button type="button" class="btn btn-link px-3" :disabled="currentPage == 0" @click="updatePage(currentPage - 1)">Previous</button>
    <button
      v-for="(page, i) in visiblePages"
      :key="i"
      type="button"
      class="btn btn-link px-3"
      :class="{ 'text-reset fw-bold text-decoration-underline': currentPage == page - 1 }"
      :disabled="currentPage == page - 1"
      @click="updatePage(page - 1)"
    >
      {{ page }}
    </button>
    <button type="button" class="btn btn-link px-3" :disabled="currentPage + 1 == pageCount" @click="updatePage(currentPage + 1)">Next</button>
    <button type="button" class="btn btn-link px-3" :disabled="currentPage + 1 == pageCount" @click="updatePage(pageCount - 1)">&raquo;</button>
  </div>
</template>

<script setup lang="ts">
  import { computed, Ref } from 'vue';

  const props = defineProps({
    totalItems: {
      type: Number,
      default: 0,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 0,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 0,
      required: true,
    },
  });

  const emit = defineEmits(['page-change']);

  const updatePage = (page: number) => {
    emit('page-change', page);
  };

  const pageCount = computed(() => {
    return Math.ceil(props.totalItems / props.pageSize);
  }) as Ref<number>;

  const visiblePages = computed(() => {
    let numShown = 5; // This sets the number of page tabs
    numShown = Math.min(numShown, pageCount.value);
    let first = props.currentPage - Math.floor(numShown / 3);
    first = Math.max(first, 1);
    first = Math.min(first, pageCount.value - numShown + 1);
    return [...Array(numShown)].map((k, i) => i + first);
  });
</script>

<style scoped></style>
