<template>
  <PaymentInitial />
  <PaymentConfirmation v-if="inbox.ruleset.requireConfirmation" />
  <PaymentChargeError />
  <PaymentInvalidContentError />
  <PaymentInitialSmsMissingError v-if="inbox.ruleset.requireConfirmation" />
  <PaymentNoConfirmationError v-if="inbox.ruleset.requireConfirmation" />
</template>

<script setup lang="ts">
  import { inject, Ref } from 'vue';
  import { SmsInboxRulesetPaymentDynamicModel, SmsInboxRulesetPaymentFixedModel, SmsInboxModel } from '@/dto/graphql';
  import PaymentInitial from '../elements/PaymentInitial.vue';
  import PaymentConfirmation from '../elements/PaymentConfirmation.vue';
  import PaymentChargeError from '../elements/PaymentChargeError.vue';
  import PaymentInvalidContentError from '../elements/PaymentInvalidContentError.vue';
  import PaymentInitialSmsMissingError from '../elements/PaymentInitialSmsMissingError.vue';
  import PaymentNoConfirmationError from '../elements/PaymentNoConfirmationError.vue';

  type TInbox = SmsInboxModel & {
    ruleset: SmsInboxRulesetPaymentFixedModel | SmsInboxRulesetPaymentDynamicModel;
  };

  const inbox = inject('inbox') as Ref<TInbox>;
</script>
