<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10">
        <Header title="Configure your settings" sub-title="General Settings" />

        <Notification type="info"> Manage your general settings such as time zone and colour mode </Notification>
        <form @submit.prevent="submit" class="card">
          <div class="card-header">
            <h3 class="card-header-title me-auto">Time zone</h3>
          </div>

          <div class="card-body">
            <div class="row mb-5">
              <label for="timeZone" class="col-form-label col-xl-4 col-lg-5 col-md-12 col-sm-5">Choose your time zone</label>

              <div class="col-xl-8 col-lg-7 col-md-12 col-sm-7">
                <select class="form-select" v-model="selectedTimezone" id="timeZone">
                  <option v-for="timeZone in timeZones" :key="timeZone">{{ timeZone }}</option>
                </select>
                <small v-if="timezoneError" class="text-danger">{{ timezoneError }}</small>
              </div>
            </div>
            <div class="row">
              <label for="colourMode" class="col-form-label col-xl-4 col-lg-5 col-md-12 col-sm-5">Choose your preferred colour mode </label>
              <div class="col-xl-8 col-lg-7 col-md-12 col-sm-7">
                <button
                  href="#"
                  @click.prevent="setTheme(Theme.Dark)"
                  class="btn btn-rounded px-4 me-3 btn-outline-secondary"
                  :class="{ activedark: currentTheme === Theme.Dark }"
                  title="Join the dark side"
                >
                  <i class="fe fe-moon"></i>
                </button>
                <button
                  @click.prevent="setTheme(Theme.Light)"
                  href="#"
                  class="btn btn-rounded btn-outline-secondary px-4"
                  :class="{ activelight: currentTheme === Theme.Light }"
                  title="Let there be light"
                >
                  <i class="fe fe-sun"></i>
                </button>
                <div v-if="currentTheme === Theme.Light">
                  <p class="text-secondary mt-3">Light mode is active</p>
                </div>
                <div v-if="currentTheme === Theme.Dark">
                  <p class="text-secondary mt-3">Dark mode is active</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-end">
            <button class="btn btn-primary" :disabled="isSubmitting">Save changes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .btn-outline-secondary.activedark {
    background-color: #12263f;
    color: #fff;
  }
  .btn-outline-secondary.activelight {
    background-color: #ffbc00;
    color: #fff;
  }
</style>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import { currentTheme, setTheme, Theme } from '@/services/Theme';
  import Notification from '@/components/shared/Notification.vue';
  import Header from '@/components/shared/Header.vue';
  import { useField, useForm } from 'vee-validate';
  import { accountSettingsGet, accountSettingsUpdate } from '@/services/GraphqlApi';
  import * as yup from 'yup';
  import { useToast } from 'vue-toastification';
  import { useDialog } from '@/services/Dialog';
  import { useSettingsStore } from '@/stores/settings';
  import { AccountSettingsModel } from '@/dto/graphql';

  const createDialog = useDialog();
  const toast = useToast();
  const settingsStore = useSettingsStore();
  const { handleSubmit, setValues, isSubmitting } = useForm();

  const timeZones = (Intl as any).supportedValuesOf('timeZone');
  const { value: selectedTimezone, errorMessage: timezoneError } = useField('timezone', yup.string().required('Time zone is required'));
  const oldTimezone = ref('');

  const submit = handleSubmit(async (values) => {
    if (values.timezone !== oldTimezone.value) {
      const res = await createDialog({
        title: `Changing timezone`,
        content: `You are about to change the timezone of your account from ${oldTimezone.value} to ${values.timezone}. Make sure to check and change the time of your events accordingly`,
        confirmText: 'Save changes',
        cancelText: 'Cancel',
      });
      if (!res) return;
    }

    await settingsStore
      .updateSettings(values as AccountSettingsModel)
      .then(async () => {
        toast.success('Settings updated successfully', {
          timeout: 3000,
        });
        oldTimezone.value = values.timezone;
      })
      .catch((error) => {
        toast.error(error.message, {
          timeout: 3000,
        });
      });
  });

  onMounted(async () => {
    await settingsStore.getSettings().then((response) => {
      if (!response) return;
      setValues({
        timezone: response.timezone,
      });
      oldTimezone.value = response.timezone;
    });
  });
</script>
