<template>
  <section>
    <h4>What type of inbox would you like to create?</h4>
    <div class="inbox-types-container pt-3 mb-5">
      <div
        class="inbox-types-item text-center p-4 btn-light rounded text-muted border"
        :class="{ 'border-primary shadow': inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel' }"
        @click="selectType('SmsInboxRulesetAutoreplyModel')"
      >
        <h4>Simple</h4>
        <p class="m-0">Allows you to receive messages and optionally send back a text message to users.</p>
      </div>
      <div
        class="inbox-types-item text-center p-4 btn-light rounded text-muted border"
        :class="{ 'border-primary shadow': inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel' }"
        @click="selectType('SmsInboxRulesetAutoreplyMultipleModel')"
      >
        <h4>Multiple</h4>
        <p class="m-0">Allows you to add multiple sub-keywords and define replies for each of them.</p>
      </div>
      <div
        class="inbox-types-item text-center p-4 btn-light rounded text-muted border"
        :class="{
          'border-primary shadow':
            inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel' || inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel',
        }"
        @click="selectType('SmsInboxRulesetPaymentFixedModel')"
      >
        <h4>Donation/payments</h4>
        <p class="m-0">Allows you to receive and handle donations and payments via text messages.</p>
      </div>
    </div>
    <div v-if="inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel' || inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel'">
      <h4>What type of payments would you like?</h4>
      <div class="inbox-types-container pt-3 mb-5">
        <div
          class="inbox-types-item text-center p-4 btn-light rounded text-muted border"
          :class="{ 'border-primary shadow': inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel' }"
          @click="selectType('SmsInboxRulesetPaymentFixedModel')"
        >
          <h4>Fixed Payment</h4>
          <p class="m-0">Receive payments with a fixed amount.</p>
        </div>
        <div
          class="inbox-types-item text-center p-4 btn-light rounded text-muted border"
          :class="{ 'border-primary shadow': inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel' }"
          @click="selectType('SmsInboxRulesetPaymentDynamicModel')"
        >
          <h4>Dynamic Payment</h4>
          <p class="m-0">Receive payments with a variable amount.</p>
        </div>
      </div>
      <label>
        <h4>Would you like to require confirmation?</h4>
        <div class="form-check form-check-inline">
          <label class="form-check-label" for="requireConfirmation1">
            <input
              :value="true"
              v-model="inbox.ruleset.requireConfirmation"
              class="form-check-input"
              type="radio"
              name="requireConfirmation1"
              id="requireConfirmation1"
            />
            Yes, require confirmation
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label" for="requireConfirmation2">
            <input
              :value="false"
              v-model="inbox.ruleset.requireConfirmation"
              class="form-check-input"
              type="radio"
              name="requireConfirmation2"
              id="requireConfirmation2"
            />
            No, charge instantly
          </label>
        </div>
      </label>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { inject, Ref, watch } from 'vue';
  import { addDays } from 'date-fns';
  import {
    SmsInboxModelResult,
    SmsInboxRulesetAutoreplyModel,
    SmsInboxRulesetAutoreplyMultipleModel,
    SmsInboxRulesetPaymentDynamicModel,
    SmsInboxRulesetPaymentFixedModel,
  } from '@/dto/graphql';
  import { useDialog } from '@/services/Dialog';
  import { ShowChatlio } from '@/components/Chatlio.vue';
  const createDialog = useDialog();

  type TInbox = SmsInboxModelResult & {
    ruleset:
      | SmsInboxRulesetAutoreplyModel
      | SmsInboxRulesetAutoreplyMultipleModel
      | SmsInboxRulesetPaymentDynamicModel
      | SmsInboxRulesetPaymentFixedModel;
  };

  type SmsInboxPayment = SmsInboxRulesetPaymentDynamicModel | SmsInboxRulesetPaymentFixedModel;

  const inbox = inject('inbox') as Ref<TInbox>;

  const selectType = (typeName: string) => {
    if (typeName === 'SmsInboxRulesetAutoreplyModel') {
      inbox.value.ruleset = {
        __typename: 'SmsInboxRulesetAutoreplyModel',
        id: inbox.value.ruleset.id || 'new',
        active: inbox.value.ruleset.active || false,
        activeFrom: inbox.value.ruleset.activeFrom || undefined,
        activeTo: inbox.value.ruleset.activeTo || undefined,
        sender: inbox.value.ruleset.sender || '',
        replyWithMessage: true,
        replyContent: '',
      };
    } else if (typeName === 'SmsInboxRulesetAutoreplyMultipleModel') {
      inbox.value.ruleset = {
        __typename: 'SmsInboxRulesetAutoreplyMultipleModel',
        id: inbox.value.ruleset.id || 'new',
        active: inbox.value.ruleset.active || false,
        activeFrom: inbox.value.ruleset.activeFrom || undefined,
        activeTo: inbox.value.ruleset.activeTo || undefined,
        sender: inbox.value.ruleset.sender || '',
        errorMessage: '',
        sendErrorMessage: false,
        keywords: [
          {
            keyword: '',
            replyContent: '',
          },
        ],
      };
    } else if (typeName === 'SmsInboxRulesetPaymentFixedModel') {
      inbox.value.ruleset = {
        __typename: 'SmsInboxRulesetPaymentFixedModel',
        id: inbox.value.ruleset.id || 'new',
        active: inbox.value.ruleset.active || true,
        activeFrom: inbox.value.ruleset.activeFrom || undefined,
        activeTo: inbox.value.ruleset.activeTo || undefined,
        errChargeContent: (inbox.value.ruleset as SmsInboxPayment).errChargeContent,
        errInvalidContent: (inbox.value.ruleset as SmsInboxPayment).errInvalidContent || '',
        errNoConfirmationContent: (inbox.value.ruleset as SmsInboxPayment).errNoConfirmationContent || undefined,
        errNoConfirmationTime: (inbox.value.ruleset as SmsInboxPayment).errNoConfirmationTime || 10,
        errNoInitialContent: (inbox.value.ruleset as SmsInboxPayment).errNoInitialContent || '',
        initialContent: (inbox.value.ruleset as SmsInboxPayment).initialContent || '',
        maxTimeCompletion: (inbox.value.ruleset as SmsInboxPayment).maxTimeCompletion || 15,
        paymentConfirmWord: (inbox.value.ruleset as SmsInboxPayment).paymentConfirmWord || '',
        paymentContent: (inbox.value.ruleset as SmsInboxPayment).paymentContent || '',
        paymentPrice: 100,
        requireConfirmation: (inbox.value.ruleset as SmsInboxPayment).requireConfirmation || true,
        sender: inbox.value.ruleset.sender || '',
      };
    } else if (typeName === 'SmsInboxRulesetPaymentDynamicModel') {
      inbox.value.ruleset = {
        __typename: 'SmsInboxRulesetPaymentDynamicModel',
        id: inbox.value.ruleset.id || 'new',
        active: inbox.value.ruleset.active || true,
        activeFrom: inbox.value.ruleset.activeFrom || undefined,
        activeTo: inbox.value.ruleset.activeTo || undefined,
        errChargeContent: (inbox.value.ruleset as SmsInboxPayment).errChargeContent,
        errInvalidContent: (inbox.value.ruleset as SmsInboxPayment).errInvalidContent || '',
        errNoConfirmationContent: (inbox.value.ruleset as SmsInboxPayment).errNoConfirmationContent || '',
        errNoConfirmationTime: (inbox.value.ruleset as SmsInboxPayment).errNoConfirmationTime || 10,
        errNoInitialContent: (inbox.value.ruleset as SmsInboxPayment).errNoInitialContent || '',
        initialContent: (inbox.value.ruleset as SmsInboxPayment).initialContent || '',
        maxTimeCompletion: (inbox.value.ruleset as SmsInboxPayment).maxTimeCompletion || 15,
        paymentConfirmWord: (inbox.value.ruleset as SmsInboxPayment).paymentConfirmWord || '',
        paymentContent: (inbox.value.ruleset as SmsInboxPayment).paymentContent || '',
        paymentPriceMax: 100,
        paymentPriceMin: 1,
        requireConfirmation: (inbox.value.ruleset as SmsInboxPayment).requireConfirmation || true,
        sender: inbox.value.ruleset.sender || '',
      };
    }
  };

  // Watch the type to show a notifcation if this user chooses a donation type.
  watch(
    () => inbox.value.ruleset.__typename,
    async (newValue, oldValue) => {
      if (oldValue === 'SmsInboxRulesetAutoreplyModel') {
        if (newValue === 'SmsInboxRulesetPaymentFixedModel' || newValue === 'SmsInboxRulesetPaymentDynamicModel') {
          const res = await createDialog({
            title: 'Important: Donation/payments',
            content: `
              You are about to select the donation/payments type. This will not work  unless you have an agreement with GatewayAPI.<br><br>
              Please contact GatewayAPI to get this setup.<br><br>
              Read more about the agreement for donations and payments <a href="https://www.rammeaftalen.dk/rammeaftale-for-mobile-indholds-og-betalingstjenester/operativt-afsnit/" target="_blank">here</a>.<br><br>
              <strong>Do you want to continue?</strong>
            `,
            cancelText: 'Cancel and contact GatewayAPI',
            confirmText: 'Continue',
          });

          if (!res) {
            selectType('SmsInboxRulesetAutoreplyModel');
            ShowChatlio();
          }
        }
      }
    },
  );
</script>

<style scoped lang="scss">
  .inbox-types-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    gap: 1rem;

    .inbox-types-item {
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #f8f9fa;
      }
    }
  }
</style>
