<template>
  <Teleport to="body">
    <div class="modal" tabindex="-1" ref="sendingModalEl">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" v-if="quickSendStatus === QuickSendStatus.Sending">Sending...</h4>
            <h4 class="modal-title" v-if="quickSendStatus === QuickSendStatus.Done">Sending complete!</h4>
          </div>
          <div class="modal-body">
            <div v-if="quickSendStatus === QuickSendStatus.Sending">
              <div class="progress mb-4">
                <div class="progress-bar bg-info" role="progressbar" :style="percentageStyle"></div>
              </div>
            </div>

            <div class="alert alert-info" v-if="quickSendStatus === QuickSendStatus.Sending">
              Please do not close this window while sending is in progress. Closing this window will immediately halt the process.
            </div>

            <div class="alert alert-success" v-if="quickSendStatus === QuickSendStatus.Done">The campaign has been sent!</div>

            <table class="table mb-0">
              <tbody>
                <tr>
                  <th style="width: 33%">Accepted SMS'es:</th>
                  <td style="width: 66%">{{ quickSend.sentMessages }}</td>
                </tr>
                <tr>
                  <th style="width: 33%">Rejected SMS'es:</th>
                  <td style="width: 66%" :class="{ 'text-danger': rejectedCount }">
                    {{ rejectedCount }}
                  </td>
                </tr>
                <tr>
                  <th style="width: 33%">Credit used:</th>
                  <td style="width: 66%">
                    {{ creditsSpent }} <small class="text-muted ms-2">{{ quickSend.currency.value }}</small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer" v-if="quickSendStatus === QuickSendStatus.Done">
            <button type="button" class="btn btn-secondary" @click="emit('close')">Close</button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
  import { computed, inject, onBeforeUnmount, onMounted, Ref, ref } from 'vue';
  import { QuickSend, QuickSendStatus } from '@/services/QuickSend';
  import { Modal } from 'bootstrap';

  const quickSend = inject('quickSend') as QuickSend;
  const quickSendStatus = quickSend.status;
  let sendingModal: Modal;
  const sendingModalEl = ref(null) as any as Ref<HTMLDivElement>;

  const emit = defineEmits(['close']);

  onMounted(() => {
    sendingModal = new Modal(sendingModalEl.value, {
      backdrop: 'static',
      keyboard: false,
    });
    sendingModal.show();

    onBeforeUnmount(async () => {
      sendingModal.hide();
      await new Promise((r) => setTimeout(r, 500));
    });
  });

  const creditsSpent = computed(() => {
    return quickSend.creditsSpent.value.toFixed(1);
  });

  const percentageStyle = computed(() => {
    const amount = Math.max(0, Math.min(1, quickSend.sentMessages.value / quickSend.recipients.value.length));
    return `width: ${amount * 100}%`;
  });

  const rejectedCount = computed(() => {
    let rejected = 0;
    for (const s of Object.values(quickSend.statusRecipients.value)) {
      rejected += s.success ? 0 : 1;
    }
    return rejected;
  });
</script>
