<template>
  <div
    :id="message.id"
    @click="showDetails = !showDetails"
    class="w-75 my-3 rounded"
    :class="[message.type === 'incoming' ? 'float-start' : 'float-end']"
    ref="parent"
  >
    <div class="rounded p-3 message" :class="[message.type === 'incoming' ? 'from' : 'to', showDetails ? 'outline' : '']">
      {{ message.message }}
    </div>
    <div v-if="showDetails" class="d-flex justify-content-between align-items-center mt-1">
      <small class="text-muted">{{
        message.type === 'incoming'
          ? formattedMSISDN(message.from)
          : message.context !== undefined && message.context !== null
          ? contextType[message.context]
          : ''
      }}</small>
      <small class="text-muted" v-tooltip="diff < 7 ? format(new Date(message.date), 'd. MMMM yyyy - HH:mm') : ''">{{ formattedDate }}</small>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { format, formatDistance } from 'date-fns';
  import vTooltip from '@/directives/vTooltip';
  import { useAutoAnimate } from '@formkit/auto-animate/vue';
  import { MessageModel } from '@/dto/graphql';
  import { getPhoneParts } from '@/services/PhoneNumber';

  const contextType: {
    [key: string]: string;
    campaign: string;
    quicksend: string;
  } = {
    campaign: 'Campaign',
    quicksend: 'Quick Send',
  };

  const showDetails = ref(false);
  const [parent] = useAutoAnimate();

  const props = defineProps<{
    message: MessageModel;
  }>();

  const diff = computed(() => {
    const diff = new Date().getTime() - new Date(props.message.date).getTime();
    return diff / (1000 * 3600 * 24);
  });

  const formattedDate = computed(() => {
    if (diff.value < 7) {
      return formatDistance(new Date(props.message.date), new Date(), { addSuffix: true });
    }
    return format(new Date(), 'd. MMMM yyyy - HH:mm');
  });

  // Format MSISDN to (+xx) xxx xxx xxx
  const formattedMSISDN = (MSISDN: string) => {
    const parts: string[] = getPhoneParts(MSISDN);
    return `(+${parts[0]}) ${parts[1]}`;
  };
</script>

<style scoped lang="scss">
  .message {
    outline: 1px solid rgba(44, 123, 229, 0);
    transition: outline 0.5s;
    white-space: break-spaces;

    &.from {
      background-color: #f5f5f5;
      color: #000;
    }

    &.to {
      background-color: var(--bs-blue);
      color: var(--bs-gray-100);
    }

    &.outline {
      outline: 1px solid rgba(44, 123, 229, 0.5);
      transition: outline 0.5s;
    }
  }

  .highlight {
    animation: highlight 3s;
  }

  // animation that fades in and out outline of message
  @keyframes highlight {
    0% {
      box-shadow: 0 0 0 0 rgba(44, 123, 229, 1);
    }
    90% {
      box-shadow: 0 0 0 6px rgba(0, 0, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
</style>
