<template>
  <Teleport to="body">
    <div class="modal fade" tabindex="-1" ref="blockRecipientModalEl">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="staticBackdropLabel">{{ editingId ? 'Update recipient' : 'Block recipient' }}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit="blockRecipient">
              <div class="row mb-3">
                <label class="form-label">
                  Recipient
                  <PhoneNumberInput v-model="recipient"></PhoneNumberInput>
                </label>
                <small v-if="recipientError" class="text-danger">{{ recipientError }}</small>
              </div>
              <div class="row mb-4">
                <label class="form-label" for="note"
                  >Note
                  <textarea ref="note" id="note" v-model="noteMsg" class="form-control" name="note" cols="30" rows="10"></textarea>
                </label>
                <small v-if="noteMsgError" class="text-danger">{{ noteMsgError }}</small>
              </div>
              <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-primary">{{ editingId ? 'Update recipient' : 'Block recipient' }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
  import { onBeforeUnmount, onMounted, Ref, ref } from 'vue';
  import { Modal } from 'bootstrap';
  import * as yup from 'yup';
  import { useForm, useField } from 'vee-validate';
  import { useToast } from 'vue-toastification';

  import PhoneNumberInput from '@/components/shared/PhoneNumberInput.vue';
  import { BlacklistModelResult, BlacklistTypes } from '@/dto/graphql';
  import { BlacklistUpsert } from '@/services/GraphqlApi';

  const props = defineProps<{
    recipientProp?: BlacklistModelResult;
  }>();

  let blockRecipientModal: Modal;
  const blockRecipientModalEl = ref(null) as any as Ref<HTMLDivElement>;

  const { handleSubmit, resetForm } = useForm();
  const toast = useToast();

  const editingId = ref<string | null>(null);
  const editingBlockedAt = ref<Date | null>(null);
  const { value: recipient, errorMessage: recipientError } = useField(
    'recipient',
    yup.string().required('You must enter a recipient').label('Recipient'),
  );
  const { value: noteMsg, errorMessage: noteMsgError } = useField('noteMsg', yup.string().label('Note'));

  const emit = defineEmits(['close', 'success']);

  onMounted(() => {
    if (props.recipientProp) {
      editingId.value = props.recipientProp._id;
      editingBlockedAt.value = props.recipientProp.blockedAt;
      recipient.value = props.recipientProp.recipient;
      noteMsg.value = props.recipientProp.note;
    }

    blockRecipientModal = new Modal(blockRecipientModalEl.value);
    blockRecipientModal.show();

    blockRecipientModalEl.value.addEventListener('hidden.bs.modal', () => {
      emit('close');
    });

    onBeforeUnmount(async () => {
      blockRecipientModal.hide();
      await new Promise((r) => setTimeout(r, 500));
      blockRecipientModal.dispose();
    });
  });

  const blockRecipient = handleSubmit(async (values) => {
    await BlacklistUpsert(
      values.recipient,
      BlacklistTypes.PhoneNumber,
      values.noteMsg,
      editingBlockedAt.value ?? undefined,
      editingId.value ?? undefined,
    )
      .then(() => {
        toast.success(editingId.value ? 'Recipient updated' : 'Recipient blocked', { timeout: 3000 });
        resetForm();
        emit('success');
      })
      .catch((err) => {
        toast.error(`${err}`, { timeout: 3000 });
      });
  });
</script>
