import { computed, ComputedGetter, ComputedRef, Ref, ref, watch, watchEffect } from 'vue';

import { MessageClass, MessageEncoding } from '@/dto/graphql';
import { ConnectionGatewayapi, SmsBulkSend, SmsSend } from '@/services/GraphqlApi';
import { getAccessToken } from '@/services/Authentication';
import { getMessageStats, SmsEncoding } from '@/services/SmsStats';

export enum QuickSendStatus {
  Draft = 'Draft',
  Sending = 'Sending',
  Done = 'Done',
}

export type QuickSendRecipientStatus = {
  [key: string]: {
    messageId: string;
    creditUsed: number;
    success: boolean;
    errorMessage?: string;
  };
};

export type QuickSend = {
  senderName: Ref<string>;
  message: Ref<string>;
  recipients: Ref<string[]>;
  senderClass: Ref<MessageClass>;
  encoding: Ref<MessageEncoding>;
  status: Ref<QuickSendStatus>;
  statusRecipients: Ref<QuickSendRecipientStatus>;
  sentMessages: Ref<number>;
  creditsSpent: Ref<number>;
  currency: Ref<string | null>;
};

export default function useQuickSend(): QuickSend {
  const senderName = ref('');
  const message = ref('');
  const recipients = ref([]) as Ref<string[]>;
  const senderClass = ref(MessageClass.Standard) as Ref<MessageClass>;

  ConnectionGatewayapi().then((res) => {
    if (res) senderName.value = res.defaultSenderId;
  });

  const encoding = computed(() => {
    const stats = getMessageStats(message.value);
    return stats.encoding === SmsEncoding.UCS2 ? MessageEncoding.Ucs2 : MessageEncoding.Utf8;
  }) as ComputedRef<MessageEncoding>;

  return {
    senderName,
    message,
    senderClass,
    encoding,
    recipients,
    status: ref(QuickSendStatus.Draft),
    statusRecipients: ref({}),
    sentMessages: ref(0),
    creditsSpent: ref(0),
    currency: ref(null),
  };
}

export async function sendNow(inst: QuickSend): Promise<boolean> {
  if (!inst.recipients.value.length) return false;
  if (inst.status.value !== QuickSendStatus.Draft) throw new Error('The QuickSend-instance is not "Draft".');
  inst.status.value = QuickSendStatus.Sending;

  const accessToken = await getAccessToken();
  if (!accessToken) throw new Error('Access token missing.');

  const { recipients, status, senderName, message, encoding, senderClass, statusRecipients, sentMessages, creditsSpent, currency } = inst;

  const batchSize = 500;

  for (let i = 0; i < recipients.value.length; i += batchSize) {
    const batchRecipients = recipients.value.slice(i, i + batchSize);
    try {
      const result = await SmsBulkSend(batchRecipients, senderName.value, message.value, accessToken, encoding.value, senderClass.value);

      statusRecipients.value[batchRecipients.length] = {
        success: true,
        creditUsed: Number(statusRecipients.value.creditUsed),
        messageId: '',
      };
      sentMessages.value += batchRecipients.length;
      creditsSpent.value += parseFloat(result.creditUsed);
      currency.value = result.creditUnit;
    } catch (e) {
      const ex = e as Error;
      statusRecipients.value[batchRecipients.length] = {
        success: false,
        errorMessage: ex.message,
        creditUsed: 0,
        messageId: '',
      };
    }
  }

  status.value = QuickSendStatus.Done;
  return true;
}
