<template>
  <div class="d-flex align-items-center border-bottom p-3">
    <div class="me-3">
      <i class="fe fe-user h1"></i>
    </div>
    <div>
      <p class="mb-0" v-if="contact">{{ contact.name }}</p>
      <p class="mb-0">{{ contact ? `(+${contact.mobileCountry}) ${contact.mobileNumber}` : formattedMSISDN(MSISDN) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ContactModelResult } from '@/dto/graphql';
  import { getPhoneParts } from '@/services/PhoneNumber';

  defineProps<{
    contact?: ContactModelResult | null;
    MSISDN: string;
  }>();

  // Format MSISDN to (+xx) xxx xxx xxx
  const formattedMSISDN = (MSISDN: string) => {
    const parts: string[] = getPhoneParts(MSISDN);
    return `(+${parts[0]}) ${parts[1]}`;
  };
</script>

<style scoped></style>
