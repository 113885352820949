<template>
  <div class="card card-fill">
    <div class="card-header">
      <h4 class="card-header-title">Thread</h4>
      <div class="dropdown">
        <button
          class="btn btn-outline-secondary btn-rounded-circle dropdown-toggle me-n2"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        ></button>
        <ul class="dropdown-menu">
          <li>
            <a @click="exportThread()" class="dropdown-item list-group-item-action d-flex py-3 text-secondary" href="#">
              <i class="fe fe-download-cloud me-3"></i>Export to spreadsheet
            </a>
          </li>
          <li>
            <a @click="$emit('closeThread')" class="dropdown-item list-group-item-action d-flex py-3 text-secondary" href="#">
              <i class="fe fe-x me-3"></i>Close
            </a>
          </li>
        </ul>
      </div>
    </div>
    <ThreadHeader :contact="thread.contact" :MSISDN="thread.MSISDN" />
    <div class="position-relative flex-grow-1">
      <div ref="messageBox" class="position-absolute overflow-auto px-4 h-100 w-100 pt-4">
        <ThreadSection v-for="day in sortedMessages" :key="day.date" :date="day.date" :messages="day.messages" />
      </div>
    </div>
    <ThreadInput
      v-model:message="message"
      :message-error="messageError || ''"
      :disabled="sendingMessage"
      :sender-id="senderId"
      @send-message="sendMessage"
      @change-sender="showSenderIdModal = true"
    />
    <SenderIDModal :sender-name="senderId" v-if="showSenderIdModal" @close="showSenderIdModal = false" @save-sender="changeSender" />
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, onMounted, watch, nextTick } from 'vue';
  import { MessageClass, MessageEncoding, SmsInboxModelResult, ThreadModelResult } from '@/dto/graphql';
  import { getMessageStats, SmsEncoding } from '@/services/SmsStats';
  import exportFromJSON from 'export-from-json';
  import { useForm, useField } from 'vee-validate';
  import { useToast } from 'vue-toastification';
  import { SmsSend } from '@/services/GraphqlApi';
  import * as yup from 'yup';
  import { format } from 'date-fns';

  import ThreadHeader from './ThreadHeader.vue';
  import ThreadInput from './ThreadInput.vue';
  import ThreadSection from './ThreadSection.vue';
  import SenderIDModal from './SenderIDModal.vue';

  const props = defineProps<{
    messageId?: string;
    thread: ThreadModelResult;
    currentInbox: SmsInboxModelResult | null;
  }>();

  const messageBox = ref<HTMLElement | null>(null);
  const sendingMessage = ref(false);
  const senderId = ref<string | undefined>(props.currentInbox?.number);
  const showSenderIdModal = ref(false);

  const { handleSubmit, resetForm } = useForm();
  const toast = useToast();
  const emit = defineEmits(['closeThread', 'updateThread']);

  const { value: message, errorMessage: messageError } = useField('message', yup.string().required('You must enter a message').label('Message'));

  watch(
    () => props.thread.messages,
    (newVal) => {
      nextTick(() => {
        if (newVal && !props.messageId) scrollToElement(newVal[newVal.length - 1].id);

        if (props.messageId) {
          scrollAndHighlight(props.messageId);
        }
      });
    },
  );

  onMounted(() => {
    if (props.messageId) {
      scrollAndHighlight(props.messageId);
    } else {
      if (props.thread.messages) scrollToElement(props.thread.messages[props.thread.messages.length - 1].id);
    }
  });

  const sendMessage = handleSubmit(async (values) => {
    sendingMessage.value = true;
    const encoding = getMessageStats(values.message).encoding === SmsEncoding.UCS2 ? MessageEncoding.Ucs2 : MessageEncoding.Utf8;
    const accessToken = localStorage.getItem('access-token') || '';
    if (senderId.value) {
      await SmsSend(props.thread.MSISDN, senderId.value, values.message, accessToken, encoding, MessageClass.Standard)
        .then((res) => {
          emit('updateThread', props.thread.MSISDN);
          resetForm();
        })
        .catch((err) => {
          console.error(err);
          toast.error('Failed to send message', {
            timeout: 3000,
          });
        })
        .finally(() => {
          sendingMessage.value = false;
        });
    } else {
      toast.error('No sender ID', {
        timeout: 3000,
      });
    }
  });

  const scrollToElement = (ref: string) => {
    const el = document.getElementById(ref);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const scrollAndHighlight = (id: string) => {
    scrollToElement(id);

    const el = document.getElementById(id);
    if (el) {
      el.classList.add('highlight');
      setTimeout(() => {
        el.classList.remove('highlight');
      }, 3000);
    }
  };

  // Messages sorted by date
  const sortedMessages = computed(() => {
    if (!props.thread.messages) {
      return [];
    }

    const messages = [...props.thread.messages];
    const sorted = messages.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    const sortedWithDates = sorted.reduce<
      {
        date: string;
        messages: typeof props.thread.messages;
      }[]
    >((acc, curr) => {
      const date = new Date(curr.date).toLocaleDateString();
      const dateIndex = acc.findIndex((item) => item.date === date);

      if (dateIndex === -1) {
        acc.push({ date, messages: [curr] });
      } else {
        acc[dateIndex].messages.push(curr);
      }

      return acc;
    }, []);

    return sortedWithDates;
  });

  const exportThread = async () => {
    if (!props.thread.messages) {
      return toast.error('Failed to export thread', {
        timeout: 3000,
      });
    }

    const data = props.thread.messages.map((message) => {
      // omit id from export
      const { id, ...rest } = message;

      return {
        ...rest,
        fullDate: new Date(message.date),
        date: format(new Date(message.date), 'dd/MM/yyyy'),
        time: format(new Date(message.date), 'HH:mm:ss'),
      };
    });

    exportFromJSON({
      data: data,
      fileName: `thread_${props.thread.MSISDN}`,
      exportType: 'csv',
    });
  };

  const changeSender = async (senderID: string) => {
    senderId.value = senderID;
    showSenderIdModal.value = false;
    toast.success('Sender ID changed', {
      timeout: 3000,
    });
  };
</script>
