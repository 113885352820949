<template>
  <SingleMessage type="to" :text="`${inbox?.keyword || 'DONATE'} 1000`" />
  <SingleMessage
    type="from"
    :text="`${
      inbox?.ruleset.errInvalidContent ||
      `Unfortunately, we only accept donations between ${inbox?.ruleset.paymentPriceMin || '1'} and ${
        inbox?.ruleset.paymentPriceMax || '500'
      } DKK. Please try again.`
    }`"
  />
  <SingleMessage type="to" :text="`${inbox?.keyword || 'DONATE'} 500`" />
  <SingleMessage
    v-if="inbox?.ruleset.requireConfirmation"
    type="from"
    :text="`${
      inbox?.ruleset.initialContent ||
      `Thank you for your support! Please, confirm your donation by replying with ${inbox?.keyword || 'DONATE'} ${
        inbox?.ruleset.paymentConfirmWord || 'CONFIRM'
      }`
    }`"
  />
  <SingleMessage
    v-if="inbox?.ruleset.requireConfirmation"
    type="to"
    :text="`${inbox?.keyword || 'DONATE'} ${inbox?.ruleset.paymentConfirmWord || 'CONFIRM'}`"
  />
  <SingleMessage type="from" :text="`${inbox?.ruleset.paymentContent || `Thank you for your donation! We really appreciate your support.`}`" />
</template>

<script setup lang="ts">
  import { inject } from 'vue';
  import { SmsInboxModelResult, SmsInboxRulesetPaymentDynamicModel } from '@/dto/graphql';
  import SingleMessage from './SingleMessage.vue';

  type TInbox = SmsInboxModelResult & {
    ruleset: SmsInboxRulesetPaymentDynamicModel;
  };

  const inbox = inject<TInbox>('inbox');
</script>
