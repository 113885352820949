import { defineStore } from 'pinia';
import { ContactFieldBaseModel } from '@/dto/graphql';
import { ContactFieldList } from '@/services/GraphqlApi';

import { useToast } from 'vue-toastification';
const toast = useToast();

export const useContactsStore = defineStore('contacts', {
  state: () => {
    return {
      contactFields: [] as ContactFieldBaseModel[],
    };
  },
  actions: {
    async fetchContactFields() {
      try {
        this.contactFields = await ContactFieldList();
      } catch (e) {
        toast.error(`An error occured: ${(e as Error).message}`);
      }
    },
  },
});
