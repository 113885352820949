<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10">
        <div class="header mt-md-5">
          <div class="header-body">
            <h6 class="header-pretitle">SMS Configuration</h6>

            <!-- Title -->
            <h1 class="header-title py-2">Configure your account</h1>
          </div>
        </div>

        <div v-if="loading" class="text-center">
          <div class="spinner-border text-muted" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="card">
            <div class="card-header">
              <h3 class="card-header-title me-auto">GatewayAPI</h3>
              <div v-if="hasConnection" class="badge bg-success">Fully configured</div>
              <div v-else class="badge bg-warning">Not configured</div>
            </div>

            <!-- FULLY CONFIGURED -->
            <div class="card-body mt-0" v-if="hasConnection">
              <div class="alert alert-success alert-dismissible" v-if="successGatewayapiKey">
                {{ successGatewayapiKey }}
                <button type="button" class="btn-close" aria-label="Close" @click="successGatewayapiKey = ''"></button>
              </div>

              <p>If you want to change to another API Token for GatewayAPI, then please click this button:</p>
              <button class="btn btn-secondary" @click.prevent="reconfigureToken(true)">Reconfigure GatewayAPI connection</button>
            </div>

            <!-- NOT CONFIGURED -->
            <div class="card-body" v-else>
              <div class="alert alert-light">
                <div class="row">
                  <div class="col-auto align-self-center"><i class="fe fe-info"></i></div>
                  <div class="col">Please enter a GatewayAPI API Token below. You must do this, before you can start sending</div>
                </div>
              </div>
              <form @submit.prevent="verifyAndSaveGatewayapiKey">
                <div class="row mb-3">
                  <label for="gatewayapiKey" class="col-form-label col-xl-4 col-lg-5 col-md-12 col-sm-5">GatewayAPI Environment</label>
                  <div class="col-xl-8 col-lg-7 col-md-12 col-sm-7">
                    <select class="form-select" v-model="gatewayapiSetup">
                      <option :value="Providers.GatewayApi">GatewayAPI.com</option>
                      <option :value="Providers.GatewayApiEu">GatewayAPI.eu</option>
                    </select>
                  </div>
                </div>

                <div class="row mb-3">
                  <label for="gatewayapiKey" class="col-form-label col-xl-4 col-lg-5 col-md-12 col-sm-5">
                    API Token
                    <InfoTooltip
                      tooltip='<div class="text-start"><strong>Instructions:</strong><br>- Open another tab, navigate to GatewayAPI Admin.<br>- Go to "API" > "API Keys".<br>- Either click "Add API Key" or "Get key / token" on an existing.<br>- Copy the "Token".<br>- Paste the token in this field.</div>'
                    />
                  </label>
                  <div class="col-xl-8 col-lg-7 col-md-12 col-sm-7">
                    <input
                      required
                      type="text"
                      v-model="gatewayapiRestToken"
                      name="gatewayapiKey"
                      id="gatewayapiKey"
                      class="form-control"
                      :class="{ 'is-invalid': errorGatewayapiKey }"
                      @input="errorGatewayapiKey = ''"
                    />
                    <div class="invalid-feedback">{{ errorGatewayapiKey }}</div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-sm-8 offset-sm-4">
                    <button v-if="!submitting" class="btn btn-primary" :disabled="!gatewayapiRestToken">Verify and Save</button>
                    <button v-else class="btn btn-primary" disabled>
                      <div class="spinner-border spinner-border-sm me-2" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      Verifying and saving...
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <SmsSenderName v-if="hasConnection" v-model="senderID" setup @save="saveDefaults"></SmsSenderName>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { inject, onMounted, Ref, ref } from 'vue';
  import { ConnectionGatewayapi, ConnectionGatewayapiSave, ConnectionDefaultsSave } from '@/services/GraphqlApi';
  import { Providers } from '@/dto/graphql';
  import { useToast } from 'vue-toastification';
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';
  import SmsSenderName from '@/components/shared/SmsSenderName.vue';

  const toast = useToast();

  // API KEY
  const loading = ref(true);
  const submitting = ref(false);
  const gatewayapiSetup = ref(Providers.GatewayApi);
  const gatewayapiRestToken = ref('');

  const errorGatewayapiKey = ref('');
  const successGatewayapiKey = ref('');

  const hasConnection = inject('hasConnection') as Ref<boolean>;

  // SENDER ID
  const senderID = ref('') as Ref<string>;
  const submittingSenderID = ref(false) as Ref<boolean>;

  const saveDefaults = async () => {
    submittingSenderID.value = true;
    try {
      await ConnectionDefaultsSave(senderID.value);
      toast.success('Defaults saved');
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      submittingSenderID.value = false;
    }
  };

  // on mounted: get the current connection to gatewayapi
  onMounted(async () => {
    try {
      const connectionDetails = await ConnectionGatewayapi();
      if (connectionDetails) senderID.value = connectionDetails.defaultSenderId;
      if (connectionDetails) hasConnection.value = true;
    } catch (e) {
      console.error(e);
    }

    loading.value = false;
  });

  // on submit: verify and save the gatewayapi key
  async function verifyAndSaveGatewayapiKey() {
    submitting.value = true;
    errorGatewayapiKey.value = '';

    try {
      await ConnectionGatewayapiSave(gatewayapiRestToken.value, gatewayapiSetup.value);
      reconfigureToken();
      hasConnection.value = true;
      successGatewayapiKey.value = "Your connection details have been verified and saved! You can send SMS'es using your GatewayAPI-account now.";
    } catch (e) {
      errorGatewayapiKey.value = (e as Error).message;
    }

    submitting.value = false;
  }

  // handler for reconfiguring the token
  function reconfigureToken(commitToServer?: boolean) {
    if (commitToServer) {
      ConnectionGatewayapiSave('', gatewayapiSetup.value);
      hasConnection.value = false;
    }

    gatewayapiSetup.value = Providers.GatewayApi;
    gatewayapiRestToken.value = '';
    errorGatewayapiKey.value = '';
    successGatewayapiKey.value = '';
  }
</script>
