<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title d-flex">Create Inbox</h4>
    </div>
    <div class="card-body">
      <StepType v-if="step === 1" />
      <StepDetails v-if="step === 2" />
      <StepSuccessMessages v-if="step === 3" />
      <StepErrorMessagesOne
        v-if="
          step === 4 &&
          (inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel' ||
            inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel' ||
            inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel')
        "
      />
      <StepErrorMessagesTwo v-if="step === 5 && (inbox.ruleset as SmsInboxPayment).requireConfirmation" />
    </div>
    <div class="card-footer d-flex justify-content-end">
      <button v-if="step > 1" type="button" class="btn btn-light me-3" @click="emit('previous')">Previous</button>
      <button type="button" class="btn btn-primary" :disabled="nextDisabled" @click="emit('next')">
        {{
          (inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel' && step === 3) ||
          (inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel' && step === 4) ||
          ((inbox.ruleset as SmsInboxPayment).requireConfirmation && step === 5) ||
          (!(inbox.ruleset as SmsInboxPayment).requireConfirmation && step === 4)
            ? 'Submit'
            : 'Next'
        }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, inject, Ref } from 'vue';
  import StepType from './setup/StepType.vue';
  import StepDetails from './setup/StepDetails.vue';
  import StepSuccessMessages from './setup/StepSuccessMessages.vue';
  import StepErrorMessagesOne from './setup/StepErrorMessagesOne.vue';
  import StepErrorMessagesTwo from './setup/StepErrorMessagesTwo.vue';
  import {
    SmsInboxModelResult,
    SmsInboxRulesetAutoreplyModel,
    SmsInboxRulesetAutoreplyMultipleModel,
    SmsInboxRulesetPaymentDynamicModel,
    SmsInboxRulesetPaymentFixedModel,
  } from '@/dto/graphql';

  type TInbox = SmsInboxModelResult & {
    ruleset:
      | SmsInboxRulesetAutoreplyModel
      | SmsInboxRulesetAutoreplyMultipleModel
      | SmsInboxRulesetPaymentDynamicModel
      | SmsInboxRulesetPaymentFixedModel;
  };

  type SmsInboxPayment = SmsInboxRulesetPaymentDynamicModel | SmsInboxRulesetPaymentFixedModel;

  const inbox = inject('inbox') as Ref<TInbox>;
  const inboxes = inject('inboxes') as Ref<TInbox[]>;

  const props = defineProps({
    step: {
      type: Number,
      required: true,
    },
  });

  const nextDisabled = computed(() => {
    if (props.step === 1) {
      return !inbox.value.ruleset.__typename;
    } else if (props.step === 2) {
      if (inbox.value.keyword === '*' && inbox.value.number === '451204') return true;
      if (inbox.value.keyword === '*' && inboxes.value.some((i) => i.keyword === '*' && i.number == inbox.value.number)) return true;
      return !inbox.value.number || !inbox.value.keyword || !inbox.value.ruleset.sender;
    } else if (props.step === 3) {
      return (
        !(
          (inbox.value.ruleset as SmsInboxPayment).requireConfirmation &&
          (inbox.value.ruleset as SmsInboxPayment).initialContent &&
          (inbox.value.ruleset as SmsInboxPayment).paymentConfirmWord &&
          (inbox.value.ruleset as SmsInboxPayment).paymentContent
        ) &&
        !(inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel') &&
        !(inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel') &&
        !(
          inbox.value.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel' &&
          inbox.value.ruleset.paymentContent &&
          inbox.value.ruleset.paymentPrice
        ) &&
        !(
          inbox.value.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel' &&
          inbox.value.ruleset.paymentContent &&
          inbox.value.ruleset.paymentPriceMin &&
          inbox.value.ruleset.paymentPriceMax
        )
      );
    } else if (props.step === 4) {
      return !(
        ((inbox.value.ruleset as SmsInboxPayment).errChargeContent && (inbox.value.ruleset as SmsInboxPayment).errInvalidContent) ||
        inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel'
      );
    } else if (props.step === 5 && (inbox.value.ruleset as SmsInboxPayment).requireConfirmation) {
      return !(
        (inbox.value.ruleset as SmsInboxPayment).errNoInitialContent &&
        (inbox.value.ruleset as SmsInboxPayment).errNoConfirmationContent &&
        (inbox.value.ruleset as SmsInboxPayment).errNoConfirmationTime
      );
    }
    return false;
  });

  const emit = defineEmits(['next', 'previous']);
</script>
