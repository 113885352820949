<template>
  <div class="container-fluid">
    <!-- HEADER -->
    <div class="header">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h6 class="header-pretitle">SMS Campaign</h6>
            <h1 class="header-title py-2 d-block d-sm-none">Create campaign</h1>
            <input
              ref="refCampaignName"
              required
              type="text"
              class="form-control form-control-flush h1 my-0 py-0 d-none d-sm-block"
              placeholder="Enter name of campaign"
              v-model.trim="campaignName"
            />
          </div>

          <div class="col-auto d-flex align-items-end flex-column flex-lg-row align-items-lg-center">
            <button type="button" class="btn btn-outline-secondary btn-lg d-flex align-items-center me-2" @click="showSettingsModal = true">
              <i class="fe fe-settings me-3"></i>
              <span class="pe-2">Settings</span>
            </button>
            <ActionsButton
              @send-campaign="submitCampaign(false, true)"
              @save-draft="submitCampaign(true, true)"
              @save-as-template="saveAsTemplate()"
            ></ActionsButton>
          </div>
        </div>
      </div>
    </div>
    <Settings v-if="showSettingsModal" @close="showSettingsModal = false" :campaign-id="id" status="Draft" />
    <!-- HEADER -->

    <MissingGatewayapiTokenAlert />

    <!-- BODY -->
    <div class="row">
      <!-- LEFT SIDEBAR -->
      <div class="col-lg-6 col-xxl-5">
        <div class="mb-4 d-sm-none">
          <label for="refCampaignNameMobile" class="form-label">Campaign name</label>
          <input
            ref="refCampaignNameMobile"
            id="refCampaignNameMobile"
            required
            type="text"
            class="form-control"
            placeholder="Enter name of campaign"
            v-model.trim="campaignName"
          />
        </div>

        <!-- RECIPIENTS -->
        <Tags></Tags>
        <!-- RECIPIENTS -->

        <!-- CAMPAIGN TAGS -->
        <CampaignTags />
        <!-- CAMPAIGN TAGS -->

        <!-- STATUS -->
        <Status></Status>
        <!-- STATUS -->
      </div>
      <!-- LEFT SIDEBAR -->

      <!-- RIGHT - MAIN CONTENT -->
      <div class="col-lg-6 col-xxl-7">
        <!-- MESSAGE -->
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">Message</h4>
          </div>
          <div class="card-body">
            <SmsMessage v-model="message" tags-selector></SmsMessage>
          </div>
        </div>
        <!-- MESSAGE -->

        <!-- SENDER NAME -->
        <SmsSenderName v-model="senderName"></SmsSenderName>
        <!-- SENDER NAME -->
      </div>
      <!-- RIGHT - MAIN CONTENT -->
    </div>
    <!-- BODY -->
  </div>
</template>

<script lang="ts" setup>
  import SmsMessage from '@/components/shared/SmsMessage.vue';
  import Status from '@/components/sms-campaign-editor/Status.vue';
  import { provide, Ref, ref, onMounted } from 'vue';
  import Tags from '@/components/sms-campaign-editor/Tags.vue';
  import SmsSenderName from '@/components/shared/SmsSenderName.vue';
  import ActionsButton from '@/components/sms-campaign-editor/ActionsButton.vue';
  import Settings from '@/components/sms-campaign-editor/settings/Settings.vue';
  import CampaignTags from '@/components/sms-campaign-editor/CampaignTags.vue';
  import {
    CampaignAllowedHoursModel,
    CampaignTagModelResult,
    ConnectionModelResult,
    CreateSendStatus,
    MessageClass,
    MessageEncoding,
  } from '@/dto/graphql';
  import MissingGatewayapiTokenAlert from '@/components/shared/MissingGatewayapiTokenAlert.vue';
  import { useToast } from 'vue-toastification';
  import { CampaignUpsert, CampaignGet, ConnectionGatewayapi, CampaignTagList, CampaignTemplateUpsert } from '@/services/GraphqlApi';
  import { getMessageStats, SmsEncoding } from '@/services/SmsStats';
  import { useRouter, useRoute } from 'vue-router';
  import { useTagsValidation } from '@/services/TagsValidation';
  import { useDialog } from '@/services/Dialog';
  import { setTitle } from '@/services/SetTitle';

  const createDialog = useDialog();
  const { validateTags } = useTagsValidation();
  const toast = useToast();
  const router = useRouter();
  const route = useRoute();
  const connectionDetails = ref<ConnectionModelResult>();
  const showSettingsModal = ref<boolean>(false);

  const id = ref('') as Ref<string>;
  const messageClass = ref(MessageClass.Standard) as Ref<MessageClass>;
  const recipientCount = ref(0) as Ref<number>;
  const message = ref('') as Ref<string>;
  const senderName = ref('') as Ref<string>;
  const tags = ref([]) as Ref<string[]>;
  const campaignName = ref('') as Ref<string>;
  const refCampaignName = ref() as Ref<HTMLInputElement>;
  const refCampaignNameMobile = ref() as Ref<HTMLInputElement>;
  const sendTime = ref() as Ref<Date | null>;
  const throttle = ref(10) as Ref<number>;
  const allowedHours = ref([]) as Ref<CampaignAllowedHoursModel[]>;
  const campaignTags = ref([]) as Ref<CampaignTagModelResult[]>;
  const selectedTags = ref(campaignTags.value.map((t: CampaignTagModelResult) => t.id) ?? []) as Ref<string[]>;

  provide('messageClass', messageClass);
  provide('recipientCount', recipientCount);
  provide('message', message);
  provide('senderName', senderName);
  provide('tags', tags);
  provide('sendTime', sendTime);
  provide('throttle', throttle);
  provide('allowedHours', allowedHours);
  provide('campaignTags', campaignTags);
  provide('selectedTags', selectedTags);

  const allCampaignTags = ref([]) as Ref<CampaignTagModelResult[]>;
  provide('allCampaignTags', allCampaignTags);

  const getCampaignTags = async () => {
    try {
      allCampaignTags.value = await CampaignTagList();
    } catch (error) {
      toast.error('Could not load campaign tags');
      console.error(error);
    }
  };

  onMounted(async () => {
    getCampaignTags();
    const parseId = (id: string | string[]) => (Array.isArray(id) ? id[0] : id);
    const campaignId: string = parseId(route.params.id);

    if (campaignId) {
      CampaignGet(campaignId)
        .then((campaign) => {
          id.value = campaign.id;
          messageClass.value = campaign.messageClass;
          recipientCount.value = campaign.recipientCount;
          message.value = campaign.message;
          senderName.value = campaign.sender;
          campaign.tags.map((tag) => tags.value.push(tag.id));
          campaignName.value = campaign.campaignName;
          sendTime.value = new Date(campaign.sendTime);
          throttle.value = campaign.throttle;
          allowedHours.value = campaign.allowedHours;
          selectedTags.value = campaign.campaignTags.map((t) => t.id);
          setTitle(campaign.campaignName);
        })
        .catch(() => {
          toast.error('Campaign not found');
          router.push({ name: 'SmsCampaignList' });
        });
    } else {
      // Use default sender id
      connectionDetails.value = await ConnectionGatewayapi();
      if (connectionDetails.value?.defaultSenderId) senderName.value = connectionDetails.value.defaultSenderId;
    }
  });

  /**
   * Send the campaign!
   */
  const submitCampaign = async (isDraft = false, pushRoute = false) => {
    try {
      // validate
      if (!campaignName.value) {
        if (refCampaignName.value.offsetParent) refCampaignName.value.focus();
        if (refCampaignNameMobile.value.offsetParent) refCampaignNameMobile.value.focus();
        throw new Error('Your campaign must have a title. The title is not sent to recipients, but used within this system.');
      }

      if (throttle.value < 1 || throttle.value > 100 || isNaN(throttle.value)) {
        throw new Error('Your campaign must have a send speed and must be a number between 1 and 100.');
      }

      if (!tags.value.length) {
        throw new Error('You must select at least one tag in the recipients list.');
      }

      validateTags(message.value);

      if (!isDraft) {
        // ask for confirmation
        const res = await createDialog({
          title: 'About to send campaign',
          content: `Your campaign is about to be sent to <strong>${recipientCount.value}</strong> recipients.<br><br>Are you sure that you are ready to continue?
        `,
          confirmText: 'Send campaign',
        });
        if (!res) return;

        createDialog({
          title: 'Please wait...',
          content: 'Campaign is being sent.',
          showCancel: false,
          showConfirm: false,
        });
      }

      const encoding = getMessageStats(message.value).encoding;
      // SEND!
      await CampaignUpsert(
        {
          campaignName: campaignName.value,
          campaignTags: selectedTags.value,
          tags: tags.value,
          message: message.value,
          messageClass: messageClass.value,
          messageEncoding: encoding === SmsEncoding.UCS2 ? MessageEncoding.Ucs2 : MessageEncoding.Utf8,
          sender: senderName.value || '1204',
          sendTime: sendTime.value || new Date(),
          throttle: throttle.value,
          allowedHours: allowedHours.value,
          sendStatus: isDraft ? CreateSendStatus.Draft : CreateSendStatus.Queued,
        },
        id.value,
      );

      toast.success(`Campaign ${isDraft ? 'saved' : 'queued'}`);
      if (pushRoute) await router.push({ name: 'SmsCampaignList' });
    } catch (e) {
      toast.warning((e as Error).message);
    }
  };

  const saveAsTemplate = async () => {
    const res = await createDialog({
      title: 'Save as template',
      content: `You are about to save this campaign as a template. Be aware that some settings won't be saved with the tamplate.`,
      defaultInputValue: campaignName.value,
      showInput: true,
      inputLabel: 'Template name',
      inputPlaceholder: 'Enter a template name',
      inputValidator(value: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
          if (!value) {
            reject('Template name cannot be empty.');
          }
          resolve(null);
        });
      },
    });

    if (!res) return;

    try {
      await CampaignTemplateUpsert({
        campaignName: res as string,
        message: message.value,
        sender: senderName.value,
        messageClass: messageClass.value,
        allowedHours: allowedHours.value,
        throttle: throttle.value,
        title: res as string, // TODO: Remove this when backend is fixed
      });
      toast.success('Template saved');
    } catch (error) {
      toast.error('Could not save template');
    }
  };
</script>
