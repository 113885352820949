<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h4>Allowed hours</h4>
    </div>
    <p>
      Optionally define date/time ranges within which delivery is acceptable. The campaign will automatically pause sending outside those hours, thus
      securing you against delivery of SMS'es at night, for instance.
    </p>
    <div class="card" v-if="allowedHours.length">
      <table class="table card-table">
        <thead>
          <tr>
            <th style="width: 40%">Date</th>
            <th style="width: 30%">Start time</th>
            <th style="width: 30%">End time</th>
            <th class="text-center">
              <button class="btn btn-rounded-circle btn-outline-secondary my-n2" type="button" @click="addHours">
                <i class="fe fe-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(range, index) in allowedHours" :key="index">
            <AllowedHoursRow v-model="allowedHours[index]" @remove="removeHours(index)" :timezone="timezone" />
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <button class="btn btn-primary btn-lg btn-smw" type="button" @click="addHours">Enable allowed hours</button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Ref, inject, computed, provide } from 'vue';
  import { CampaignAllowedHoursModel } from '@/dto/graphql';
  import AllowedHoursRow from './AllowedHoursRow.vue';
  import { set, addDays } from 'date-fns';

  const allowedHours = inject('allowedHours') as Ref<CampaignAllowedHoursModel[]>;

  const disabledDates = computed(() => allowedHours.value.map((range) => new Date(range.start)));

  provide('disabledDates', disabledDates);

  defineProps({
    timezone: {
      type: String,
      required: false,
      default: 'UTC',
    },
  });

  const addHours = () => {
    let newDate = new Date();

    if (allowedHours.value.length > 0) {
      const lastDate = allowedHours.value[allowedHours.value.length - 1].start;
      newDate = addDays(new Date(lastDate), 1);
    }

    allowedHours.value.push({
      start: set(newDate, { hours: 11, minutes: 0, seconds: 0 }),
      end: set(newDate, { hours: 22, minutes: 0, seconds: 0 }),
    });
  };

  const removeHours = (index: number) => {
    allowedHours.value.splice(index, 1);
  };
</script>
