<template>
  <DialogProvider v-if="layout && authFlowComplete">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </DialogProvider>
  <Chatlio></Chatlio>
</template>

<script setup lang="ts">
  import { setTheme } from './services/Theme';
  import { DefineComponent, onMounted, provide, Ref, ref, shallowRef, watch } from 'vue';
  import { ConnectionGatewayapi } from '@/services/GraphqlApi';
  import Chatlio from './components/Chatlio.vue';
  import DialogProvider from './components/shared/DialogProvider.vue';
  import { RouterView, useRoute, useRouter } from 'vue-router';
  import { getAccessToken, userAccounts } from '@/services/Authentication';
  import useSplash from '@/composables/useSplash';

  import DefaultLayout from './layouts/DefaultLayout.vue';

  const router = useRouter();
  const route = useRoute();
  const layout = shallowRef(null) as Ref<DefineComponent | null>;
  const hasConnection = ref(true) as Ref<boolean>;
  provide('hasConnection', hasConnection);
  const authFlowComplete = ref(false);

  setTheme();

  const { hideSplash } = useSplash();

  onMounted(async () => {
    await getAccessToken();

    if (userAccounts.length === 0) {
      await router.push('/welcome');
    } else {
      try {
        await ConnectionGatewayapi();
      } catch (e) {
        hasConnection.value = false;
      }
    }

    authFlowComplete.value = true;
    hideSplash();

    // ensure proper layout now and in the future
    watch(
      route,
      async () => {
        layout.value = (route?.meta?.layout as DefineComponent) ?? DefaultLayout;
      },
      { immediate: true },
    );
  });
</script>

<style lang="scss">
  html,
  body {
    scroll-behavior: smooth;
  }

  .navbar-brand-img,
  .navbar-brand > img {
    max-height: 2.5rem;
  }

  // tooltip improvements for dark mode and positioning when placed start/end of element instead of on top
  .tooltip {
    .tooltip-inner {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
      @at-root body[data-theme='dark'] & {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      }
    }

    &.bs-tooltip-end,
    &.bs-tooltip-start {
      .tooltip-arrow {
        margin-top: -5px;
      }
    }
  }

  @import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
  .dp__input {
    font-size: 0.9375rem;
    &::placeholder {
      color: #b1c2d9;
      opacity: 1;
    }
  }
  .dp__theme_light {
    --dp-success-color: var(--bs-primary);
    --dp-icon-color: var(--bs-secondary);
    --dp-danger-color: var(--bs-danger);
  }

  .dp__theme_dark {
    --dp-background-color: #1e3a5c;
    --dp-border-color: #1e3a5c;
    --dp-border-color-hover: var(--bs-primary);
    --dp-success-color: white;
  }

  // vue toastification container
  .Vue-Toastification__container.top-right {
    padding-top: 85px;
    padding-right: 21px;
  }
</style>
