<template>
  <div class="card"
    :class="{ 'bg-danger-soft pulse-animation': !dpa?.signingStatus, 'bg-success-soft': dpa?.signingStatus }">
    <div class="card-body">
      <div class="row align-items-center gx-0">
        <div class="col">
          <h6 class="text-uppercase mb-2 text-muted">Legal</h6>

          <div v-if="loading" class="h2 mb-0"><span class="spinner-border spinner-border-sm text-muted"></span> &nbsp;
          </div>

          <div v-else-if="!dpa?.signingStatus">
            <div class="d-flex align-items-center justify-content-between">
              <span class="h2 mb-0">DPA is not signed</span>
            </div>
            <p class="mb-0 mt-2">Without a signed Data Processing Agreement, you are only allowed to use this account for
              testing purposes.</p>
            <button @click="showUpdateDPAModal = true" class="btn btn-sm btn-success d-inline-flex mt-3 px-4 py-1">
              <i class="fe fe-settings me-2"></i>Fix it now
            </button>
          </div>
          <div v-else>
            <div class="d-flex align-items-center justify-content-between">
              <span class="h2 mb-0">Your account has a signed DPA</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <UpdateDPAModal v-if="showUpdateDPAModal" @close="showUpdateDPAModal = false" @update="getDpa()" :dpa="dpa" />
</template>

<script lang="ts" setup>
import { accountDpaGet } from '@/services/GraphqlApi';
import { ref, onMounted } from 'vue';
import UpdateDPAModal from '@/components/account/UpdateDPAModal.vue';
import { DpaModelResult } from '@/dto/graphql';

const showUpdateDPAModal = ref(false);

const loading = ref<boolean>(false);
const dpa = ref<DpaModelResult | undefined>();

const getDpa = () => {
  loading.value = true;
  accountDpaGet()
    .then((res) => {
      dpa.value = res;
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  getDpa();
});
</script>

<style lang="scss" scoped>
.gatewayapi-icon {
  height: 24px;
  width: 24px;
  display: inline-block;
  background-image: url('@/assets/gatewayapi-grey.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.5;
}

.pulse-animation {
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #fad7dd;
    }

    70% {
      box-shadow: 0 0 0 5px transparent;
    }

    100% {
      box-shadow: 0 0 0 0 transparent;
    }
  }
}
</style>
