import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Dashboard from './pages/Dashboard.vue';
import Blacklist from './pages/Blacklist.vue';
import SmsQuickSend from './pages/SmsQuickSend.vue';
import SmsCampaignCreate from './pages/SmsCampaignCreate.vue';
import SmsCampaignEditor from './pages/SmsCampaignEditor.vue';
import SmsCampaignStatistics from './pages/SmsCampaignStatistics.vue';
import SmsCampaignList from './pages/SmsCampaignList.vue';
import SmsTemplatesList from './pages/SmsTemplatesList.vue';
import SmsTemplatesEditor from './pages/SmsTemplatesEditor.vue';
import SmsInboxList from './pages/SmsInboxList.vue';
import SmsInboxEditor from './pages/SmsInboxEditor.vue';
import SmsInboxWizard from './pages/SmsInboxWizard.vue';
import Contacts from './pages/Contacts.vue';
import SmsConfiguration from './pages/SmsConfiguration.vue';
import Welcome from './pages/WelcomePage.vue';
import WelcomeLayout from '@/layouts/WelcomeLayout.vue';
import GeneralSettings from '@/pages/GeneralSettings.vue';
import DataPolicy from '@/pages/DataPolicy.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { title: 'Dashboard' },
  },
  {
    path: '/blacklist',
    name: 'Blacklist',
    component: Blacklist,
    meta: { title: 'Blacklist' },
  },
  {
    path: '/quicksend',
    name: 'SmsQuickSend',
    component: SmsQuickSend,
    meta: { module: 'sms', title: 'Quick Send' },
  },
  {
    path: '/campaigns',
    name: 'SmsCampaignList',
    component: SmsCampaignList,
    meta: { module: 'sms', title: 'Campaigns' },
  },
  {
    path: '/campaigns/create',
    name: 'SmsCampaignCreate',
    component: SmsCampaignCreate,
    meta: { module: 'sms', title: 'Create Campaign' },
  },
  {
    path: '/campaigns/:id/edit',
    name: 'SmsCampaignEditor',
    component: SmsCampaignEditor,
    meta: { module: 'sms', title: 'Edit Campaign' },
  },
  {
    path: '/campaigns/:id/statistics',
    name: 'SmsCampaignStatistics',
    component: SmsCampaignStatistics,
    meta: { title: 'Statistics' },
  },
  {
    path: '/templates',
    name: 'SmsTemplatesList',
    component: SmsTemplatesList,
    meta: { module: 'sms', title: 'Templates' },
  },
  {
    path: '/templates/:id/edit',
    name: 'SmsTemplatesEditor',
    component: SmsTemplatesEditor,
    meta: { module: 'sms', title: 'Edit Template' },
  },
  {
    path: '/inbox',
    name: 'SmsInboxList',
    component: SmsInboxList,
    meta: { module: 'sms', title: 'Inbox' },
  },
  {
    path: '/inbox/create',
    name: 'SmsInboxWizard',
    component: SmsInboxWizard,
    meta: { module: 'sms', title: 'Create Inbox' },
  },
  {
    path: '/inbox/:id/edit',
    name: 'SmsInboxEditor',
    component: SmsInboxEditor,
    meta: { module: 'sms', title: 'Edit Inbox' },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: { module: 'contacts', title: 'Contacts' },
  },
  {
    path: '/configuration',
    name: 'SMSConfiguration',
    component: SmsConfiguration,
    meta: { title: 'SMS Configuration' },
  },
  {
    path: '/general-settings',
    name: 'GeneralSettings',
    component: GeneralSettings,
    meta: { title: 'General Settings' },
  },
  {
    path: '/data-policy',
    name: 'DataPolicy',
    component: DataPolicy,
    meta: { title: 'Data Policy' },
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: {
      layout: WelcomeLayout,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} - RelationCity`;
  }
});

export default router;
