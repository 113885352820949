<template>
  <div class="container-fluid vh-100 d-lg-flex flex-column">
    <Header title="Blocked Recipients" sub-title="BLACKLIST">
      <button class="btn btn-primary btn-lg d-flex align-items-center px-4" @click="showBlockRecipientModal = true">
        <i class="fe fe-x me-3"></i>
        Block recipient
      </button>
    </Header>
    <BlockRecipientModal v-if="showBlockRecipientModal" @close="showBlockRecipientModal = false" @success="onBlockSuccess" />
    <div class="card card-fill-lg">
      <div class="card-header">
        <input v-model="search" type="text" class="form-control form-control-flush h4" placeholder="Search for recipients" />

        <div class="dropdown">
          <button
            class="btn btn-outline-secondary btn-rounded-circle dropdown-toggle me-n2"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></button>
          <ul class="dropdown-menu">
            <li>
              <button @click.prevent="exportRecipients" class="dropdown-item list-group-item-action d-flex py-3 text-secondary">
                <i class="fe fe-download-cloud me-3"></i>Export to spreadsheet
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="position-relative mt-0 overflow-auto flex-grow-1 d-flex">
        <div class="inner position-absolute overflow-auto top-0 start-0 h-100 w-100">
          <div class="table-responsive-sm table-responsive-md">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 33%; min-width: 140px">Recipient</th>
                  <th style="width: 33%; min-width: 140px">Date</th>
                  <th style="width: 33%; min-width: 140px">Note</th>
                  <th><!-- Actions --></th>
                </tr>
              </thead>
              <tbody v-if="!loading && blacklist.results.length > 0">
                <BlacklistListRow v-for="recipient in blacklist.results" :key="recipient._id" :recipient="recipient" @success="getBlacklist" />
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4" class="text-center">
                    <Loading v-if="loading" />
                    <EmptyState v-else-if="blacklist.results.length === 0" text="No blacklisted recipients found." />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="blacklist && blacklist.totalCount > perPage" class="card-footer card-footer-boxed">
        <ListPagination
          :total-items="blacklist.totalCount"
          :page-size="perPage"
          :current-page="currentPage"
          @page-change="(page) => (currentPage = page)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue';
  import exportFromJSON from 'export-from-json';
  import { watchDebounced } from '@vueuse/core';
  import { useToast } from 'vue-toastification';

  import Header from '@/components/shared/Header.vue';
  import Loading from '@/components/shared/Loading.vue';
  import EmptyState from '@/components/shared/EmptyState.vue';
  import ListPagination from '@/components/shared/ListPagination.vue';
  import BlacklistListRow from '@/components/blacklist/BlacklistListRow.vue';
  import BlockRecipientModal from '@/components/blacklist/BlockRecipientModal.vue';
  import { BlacklistListModelResult } from '@/dto/graphql';
  import { BlacklistList } from '@/services/GraphqlApi';

  const showBlockRecipientModal = ref<boolean>(false);
  const perPage = 100;
  const currentPage = ref<number>(0);
  const loading = ref<boolean>(false);

  const search = ref<string>('');

  const blacklist = ref<BlacklistListModelResult>({
    totalCount: 0,
    results: [],
  });
  const toast = useToast();

  const getBlacklist = async (search?: string) => {
    loading.value = true;
    await BlacklistList({
      search,
      page: currentPage.value,
      limit: perPage,
      sortBy: 'blockedAt',
    }).then((result) => {
      blacklist.value = result;
      loading.value = false;
    });
  };

  const exportRecipients = async () => {
    try {
      const bl = await BlacklistList({
        search: search.value,
        sortBy: 'blockedAt',
      });
      const data = bl.results.map((recipient) => {
        return {
          recipient: recipient.recipient,
          date: recipient.blockedAt,
          note: recipient.note,
        };
      });

      exportFromJSON({ data, fileName: `blacklist-recipients-${new Date().toISOString()}`, exportType: 'csv', withBOM: true });
    } catch (error) {
      toast.error('Error exporting recipients', { timeout: 3000 });
    }
  };

  onMounted(async () => {
    getBlacklist();
  });

  const onBlockSuccess = () => {
    getBlacklist();
    showBlockRecipientModal.value = false;
  };

  watch(currentPage, () => {
    getBlacklist();
  });

  watchDebounced(
    search,
    (v) => {
      getBlacklist(v);
    },
    { debounce: 500, maxWait: 1000 },
  );
</script>

<style scoped></style>
