<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10">
        <Header title="Configure your data" sub-title="Data Policy" />

        <Loading v-if="isFetchingData" />

        <div v-else>
          <Notification type="info">
            <p>Please carefully read through this page and adapt the settings, to match (or exceed) your company's data protection policy.</p>
            <p class="mb-0">
              When properly configured, your contacts are not kept in this system for longer than necessary. This module also ensures deletion
              notification, when automatic deletion occurs.
            </p>
          </Notification>
          <form @submit="onSubmit" class="card">
            <div class="card-header">
              <h3 class="card-header-title me-auto">Automatic cleanup of inactive contacts</h3>
            </div>

            <div class="card-body">
              <p>When is a contact considered inactive?</p>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="InactiveStatus"
                  id="inactiveCampaign"
                  v-model="inactiveStatus"
                  :value="DataPolicyInactiveStatus.ReceivedCampaigns"
                />

                <label class="form-check-label" for="inactiveCampaign">
                  Contact has not received any campaigns for a prolonged period of time.
                </label>
                <small v-if="inactiveStatusErrorMessage" class="text-danger">{{ inactiveStatusErrorMessage }}</small>
              </div>

              <div class="form-check mt-2 mb-4">
                <input
                  class="form-check-input"
                  type="radio"
                  name="InactiveStatus"
                  id="inactiveEdit"
                  v-model="inactiveStatus"
                  :value="DataPolicyInactiveStatus.UpdatedEdited"
                />

                <label class="form-check-label" for="inactiveEdit"> Contact has not been updated/edited for a prolonged period of time. </label>
                <small v-if="inactiveStatusErrorMessage" class="text-danger">{{ inactiveStatusErrorMessage }}</small>
              </div>

              <div class="row mb-4">
                <label for="inactiveMonths" class="col-form-label col-12">
                  How much time must have passed, for a contact to be marked 'inactive'?
                </label>
                <div class="input-group col-6">
                  <input
                    placeholder="12"
                    aria-describedby="inactiveMonths"
                    type="number"
                    name="inactiveMonths"
                    id="inactiveMonths"
                    class="form-control"
                    v-model.number="inactiveMonths"
                  />
                  <span class="input-group-text" id="inactiveMonths">month(s)</span>
                </div>
                <small v-if="inactiveMonthsErrorMessage" class="text-danger mt-2">{{ inactiveMonthsErrorMessage }}</small>
              </div>

              <p>What happens upon inactivation?</p>

              <div class="form-check">
                <input class="form-check-input" v-model="deleteInactiveContacts" type="checkbox" value="" id="formDelete" />
                <label class="form-check-label" for="formDelete"> Contact is deleted 14 days after inactivation </label>
                <small v-if="deleteInactiveContactsErrorMessage" class="text-danger">{{ deleteInactiveContactsErrorMessage }}</small>
              </div>

              <div class="row my-3">
                <label for="time" class="col-form-label col-12"> When should these rules be checked each day? </label>

                <div class="input-group col-xl-8 col-lg-7 col-md-12 col-sm-7">
                  <span class="input-group-text" id="inactiveTime">At</span>
                  <select class="form-select" v-model="cronTime" id="time">
                    <option v-for="time in times" :key="time">{{ time }}</option>
                  </select>
                  <small v-if="cronTimeErrorMessage" class="text-danger">{{ cronTimeErrorMessage }}</small>
                </div>
              </div>

              <div class="form-check mt-5 mb-3">
                <input class="form-check-input" type="checkbox" v-model="sendSmsOnInactivation" id="inactive" />
                <label class="form-check-label" for="inactive"> Send message upon inactivation? </label>
                <small v-if="sendSmsOnInactivationErrorMessage" class="text-danger">{{ sendSmsOnInactivationErrorMessage }}</small>
              </div>
              <transition name="fade">
                <div v-if="sendSmsOnInactivation">
                  <SmsMessage
                    v-model="inactivatedMessage"
                    :error="inactivatedMessageErrorMessage"
                    label="Message"
                    placeholder="Hi. Your account has been deactivated."
                  ></SmsMessage>
                </div>
              </transition>

              <div class="form-check mt-5 mb-3">
                <input class="form-check-input" type="checkbox" v-model="sendSmsOnDeletion" id="deletion" />
                <label class="form-check-label" for="deletion"> Send message upon deletion? </label>
                <small v-if="sendSmsOnDeletionErrorMessage" class="text-danger">{{ sendSmsOnDeletionErrorMessage }}</small>
              </div>
              <transition name="fade">
                <div v-if="sendSmsOnDeletion">
                  <SmsMessage
                    v-model="deletedMessage"
                    :error="deletedMessageErrorMessage"
                    label="Message"
                    placeholder="Hi. Your account has been deleted."
                  ></SmsMessage>
                </div>
              </transition>
              <div class="mt-5" v-if="sendSmsOnDeletion || sendSmsOnInactivation">
                <label class="form-label" for="sender-name">Sender name</label>
                <input class="form-control" type="text" name="sender-name" id="sender-name" placeholder="Sender name" v-model="senderName" />
                <small v-if="senderNameErrorMessage" class="text-danger">{{ senderNameErrorMessage }}</small>
              </div>

              <div class="card-header ps-0 mt-4 mb-3">
                <h3 class="card-header-title me-auto">Tags exempt from automatic data cleanup</h3>
              </div>
              <Notification type="info">Any contacts with the selected tags below will not be affected by the rules above</Notification>
              <div v-if="contactTags && contactTags.length">
                <TagsListDataPolicy :tags="contactTags" v-model="excludedContactTags" />
                <small v-if="excludedContactTagsErrorMessage" class="text-danger">{{ excludedContactTagsErrorMessage }}</small>
              </div>
              <div v-else>
                <p class="text-muted">You do not have any contact tags on this account</p>
              </div>

              <div class="card-header ps-0 mt-4 mb-3">
                <h3 class="card-header-title me-auto">Anonymization and deletion of logs</h3>
              </div>

              <div class="row mb-4">
                <label for="anonymizeLogsAfter" class="col-form-label col-12"> How much time must have passed, for logs to be anonymized? </label>
                <div class="input-group col-6">
                  <input
                    placeholder="3"
                    aria-describedby="anonymizeLogsAfter"
                    type="number"
                    name="anonymizeLogsAfter"
                    id="anonymizeLogsAfter"
                    class="form-control"
                    v-model.number="anonymizeLogsAfter"
                  />
                  <span class="input-group-text" id="anonymizeLogsAfter">month(s)</span>
                </div>
                <small v-if="anonymizeLogsAfterErrorMessage" class="text-danger mt-2">{{ anonymizeLogsAfterErrorMessage }}</small>
              </div>

              <div class="row mb-4">
                <label for="deleteLogsAfter" class="col-form-label col-12"> How much time must have passed, for logs to be deleted? </label>
                <div class="input-group col-6">
                  <input
                    placeholder="12"
                    aria-describedby="deleteLogsAfter"
                    type="number"
                    name="deleteLogsAfter"
                    id="deleteLogsAfter"
                    class="form-control"
                    v-model.number="deleteLogsAfter"
                  />
                  <span class="input-group-text" id="deleteLogsAfter">month(s)</span>
                </div>
                <small v-if="deleteLogsAfterErrorMessage" class="text-danger mt-2">{{ deleteLogsAfterErrorMessage }}</small>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
              <button type="submit" :disabled="isSavingData" class="btn btn-primary">Save changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import { useField, useForm } from 'vee-validate';
  import * as yup from 'yup';
  import { useToast } from 'vue-toastification';
  import { DataPolicyInactiveStatus, ContactTagModelResult, AccountSettingsModel } from '@/dto/graphql';
  import { accountDataPolicyGet, accountDataPolicyUpdate, accountSettingsGet, ContactTagList } from '@/services/GraphqlApi';
  import Notification from '@/components/shared/Notification.vue';
  import Header from '@/components/shared/Header.vue';
  import TagsListDataPolicy from '@/components/data-policy/TagsListDataPolicy.vue';
  import Loading from '@/components/shared/Loading.vue';
  import { convertFromUTC, convertToUTC } from '@/services/Time';
  import SmsMessage from '@/components/shared/SmsMessage.vue';

  const { handleSubmit, setValues } = useForm();
  const toast = useToast();
  const isFetchingData = ref<boolean>(true);
  const isSavingData = ref<boolean>(false);
  const contactTags = ref<ContactTagModelResult[] | null>(null);
  const accountSettings = ref<AccountSettingsModel | null>(null);

  const { value: cronTime, errorMessage: cronTimeErrorMessage } = useField('cronTime', yup.string().required().label('Time of check'));
  const { value: inactiveStatus, errorMessage: inactiveStatusErrorMessage } = useField('inactiveStatus', yup.string().label('Inactive status'));
  const { value: inactiveMonths, errorMessage: inactiveMonthsErrorMessage } = useField(
    'inactiveMonths',
    yup.number().typeError('This needs to be a number').required('Please enter number of months').min(1).label('Inactive months'),
  );
  const { value: deleteInactiveContacts, errorMessage: deleteInactiveContactsErrorMessage } = useField(
    'deleteInactiveContacts',
    yup.boolean().label('Inactive months'),
  );
  const { value: sendSmsOnInactivation, errorMessage: sendSmsOnInactivationErrorMessage } = useField(
    'sendSmsOnInactivation',
    yup.bool().label('Send SMS on inactivation'),
  );
  const { value: sendSmsOnDeletion, errorMessage: sendSmsOnDeletionErrorMessage } = useField(
    'sendSmsOnDeletion',
    yup.bool().label('Send SMS on deletion'),
  );
  const { value: senderName, errorMessage: senderNameErrorMessage } = useField(
    'senderName',
    yup
      .string()
      .label('Sender name')
      .when([], {
        is: () => sendSmsOnInactivation.value || sendSmsOnDeletion.value,
        then: yup.string().required('Please enter a sender name'),
      }),
  );
  const { value: inactivatedMessage, errorMessage: inactivatedMessageErrorMessage } = useField(
    'inactivatedMessage',
    yup
      .string()
      .label('Message on inactivation')
      .when([], {
        is: () => sendSmsOnInactivation.value,
        then: yup.string().required('Please enter a message'),
      }),
  );
  const { value: deletedMessage, errorMessage: deletedMessageErrorMessage } = useField(
    'deletedMessage',
    yup
      .string()
      .label('Message on deletion')
      .when([], {
        is: () => sendSmsOnDeletion.value,
        then: yup.string().required('Please enter a message'),
      }),
  );
  const { value: excludedContactTags, errorMessage: excludedContactTagsErrorMessage } = useField(
    'excludedContactTags',
    yup.array().of(yup.string()).label('Excluded contact tags'),
  );
  const { value: anonymizeLogsAfter, errorMessage: anonymizeLogsAfterErrorMessage } = useField(
    'anonymizeLogsAfter',
    yup.number().typeError('This needs to be a number').required('Please enter number of months').min(1).label('Anonymize logs after'),
  );
  const { value: deleteLogsAfter, errorMessage: deleteLogsAfterErrorMessage } = useField(
    'deleteLogsAfter',
    yup.number().typeError('This needs to be a number').required('Please enter number of months').min(1).label('Delete logs after'),
  );

  onMounted(async () => {
    await accountSettingsGet().then((res) => {
      accountSettings.value = res;
    });
    accountDataPolicyGet().then((res) => {
      setValues({
        cronTime: convertFromUTC(res.cronTime, accountSettings.value?.timezone ?? 'UTC'),
        inactiveStatus: res.inactiveStatus,
        inactiveMonths: res.inactiveMonths,
        deleteInactiveContacts: res.deleteInactiveContacts,
        sendSmsOnInactivation: res.sendSmsOnInactivation,
        sendSmsOnDeletion: res.sendSmsOnDeletion,
        inactivatedMessage: res.inactivatedMessage,
        deletedMessage: res.deletedMessage,
        excludedContactTags: res.excludedContactTags,
        anonymizeLogsAfter: res.anonymizeLogsAfter,
        deleteLogsAfter: res.deleteLogsAfter,
        senderName: res.senderName,
      });
    });

    ContactTagList().then((res) => {
      contactTags.value = res;
    });

    isFetchingData.value = false;
  });

  const onSubmit = handleSubmit((values) => {
    const utcTime = convertToUTC(values.cronTime, accountSettings.value?.timezone ?? 'UTC');
    isSavingData.value = true;
    try {
      accountDataPolicyUpdate({
        ...values,
        cronTime: utcTime,
      }).then(() =>
        toast.success('Saved data policy', {
          timeout: 3000,
        }),
      );
    } catch (error) {
      toast.error('Something went wrong', {
        timeout: 3000,
      });
    } finally {
      isSavingData.value = false;
    }
  });

  // the hours and minutes from 00:00 to 23:30
  const times = Array.from(
    { length: 48 },
    (_, i) =>
      `${Math.floor(i / 2)
        .toString()
        .padStart(2, '0')}:${i % 2 === 0 ? '00' : '30'}`,
  );
</script>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
