<template>
  <tr class="position-relative">
    <td>
      <div class="d-flex align-items-center justify-content-between">
        <h4>{{ campaign.campaignName }}</h4>
        <div class="h3 mt-0 mb-2">
          <span class="badge bg-success" v-if="campaign.sendStatus === SendStatus.Completed">Completed</span>
          <span class="badge bg-info" v-if="campaign.sendStatus === SendStatus.Queued">Queued</span>
          <span class="badge bg-warning" v-if="campaign.sendStatus === SendStatus.Sending">Sending</span>
          <span class="badge bg-danger" v-if="campaign.sendStatus === SendStatus.Failed">Failed</span>
          <span class="badge bg-light" v-if="campaign.sendStatus === SendStatus.Draft">Draft</span>
          <span
            class="badge pause-badge ms-2 bg-dark-soft"
            :class="{ 'text-white': currentTheme === Theme.Dark }"
            v-if="campaign.paused || campaign.isWithinAllowedHours"
          >
            <i class="fe fe-pause"></i>
            Paused
          </span>
        </div>
      </div>
      <div class="position-relative">
        <small class="position-absolute w-100 text-truncate d-block">
          {{ campaign.message }}
        </small>
        <small class="invisible">&nbsp;</small>
      </div>
    </td>
    <td>
      <div>
        <span v-if="statusTotal">
          {{ statusTotal.toLocaleString() }} /
          {{ campaign.recipientCount.toLocaleString() }}
        </span>
        <span class="text-muted" v-else>Amount determined at send time</span>
      </div>
      <span class="badge bg-light" v-for="tag of campaign.tags" :key="tag.id">{{ tag.name }}</span>
    </td>
    <td class="text-center">
      <div class="progress mb-2 position-relative">
        <div v-if="statusTotal" class="position-absolute text-white w-100 text-center pe-none">
          {{ Math.round((statusTotal / campaign.recipientCount) * 100) }}%
        </div>
        <div
          v-tooltip="'Delivered'"
          class="progress-bar bg-success"
          role="progressbar"
          :style="{ width: `${fullyDeliveredPercentage * 100}%` }"
        ></div>
        <div v-tooltip="'Awaiting status'" class="progress-bar" role="progressbar" :style="{ width: `${sentToGatewayPercentage * 100}%` }"></div>
        <div v-tooltip="'Delivery failed'" class="progress-bar bg-danger" role="progressbar" :style="{ width: `${failedPercentage * 100}%` }"></div>
      </div>
      <span v-if="statusTotal">
        {{ statusTotal.toLocaleString() }} /
        {{ campaign.recipientCount.toLocaleString() }}
      </span>
      <span v-else>
        {{ campaign.recipientCount === 1 ? `${campaign.recipientCount} recipient` : `${campaign.recipientCount.toLocaleString()} recipients` }}
      </span>
    </td>
    <td>
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle rounded-pill" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
        <ul class="dropdown-menu">
          <li
            v-if="showStatisticsModal === 'enabled' && (campaign.sendStatus === SendStatus.Sending || campaign.sendStatus === SendStatus.Completed)"
          >
            <a class="dropdown-item" href="#" @click.prevent="router.push(`/campaigns/${campaign.id}/statistics`)">
              <i class="fe fe-bar-chart-2 me-3"></i>
              <span>View statistics</span>
            </a>
          </li>
          <li v-if="campaign.sendStatus === SendStatus.Draft">
            <a class="dropdown-item" href="#" @click.prevent="router.push({ name: 'SmsCampaignEditor', params: { id: campaign.id } })">
              <i class="fe fe-edit me-3"></i>
              <span>Edit draft</span>
            </a>
          </li>
          <li v-if="![SendStatus.Completed, SendStatus.Draft].includes(campaign.sendStatus)">
            <a class="dropdown-item" href="#" @click.prevent="pauseOrResume(campaign.id)">
              <i class="fe me-3" :class="campaign.paused ? 'fe-play' : 'fe-pause'"></i>
              <span>{{ campaign.paused ? 'Resume campaign' : 'Pause campaign' }}</span>
            </a>
          </li>
          <li v-if="campaign.sendStatus !== SendStatus.Sending">
            <a class="dropdown-item" href="#" @click.prevent="renameCampaign(campaign.id)">
              <i class="fe fe-edit-3 me-3"></i>
              <span>Rename campaign</span>
            </a>
          </li>
          <li v-if="![SendStatus.Completed].includes(campaign.sendStatus)">
            <a class="dropdown-item" href="#" @click.prevent="showSettingsModal = true">
              <i class="fe fe-settings me-3"></i>
              <span>Sending settings</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click.prevent="duplicateCampaign(campaign.id)">
              <i class="fe fe-copy me-3"></i>
              <span>Duplicate and edit</span>
            </a>
          </li>
          <li v-if="campaign.sendStatus !== SendStatus.Sending || campaign.paused">
            <a class="dropdown-item text-danger" href="#" @click.prevent="deleteCampaign(campaign)">
              <i class="fe fe-trash me-3"></i>
              <span>Delete</span>
            </a>
          </li>
        </ul>
      </div>
    </td>
    <div v-if="deleting" class="delete-overlay">
      <Loading text="Deleting" />
    </div>
  </tr>
  <Settings v-if="showSettingsModal" @close="showSettingsModal = false" :campaign-id="campaign.id" :status="campaign.sendStatus" />
</template>

<script lang="ts" setup>
  import { CampaignModelResult, CreateSendStatus, SendStatus } from '@/dto/graphql';
  import { computed, PropType, provide, ref, Ref, toRef } from 'vue';
  import { useQuery } from 'villus';
  import { getAccessToken } from '@/services/Authentication';
  import { useRouter } from 'vue-router';
  import vTooltip from '@/directives/vTooltip';
  import { CampaignDelete, CampaignUpdateSendingStatus, CampaignUpsert } from '@/services/GraphqlApi';
  import { useToast } from 'vue-toastification';
  import _ from 'lodash';
  import { currentTheme, Theme } from '@/services/Theme';
  import Settings from '@/components/sms-campaign-editor/settings/Settings.vue';
  import { useDialog } from '@/services/Dialog';
  import Loading from '../shared/Loading.vue';

  const createDialog = useDialog();

  const props = defineProps({
    campaign: {
      type: Object as PropType<CampaignModelResult>,
      required: true,
    },
  });

  const emit = defineEmits(['deleted', 'update']);

  const toast = useToast();
  const router = useRouter();

  const showSettingsModal = ref(false) as Ref<boolean>;
  const showStatisticsModal = ref(import.meta.env.VITE_EXPERIMENTAL_CAMPAIGN_STATISTICS) as Ref<string>;

  const campaign = toRef(props, 'campaign') as Ref<CampaignModelResult>;

  const fullyDeliveredPercentage = computed(() => campaign.value.statusSent / Math.max(1, campaign.value.recipientCount));
  const sentToGatewayPercentage = computed(() => campaign.value.statusReceived / Math.max(1, campaign.value.recipientCount));
  const failedPercentage = computed(() => campaign.value.statusFailed / Math.max(1, campaign.value.recipientCount));
  const statusTotal = computed(() => campaign.value.statusSent + campaign.value.statusReceived + campaign.value.statusFailed);
  const deleting = ref(false);

  provide('messageClass', toRef(campaign.value, 'messageClass'));
  provide('sendTime', toRef(campaign.value, 'sendTime'));
  provide('throttle', toRef(campaign.value, 'throttle'));
  provide('allowedHours', toRef(campaign.value, 'allowedHours'));
  provide('recipientCount', toRef(campaign.value, 'recipientCount'));
  provide('message', toRef(campaign.value, 'message'));
  provide('sendStatus', toRef(campaign.value, 'sendStatus'));

  const deleteCampaign = async (campaign: CampaignModelResult) => {
    try {
      const res = await createDialog({
        title: `Delete ${_.escape(campaign.campaignName)}?`,
        content: `This action is <strong>irreversible</strong>.<br><br>Do you want to continue?`,
        confirmText: 'Delete campaign',
      });

      if (!res) return;

      deleting.value = true;
      await CampaignDelete(campaign.id);
      toast.success(`Campaign ${campaign.campaignName} deleted.`, { timeout: 3000 });
      emit('deleted');
      deleting.value = false;
    } catch (e) {
      toast.error(`Campaign ${campaign.campaignName} could not be deleted.`);
    }
  };

  const pauseOrResume = async (campaignId: string) => {
    try {
      await CampaignUpdateSendingStatus({
        campaignId: campaignId,
        paused: !campaign.value.paused,
      });
      toast.success(`Campaign has been ${campaign.value.paused ? 'resumed' : 'paused'}`, { timeout: 3000 });
      emit('update');
    } catch (error) {
      toast.error(`Could not pause or resume campaign`, { timeout: 3000 });
    }
  };

  const getOriginalCampaign = async (campaignId: string) => {
    const accessToken = await getAccessToken();
    const { data: originalCampaignRaw } = await useQuery({
      query: `query ($campaignId: String!) {
            campaignGet(campaignId: $campaignId) {
              campaignName
              campaignTags {
                id
              }
              sendTime
              message
              messageClass
              messageEncoding
              sender
              tags {
                id
              }
              throttle
            }
          }
        `,
      context: { headers: { Authorization: `Bearer ${accessToken}` } },
      variables: { campaignId },
    });

    // seems impossible, but to please TS
    if (!originalCampaignRaw.value?.campaignGet) throw new Error('Campaign not found');

    return originalCampaignRaw.value.campaignGet;
  };

  const duplicateCampaign = async (campaignId: string) => {
    try {
      const originalCampaign: CampaignModelResult = await getOriginalCampaign(campaignId);

      const newCampaign = await CampaignUpsert({
        ...originalCampaign,
        campaignTags: originalCampaign.campaignTags.map((tag) => tag.id),
        campaignName: `${originalCampaign.campaignName} (copy)`,
        tags: originalCampaign.tags.map((tag) => tag.id),
        sendTime: new Date(),
        sendStatus: CreateSendStatus.Predraft,
      });
      toast.success(`Campaign ${originalCampaign.campaignName} duplicated.`, { timeout: 3000 });
      await router.push({ name: 'SmsCampaignEditor', params: { id: newCampaign } });
    } catch (error) {
      console.error(error);
      toast.error(`Campaign could not be duplicated.`);
    }
  };

  const renameCampaign = async (campaignId: string) => {
    const res = await createDialog({
      title: 'Change campaign name',
      content: 'Enter a campaign name',
      defaultInputValue: campaign.value.campaignName,
      showInput: true,
      inputLabel: 'Name',
      inputPlaceholder: 'Enter a campaign name',
      inputValidator(value: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
          if (!value) {
            reject('Campaign name cannot be empty.');
          }
          resolve(null);
        });
      },
    });

    if (!res) return;

    try {
      const originalCampaign: CampaignModelResult = await getOriginalCampaign(campaignId);

      await CampaignUpsert(
        {
          ...originalCampaign,
          campaignName: res as string,
          campaignTags: originalCampaign.campaignTags.map((tag) => tag.id),
          tags: originalCampaign.tags.map((tag) => tag.id),
          sendStatus: originalCampaign.sendStatus as unknown as CreateSendStatus,
        },
        campaignId,
      );
      toast.success(`Campaign renamed to ${res}.`, { timeout: 3000 });
      emit('update');
    } catch (error) {
      console.error(error);
      toast.error(`Campaign could not be renamed.`, { timeout: 3000 });
    }
  };
</script>

<style lang="scss" scoped>
  .pause-badge {
    animation: pulse 2s infinite;

    &.text-white {
      animation: pulse-dark 2s infinite;
    }
  }

  .delete-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    }

    70% {
      box-shadow: 0 0 0 5px rgba(255, 121, 63, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
  }

  @keyframes pulse-dark {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
    }

    70% {
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  .dropdown-item {
    display: flex;
    i {
      width: 18px;
      text-align: center3;
    }
  }
</style>
