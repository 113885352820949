<template>
  <nav class="navbar navbar-vertical fixed-start navbar-expand-md" :class="currentTheme === Theme.Dark ? 'navbar-light' : 'navbar-dark'" id="sidebar">
    <div class="container-fluid">
      <div class="d-flex align-items-center justify-content-between w-100 py-3">
        <!-- Brand -->
        <router-link class="navbar-brand" :to="{ name: 'Dashboard' }">
          <img src="@/assets/logo-RelationCity-white.svg" class="navbar-brand-img mx-auto" alt="RelationCity logo" />
        </router-link>

        <!-- Toggler -->
        <div class="d-block d-md-none">
          <button
            class="navbar-toggler align-self-start"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse" ref="mobileSidebarEl">
        <SelectAccount />

        <!-- Navigation -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Dashboard' }" active-class="active"> <i class="fe fe-home"></i> Dashboard </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Contacts' }" class="nav-link" active-class="active"> <i class="fe fe-users"></i> Contacts </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#" :aria-expanded="smsOpen" @click.prevent="smsOpen = !smsOpen">
              <i class="fe fe-smartphone"></i> SMS
            </a>
            <transition name="grow-submenu">
              <div v-if="smsOpen">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link :to="{ name: 'SmsQuickSend' }" class="nav-link" active-class="active"> Quick Send </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'SmsCampaignList' }" class="nav-link" active-class="active"> Campaigns </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'SmsTemplatesList' }" class="nav-link" active-class="active"> Templates </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'SmsInboxList' }" class="nav-link" active-class="active"> Inboxes </router-link>
                  </li>
                </ul>
              </div>
            </transition>
          </li>
        </ul>

        <hr class="navbar-divider my-3" />
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link :to="{ name: 'Blacklist' }" class="nav-link" active-class="active"> <i class="fe fe-x-circle"></i> Blacklist </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#" :aria-expanded="settingsOpen" @click.prevent="settingsOpen = !settingsOpen">
              <i class="fe fe-settings"></i> Settings
            </a>
            <transition name="grow-submenu">
              <div v-if="settingsOpen">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <a class="nav-link" active-class="active" :href="accountUrl">
                      <span class="flex-fill"> Account Settings </span><i class="fe fe-external-link me-n3"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'SMSConfiguration' }" class="nav-link" active-class="active"> SMS Configuration </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'GeneralSettings' }" class="nav-link" active-class="active"> General Settings </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'DataPolicy' }" class="nav-link" active-class="active"> Data Policy </router-link>
                  </li>
                </ul>
              </div>
            </transition>
          </li>
        </ul>

        <!-- Push content down -->
        <div class="mt-auto py-3"></div>

        <ul v-if="hasConnection" class="navbar-nav mb-3" style="margin-left: 0px">
          <h5 class="text-muted">Our other products</h5>
          <li class="nav-item">
            <a :href="gatewayAPIurl" class="nav-link p-0" active-class="active"> <span class="gatewayapi-icon"></span>GatewayAPI </a>
          </li>
        </ul>

        <div class="d-flex w-100 my-3 justify-content-between">
          <!-- Switch theme -->
          <a
            v-if="currentTheme === Theme.Light"
            href="#"
            @click.prevent="setTheme(Theme.Dark)"
            class="btn btn-rounded-circle btn-dark"
            v-tooltip
            title="Join the dark side"
          >
            <i class="fe fe-moon"></i>
          </a>
          <a
            v-if="currentTheme === Theme.Dark"
            @click.prevent="setTheme(Theme.Light)"
            href="#"
            class="btn btn-rounded-circle btn-dark"
            v-tooltip
            title="Let there be light"
          >
            <i class="fe fe-sun"></i>
          </a>

          <!-- Support -->
          <a
            v-if="ChatlioReady"
            href="#"
            class="btn btn-rounded-circle btn-secondary"
            v-tooltip
            title="Live chat support"
            @click.prevent="ShowChatlio"
          >
            <i class="fe fe-message-circle"></i>
          </a>

          <!-- Logout -->
          <button class="btn btn-rounded-circle btn-dark" v-tooltip title="Log out of RelationCity" @click="logout()">
            <i class="fe fe-log-out"></i>
          </button>
        </div>
      </div>
      <!-- / .navbar-collapse -->
    </div>
  </nav>
</template>

<style lang="scss">
  .grow-submenu-enter-active,
  .grow-submenu-leave-active {
    transition: all 0.2s linear;
    max-height: 200px;
    overflow: hidden;
  }

  .grow-submenu-enter-from,
  .grow-submenu-leave-to {
    max-height: 0;
  }
</style>

<style lang="scss" scoped>
  .gatewayapi-icon {
    height: 20px;
    width: 18px;
    background-image: url('@/assets/gatewayapi-grey.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.5;
    margin-left: -1px;
    margin-right: 11px;
  }

  .nav-item:hover {
    .gatewayapi-icon {
      opacity: 1;
    }
  }

  #sidebar {
    overflow-y: auto;

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    & {
      scrollbar-width: auto;
      scrollbar-color: var(--bs-gray-600);
    }

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #152e4d;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #152e4d;
      border-radius: 5px;
      border: 0px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: var(--bs-gray-600);
      }
    }
  }
</style>

<script lang="ts" setup>
  import { currentTheme, setTheme, Theme } from '@/services/Theme';
  import { useRoute } from 'vue-router';
  import { computed, inject, onMounted, Ref, ref, watch } from 'vue';
  import vTooltip from '@/directives/vTooltip';
  import { ChatlioReady, ShowChatlio } from './Chatlio.vue';
  import { getOcidAccountUrl, getOcidUrl } from '@/services/Env';
  import { ConnectionGatewayapi } from '@/services/GraphqlApi';
  import { ConnectionModelResult, Providers } from '@/dto/graphql';
  import SelectAccount from './shared/SelectAccount.vue';
  import { currentAccount, logout } from '@/services/Authentication';

  const mobileSidebarEl = ref() as Ref<HTMLDivElement>;
  const route = useRoute();

  const hasConnection = inject('hasConnection') as Ref<boolean>;

  const smsOpen = ref(false);
  const settingsOpen = ref(false);
  const connectionDetails = ref<ConnectionModelResult | null>(null);

  onMounted(async () => {
    const con = await ConnectionGatewayapi();
    if (con) connectionDetails.value = con;
  });

  const gatewayAPIurl = computed(() => {
    if (connectionDetails.value?.provider === Providers.GatewayApi) {
      return 'https://auth.onlinecity.io/gatewayapi';
    } else if (connectionDetails.value?.provider === Providers.GatewayApiEu) {
      return 'https://auth.onlinecity.io/gatewayapi-eu';
    } else {
      return 'https://auth.onlinecity.io/gatewayapi-eu';
    }
  });

  watch(
    route,
    (r) => {
      smsOpen.value = r.meta.module === 'sms';
      if (mobileSidebarEl.value) {
        mobileSidebarEl.value.style.height = mobileSidebarEl.value.scrollHeight + 'px';
        mobileSidebarEl.value.classList.add('collapsing');
        mobileSidebarEl.value.classList.remove('collapse');
        mobileSidebarEl.value.classList.remove('show');
        setTimeout(() => {
          mobileSidebarEl.value.style.height = 0 + 'px';
        });
        setTimeout(() => {
          mobileSidebarEl.value.classList.remove('collapsing');
          mobileSidebarEl.value.classList.add('collapse');
          mobileSidebarEl.value.style.height = 'auto';
        }, 500);
      }
    },
    { immediate: true },
  );

  const accountUrl = getOcidAccountUrl(currentAccount?.id || '');
</script>
