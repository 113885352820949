<template>
  <div class="dropdown">
    <button href="#" class="btn btn-light dropdown-toggle me-3" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Insert tags
    </button>

    <div class="dropdown-menu dropdown-menu-end">
      <button type="button" class="dropdown-item" @click="emit('insert', '%NAME%')">Name</button>
      <button type="button" class="dropdown-item" v-for="tag in contactStore.contactFields" @click="emit('insert', `%${tag.tagName}%`)">
        {{ tag.name }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted } from 'vue';
  import { useContactsStore } from '@/stores/contacts';

  const contactStore = useContactsStore();

  const emit = defineEmits(['insert']);

  onMounted(async () => {
    await contactStore.fetchContactFields();
  });
</script>
