<template>
  <div class="card">
    <div class="card-body">
      <h6 class="text-uppercase text-muted mb-3">Statuses last 30 days</h6>
      <div v-show="statistics && statistics?.data.length > 0" class="chart chart-appended">
        <canvas class="chart-canvas" ref="recipientChart"></canvas>
      </div>
      <Loading v-if="loadingStatistics" />
      <EmptyState v-else-if="statistics && statistics.data.length === 0" text="No statistics yet." />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, onMounted, Ref, ref } from 'vue';
  import { StatisticDailyRecipientsModel } from '@/dto/graphql';
  import { StatisticsRecipientsDaily } from '@/services/GraphqlApi';
  import { subDays } from 'date-fns';
  import { Chart, ChartData } from 'chart.js';
  import Loading from '../shared/Loading.vue';
  import EmptyState from '../shared/EmptyState.vue';
  import initChartJsStyling from '@/assets/js/chart-styling';

  const loadingStatistics = ref(true);
  const recipientChart = ref(null) as Ref<HTMLCanvasElement | null>;
  const statistics = ref(null) as Ref<StatisticDailyRecipientsModel | null>;

  const getCSSVariableValue = (variable: string) => {
    return getComputedStyle(document.documentElement).getPropertyValue(variable);
  };

  onMounted(async () => {
    const response = await StatisticsRecipientsDaily(subDays(new Date(), 30), new Date());
    statistics.value = response;
    initChartJsStyling();

    const chartData = computed(() => {
      if (!statistics.value) return null;

      return {
        labels: ['Successful', 'Failed', 'Waiting'],
        datasets: [
          {
            data: [statistics.value.statusTotalSuccess, statistics.value.statusTotalFailure, statistics.value.statusTotalWaiting],
            backgroundColor: [getCSSVariableValue('--bs-green'), getCSSVariableValue('--bs-red'), getCSSVariableValue('--bs-yellow')],
          },
        ],
      };
    });

    if (!recipientChart.value) return;
    new Chart(recipientChart.value, {
      type: 'doughnut',
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              afterLabel: function () {
                return ' recipients';
              },
            },
          },
        },
      },
      data: chartData.value as ChartData,
    });

    loadingStatistics.value = false;
  });
</script>

<style scroped lang="scss">
  .chart {
    text-align: center;

    canvas {
      display: inline !important;
      max-height: 300px !important;
      max-width: 300px !important;
    }
  }
</style>
