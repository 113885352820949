<template>
  <Teleport to="body">
    <Transition>
      <div class="modal-wrapper" v-if="state.isOpen">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">{{ state.options.title }}</h3>
              <button type="button" class="btn-close" @click="cancel"></button>
            </div>
            <div class="modal-body">
              <div v-html="state.options.content"></div>
              <form @submit.prevent="confirm">
                <label v-if="state.options.showInput" class="w-100 mt-4" for="input">
                  {{ state.options.inputLabel }}
                  <input
                    type="text"
                    autofocus
                    class="form-control mt-1"
                    :class="{ 'border-danger': inputError }"
                    id="input"
                    :placeholder="state.options.inputPlaceholder"
                    v-model="state.input"
                  />
                  <small v-if="inputError" class="text-danger text-sm mt-2">{{ inputError }}</small>
                </label>
              </form>
            </div>
            <div v-if="state.options.showCancel || state.options.showConfirm" class="modal-footer">
              <button v-if="state.options.showCancel" @click="cancel" type="button" class="btn btn-outline-secondary">
                {{ state.options.cancelText }}
              </button>
              <button v-if="state.options.showConfirm" @click="confirm" type="button" class="btn btn-primary">{{ state.options.confirmText }}</button>
            </div>
          </div>
        </div>
        <div class="modal-background" @click.prevent="cancel"></div>
      </div>
    </Transition>
  </Teleport>
  <slot />
</template>

<script lang="ts" setup>
  import { CreateDialogKey, CreateDialogOptions } from '@/services/Dialog';
  import { reactive, provide, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  const route = useRoute();
  const inputError = ref<string | null>(null);

  const initialState = {
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resolve: (_val: boolean | string) => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    reject: (_val: boolean) => {},
    input: '',
    options: {
      content: '',
      title: '',
      showInput: false,
      inputLabel: '',
      inputPlaceholder: '',
      cancelText: 'Cancel',
      confirmText: 'Confirm',
      showCancel: true,
      showConfirm: true,
    } as CreateDialogOptions,
  };

  const state = reactive({ ...initialState });

  const resetState = () => {
    Object.assign(state, initialState);
    inputError.value = null;
  };

  const createDialog = (options: CreateDialogOptions) => {
    // Only allow one dialog to be open at a time
    if (state.isOpen) {
      throw new Error('Dialog is already open');
    }

    resetState();

    Object.assign(state, {
      isOpen: true,
      input: options.defaultInputValue || '',
      options: {
        ...initialState.options,
        ...options,
      },
    });

    return new Promise<boolean | string>((resolve, reject) => {
      state.resolve = resolve;
      state.reject = reject;
    });
  };

  provide(CreateDialogKey, createDialog);

  const confirm = () => {
    if (state.options.inputValidator) {
      state.options
        .inputValidator(state.input)
        .then(() => {
          state.resolve(state.input);
          resetState();
        })
        .catch((err) => {
          inputError.value = err;
        });
    } else {
      state.resolve(true);
      resetState();
    }
  };

  const cancel = () => {
    state.resolve(false);
    state.isOpen = false;
  };

  //If route changes, cancel the dialog
  watch(
    () => route.name,
    (val, oldVal) => {
      if (val !== oldVal) {
        cancel();
      }
    },
  );
</script>

<style lang="scss" scoped>
  .modal-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.5);

    .modal-dialog {
      width: 100%;
      max-width: 500px;
    }

    .modal-background {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.1s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
