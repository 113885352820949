<template>
  <Teleport to="body">
    <div class="modal" ref="updateDpaModalEl" tabindex="-1">
      <form method="post" @submit.prevent="updateDPA">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="staticBackdropLabel">Sign DPA</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div class="modal-body">
              <CreateAccountStep3 v-model:agreement-read="agreementRead" v-model:signee-company-name="signeeCompanyName"
                v-model:signee-vat-no="signeeVatNo" v-model:signee-name="signeeName" v-model:confirmation="confirmation"
                v-model:signing-status="signingStatus" :signee-company-name-error-message="signeeCompanyNameErrorMessage"
                :signee-vat-no-error-message="signeeVatNoErrorMessage" :signee-name-error-message="signeeNameErrorMessage"
                :confirmation-error-message="confirmationErrorMessage" />
            </div>
            <div class="modal-footer d-flex justify-content-end">
              <button type="submit" class="btn btn-primary">Update</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import CreateAccountStep3 from './CreateAccountStep3.vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { onMounted, onBeforeUnmount, ref, Ref } from 'vue';
import { currentAccount } from '@/services/Authentication';
import { accountDpaUpdate } from '@/services/GraphqlApi';
import { useToast } from 'vue-toastification';
import { DpaModelResult } from '@/dto/graphql';
import { Modal } from 'bootstrap';

const emit = defineEmits(['close', 'update']);
const props = defineProps<{
  dpa?: DpaModelResult;
}>();
const toast = useToast();

const agreementRead = ref(false);

const { handleSubmit } = useForm({
  initialValues: {
    name: currentAccount?.name,
    signingStatus: props.dpa?.signingStatus || false,
    signeeCompanyName: props.dpa?.signeeCompanyName || '',
    signeeVatNo: props.dpa?.signeeVatNo || '',
    signeeName: props.dpa?.signeeName || '',
    confirmation: false,
  },
});

const { value: signingStatus, errorMessage: signingStatusErrorMessage } = useField('signingStatus', yup.boolean().label('Signing status'));
const { value: signeeCompanyName, errorMessage: signeeCompanyNameErrorMessage } = useField(
  'signeeCompanyName',
  yup
    .string()
    .label('Company name')
    .when([], {
      is: () => signingStatus.value,
      then: yup.string().required('Please enter company name'),
    }),
);
const { value: signeeVatNo, errorMessage: signeeVatNoErrorMessage } = useField(
  'signeeVatNo',
  yup
    .string()
    .label('VAT ID')
    .when([], {
      is: () => signingStatus.value,
      then: yup.string().required('Please enter VAT ID'),
    }),
);
const { value: signeeName, errorMessage: signeeNameErrorMessage } = useField(
  'signeeName',
  yup
    .string()
    .label('Contact name')
    .when([], {
      is: () => signingStatus.value,
      then: yup.string().required('Please enter your name'),
    }),
);
const { value: confirmation, errorMessage: confirmationErrorMessage } = useField(
  'confirmation',
  yup
    .boolean()
    .label('Confirmation')
    .when([], {
      is: () => signingStatus.value,
      then: yup
        .boolean()
        .required('You must confirm that you have read the agreement')
        .oneOf([true], 'You must confirm that you have read the agreement'),
    }),
);

const updateDPA = handleSubmit(async (values) => {
  await accountDpaUpdate({
    dpaVersion: '1.0',
    signeeCompanyName: values.signeeCompanyName,
    signeeVatNo: values.signeeVatNo,
    signeeName: values.signeeName,
    signingStatus: values.signingStatus,
    signedDate: new Date().toISOString(),
  })
    .then(() => {
      emit('update');
      emit('close');
      toast.success('DPA updated', {
        timeout: 3000,
      });
    })
    .catch((error) => {
      toast.error(error.message, {
        timeout: 3000,
      });
    });
});


const updateDpaModalEl = ref<HTMLElement>() as Ref<HTMLElement>;
onMounted(async () => {
  const modal = new Modal(updateDpaModalEl.value);
  modal.show();

  onBeforeUnmount(async () => {
    modal.hide();
  });

  updateDpaModalEl.value.addEventListener('hidden.bs.modal', () => {
    emit('close');
    modal.dispose();
  });
});
</script>