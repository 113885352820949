<template>
  <div class="container-fluid vh-100 d-lg-flex flex-column">
    <!-- HEADER -->
    <div class="header">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h6 class="header-pretitle">Contacts</h6>
            <h1 class="header-title py-2">List of Contacts</h1>
          </div>

          <div class="col-auto">
            <button
              class="btn btn-primary btn-lg d-flex align-items-center"
              @click.prevent="
                currentContact = null;
                isCreatingNewContact = true;
              "
            >
              <span class="fe fe-plus me-3"></span>
              <span>Add contact</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- HEADER -->

    <!-- BODY -->
    <div class="row flex-lg-fill">
      <div class="col-lg-6 col-xl-3" :class="{ 'd-none d-xl-block': contactsEditorOpen }">
        <TagsList :key="reloadKey" @reload="reloadKey++" />
      </div>
      <div class="col-lg-6" :class="{ 'col-xl-4 d-none d-xl-block': contactsEditorOpen, 'col-xl-9': !contactsEditorOpen }">
        <ContactsList @reload="reloadKey++" :key="reloadKey" />
      </div>
      <div v-if="contactsEditorOpen" class="col-xl-5">
        <ContactEditor :key="currentContact?.id" @success="onContactSaved" />
      </div>
    </div>
    <!-- BODY -->
  </div>
</template>

<script lang="ts" setup>
  import { ContactList, ContactTagList } from '@/services/GraphqlApi';
  import { computed, provide, Ref, ref, watch, onMounted } from 'vue';
  import { ContactListModelResult, ContactModel, ContactModelResult, ContactTagModelResult } from '@/dto/graphql';
  import { useContactsStore } from '@/stores/contacts';
  import TagsList from '@/components/contacts/TagsList.vue';
  import ContactsList from '@/components/contacts/ContactsList.vue';
  import ContactEditor from '@/components/contacts/ContactEditor.vue';

  const contactStore = useContactsStore();

  const currentTag = ref('') as Ref<string>;
  const currentContact = ref(null) as Ref<ContactModelResult | null>;
  const isCreatingNewContact = ref(false);

  const contacts = ref({}) as Ref<ContactListModelResult>;
  const contactTags = ref({}) as Ref<ContactTagModelResult[]>;

  const reloadKey = ref(0);

  onMounted(() => {
    contactStore.fetchContactFields();
  });

  provide('contacts', contacts);
  provide('currentContact', currentContact);

  provide('contactTags', contactTags);
  provide('currentTag', currentTag);

  provide('customContactFields', contactStore.contactFields);

  provide('isCreatingNewContact', isCreatingNewContact);

  const contactsEditorOpen = computed(() => {
    return isCreatingNewContact.value || currentContact.value;
  });

  // when a contact has been saved, update the tags list
  const onContactSaved = async () => {
    contactTags.value = await ContactTagList();
    reloadKey.value++;
  };
</script>
