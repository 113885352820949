<template>
  <Datepicker
    v-model="value"
    :dark="isDarkMode"
    placeholder="Select date"
    :clearable="false"
    :month-change-on-scroll="false"
    :enable-time-picker="false"
  ></Datepicker>
</template>

<script setup lang="ts">
  import { PropType, watch, ref, computed } from 'vue';
  import { ContactFieldDateModel } from '@/dto/graphql';
  import Datepicker from '@vuepic/vue-datepicker';
  import { currentTheme, Theme } from '@/services/Theme';

  const isDarkMode = computed(() => currentTheme.value === Theme.Dark);

  const emit = defineEmits(['update:modelValue']);

  const props = defineProps({
    modelValue: {
      type: [Date, String],
      default: '',
    },
    field: {
      type: Object as PropType<ContactFieldDateModel>,
      required: true,
    },
  });

  const value = ref(props.modelValue);

  watch(value, (v) => {
    emit('update:modelValue', v);
  });
</script>

<style scoped></style>
