<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title align-items-center me-auto">Basic details</h4>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label for="replySender" class="w-100">
          Reply sender:
          <input type="text" class="form-control" id="replySender" placeholder="Reply sender" v-model="inbox.ruleset.sender" />
        </label>
      </div>
      <label>
        <p class="mb-1">Is active?</p>
        <div class="form-check form-check-inline">
          <label class="form-check-label" for="inboxActive1">
            <input :value="true" v-model="inbox.ruleset.active" class="form-check-input" type="radio" name="inlineRadioOptions" id="inboxActive1" />
            Active
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label" for="inboxActive2">
            <input :value="false" v-model="inbox.ruleset.active" class="form-check-input" type="radio" name="inlineRadioOptions" id="inboxActive2" />
            Inactive
          </label>
        </div>
      </label>
      <div class="form-group">
        <label class="w-100 mt-4">
          <p class="mb-1">Active from/to:</p>
          <Datepicker
            v-model="dateRange"
            range
            :timezone="accountSettings?.timezone"
            :dark="isDarkMode"
            placeholder="Select date"
            :month-change-on-scroll="false"
          />
        </label>
      </div>
      <div class="form-group mt-4">
        <span
          v-if="inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel' || inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel'"
        >
          <label :class="inboxId === 'new' && 'mt-4'">
            <p class="mb-1">Require confirmation</p>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="requireConfirmation1">
                <input
                  :value="true"
                  v-model="inbox.ruleset.requireConfirmation"
                  class="form-check-input"
                  type="radio"
                  name="requireConfirmation1"
                  id="requireConfirmation1"
                />
                Yes, require confirmation
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="requireConfirmation2">
                <input
                  :value="false"
                  v-model="inbox.ruleset.requireConfirmation"
                  class="form-check-input"
                  type="radio"
                  name="requireConfirmation2"
                  id="requireConfirmation2"
                />
                No, charge instantly
              </label>
            </div>
          </label>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, inject, Ref } from 'vue';
  import { useRouter } from 'vue-router';
  import Datepicker from '@vuepic/vue-datepicker';
  import {
    AccountSettingsModel,
    SmsInboxModelResult,
    SmsInboxRulesetAutoreplyModel,
    SmsInboxRulesetPaymentDynamicModel,
    SmsInboxRulesetPaymentFixedModel,
  } from '@/dto/graphql';
  import { currentTheme, Theme } from '@/services/Theme';

  const isDarkMode = computed(() => currentTheme.value === Theme.Dark);

  const router = useRouter();
  const inboxId = router.currentRoute.value.params.id;

  type TInbox = SmsInboxModelResult & {
    ruleset: SmsInboxRulesetAutoreplyModel | SmsInboxRulesetPaymentDynamicModel | SmsInboxRulesetPaymentFixedModel;
  };

  const inbox = inject('inbox') as Ref<TInbox>;
  const accountSettings = inject('accountSettings') as Ref<AccountSettingsModel | null>;

  const dateRange = computed({
    get() {
      return [inbox.value.ruleset.activeFrom || undefined, inbox.value.ruleset.activeTo || undefined];
    },
    set(value) {
      if (!value) {
        inbox.value.ruleset.activeFrom = undefined;
        inbox.value.ruleset.activeTo = undefined;
        return;
      }

      inbox.value.ruleset.activeFrom = value[0];
      inbox.value.ruleset.activeTo = value[1];
    },
  });
</script>
