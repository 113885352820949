<template>
  <Loading v-if="loadingInbox" />
  <div v-else-if="inbox" class="container-fluid">
    <header class="header">
      <div class="header-body">
        <h6 class="header-pretitle">SMS Inbox</h6>
        <div class="row align-items-center">
          <div class="col">
            <div class="py-2 row align-items-center">
              <div class="col-2">
                <div class="col-3 input-group input-group-merge input-group-reverse">
                  <input
                    v-model.number="inbox.number"
                    required
                    type="phone"
                    class="form-control h2 d-none d-sm-block"
                    placeholder="1204"
                    aria-label="Receiving number"
                    aria-describedby="sendingNumber"
                  />
                  <div class="input-group-text" id="sendingNumber">
                    <span class="fe fe-hash"></span>
                  </div>
                </div>
              </div>
              <div class="col">
                <input
                  required
                  v-model="keyword"
                  type="text"
                  class="form-control h2 my-0 d-none d-sm-block text-uppercase"
                  placeholder="DONATE etc."
                  aria-label="Keyword"
                  @keydown.space.prevent
                />
              </div>
            </div>
          </div>

          <div class="col-auto d-flex align-items-end flex-column flex-lg-row align-items-lg-center">
            <button @click="saveInbox()" type="button" class="btn btn-primary btn-lg d-flex align-items-center me-2">
              <span>Save changes</span>
            </button>
          </div>
        </div>
      </div>
    </header>
    <main class="row">
      <div class="col-lg-6 col-xl-5 col-xxl-4">
        <BasicDetails />
        <GatewayConnection />
      </div>
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title align-items-center me-auto">Ruleset</h4>
          </div>
          <div class="card-body">
            <RulesetAutoreply v-if="inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel'" />
            <div v-if="inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel'">
              <RulesetAutoreplyMultiple />
              <hr />
              <MultipleNoKeywordError />
            </div>
            <RulesetPayments
              v-if="
                inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel' || inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel'
              "
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
  import { computed, onMounted, provide, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useToast } from 'vue-toastification';
  import BasicDetails from '@/components/sms-inbox-editor/BasicDetails.vue';
  import GatewayConnection from '../components/sms-inbox-editor/GatewayConnection.vue';
  import RulesetAutoreply from '../components/sms-inbox-editor/ruleset/RulesetAutoreply.vue';
  import RulesetAutoreplyMultiple from '../components/sms-inbox-editor/ruleset/RulesetAutoreplyMultiple.vue';
  import RulesetPayments from '@/components/sms-inbox-editor/ruleset/RulesetPayments.vue';
  import MultipleNoKeywordError from '@/components/sms-inbox-editor/elements/MultipleNoKeywordError.vue';

  import {
    AccountSettingsModel,
    SmsInboxModel,
    SmsInboxModelResult,
    SmsInboxRulesetAutoreplyModel,
    SmsInboxRulesetAutoreplyMultipleModel,
    SmsInboxRulesetAutoreplyModelInput,
    SmsInboxRulesetPaymentDynamicModel,
    SmsInboxRulesetPaymentFixedModel,
    SmsInboxRulesetAutoreplyMultipleModelInput,
  } from '@/dto/graphql';
  import {
    SmsInboxGet,
    SmsInboxRulesetAutoreplyUpsert,
    SmsInboxUpsert,
    SmsInboxRulesetPaymentFixedUpsert,
    SmsInboxRulesetPaymentDynamicUpsert,
    accountSettingsGet,
    SmsInboxRulesetAutoreplyMultipleUpsert,
  } from '@/services/GraphqlApi';
  import Loading from '@/components/shared/Loading.vue';
  import { setTitle } from '@/services/SetTitle';

  const router = useRouter();
  const toast = useToast();
  type TInbox = SmsInboxModelResult & {
    ruleset:
      | SmsInboxRulesetAutoreplyModel
      | SmsInboxRulesetAutoreplyMultipleModel
      | SmsInboxRulesetPaymentDynamicModel
      | SmsInboxRulesetPaymentFixedModel;
  };

  const inbox = ref<TInbox>();
  const loadingInbox = ref(true);
  const inboxId = router.currentRoute.value.params.id;

  const accountSettings = ref<AccountSettingsModel | null>(null);
  provide('accountSettings', accountSettings);

  const keyword = computed({
    get: () => inbox.value?.keyword || '',
    set: (value: string) => {
      if (inbox.value) {
        inbox.value.keyword = value.replace(/\s/g, '').toLocaleUpperCase();
      }
    },
  });

  provide('inbox', inbox);

  onMounted(async () => {
    await SmsInboxGet(inboxId as string)
      .then((result: SmsInboxModelResult) => {
        inbox.value = result as TInbox;
        setTitle(`SMS Inbox ${inbox.value.number}`);
      })
      .catch(() => {
        toast.error('Inbox not found');
        router.push({ name: 'SmsInboxList' });
      })
      .finally(() => {
        loadingInbox.value = false;
      });

    accountSettingsGet().then((res) => {
      accountSettings.value = res;
    });
  });

  const saveInbox = async () => {
    if (inbox.value && inboxId) {
      let savedRuleset;
      try {
        if (inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel') {
          await SmsInboxRulesetAutoreplyUpsert(
            {
              active: inbox.value.ruleset.active,
              activeFrom: inbox.value.ruleset.activeFrom || null,
              activeTo: inbox.value.ruleset.activeTo || null,
              replyWithMessage: (inbox.value.ruleset as SmsInboxRulesetAutoreplyModel).replyWithMessage,
              replyContent: (inbox.value.ruleset as SmsInboxRulesetAutoreplyModel).replyContent,
              sender: (inbox.value.ruleset as SmsInboxRulesetAutoreplyModel).sender,
            } as SmsInboxRulesetAutoreplyModelInput,
            inbox.value.ruleset.id,
          ).then((ruleset: string) => {
            savedRuleset = ruleset;
          });
        } else if (inbox.value.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel') {
          await SmsInboxRulesetAutoreplyMultipleUpsert(
            {
              active: inbox.value.ruleset.active,
              activeFrom: inbox.value.ruleset.activeFrom || null,
              activeTo: inbox.value.ruleset.activeTo || null,
              keywords: (inbox.value.ruleset as SmsInboxRulesetAutoreplyMultipleModel).keywords,
              sendErrorMessage: (inbox.value.ruleset as SmsInboxRulesetAutoreplyMultipleModel).sendErrorMessage,
              errorMessage: (inbox.value.ruleset as SmsInboxRulesetAutoreplyMultipleModel).errorMessage,
              sender: (inbox.value.ruleset as SmsInboxRulesetAutoreplyMultipleModel).sender,
            } as SmsInboxRulesetAutoreplyMultipleModelInput,
            inbox.value.ruleset.id,
          ).then((ruleset: string) => {
            savedRuleset = ruleset;
          });
        } else if (inbox.value.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel') {
          await SmsInboxRulesetPaymentFixedUpsert(
            {
              active: inbox.value.ruleset.active,
              activeFrom: inbox.value.ruleset.activeFrom || null,
              activeTo: inbox.value.ruleset.activeTo || null,
              errChargeContent: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).errChargeContent,
              errInvalidContent: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).errInvalidContent,
              errNoConfirmationContent: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).errNoConfirmationContent,
              errNoConfirmationTime: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).errNoConfirmationTime,
              errNoInitialContent: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).errNoInitialContent,
              initialContent: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).initialContent,
              maxTimeCompletion: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).maxTimeCompletion,
              paymentConfirmWord: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).paymentConfirmWord,
              paymentContent: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).paymentContent,
              paymentPrice: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).paymentPrice,
              requireConfirmation: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).requireConfirmation,
              sender: (inbox.value.ruleset as SmsInboxRulesetPaymentFixedModel).sender,
            },
            inbox.value.ruleset.id,
          ).then((ruleset: string) => {
            savedRuleset = ruleset;
          });
        } else if (inbox.value.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel') {
          await SmsInboxRulesetPaymentDynamicUpsert(
            {
              active: inbox.value.ruleset.active,
              activeFrom: inbox.value.ruleset.activeFrom || null,
              activeTo: inbox.value.ruleset.activeTo || null,
              errChargeContent: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).errChargeContent,
              errInvalidContent: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).errInvalidContent,
              errNoConfirmationContent: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).errNoConfirmationContent,
              errNoConfirmationTime: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).errNoConfirmationTime,
              errNoInitialContent: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).errNoInitialContent,
              initialContent: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).initialContent,
              maxTimeCompletion: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).maxTimeCompletion,
              paymentConfirmWord: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).paymentConfirmWord,
              paymentContent: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).paymentContent,
              paymentPriceMin: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).paymentPriceMin,
              paymentPriceMax: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).paymentPriceMax,
              requireConfirmation: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).requireConfirmation,
              sender: (inbox.value.ruleset as SmsInboxRulesetPaymentDynamicModel).sender,
            },
            inbox.value.ruleset.id,
          ).then((ruleset: string) => {
            savedRuleset = ruleset;
          });
        }
      } catch (error) {
        toast.error('Error saving inbox');
        console.error(error);
      } finally {
        if (savedRuleset) {
          await SmsInboxUpsert(
            {
              keyword: inbox.value?.keyword,
              number: `${inbox.value?.number}`,
              ruleset: savedRuleset,
            } as SmsInboxModel,
            inboxId !== 'new' ? (inboxId as string) : '',
          )
            .then(() => {
              toast.success('Inbox saved', { timeout: 2000 });
              router.push({ name: 'SmsInboxList' });
            })
            .catch((e) => {
              toast.error('Error saving inbox', { timeout: 2000 });
              return console.error(e);
            });
        }
      }
    }
  };
</script>

<style scoped></style>
