import gql from 'graphql-tag';
import { cache, CombinedError, createClient, dedup } from 'villus';
import {
  AuthorizeResponse,
  CampaignListModelResult,
  CampaignModelInput,
  CampaignModelResult,
  CampaignTagModel,
  CampaignTagModelResult,
  CampaignTemplateListModelResult,
  CampaignTemplateModel,
  ConnectionGatewayapiStatus,
  ConnectionModelResult,
  ContactFieldBaseModel,
  ContactFieldDateModelInput,
  ContactFieldTextModelInput,
  ContactListModelResult,
  ContactModel,
  ContactModelResult,
  ContactTagModel,
  ContactTagModelResult,
  AccountDataPolicyModel,
  AccountDataPolicyInput,
  MeModel,
  MessageClass,
  MessageEncoding,
  MutationCampaignSendingStatusUpdateArgs,
  Providers,
  SmsInboxListModelResult,
  SmsInboxModel,
  SmsInboxModelResult,
  SmsInboxRulesetAutoreplyModelInput,
  SmsInboxRulesetPaymentDynamicModelInput,
  SmsInboxRulesetPaymentFixedModelInput,
  SmsIncomingListModelResult,
  SmsSendResponse,
  StatisticCampaigns,
  StatisticContactCountriesModel,
  AccountSettingsModel,
  AccountSettingsInput,
  StatisticDailyContactsModel,
  StatisticDailyRecipientsModel,
  ThreadModelResult,
  DpaModelInput,
  DpaModelResult,
  BlacklistListModelResult,
  BlacklistModelResult,
  BlacklistTypes,
  SmsBulkSendResponse,
  SmsInboxRulesetAutoreplyMultipleModelInput,
} from '@/dto/graphql';
import { getAccessToken, getAuthenticationToken } from '@/services/Authentication';
import { batch } from '@villus/batch';

export const BATCH_TIMEOUT = 25;

const client = createClient({
  url: '/graphql',
  cachePolicy: 'network-only',
  use: [cache(), dedup(), batch({ timeout: BATCH_TIMEOUT })],
});
export default client;

export function getBestErrorMessage(ex: CombinedError): string {
  const err = ex?.graphqlErrors;
  if (!err || !err.length) return 'Internal error';

  // @ts-ignore
  const msg = err[0].extensions?.exception?.response?.message;
  if (msg && msg.length) return msg[0];

  // @ts-ignore
  const msg2 = err[0].extensions?.response?.message;
  if (msg2 && msg2.length) return msg2[0];

  return err[0].message;
}

export async function Authorize(authenticationToken: string): Promise<AuthorizeResponse> {
  const res = await client.executeMutation({
    query: gql`
      mutation authorize($authenticationToken: String!) {
        authorize(authenticationToken: $authenticationToken) {
          authenticationToken
        }
      }
    `,
    variables: { authenticationToken },
  });

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.authorize;
}

export async function AccountCreate(accountName: string, dpa: DpaModelInput, timezone: string): Promise<{ accessToken: string; accountId: string }> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation accountCreate($accountName: String!, $dpa: DpaModelInput!, $timezone: String!) {
          accountCreate(accountName: $accountName, dpa: $dpa, timezone: $timezone) {
            accessToken
            accountId
          }
        }
      `,
      variables: { accountName, dpa, timezone },
    },
    { headers: { Authorization: `Bearer ${await getAuthenticationToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.accountCreate;
}

export async function AuthorizeAccount(accountId: string): Promise<{ accessToken: string }> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation authorizeAccount($accountId: String!) {
          authorizeAccount(accountId: $accountId) {
            accessToken
          }
        }
      `,
      variables: { accountId },
    },
    { headers: { Authorization: `Bearer ${await getAuthenticationToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.authorizeAccount;
}

export async function Me(): Promise<MeModel> {
  const res = await client.executeMutation(
    {
      query: gql`
        query me {
          me {
            name
            email
            myAccounts {
              id
              name
              emailDomainWhitelist
              emailDomainWhitelistEnabled
              myRole {
                name
                account
                builtIn
                description
                capabilities
              }
            }
          }
        }
      `,
    },
    {
      headers: {
        Authorization: `Bearer ${await getAuthenticationToken()}`,
      },
    },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.me;
}

/** Connection API: Connection save mutation, for saving a GatewayAPI API key **/
export async function ConnectionGatewayapiSave(key: string, provider: Providers): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation connectionGatewayapiSave($key: String!, $provider: Providers!) {
          connectionGatewayapiSave(key: $key, provider: $provider)
        }
      `,
      variables: { key, provider },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.connectionGatewayapiSave;
}

export async function ConnectionGatewayapiStatusGet(): Promise<ConnectionGatewayapiStatus | undefined> {
  const res = await client.executeMutation(
    {
      query: gql`
        query {
          connectionGatewayapiStatus {
            credit
            currency
            id
          }
        }
      `,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) return;
  return res.data.connectionGatewayapiStatus;
}

/** Connection API: Save connection defaults  **/
export async function ConnectionDefaultsSave(defaultSenderId: string): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation connectionDefaultsSave($defaultSenderId: String!) {
          connectionDefaultsSave(defaultSenderId: $defaultSenderId)
        }
      `,
      variables: { defaultSenderId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.connectionDefaultsSave;
}

/** SMS API: SMS send mutation, for sending a single sms message **/
export async function SmsSend(
  recipient: string,
  sender: string,
  message: string,
  accessToken: string,
  encoding: MessageEncoding,
  messageClass: MessageClass,
): Promise<SmsSendResponse> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation smsSend($recipient: String!, $sender: String!, $message: String!, $encoding: MessageEncoding!, $messageClass: MessageClass!) {
          smsSend(recipient: $recipient, sender: $sender, message: $message, encoding: $encoding, messageClass: $messageClass) {
            gatewayapiId
            creditUsed
            creditUnit
          }
        }
      `,
      variables: { recipient, sender, message, encoding, messageClass },
    },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.smsSend;
}

/** SMS API: SMS bulk send mutation, for sending bulk sms messages **/
export async function SmsBulkSend(
  recipients: string[],
  sender: string,
  message: string,
  accessToken: string,
  encoding: MessageEncoding,
  messageClass: MessageClass,
): Promise<SmsBulkSendResponse> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation smsBulkSend(
          $recipients: [String!]!
          $sender: String!
          $message: String!
          $encoding: MessageEncoding!
          $messageClass: MessageClass!
        ) {
          smsBulkSend(recipients: $recipients, sender: $sender, message: $message, encoding: $encoding, messageClass: $messageClass) {
            gatewayapiIds
            creditUsed
            creditUnit
          }
        }
      `,
      variables: { recipients, sender, message, encoding, messageClass },
    },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.smsBulkSend;
}

/** Contact API: Contact upsert mutation, for saving or updating a contact **/
export async function ContactUpsert(contact: ContactModel, contactId?: string): Promise<ContactModelResult> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation contactUpsert($contact: ContactModel!, $contactId: String) {
          contactUpsert(contact: $contact, contactId: $contactId) {
            id
            name
            mobileCountry
            mobileNumber
            isActive
            tags {
              id
              name
            }
            textFields {
              id
              value
            }
            dateFields {
              id
              value
            }
          }
        }
      `,
      variables: { contact, contactId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactUpsert;
}

/** Contact API: Contact bulk insert mutation, for saving a lot of contacts at the same time **/
export async function ContactBulkInsert(contacts: ContactModel[]): Promise<void> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation contactBulkInsert($contacts: [ContactModel!]!) {
          contactBulkInsert(contacts: $contacts)
        }
      `,
      variables: { contacts },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactUpsert;
}

/** Contact API: Contact delete mutation, for deleting a contact **/
export async function ContactDelete(contactId: string): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation contactDelete($contactId: String!) {
          contactDelete(contactId: $contactId)
        }
      `,
      variables: { contactId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactDelete;
}

/** Contact API: Contact tag upsert mutation, for saving or updating a contact tag **/
export async function ContactTagUpsert(contactTag: ContactTagModel, contactTagId?: string): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation contactTagUpsert($contactTag: ContactTagModel!, $contactTagId: String) {
          contactTagUpsert(contactTag: $contactTag, contactTagId: $contactTagId)
        }
      `,
      variables: { contactTag, contactTagId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactTagUpsert;
}

/** Contact API: Delete tag incl. removing association on contacts with the tag **/
export async function ContactTagDelete(contactTagId: string, deleteContactsToo = false): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation contactTagDelete($contactTagId: String!, $deleteContactsToo: Boolean) {
          contactTagDelete(contactTagId: $contactTagId, deleteContactsToo: $deleteContactsToo)
        }
      `,
      variables: { contactTagId, deleteContactsToo },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactTagDelete;
}

/** Contact API: Contact custom text field upsert mutation, for saving or updating a contact custom text field**/
export async function ContactFieldTextUpsert(contactTextField: ContactFieldTextModelInput, contactFieldId?: string): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation contactFieldTextUpsert($contactTextField: ContactFieldTextModelInput!, $contactFieldId: String) {
          contactFieldTextUpsert(contactTextField: $contactTextField, contactFieldId: $contactFieldId)
        }
      `,
      variables: { contactTextField, contactFieldId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactFieldTextUpsert;
}

/** Contact API: Contact custom text field upsert mutation, for saving or updating a contact custom text field**/
export async function ContactFieldDateUpsert(contactDateField: ContactFieldDateModelInput, contactFieldId?: string): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation contactFieldDateUpsert($contactDateField: ContactFieldDateModelInput!, $contactFieldId: String) {
          contactFieldDateUpsert(contactDateField: $contactDateField, contactFieldId: $contactFieldId)
        }
      `,
      variables: { contactDateField, contactFieldId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactFieldDateUpsert;
}

/** Contact API: Delete contact field **/
export async function ContactFieldDelete(contactFieldId: string): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation contactFieldDelete($contactFieldId: String!) {
          contactFieldDelete(contactFieldId: $contactFieldId)
        }
      `,
      variables: { contactFieldId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.ContactFieldDelete;
}

/** Campaign API: Campaign upsert mutation, for saving or updating a campaigns **/
export async function CampaignUpsert(campaign: CampaignModelInput, campaignId?: string): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation campaignUpsert($campaign: CampaignModelInput!, $campaignId: ID) {
          campaignUpsert(campaign: $campaign, campaignId: $campaignId)
        }
      `,
      variables: { campaign, campaignId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignUpsert;
}

/** Campaign API: Campaign upsert mutation, for saving or updating a campaigns **/
export async function CampaignUpdateSendingStatus(args: {
  campaignId: string;
  paused?: boolean;
  sendTime?: Date;
  throttle?: number;
}): Promise<MutationCampaignSendingStatusUpdateArgs> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation campaignSendingStatusUpdate($campaignId: ID!, $paused: Boolean, $sendTime: Timestamp, $throttle: Int) {
          campaignSendingStatusUpdate(campaignId: $campaignId, paused: $paused, sendTime: $sendTime, throttle: $throttle)
        }
      `,
      variables: args,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignSendingStatusUpdate;
}

/** Campaign API: Campaign delete mutation, for deleting a campaign **/
export async function CampaignDelete(campaignId: string): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation campaignDelete($campaignId: String!) {
          campaignDelete(campaignId: $campaignId)
        }
      `,
      variables: { campaignId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignDelete;
}

/** Connection API: Connection query, for getting information about the current saved connection (GatewayAPI API key) **/
export async function ConnectionGatewayapi(): Promise<ConnectionModelResult | undefined> {
  const res = await client.executeMutation(
    {
      query: gql`
        query connectionGatewayapi {
          connectionGatewayapi {
            id
            defaultSenderId
            account
            provider
            createdAt
            lastUsedAt
          }
        }
      `,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) return;
  return res.data.connectionGatewayapi;
}

/** Contact API: Contact get query, for getting a single contacts by account and id **/
export async function ContactGet(accessToken: string, contactId: string): Promise<ContactModelResult> {
  const res = await client.executeMutation(
    {
      query: gql`
        query ($contactId: String!) {
          contactGet(contactId: $contactId) {
            id
            name
            mobileNumber
            mobileCountry
            isActive
            tags {
              id
              name
            }
            textFields {
              id
              value
            }
            dateFields {
              id
              value
            }
          }
        }
      `,
      variables: { contactId },
    },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactGet;
}

/** Contact API: Contact list query, for getting all contacts based on filters and search **/
export async function ContactList(variables: {
  tags?: string[];
  search?: string;
  sortBy?: string;
  limit?: number;
  page?: number;
}): Promise<ContactListModelResult> {
  const res = await client.executeMutation(
    {
      query: gql`
        query ($tags: [ID!], $search: String, $sortBy: String, $limit: Float, $page: Float) {
          contactList(tags: $tags, search: $search, sortBy: $sortBy, limit: $limit, page: $page) {
            results {
              id
              name
              isActive
              mobileCountry
              mobileNumber
              tags {
                id
                name
              }
              textFields {
                id
                value
              }
              dateFields {
                id
                value
              }
            }
            totalCount
            totalMatchedActive
          }
        }
      `,
      variables,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactList;
}

/** Contact API: Contact list query, for getting all contacts based on filters and search **/
export async function ContactFieldList(): Promise<ContactFieldBaseModel[]> {
  const res = await client.executeMutation(
    {
      query: gql`
        query {
          contactFieldList {
            id
            name
            tagName
            __typename
            ... on ContactFieldTextModel {
              placeholder
            }
          }
        }
      `,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactFieldList;
}

/** Contact API: Contact fields query, for getting all contact tags **/
export async function ContactTagList(): Promise<ContactTagModelResult[]> {
  const res = await client.executeMutation(
    {
      query: gql`
        query {
          contactTagList {
            id
            name
            activeCount
            totalCount
          }
        }
      `,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.contactTagList;
}

/** Campaign API: Campaign get query, for getting a single campaign basaed on ID **/
export async function CampaignGet(campaignId: string): Promise<CampaignModelResult> {
  const res = await client.executeQuery(
    {
      query: gql`
        query ($campaignId: String!) {
          campaignGet(campaignId: $campaignId) {
            id
            campaignName
            campaignTags {
              id
              name
              totalCount
            }
            allowedHours {
              start
              end
            }
            message
            messageClass
            messageEncoding
            sender
            sendTime
            recipientCount
            sendStatus
            statusFailed
            statusReceived
            statusSent
            tags {
              id
              name
            }
            throttle
            paused
            isWithinAllowedHours
          }
        }
      `,
      variables: { campaignId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignGet;
}

/** Campaign API: Campaign list query, for getting all Campaigns based on filters and search **/
export async function CampaignList(args: {
  campaignTags?: string[];
  contactTags?: string[];
  search?: string;
  sortBy?: string;
  limit?: number;
  page?: number;
}): Promise<CampaignListModelResult> {
  const res = await client.executeMutation(
    {
      query: gql`
        query ($campaignTags: [ID!], $contactTags: [ID!], $search: String, $sortBy: String, $limit: Float, $page: Float) {
          campaignList(campaignTags: $campaignTags, contactTags: $contactTags, search: $search, sortBy: $sortBy, limit: $limit, page: $page) {
            results {
              id
              campaignName
              campaignTags {
                id
                name
                totalCount
              }
              allowedHours {
                start
                end
              }
              message
              messageClass
              messageEncoding
              sender
              sendTime
              recipientCount
              sendStatus
              statusFailed
              statusReceived
              statusSent
              tags {
                id
                name
              }
              throttle
              paused
              isWithinAllowedHours
            }
            totalCount
            matchedCount
          }
        }
      `,
      variables: args,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignList;
}

/** Campaign API: Get all campaign tags **/
export async function CampaignTagList(): Promise<CampaignTagModelResult[]> {
  const res = await client.executeMutation(
    {
      query: gql`
        query {
          campaignTagList {
            id
            name
            totalCount
            totalDraftCount
            totalScheduledCount
            totalSentCount
          }
        }
      `,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignTagList;
}

/** Campaign API: Campaign tag upsert **/
export async function CampaignTagUpsert(campaignTag: CampaignTagModel, campaignTagId?: string): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation campaignTagUpsert($campaignTag: CampaignTagModel!, $campaignTagId: ID) {
          campaignTagUpsert(campaignTag: $campaignTag, campaignTagId: $campaignTagId)
        }
      `,
      variables: { campaignTag, campaignTagId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignTagUpsert;
}

/** Campaign API: Delete camapign tag **/
export async function CampaignTagDelete(campaignTagId: string): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation campaignTagDelete($campaignTagId: String!) {
          campaignTagDelete(campaignTagId: $campaignTagId)
        }
      `,
      variables: { campaignTagId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignTagDelete;
}

/** Templates API: Campaign template list query, for getting all templates based on filters and search **/
export async function CampaignTemplateList(args: {
  search?: string;
  sortBy?: string;
  limit?: number;
  page?: number;
}): Promise<CampaignTemplateListModelResult> {
  const res = await client.executeMutation(
    {
      query: gql`
        query ($search: String, $sortBy: String, $limit: Float, $page: Float) {
          campaignTemplateList(search: $search, sortBy: $sortBy, limit: $limit, page: $page) {
            results {
              id
              campaignName
              message
              messageClass
              sender
              throttle
              title
              allowedHours {
                end
                start
              }
            }
          }
        }
      `,
      variables: args,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignTemplateList;
}

/** Templates API: Template get query, for getting a single template basaed on ID **/
export async function CampaignTemplateGet(campaignTemplateId: string): Promise<CampaignTemplateModel> {
  const res = await client.executeQuery(
    {
      query: gql`
        query ($campaignTemplateId: String!) {
          campaignTemplateGet(campaignTemplateId: $campaignTemplateId) {
            allowedHours {
              end
              start
            }
            campaignName
            id
            message
            messageClass
            sender
            throttle
            title
          }
        }
      `,
      variables: { campaignTemplateId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignTemplateGet;
}

/** Templates API: Template upsert mutation, for saving or updating a templates **/
export async function CampaignTemplateUpsert(campaignTemplate: CampaignTemplateModel, campaignTemplateId?: string): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation campaignTemplateUpsert($campaignTemplate: CampaignTemplateModel!, $campaignTemplateId: ID) {
          campaignTemplateUpsert(campaignTemplate: $campaignTemplate, campaignTemplateId: $campaignTemplateId)
        }
      `,
      variables: { campaignTemplate, campaignTemplateId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignTemplateUpsert;
}

/** Templates API: Template delete mutation, for deleting a template **/
export async function CampaignTemplateDelete(campaignTemplateId: string): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation campaignTemplateDelete($campaignTemplateId: String!) {
          campaignTemplateDelete(campaignTemplateId: $campaignTemplateId)
        }
      `,
      variables: { campaignTemplateId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.campaignTemplateDelete;
}

/** Inbox API: Inbox ruleset upsert mutation, for saving or updating a SmsInboxRulesetAutoreplyModel **/
export async function SmsIncomingList(inboxId: string, limit?: number, page?: number, sortBy?: string): Promise<SmsIncomingListModelResult> {
  const res = await client.executeQuery(
    {
      query: gql`
        query smsIncomingList($inboxId: String!, $limit: Float, $page: Float, $sortBy: String) {
          smsIncomingList(inboxId: $inboxId, limit: $limit, page: $page, sortBy: $sortBy) {
            results {
              id
              message
              senderNumber
              createdAt
            }
            totalCount
          }
        }
      `,
      variables: { inboxId, limit, page, sortBy },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.smsIncomingList;
}

/** Inbox API: Inbox ruleset upsert mutation, for saving or updating a SmsInboxRulesetAutoreplyModel **/
export async function SmsInboxRulesetAutoreplyUpsert(smsInboxRuleset: SmsInboxRulesetAutoreplyModelInput, rulesetId?: string): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation smsInboxRulesetAutoreplyUpsert($smsInboxRuleset: SmsInboxRulesetAutoreplyModelInput!, $rulesetId: String) {
          smsInboxRulesetAutoreplyUpsert(smsInboxRuleset: $smsInboxRuleset, rulesetId: $rulesetId)
        }
      `,
      variables: { smsInboxRuleset, rulesetId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.smsInboxRulesetAutoreplyUpsert;
}

/** Inbox API: Inbox ruleset upsert mutation, for saving or updating a SmsInboxRulesetAutoreplyMultipleModel **/
export async function SmsInboxRulesetAutoreplyMultipleUpsert(
  smsInboxRuleset: SmsInboxRulesetAutoreplyMultipleModelInput,
  rulesetId?: string,
): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation smsInboxRulesetAutoreplyMultipleUpsert($smsInboxRuleset: SmsInboxRulesetAutoreplyMultipleModelInput!, $rulesetId: String) {
          smsInboxRulesetAutoreplyMultipleUpsert(smsInboxRuleset: $smsInboxRuleset, rulesetId: $rulesetId)
        }
      `,
      variables: { smsInboxRuleset, rulesetId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.smsInboxRulesetAutoreplyMultipleUpsert;
}

/** Inbox API: Dynamic inbox ruleset upsert mutation, for saving or updating a SmsInboxRulesetPaymentDynamicModel **/
export async function SmsInboxRulesetPaymentDynamicUpsert(
  smsInboxRuleset: SmsInboxRulesetPaymentDynamicModelInput,
  rulesetId?: string,
): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation smsInboxRulesetPaymentDynamicUpsert($smsInboxRuleset: SmsInboxRulesetPaymentDynamicModelInput!, $rulesetId: String) {
          smsInboxRulesetPaymentDynamicUpsert(smsInboxRuleset: $smsInboxRuleset, rulesetId: $rulesetId)
        }
      `,
      variables: { smsInboxRuleset, rulesetId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.smsInboxRulesetPaymentDynamicUpsert;
}

/** Inbox API: Fixed inbox ruleset upsert mutation, for saving or updating a SmsInboxRulesetPaymentFixedModel **/
export async function SmsInboxRulesetPaymentFixedUpsert(smsInboxRuleset: SmsInboxRulesetPaymentFixedModelInput, rulesetId?: string): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation smsInboxRulesetPaymentFixedUpsert($smsInboxRuleset: SmsInboxRulesetPaymentFixedModelInput!, $rulesetId: String) {
          smsInboxRulesetPaymentFixedUpsert(smsInboxRuleset: $smsInboxRuleset, rulesetId: $rulesetId)
        }
      `,
      variables: { smsInboxRuleset, rulesetId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.smsInboxRulesetPaymentFixedUpsert;
}

/** Inbox API: Inbox upsert mutation, for saving or updating a inboxes **/
export async function SmsInboxUpsert(smsInbox: SmsInboxModel, smsInboxId?: string): Promise<string> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation smsInboxUpsert($smsInbox: SmsInboxModel!, $smsInboxId: String) {
          smsInboxUpsert(smsInbox: $smsInbox, smsInboxId: $smsInboxId)
        }
      `,
      variables: { smsInbox, smsInboxId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.smsInboxUpsert;
}

/** Inbox API: Inbox list query, for getting a list inboxs **/
export async function SmsInboxList(limit?: number, page?: number, sortBy?: string): Promise<SmsInboxListModelResult> {
  const res = await client.executeQuery(
    {
      query: gql`
        query ($limit: Float, $page: Float, $sortBy: String) {
          smsInboxList(limit: $limit, page: $page, sortBy: $sortBy) {
            results {
              id
              keyword
              number
              ruleset {
                ... on SmsInboxRulesetAutoreplyModel {
                  __typename
                  active
                  activeFrom
                  activeTo
                  id
                  replyWithMessage
                  replyContent
                  sender
                }
                ... on SmsInboxRulesetAutoreplyMultipleModel {
                  __typename
                  active
                  activeFrom
                  activeTo
                  id
                  keywords {
                    keyword
                    replyContent
                  }
                  sender
                }
                ... on SmsInboxRulesetPaymentDynamicModel {
                  __typename
                  active
                  activeFrom
                  activeTo
                  errChargeContent
                  errInvalidContent
                  errNoConfirmationContent
                  errNoConfirmationTime
                  errNoInitialContent
                  id
                  initialContent
                  dynamic: maxTimeCompletion
                  paymentConfirmWord
                  paymentContent
                  paymentPriceMax
                  paymentPriceMin
                  requireConfirmation
                  sender
                }
                ... on SmsInboxRulesetPaymentFixedModel {
                  __typename
                  active
                  activeFrom
                  activeTo
                  errChargeContent
                  errInvalidContent
                  errNoConfirmationContent
                  errNoConfirmationTime
                  errNoInitialContent
                  id
                  initialContent
                  maxTimeCompletion
                  paymentConfirmWord
                  paymentContent
                  paymentPrice
                  requireConfirmation
                  sender
                }
              }
            }
          }
        }
      `,
      variables: { limit, page, sortBy },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.smsInboxList;
}

/** Inbox API: Inbox get query, for getting a single inbox based on ID **/
export async function SmsInboxGet(smsInboxId: string): Promise<SmsInboxModelResult> {
  const res = await client.executeQuery(
    {
      query: gql`
        query ($smsInboxId: String!) {
          smsInboxGet(smsInboxId: $smsInboxId) {
            gatewayapiToken
            id
            keyword
            number
            ruleset {
              ... on SmsInboxRulesetAutoreplyModel {
                __typename
                active
                activeFrom
                activeTo
                id
                replyWithMessage
                replyContent
                sender
              }
              ... on SmsInboxRulesetAutoreplyMultipleModel {
                __typename
                active
                activeFrom
                activeTo
                id
                keywords {
                  keyword
                  replyContent
                }
                errorMessage
                sendErrorMessage
                sender
              }
              ... on SmsInboxRulesetPaymentDynamicModel {
                __typename
                active
                activeFrom
                activeTo
                errChargeContent
                errInvalidContent
                errNoConfirmationContent
                errNoConfirmationTime
                errNoInitialContent
                id
                initialContent
                dynamic: maxTimeCompletion
                paymentConfirmWord
                paymentContent
                paymentPriceMax
                paymentPriceMin
                requireConfirmation
                sender
              }
              ... on SmsInboxRulesetPaymentFixedModel {
                __typename
                active
                activeFrom
                activeTo
                errChargeContent
                errInvalidContent
                errNoConfirmationContent
                errNoConfirmationTime
                errNoInitialContent
                id
                initialContent
                payment: maxTimeCompletion
                paymentConfirmWord
                paymentContent
                paymentPrice
                requireConfirmation
                sender
              }
            }
          }
        }
      `,
      variables: { smsInboxId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.smsInboxGet;
}

/** Statistics: Contacts and Countries **/
export async function StatisticsContactsCountries(): Promise<StatisticContactCountriesModel> {
  const res = await client.executeMutation(
    {
      query: gql`
        query {
          statisticsContactsCountries {
            contactTotalCount
            countryTotalCount
            data {
              contactCount
              countryPrefix
            }
          }
        }
      `,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.statisticsContactsCountries;
}

/** Statistics: Contacts created **/
export async function StatisticsContactsCreated(dateFrom: Date, dateTo: Date): Promise<StatisticDailyContactsModel> {
  const res = await client.executeQuery(
    {
      query: gql`
        query ($dateFrom: Timestamp!, $dateTo: Timestamp!) {
          statisticsContactsCreated(dateFrom: $dateFrom, dateTo: $dateTo) {
            contactTotalCount
            data {
              contactCount
              day
            }
          }
        }
      `,
      variables: { dateFrom, dateTo },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.statisticsContactsCreated;
}

/** Remove tokens **/
export async function RemoveTokens(authenticationToken: string, accessToken: string): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation removeTokens($authenticationToken: String!, $accessToken: String!) {
          removeTokens(authenticationToken: $authenticationToken, accessToken: $accessToken)
        }
      `,
      variables: { authenticationToken, accessToken },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.statisticsContactsCreated;
}

/** Statistics: Campaigns **/
export async function StatisticsCampaigns(sampleSize: number): Promise<StatisticCampaigns> {
  const res = await client.executeQuery(
    {
      query: gql`
        query ($sampleSize: Float!) {
          statisticsCampaigns(sampleSize: $sampleSize) {
            data {
              campaignName
              recipientCount
              statusFailure
              statusSuccess
              statusWaiting
            }
          }
        }
      `,
      variables: { sampleSize },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.statisticsCampaigns;
}

/** Statistics: Campaign Recipients Summary **/
export async function StatisticsRecipientsDaily(dateFrom: Date, dateTo: Date): Promise<StatisticDailyRecipientsModel> {
  const res = await client.executeQuery(
    {
      query: gql`
        query ($dateFrom: Timestamp!, $dateTo: Timestamp!) {
          statisticsRecipientsDaily(dateFrom: $dateFrom, dateTo: $dateTo) {
            data {
              day
              recipientCount
              statusFailure
              statusSuccess
              statusWaiting
            }
            recipientTotalCount
            statusTotalFailure
            statusTotalSuccess
            statusTotalWaiting
          }
        }
      `,
      variables: { dateFrom, dateTo },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.statisticsRecipientsDaily;
}

/** Threads: Get a thread by MSISDN */
export async function ThreadGet(MSISDN: string): Promise<ThreadModelResult> {
  const res = await client.executeQuery(
    {
      query: gql`
        query ($MSISDN: String!) {
          threadGet(MSISDN: $MSISDN) {
            MSISDN
            messages {
              date
              id
              message
              context
              gatewayapiId
              type
              from
            }
            contact {
              id
              name
              mobileCountry
              mobileNumber
            }
          }
        }
      `,
      variables: { MSISDN },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );
  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.threadGet;
}

/** Data Policy: Query for getting list of data policy fields **/
export async function accountDataPolicyGet(): Promise<AccountDataPolicyModel> {
  const res = await client.executeQuery(
    {
      query: gql`
        query AccountDataPolicyGet {
          accountDataPolicyGet {
            cronTime
            deleteInactiveContacts
            deletedMessage
            excludedContactTags
            inactivatedMessage
            inactiveMonths
            inactiveStatus
            senderName
            sendSmsOnDeletion
            sendSmsOnInactivation
            anonymizeLogsAfter
            deleteLogsAfter
          }
        }
      `,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );
  /** Throws Error Message if Data Policy is unreachable **/
  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.accountDataPolicyGet;
}
/** Data Policy: Mutation for updating data policy fields **/
export async function accountDataPolicyUpdate(accountDataPolicy: AccountDataPolicyInput): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation AccountDataPolicyUpdate($accountDataPolicy: AccountDataPolicyInput!) {
          accountDataPolicyUpdate(accountDataPolicy: $accountDataPolicy)
        }
      `,
      variables: { accountDataPolicy },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.accountDataPolicyUpdate;
}

/** Settings: Query for getting settings **/
export async function accountSettingsGet(): Promise<AccountSettingsModel> {
  const res = await client.executeQuery(
    {
      query: gql`
        query AccountSettingsGet {
          accountSettingsGet {
            timezone
          }
        }
      `,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.accountSettingsGet;
}

/** Settings: Mutation for updating settings **/
export async function accountSettingsUpdate(accountSettings: AccountSettingsInput): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation AccountSettingsUpdate($accountSettings: AccountSettingsInput!) {
          accountSettingsUpdate(accountSettings: $accountSettings)
        }
      `,
      variables: { accountSettings },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.accountSettingsUpdate;
}

/** DPA: Query to get account DPA */
export async function accountDpaGet(): Promise<DpaModelResult> {
  const res = await client.executeQuery(
    {
      query: gql`
        query accountDpaGet {
          accountDpaGet {
            dpaVersion
            signedDate
            signeeCompanyName
            signeeEmail
            signeeName
            signeeVatNo
            signingStatus
          }
        }
      `,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.accountDpaGet;
}

/** DPA: Update the DPA of an account */
export async function accountDpaUpdate(dpa: DpaModelInput): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation accountDpaUpdate($dpa: DpaModelInput!) {
          accountDpaUpdate(dpa: $dpa)
        }
      `,
      variables: { dpa },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.accountDpaUpdate;
}

/** Blacklist: Get a list of all blackedlisted recipients for account */
export async function BlacklistList(variables: {
  sortBy?: string;
  search?: string;
  limit?: number;
  page?: number;
}): Promise<BlacklistListModelResult> {
  const res = await client.executeQuery(
    {
      query: gql`
        query BlacklistList($sortBy: String, $search: String, $limit: Float, $page: Float) {
          blacklistList(sortBy: $sortBy, search: $search, limit: $limit, page: $page) {
            results {
              _id
              blockedAt
              note
              type
              recipient
            }
            totalCount
          }
        }
      `,
      variables,
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.blacklistList;
}

/** Blacklist: Add a recipient to the blacklist */
export async function BlacklistUpsert(
  recipient: string,
  type: BlacklistTypes,
  note?: string,
  blockedAt?: Date,
  blacklistId?: string,
): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation BlacklistUpsert($recipient: String!, $type: BlacklistTypes!, $note: String, $blockedAt: Timestamp, $blacklistId: String) {
          blacklistUpsert(recipient: $recipient, type: $type, note: $note, blockedAt: $blockedAt, blacklistId: $blacklistId)
        }
      `,
      variables: { recipient, type, note, blockedAt, blacklistId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.blacklistUpsert;
}

/** Blacklist: Remove a recipient from the blacklist */
export async function BlacklistDelete(blacklistId: string): Promise<boolean> {
  const res = await client.executeMutation(
    {
      query: gql`
        mutation BlacklistDelete($blacklistId: String!) {
          blacklistDelete(blacklistId: $blacklistId)
        }
      `,
      variables: { blacklistId },
    },
    { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
  );

  if (res.error) throw new Error(getBestErrorMessage(res.error));
  return res.data.blacklistDelete;
}
