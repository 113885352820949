<template>
  <Teleport to="body">
    <div class="modal" ref="importModalEl" tabindex="-1">
      <div class="modal-dialog" :class="{ 'modal-xl': currentStep === CurrentStep.ConfigureXlsx || currentStep === CurrentStep.ConfigureCsv }">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="staticBackdropLabel">Import recipients</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- PICK FILE STEP -->
            <div v-show="currentStep === CurrentStep.Upload">
              <div class="alert alert-light">
                Please provide a CSV or Excel-file. You will pick which columns contain phone numbers in the next step.
              </div>
              <input class="form-control" ref="fileField" @input="onNewFile" accept=".csv, .xlsx" type="file" name="numbers" />

              <p class="small text-muted mt-2 mb-0">The spreadsheet file you choose, is processed entirely in your browser.</p>
            </div>
            <!-- PICK FILE STEP -->

            <!-- PREVIEW -->
            <div v-if="data.length && (currentStep === CurrentStep.ConfigureXlsx || currentStep === CurrentStep.ConfigureCsv)">
              <div class="alert alert-light">
                <p>Below is a preview of your file.</p>
                <p class="mb-0">
                  Please pick the column containing the country code + national number (MSISDN), or the two separate columns containing the country
                  calling code and national number.
                </p>
              </div>

              <div class="row mb-2 align-items-center" v-if="!nationalNumberColumn && !countryCodeColumn">
                <div class="col-sm-4">
                  Full phone number (MSISDN)
                  <InfoTooltip
                    tooltip="A column containing the full phone number (MSISDN), ie. country code + phone number in one field."
                  ></InfoTooltip>
                </div>
                <div class="col-sm-8">
                  <select class="form-control" v-model="msisdnColumn">
                    <option value="">- No full phone number (MSISDN) column -</option>
                    <option v-for="[idx, title] of Object.entries(data[0])" :value="idx" style="min-width: 150px">#{{ idx }} - {{ title }}</option>
                  </select>
                </div>
              </div>

              <div class="row mb-2 align-items-center" v-if="!msisdnColumn">
                <div class="col-sm-4">
                  Country calling code
                  <InfoTooltip tooltip="A column containing just the country calling code."></InfoTooltip>
                </div>
                <div class="col-sm-8">
                  <select v-model="countryCodeColumn" class="form-control">
                    <option value="">- No column code column -</option>
                    <option v-for="[idx, title] of Object.entries(data[0])" :value="idx" style="min-width: 150px">#{{ idx }} - {{ title }}</option>
                  </select>
                </div>
              </div>

              <div class="row mb-2 align-items-center" v-if="!msisdnColumn">
                <div class="col-sm-4">
                  National number column
                  <InfoTooltip
                    tooltip="A column containing just the national number, ie. the phone number without a country code prefix."
                  ></InfoTooltip>
                </div>
                <div class="col-sm-8">
                  <select v-model="nationalNumberColumn" class="form-control">
                    <option value="">- No national code column -</option>
                    <option v-for="[idx, title] of Object.entries(data[0])" :value="idx" style="min-width: 150px">#{{ idx }} - {{ title }}</option>
                  </select>
                </div>
              </div>

              <div class="table-responsive mt-4" v-if="data.length">
                <table class="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th style="min-width: 150px" v-for="[idx, title] of Object.entries(data[0])" class="text-center p-3">
                        <span class="badge bg-secondary">#{{ idx }}</span
                        ><br />
                        {{ title }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="[rowIdx, row] of Object.entries(data.slice(1, 6))">
                      <td class="p-3" v-for="[colIdx, title] of Object.entries(row)">
                        {{ title }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- PREVIEW -->

            <!-- IMPORTING -->
            <div v-if="currentStep === CurrentStep.Importing" class="alert alert-info">Parsing in progress. Please wait.</div>
          </div>
          <div class="modal-footer" v-if="currentStep !== CurrentStep.Importing">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" :disabled="!readyToImport" @click.prevent="doImport">Import</button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
  import { inject, onBeforeUnmount, onMounted, Ref, ref, watchEffect } from 'vue';
  import { Modal } from 'bootstrap';
  import { endsWith } from 'lodash';
  import { parse as csvParse } from 'papaparse';
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';
  import { parsePhoneNumberFromString } from 'libphonenumber-js';
  import readXlsxFile from 'read-excel-file';
  import { QuickSend } from '@/services/QuickSend';

  enum CurrentStep {
    Upload,
    ConfigureCsv,
    ConfigureXlsx,
    Importing,
  }

  const importModalEl = ref(null) as any as Ref<HTMLDivElement>;
  const quickSend = inject('quickSend') as QuickSend;

  const emit = defineEmits(['close']);

  const readyToImport = ref(false) as Ref<boolean>;
  const currentStep = ref(CurrentStep.Upload) as Ref<CurrentStep>;

  onMounted(() => {
    const importModal = new Modal(importModalEl.value);
    importModal.show();

    onBeforeUnmount(async () => {
      importModal.hide();
    });

    importModalEl.value.addEventListener('hidden.bs.modal', () => {
      emit('close');
      importModal.dispose();
    });
  });

  // STEP 1: UPLOAD FILE
  const file = ref(null) as any as Ref<File>;
  const fileCsvRaw = ref('') as Ref<string>;
  const onNewFile = (ev: Event) => {
    const fileField = ev.target as HTMLInputElement;
    if (!fileField.files || !fileField.files[0]) return;

    file.value = fileField.files[0];
    const filename = file.value.name.toLowerCase();
    if (endsWith(filename, '.csv')) {
      file.value.text().then((t) => {
        fileCsvRaw.value = t;
        currentStep.value = CurrentStep.ConfigureCsv;
      });
    } else if (endsWith(filename, '.xlsx')) {
      currentStep.value = CurrentStep.ConfigureXlsx;
    } else {
      window.alert('The file you choose, must have either ".csv" or ".xlsx" as its extension.');
    }
  };

  const data = ref([]);
  const msisdnColumn = ref('');
  const countryCodeColumn = ref('');
  const nationalNumberColumn = ref('');

  // STEP 2: IMPORT
  watchEffect(() => {
    if (currentStep.value === CurrentStep.ConfigureCsv) {
      data.value = csvParse(fileCsvRaw.value).data as [];
    }

    if (currentStep.value === CurrentStep.ConfigureXlsx) {
      readXlsxFile(file.value).then((rows) => {
        data.value = rows as [];
      });
    }
  });

  watchEffect(() => {
    readyToImport.value = !!(msisdnColumn.value || (countryCodeColumn.value && nationalNumberColumn.value));
  });

  const recipients = quickSend.recipients;

  // STEP 3: IMPORT
  const doImport = () => {
    readyToImport.value = false;
    currentStep.value = CurrentStep.Importing;

    for (const row of data.value) {
      if (msisdnColumn.value) {
        const msisdn = String(row[Number(msisdnColumn.value)] as string).replace(/\D+/g, '');
        const parsed = parsePhoneNumberFromString(`+${msisdn}`);
        if (!parsed?.country) continue;
        if (!recipients.value.includes(msisdn)) recipients.value.push(msisdn);
      } else if (countryCodeColumn.value && nationalNumberColumn.value) {
        const cc = String(row[Number(countryCodeColumn.value)] as string).replace(/\D+/g, '');
        const no = String(row[Number(nationalNumberColumn.value)] as string).replace(/\D+/g, '');
        const msisdn = `${cc}${no}`;
        const parsed = parsePhoneNumberFromString(`+${msisdn}`);
        if (!parsed?.country) continue;
        if (!recipients.value.includes(msisdn)) recipients.value.push(msisdn);
      }
    }

    emit('close');
  };
</script>
