<template>
  <label class="form-label d-flex align-items-center justify-content-between mt-3" :for="field.name">
    {{ field.name }}
    <Popper
      reference-is="span"
      popper-is="div"
      :popper-props="{ style: 'z-index: 2000' }"
      :teleport-props="{ to: 'body' }"
      :disabled="!editDropdownOpen"
      :force-show="editDropdownOpen"
    >
      <template #reference>
        <button
          class="btn btn-outline-secondary"
          :class="{ 'btn-sm dropdown-toggle btn-rounded-circle': true }"
          @click="editDropdownOpen = !editDropdownOpen"
          type="button"
        />
      </template>
      <ContactEditorCustomFieldEditor v-if="editDropdownOpen" @close="editDropdownOpen = false" is-editing :field="field" />
    </Popper>
  </label>
  <component :is="fieldTypeMap[field?.__typename ?? 'ContactFieldTextModel']" v-model="fieldValue" :field="field" />
</template>

<script setup lang="ts">
  import { computed, inject, PropType, Ref, ref } from 'vue';
  import { ContactFieldDateModel, ContactFieldTextModel, ContactFieldTextValueModel, ContactModelResult } from '@/dto/graphql';
  import ContactEditorCustomFieldEditor from '@/components/contacts/ContactEditorCustomFieldEditor.vue';
  import ContactCustomTextField from './field-types/ContactCustomTextField.vue';
  import ContactCustomDateField from './field-types/ContactCustomDateField.vue';
  import { Popper } from 'vue-use-popperjs';

  const currentContact = inject('currentContact') as Ref<ContactModelResult | null>;
  const fieldTypeMap = {
    ContactFieldTextModel: ContactCustomTextField,
    ContactFieldDateModel: ContactCustomDateField,
  };

  const props = defineProps({
    field: {
      type: Object as PropType<ContactFieldDateModel | ContactFieldTextModel>,
      required: true,
    },
  });

  const editDropdownOpen = ref(false);

  const fieldArray = ref(
    props.field.__typename === 'ContactFieldTextModel' ? currentContact.value?.textFields : currentContact.value?.dateFields,
  ) as Ref<ContactFieldTextValueModel[]>;
  const fieldValue = computed({
    get(): string {
      if (!currentContact.value || !fieldArray.value) return '';
      const a = fieldArray.value as ContactFieldTextValueModel[];
      return a.find((f: any) => f.id === props.field.id)?.value ?? '';
    },
    set(value: string) {
      if (!fieldArray.value?.some((f) => f.id == props.field.id)) {
        fieldArray.value?.push({
          id: (props.field as any as ContactFieldTextModel).id,
          value,
        });
      } else {
        Object.assign(
          fieldArray.value,
          fieldArray.value.map((el) =>
            el.id === props.field.id
              ? {
                  id: props.field.id,
                  value,
                }
              : el,
          ),
        );
      }
    },
  });
</script>

<style scoped></style>
