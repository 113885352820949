<template>
  <h3 class="mb-4 text-center">Regional settings</h3>
  <p>Your account needs a timezone. This is used throughout the system to ensure that we send and display dates correctly.</p>

  <label class="form-label" for="name">Time zone</label>
  <select class="form-select" :value="timezone" @input="$emit('update:timezone', ($event.target as HTMLInputElement).value)" id="timeZone" autofocus>
    <option v-for="timeZone in timeZones" :key="timeZone">{{ timeZone }}</option>
  </select>
  <small v-if="errorMessage" class="text-danger">{{ errorMessage }}</small>
</template>

<script setup lang="ts">
  const timeZones = (Intl as any).supportedValuesOf('timeZone');

  defineProps<{
    timezone: string;
    errorMessage?: string | undefined;
  }>();
  defineEmits(['update:timezone']);
</script>
