<template>
  <div :class="['alert', `alert-${notification.backgroundType}`]">
    <div class="row">
      <div class="col-auto align-self-center">
        <i :class="notification.icon" class="h1"></i>
      </div>
      <div class="col align-self-center">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script lang="ts" setup>
  import { computed, defineProps } from 'vue';

  const props = defineProps({
    type: {
      type: String,
      default: 'info',
    },
  });

  const notification = computed(() => {
    switch (props.type) {
      case 'success':
        return {
          backgroundType: 'success',
          icon: 'fe fe-check-circle',
        };
      case 'error':
        return {
          backgroundType: 'danger',
          icon: 'fe fe-x-circle',
        };
      case 'warning':
        return {
          backgroundType: 'warning',
          icon: 'fe fe-alert-triangle',
        };
      default:
        return {
          backgroundType: 'light',
          icon: 'fe fe-info',
        };
    }
  });
</script>
