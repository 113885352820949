<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title align-items-center me-auto">GatewayAPI connection</h4>
    </div>
    <div class="card-body" v-if="inbox.id !== 'new'">
      <div class="form-group">
        <label class="w-100">
          <p class="mb-1">Status</p>
          <span class="h2">
            <span v-if="!messageCount" class="badge bg-warning">Not tested</span>
            <span v-else-if="messageCount > 0" class="badge bg-success">Connected</span>
          </span>
        </label>
      </div>
      <div class="form-group">
        <label class="w-100">
          <p class="mb-1">Webhook URL:</p>
          <div class="d-flex align-items-center">
            <input v-tooltip="`Click to copy`" type="text" readonly @click="copyUrl()" class="form-control url"
              v-model="webhookUrl" />
          </div>
        </label>
      </div>
      <p class="m-0">This must be entered in GatewayAPI when setting up the keyword.</p>
      <a href="#" @click="showInstructionsToast()">Read instructions here</a>
    </div>
    <div class="card-body" v-else>
      <div class="bg-light rounded p-3 flex align-items-center">
        <i class="fe fe-info me-2"></i>
        You must save your inbox before testing your connection.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, Ref, computed, ref, onMounted } from 'vue';
import { SmsInboxModelResult, SmsIncomingListModelResult } from '@/dto/graphql';
import { useToast } from 'vue-toastification';
import { SmsIncomingList } from '@/services/GraphqlApi';
import vTooltip from '@/directives/vTooltip';

const toast = useToast();
const inbox = inject('inbox') as Ref<SmsInboxModelResult>;
const messageCount = ref<number>();
const isLoadingMessageCount = ref<boolean>(true);
const baseUrl = `${window.location.protocol}//${window.location.hostname}`;
const webhookUrl = computed(() => `${baseUrl}/api/sms-incoming/${inbox.value.id ?? ''}/${inbox.value.gatewayapiToken ?? ''}`);

onMounted(async () => {
  if (inbox.value.id !== 'new') {
    SmsIncomingList(inbox.value.id)
      .then((result: SmsIncomingListModelResult) => {
        messageCount.value = result.totalCount;
      })
      .finally(() => {
        isLoadingMessageCount.value = false;
      });
  }
});

const copyUrl = () => {
  navigator.clipboard.writeText(webhookUrl.value);
  toast.success('Copied to clipboard', { timeout: 2000 });
};

const showInstructionsToast = () => {
  toast.info(
    "Sorry, but we're still working on the instructions. For now, you may contact us via the chat support, and we can guide you through the proces.",
    {
      timeout: 5000,
    },
  );
};
</script>

<style scoped>
.url {
  cursor: pointer;
}
</style>
