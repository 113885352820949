<template>
  <div class="card card-fill-lg">
    <div class="card-header">
      <input v-model="search" type="text" class="form-control form-control-flush h4" placeholder="Search for contacts" />

      <div class="dropdown">
        <button
          class="btn btn-outline-secondary btn-rounded-circle dropdown-toggle me-n2"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        ></button>
        <ul class="dropdown-menu">
          <li>
            <a @click.prevent="showImportModal = true" class="dropdown-item list-group-item-action d-flex py-3 text-secondary" href="#">
              <i class="fe fe-upload-cloud me-3"></i>
              Import from spreadsheet</a
            >
          </li>
          <li>
            <a @click.prevent="showExportModal = true" class="dropdown-item list-group-item-action d-flex py-3 text-secondary" href="#"
              ><i class="fe fe-download-cloud me-3"></i>Export to spreadsheet</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body contacts-body-inner position-relative mt-0 overflow-auto flex-grow-1 d-flex">
      <!-- LIST OF CONTACTS -->
      <div class="inner position-absolute overflow-auto top-0 start-0 h-100 w-100" v-if="contacts.results?.length">
        <ul class="list-group list-group-flush border-bottom border-light">
          <a
            v-for="contact of contactsSorted"
            href="#"
            class="list-group-item list-group-item-action py-3 px-4 d-flex justify-content-between align-items-start"
            :class="{ active: contact.id === currentContact?.id }"
            :key="contact.id"
            @mousedown.prevent="currentContact = contact"
          >
            <div class="me-auto">
              <strong>{{ contact.name }}</strong
              ><br />
              (+{{ contact.mobileCountry }}) {{ contact.mobileNumber }}
            </div>
            <span class="badge bg-success rounded-pill" v-if="contact.isActive">Active</span>
            <span class="badge bg-warning rounded-pill" v-else>Inactive</span>
          </a>
        </ul>
      </div>
      <!-- LIST OF CONTACTS -->

      <div v-else class="w-100">
        <!-- ERROR: NO CONTACTS FOUND WITH SEARCH STRING -->
        <EmptyState v-if="contacts.totalCount && search" icon="fe fe-user" :text="`No contacts matching ${search}`" />
        <!-- ERROR: NO CONTACTS FOUND WITH SEARCH STRING -->

        <!-- ERROR: NO CONTACTS FOUND (BUT SOME EXIST) -->
        <EmptyState v-else-if="contacts.totalCount" icon="fe fe-user" text="The selected tag does not contain any contacts." />
        <!-- ERROR: NO CONTACTS FOUND (BUT SOME EXIST) -->

        <!-- ERROR: NO CONTACTS AT ALL -->
        <div v-else-if="!_.isEmpty(contacts)" class="align-self-center w-100">
          <div class="alert alert-light text-muted d-flex align-items-center mb-0" role="alert">
            <div>
              You do not have any contacts yet. Create as many contacts as you please.<br /><br />
              Contacts enable you to send to groups of people with personalized messages, and much more.
            </div>
          </div>
        </div>
        <!-- ERROR: NO CONTACTS AT ALL -->
      </div>
    </div>

    <div class="card-footer card-footer-boxed">
      <ListPagination
        :total-items="contacts.totalMatchedActive"
        :page-size="pageSize"
        :current-page="currentPage"
        @page-change="(page) => (currentPage = page)"
      />
    </div>
  </div>

  <ContactsImportModal
    v-if="showImportModal"
    @close="
      showImportModal = false;
      emit('reload');
    "
  />
  <ContactsExportModal v-if="showExportModal" @close="showExportModal = false" />
</template>

<style lang="scss" scoped>
  @media (max-width: 991px) {
    .contacts-body-inner {
      padding: 0 !important;

      .inner {
        position: relative !important;
        height: auto;
        max-height: 75vh;
      }
    }
  }
</style>

<script lang="ts" setup>
  import { computed, inject, provide, ref, Ref, watch, watchEffect } from 'vue';
  import { ContactListModelResult, ContactModelResult } from '@/dto/graphql';
  import { ContactList } from '@/services/GraphqlApi';
  import _ from 'lodash';
  import { debouncedWatch } from '@vueuse/core';
  import ContactsImportModal from '@/components/contacts/ContactsImportModal.vue';
  import EmptyState from '../shared/EmptyState.vue';
  import ContactsExportModal from './ContactsExportModal.vue';
  import ListPagination from '../shared/ListPagination.vue';

  const emit = defineEmits(['reload']);

  const contacts = inject('contacts') as Ref<ContactListModelResult>;
  const currentContact = inject('currentContact') as Ref<ContactModelResult | null>;
  const search = ref('') as Ref<string>;
  const sortBy = ref('name');
  const pageSize = ref(100);
  const currentPage = ref(0);
  const currentTag = inject('currentTag') as Ref<string>;

  const showImportModal = ref(false) as Ref<boolean>;
  const showExportModal = ref(false) as Ref<boolean>;

  provide('currentPage', currentPage);
  provide('pageSize', pageSize);

  const contactsSorted = computed(() => {
    const withLowerName = _.map(contacts.value.results, (c: ContactModelResult) => {
      return { ...c, nameLower: c.name.toLocaleLowerCase() };
    });
    return _.sortBy(withLowerName, ['nameLower']);
  });

  watch(
    () => currentTag.value,
    () => {
      currentPage.value = 0;
      search.value = '';
    },
  );

  debouncedWatch(
    search,
    (newVal: string) => {
      ContactList({
        search: newVal,
        sortBy: sortBy.value,
        limit: pageSize.value,
        page: currentPage.value,
        tags: currentTag.value ? [currentTag.value] : undefined,
      }).then((res) => {
        contacts.value = res;
      });
    },
    { debounce: 500 },
  );

  watchEffect(() => {
    ContactList({
      sortBy: sortBy.value,
      limit: pageSize.value,
      page: currentPage.value,
      tags: currentTag.value ? [currentTag.value] : undefined,
    }).then((res) => {
      contacts.value = res;
    });
  });

  watch(currentTag, () => {
    currentPage.value = 0;
  });
</script>
