<template>
  <Teleport to="body">
    <div class="modal" ref="exportModalEl" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="staticBackdropLabel">Export contacts</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body" v-if="!currentlyExporting">
            <!-- Name and type -123 -->
            <div class="row align-items-center">
              <div class="col-1">
                <i class="fe fe-file-text h1 text-primary mb-0" />
              </div>
              <div class="col-7">
                <label for="filename">File name</label>
                <input type="text" id="filename" class="form-control" placeholder="File name" v-model="file.name" />
              </div>
              <div class="col-4">
                <label for="filetype">File type</label>
                <select id="filetype" class="form-select" placeholder="File type" v-model="file.type">
                  <option value="csv">CSV</option>
                  <option value="xls">XLSX (Excel)</option>
                </select>
              </div>
            </div>

            <!-- Columns -->
            <ul class="list-group mt-4">
              <h4>Columns</h4>
              <li class="list-group-item" v-for="(column, index) in columns" :key="index">
                <label class="form-check">
                  <span class="form-check-label">{{ column.name }}</span>
                  <input class="form-check-input" type="checkbox" v-model="column.active" />
                </label>
              </li>
            </ul>
          </div>
          <div class="modal-body" v-else>
            <div class="row align-items-center">
              <div class="col-1">
                <i class="fe fe-file-text h1 text-primary mb-0" />
              </div>
              <div class="col-11">
                <h4>Exporting contacts</h4>
                <p class="mb-0">Please wait while we export your contacts.</p>
              </div>
            </div>
            <div class="progress mt-3">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                :style="`width: ${completedPercentage}%`"
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" :disabled="currentlyExporting">Close</button>
            <button type="button" class="btn btn-primary" @click="exportContacts" :disabled="currentlyExporting">Export</button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
  import { reactive, ref, Ref, inject, onMounted, onBeforeUnmount } from 'vue';
  import { Modal } from 'bootstrap';
  import { useContactsStore } from '@/stores/contacts';
  import exportFromJSON, { ExportType } from 'export-from-json';
  import { ContactModelResult, ContactFieldBaseModel, ContactTagModelResult } from '@/dto/graphql';
  import { ContactList } from '@/services/GraphqlApi';
  import { omit } from 'lodash';

  const emit = defineEmits(['close']);
  const contactStore = useContactsStore();
  const currentTag = inject('currentTag') as Ref<string>;
  const contactTags = inject('contactTags') as Ref<ContactTagModelResult[]>;
  const currentlyExporting = ref(false);

  const completedPercentage = ref(0);

  const fetchContacts = async () => {
    currentlyExporting.value = true;
    const contacts = [] as ContactModelResult[];
    let page = 0;
    let totalCount = 0;

    while (contacts.length < totalCount || page === 0) {
      const { results, totalMatchedActive } = await ContactList({
        limit: 5000,
        page,
        tags: currentTag.value ? [currentTag.value] : undefined,
      });

      completedPercentage.value = Math.round((contacts.length / totalMatchedActive) * 100 + 10);
      totalCount = totalMatchedActive;
      contacts.push(...results);
      page++;
    }

    return contacts;
  };

  interface ExportableContact {
    id?: string;
    name?: string;
    mobileNumber?: string;
    mobileCountry?: string;
    isActive?: boolean;
    tags?: string;
    [key: string]: any; // Allow for dynamic custom fields
  }

  const filteredContacts = async (): Promise<ExportableContact[]> => {
    const contacts = await fetchContacts();
    const exportColumns = [...columns.value];

    const uniqueTags = Array.from(new Set(contacts.flatMap((contact) => contact.tags.map((tag) => tag.name))));

    uniqueTags.forEach((tag) => {
      if (!exportColumns.some((column) => column.value === tag)) {
        exportColumns.push({
          name: tag,
          active: true,
          value: tag,
        });
      }
    });

    const filteredContacts = contacts.map((contact) => {
      const contactWithTags: ExportableContact = {
        id: contact.id,
        name: contact.name,
        mobileNumber: contact.mobileNumber,
        mobileCountry: contact.mobileCountry,
        isActive: contact.isActive,
      };

      // Format the tags field as a comma-separated string of tag names
      if (contact.tags && contact.tags.length > 0) {
        contactWithTags.tags = contact.tags.map((tag) => tag.name).join(', ');
      } else {
        contactWithTags.tags = '';
      }

      // Add a "true" value for each tag the contact has, otherwise an empty string
      uniqueTags.forEach((tag) => {
        contactWithTags[tag] = contact.tags.some((contactTag) => contactTag.name === tag) ? 'true' : '';
      });

      if (customColumns.value.length > 0) {
        customColumns.value.forEach((column) => {
          const textField = contact.textFields?.find((field) => field.id === column.id);
          const dateField = contact.dateFields?.find((field) => field.id === column.id);

          if (textField) {
            contactWithTags[column.name] = textField.value;
          } else if (dateField) {
            contactWithTags[column.name] = dateField.value;
          } else {
            contactWithTags[column.name] = ''; // Add empty value if field doesn't exist for this contact
          }
        });
      }

      // Filter out unwanted columns based on active status
      return omit(
        contactWithTags,
        Object.keys(contactWithTags).filter(
          (key) =>
            !exportColumns.find((column) => column.value.toLowerCase() === key.toLowerCase() || column.name.toLowerCase() === key.toLowerCase())
              ?.active,
        ),
      ) as ExportableContact;
    });

    return filteredContacts;
  };

  const exportContacts = async () => {
    const data = await filteredContacts();

    exportFromJSON({ data, fileName: file.name, exportType: file.type as ExportType, withBOM: true });

    currentlyExporting.value = false;
    completedPercentage.value = 0;
  };

  const file = reactive({
    name: 'contacts',
    type: 'csv',
  });

  const columns = ref([
    {
      name: 'ID',
      active: true,
      value: 'id',
    },
    {
      name: 'Name',
      active: true,
      value: 'name',
    },
    {
      name: 'Mobile number',
      active: true,
      value: 'mobileNumber',
    },
    {
      name: 'Mobile country',
      active: true,
      value: 'mobileCountry',
    },
    {
      name: 'Tags',
      active: true,
      value: 'tags',
    },
    {
      name: 'Active',
      active: true,
      value: 'isActive',
    },
  ]);

  const customColumns = ref([]) as Ref<ContactFieldBaseModel[]>;

  const exportModalEl = ref(null) as any as Ref<HTMLDivElement>;

  onMounted(async () => {
    // If a tag is selected, replace file name with tag name (with lowercase and spaces/tabs replaced with underscores)
    file.name =
      contactTags.value
        .find((tag) => tag.id === currentTag.value)
        ?.name.toLocaleLowerCase()
        .replace(/\s+/g, '_') || 'contacts';

    contactStore.fetchContactFields().then(() => {
      contactStore.contactFields.map((field) => {
        columns.value.push({
          name: field.name,
          active: true,
          value: field.name,
        });
        customColumns.value.push(field);
      });
    });

    const exportModal = new Modal(exportModalEl.value);
    exportModal.show();

    onBeforeUnmount(async () => {
      exportModal.hide();
    });

    exportModalEl.value.addEventListener('hidden.bs.modal', () => {
      emit('close');
      exportModal.dispose();
    });
  });
</script>

<style scoped></style>
