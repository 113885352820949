<template>
  <div class="card">
    <div class="card-body">
      <div class="row align-items-center gx-0">
        <div class="col">
          <h6 class="text-uppercase text-muted mb-2">New contacts last 30 days</h6>
          <div class="line-chart" v-show="contactsCreated && contactsCreated?.data.length > 0">
            <canvas ref="contactsChart"></canvas>
          </div>
          <Loading v-if="loadingStatistics" />
          <EmptyState v-else-if="contactsCreated && contactsCreated.data.length === 0" text="No statistics yet." />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { StatisticsContactsCreated } from '@/services/GraphqlApi';
  import { StatisticDailyContactsModel } from '@/dto/graphql';
  import { eachDayOfInterval, subDays, formatISO } from 'date-fns';
  import { Chart as ChartJS, ChartData } from 'chart.js';
  import { computed, onMounted, Ref, ref } from 'vue';
  import EmptyState from '../shared/EmptyState.vue';
  import Loading from '../shared/Loading.vue';
  import initChartJsStyling from '@/assets/js/chart-styling';

  const loadingStatistics = ref(true);
  const contactsCreated = ref(null) as Ref<StatisticDailyContactsModel | null>;
  const contactsChart = ref(null) as Ref<HTMLCanvasElement | null>;

  onMounted(async () => {
    const response = await StatisticsContactsCreated(subDays(new Date(), 30), new Date());
    contactsCreated.value = response;
    initChartJsStyling();

    const chartData = computed(() => {
      if (!contactsCreated.value) return null;

      const days = eachDayOfInterval({
        start: subDays(new Date(), 30),
        end: new Date(),
      });

      const labels = days.map((day) => day.toLocaleDateString());
      const data = days.map((day) => {
        const found = contactsCreated.value?.data.find((x) => x.day === formatISO(day, { representation: 'date' }));

        return found ? found.contactCount : 0;
      });

      return {
        labels,
        datasets: [
          {
            data,
            backgroundColor: ['#007bff'],
          },
        ],
      };
    });

    loadingStatistics.value = false;

    if (!contactsChart.value) return;
    new ChartJS(contactsChart.value, {
      type: 'line',
      data: chartData.value as ChartData,
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              afterLabel: function () {
                return ' contacts';
              },
            },
          },
        },
      },
    });
  });
</script>

<style scoped lang="scss">
  .line-chart {
    height: 300px;
  }
</style>
