<template>
  <Teleport to="body">
    <div class="modal" ref="importModalEl" tabindex="-1">
      <div class="modal-dialog" :class="{ 'modal-xl': currentStep === CurrentStep.ConfigureXlsx || currentStep === CurrentStep.ConfigureCsv }">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="staticBackdropLabel">Import recipients</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- PICK FILE STEP -->
            <div v-show="currentStep === CurrentStep.Upload">
              <div class="alert" :class="{ 'alert-light': currentTheme === Theme.Light, 'alert-secondary': currentTheme === Theme.Dark }">
                Please provide a CSV or Excel-file. You will map columns to fields in the next step.
              </div>
              <input class="form-control" ref="fileField" @input="onNewFile" accept=".csv, .xlsx" type="file" name="numbers" />

              <p class="small text-muted mt-2 mb-0">The spreadsheet file you choose, is processed entirely in your browser.</p>
            </div>
            <!-- PICK FILE STEP -->

            <div v-if="isParsingFile">
              <div class="alert alert-info">
                Please wait - your file is being read and parsed.<br /><br />
                Parsing of CSV-files is almost instant, while Excel-files on a fast PC, usually takes approximately 1 minute per 500.000 row.
              </div>
            </div>

            <!-- PREVIEW -->
            <div v-if="data.length && (currentStep === CurrentStep.ConfigureXlsx || currentStep === CurrentStep.ConfigureCsv)">
              <div class="alert" :class="{ 'alert-light': currentTheme === Theme.Light, 'alert-secondary': currentTheme === Theme.Dark }">
                <p class="mb-0">
                  Below is a preview of your file. You must at least specify which fields contain the phone number parts or the field which contains
                  the entire phone number.
                </p>
              </div>

              <div class="table-responsive mt-4" v-if="data.length">
                <table class="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th style="min-width: 150px" v-for="[idx, title] of Object.entries(headerRow)" class="text-center p-3" :key="idx">
                        <span class="badge bg-secondary">{{ title }}</span
                        ><br />
                        <select v-model="columnMap[Number(idx)]" @change="ensureNoConflictingColumn(~~idx)" class="form-select mt-2">
                          <option value="" selected>- Don't import -</option>
                          <optgroup label="Phone number">
                            <option value="countryCode">Phone country code</option>
                            <option value="nationalNumber">National number</option>
                            <option value="msisdn">Full phone (country + national)</option>
                          </optgroup>
                          <optgroup label="Meta fields">
                            <option value="name">Name</option>
                            <option v-for="field in customFields" :key="field.id" :value="field.tagName">
                              {{ field.name }}
                            </option>
                          </optgroup>
                          <optgroup label="Tags">
                            <option v-for="tag in allTags" :key="tag.id" :value="`tag:${tag.name}`">
                              {{ tag.name }}
                            </option>
                          </optgroup>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="[rowIdx, row] of Object.entries(data.slice(1, 6))" :key="rowIdx">
                      <td class="p-3" v-for="[colIdx, title] of Object.entries(row)" :key="colIdx">
                        {{ title }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- PREVIEW -->

            <!-- TAGS -->
            <div v-if="currentStep === CurrentStep.Tags">
              <div class="alert" :class="{ 'alert-light': currentTheme === Theme.Light, 'alert-secondary': currentTheme === Theme.Dark }">
                <p>You are about to import {{ data.length - 1 }} contacts.</p>
                <p class="mb-0">
                  {{
                    hasTagsInColumns
                      ? 'You can optionally select a tag that will be applied to all the imported contacts.'
                      : 'Please select at least one tag to apply to all the imported contacts.'
                  }}
                </p>
              </div>
              <ContactEditorTagEditor />
            </div>
            <!-- TAGS -->

            <!-- IMPORTING -->
            <div v-if="currentStep === CurrentStep.Importing">
              <div v-if="!importFailure">
                <div class="alert alert-info" v-if="imported < data.length - 1">Importing in progress. Please wait.</div>
                <div class="alert alert-success" v-else>Import complete! Feel free to close this modal now.</div>
              </div>
              <div v-else class="alert alert-danger">
                <p>Import failed.</p>
                <p class="mb-0">{{ importFailure }}</p>
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  :class="{ 'bg-success': imported >= data.length - 1 }"
                  role="progressbar"
                  :style="`width: ${completedPercentage}%`"
                ></div>
              </div>
            </div>
          </div>

          <div class="modal-footer" v-if="currentStep === CurrentStep.Importing">
            <button type="button" class="btn btn-outline-secondary" :disabled="imported < data.length - 1" data-bs-dismiss="modal">Close</button>
          </div>
          <div class="modal-footer" v-else>
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="currentStep !== CurrentStep.Tags"
              :disabled="!readyForTags"
              @click.prevent="currentStep = CurrentStep.Tags"
            >
              Continue
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="currentStep === CurrentStep.Tags"
              :disabled="!readyForImport"
              @click.prevent="doImport"
            >
              Import
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
  import { computed, ComputedRef, inject, onBeforeUnmount, onMounted, provide, Ref, ref, watch, watchEffect } from 'vue';
  import { Modal } from 'bootstrap';
  import { endsWith } from 'lodash';
  import { parse as csvParse } from 'papaparse';
  import readXlsxFile from 'read-excel-file';
  import { ContactBulkInsert, ContactFieldList, getBestErrorMessage } from '@/services/GraphqlApi';
  import { getPhoneParts } from '@/services/PhoneNumber';
  import ContactEditorTagEditor from '@/components/contacts/ContactEditorTagEditor.vue';
  import { currentTheme, Theme } from '@/services/Theme';
  import {
    ContactFieldDateModel,
    ContactFieldDateValueInput,
    ContactFieldTextModel,
    ContactFieldTextValueInput,
    ContactModel,
    ContactTagModelResult,
  } from '@/dto/graphql';
  import { useToast } from 'vue-toastification';

  const MAX_PER_BATCH = 1000;

  enum CurrentStep {
    Upload,
    ConfigureCsv,
    ConfigureXlsx,
    Tags,
    Importing,
  }

  const importModalEl = ref(null) as any as Ref<HTMLDivElement>;

  const emit = defineEmits(['close']);
  const importFailure = ref('');

  const currentStep = ref(CurrentStep.Upload) as Ref<CurrentStep>;
  const allTags = inject('contactTags') as Ref<ContactTagModelResult[]>;

  const selectedTags = ref([]) as Ref<string[]>;
  provide('selectedTags', selectedTags);

  const customFields = ref([]) as Ref<ContactFieldDateModel[] | ContactFieldTextModel[]>;
  provide('customFields', customFields);

  // Computed that returns true or false depending on if a tag is present in columnMap that exist in allTags
  const hasTagsInColumns = computed(() => {
    const tagColumns = Object.values(columnMap.value).filter((col) => col.startsWith('tag:'));
    return tagColumns.length > 0;
  });

  onMounted(async () => {
    customFields.value = await ContactFieldList();

    const importModal = new Modal(importModalEl.value);
    importModal.show();

    onBeforeUnmount(async () => {
      importModal.hide();
    });

    importModalEl.value.addEventListener('hidden.bs.modal', () => {
      emit('close');
      importModal.dispose();
    });
  });

  const headerRow = computed(() => {
    return data.value.length ? data.value[0] : [];
  }) as ComputedRef<string[]>;

  // STEP 1: UPLOAD FILE
  const file = ref(null) as any as Ref<File>;
  const fileCsvRaw = ref('') as Ref<string>;
  const isParsingFile = ref(false) as Ref<boolean>;
  const onNewFile = (ev: Event) => {
    const fileField = ev.target as HTMLInputElement;
    if (!fileField.files || !fileField.files[0]) return;

    file.value = fileField.files[0];
    const filename = file.value.name.toLowerCase();
    isParsingFile.value = true;
    if (endsWith(filename, '.csv')) {
      file.value.text().then((t) => {
        fileCsvRaw.value = t;
        currentStep.value = CurrentStep.ConfigureCsv;
      });
    } else if (endsWith(filename, '.xlsx')) {
      currentStep.value = CurrentStep.ConfigureXlsx;
    } else {
      window.alert('The file you choose, must have either ".csv" or ".xlsx" as its extension.');
    }
  };

  const data = ref([]);
  const columnMap = ref([]) as Ref<string[]>;

  // STEP 2: IMPORT
  watchEffect(() => {
    if (currentStep.value === CurrentStep.ConfigureCsv) {
      data.value = csvParse(fileCsvRaw.value).data as [];
      isParsingFile.value = false;
    }

    if (currentStep.value === CurrentStep.ConfigureXlsx) {
      readXlsxFile(file.value).then((rows) => {
        data.value = rows as [];
        isParsingFile.value = false;
      });
    }
  });

  // ensure no duplicate or conflicting columns
  const ensureNoConflictingColumn = (compareIdx: number) => {
    for (const [i, v] of columnMap.value.entries()) {
      if (i === compareIdx) continue;

      // no duplicates
      if (v === columnMap.value[compareIdx]) columnMap.value[i] = '';

      // if msisdn, then don't allow countryCode + nationalNumber
      if (columnMap.value[compareIdx] === 'msisdn' && ['countryCode', 'nationalNumber'].includes(v)) columnMap.value[i] = '';

      // ... and vice versa
      if (columnMap.value[compareIdx] === 'countryCode' && v === 'msisdn') columnMap.value[i] = '';
      if (columnMap.value[compareIdx] === 'nationalNumber' && v === 'msisdn') columnMap.value[i] = '';
    }
  };

  watch(data, (res) => {
    columnMap.value.length = 0;
    for (const [i] of (res[0] as []).entries()) {
      columnMap.value[i] = '';
    }
  });

  const msisdnColumn = computed(() => columnMap.value.indexOf('msisdn'));
  const countryCodeColumn = computed(() => columnMap.value.indexOf('countryCode'));
  const nationalNumberColumn = computed(() => columnMap.value.indexOf('nationalNumber'));
  const nameColumn = computed(() => columnMap.value.indexOf('name'));
  const readyForTags = computed(() => msisdnColumn.value !== -1 || (countryCodeColumn.value !== -1 && nationalNumberColumn.value !== -1));
  const readyForImport = computed(() => {
    if (readyForTags.value) {
      if (hasTagsInColumns.value) return true;
      return selectedTags.value.length > 0;
    }
    return false;
  });

  // STEP 4: IMPORT
  const imported = ref(0);
  const doImport = async () => {
    try {
      currentStep.value = CurrentStep.Importing;

      // logic: try to split into 10 parts, though at most IMPORT_PER_BATCH contacts per batch
      const chosenImportPerBatch = Math.min(MAX_PER_BATCH, ~~Math.max(data.value.length / 10, 1));

      const contacts = [] as ContactModel[];
      for (const [i, rowOrig] of data.value.entries()) {
        const row = rowOrig as string[];

        // ensure we only import IMPORT_PER_BATCH at a time by sleeping until the bus has left
        if (contacts.length >= chosenImportPerBatch) {
          await ContactBulkInsert(contacts);
          imported.value += contacts.length;
          contacts.length = 0;
        }

        const msisdn = msisdnColumn.value !== -1 ? `${row[msisdnColumn.value]}` : `${row[countryCodeColumn.value]}${row[nationalNumberColumn.value]}`;

        const [mobileCountry, mobileNumber] = getPhoneParts(msisdn);
        const name = nameColumn.value !== -1 ? row[nameColumn.value] : `(+${mobileCountry}) ${mobileNumber}`;

        const textFields = [] as ContactFieldTextValueInput[];
        const dateFields = [] as ContactFieldDateValueInput[];

        customFields.value.map((field) => {
          if (columnMap.value.includes(field.tagName)) {
            if (field.__typename === 'ContactFieldTextModel') {
              textFields.push({
                id: field.id,
                value: `${row[columnMap.value.indexOf(field.tagName)]}`,
              });
            } else if (field.__typename === 'ContactFieldDateModel') {
              dateFields.push({
                id: field.id,
                value: row[columnMap.value.indexOf(field.tagName)],
              });
            }
          }
        });

        // Find all tags in columnMap that exist in allTags, and add them to the contact
        const tags = [];
        for (const [i, columnValue] of columnMap.value.entries()) {
          const isTag = columnValue.startsWith('tag:');
          if (isTag) {
            const tagName = columnValue.slice(4);
            const tag = allTags.value.find((t) => t.name === tagName);
            const tagValue = row[i];
            const isTagValueValid = tagValue && Number(tagValue) !== 0 && tagValue !== 'false' && tagValue !== 'undefined' && tagValue !== 'null';
            if (tag && isTagValueValid) {
              tags.push(tag.id);
            }
          }
        }

        if (!mobileCountry || !mobileNumber || !name) continue;

        contacts.push({
          mobileCountry,
          mobileNumber,
          name: `${name}`,
          isActive: true,
          tags: [...selectedTags.value, ...tags],
          textFields,
          dateFields,
        });
      }

      await ContactBulkInsert(contacts);
      imported.value += data.value.length;
      contacts.length = 0;
    } catch (e) {
      importFailure.value = (e as Error).message;
      return;
    }
  };
  const completedPercentage = computed(() => ~~(100 * (imported.value / Math.max(data.value.length, 1))));
</script>
