<template>
  <div class="card">
    <div class="card-body">
      <h6 class="text-uppercase text-muted mb-2">Contact countries</h6>
      <ul class="list-group-flush p-0 countries-list" v-if="countries.length > 0">
        <div v-for="(country, index) in countries" :key="index" class="list-group-item d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center w-100" style="max-width: 100%">
            <img
              class="flag me-3 rounded-circle border border-1 shadow-sm my-n4"
              :src="getFlagByNationalNumber(parseInt(country.countryPrefix))"
              :alt="`${getCountryByNationalNumber(parseInt(country.countryPrefix))?.name} flag` || 'Unknown country flag'"
            />
            <div class="m-0 text-truncate text-nowrap w-100">
              {{ getCountryByNationalNumber(parseInt(country.countryPrefix))?.name || 'Unknown country' }}
            </div>
            <div>
              <span class="badge bg-primary-soft ms-2">{{ country.contactCount }}</span>
            </div>
          </div>
        </div>
      </ul>
      <Loading v-else-if="loadingCountries" />
      <EmptyState v-else text="No statistics yet." />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { DataContactCountriesModel } from '@/dto/graphql';
  import { StatisticsContactsCountries } from '@/services/GraphqlApi';
  import { onMounted, Ref, ref } from 'vue';
  import Loading from '../shared/Loading.vue';
  import EmptyState from '../shared/EmptyState.vue';
  import { getCountryByNationalNumber, getFlagByNationalNumber } from '@/services/PhoneNumber';

  const countries = ref({}) as Ref<DataContactCountriesModel[]>;
  const loadingCountries = ref(true);

  onMounted(async () => {
    const { data } = await StatisticsContactsCountries();
    countries.value = data.sort((a, b) => b.contactCount - a.contactCount);
    loadingCountries.value = false;
  });
</script>

<style lang="scss" scoped>
  .flag {
    height: 32px;
  }

  .countries-list {
    max-height: 315px;
    overflow-y: auto;
  }
</style>
