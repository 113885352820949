<template>
  <div class="phone rounded">
    <div v-if="inbox" class="screen" :class="{ dark: currentTheme === Theme.Dark }">
      <div class="info" :class="{ dark: currentTheme === Theme.Dark }">
        <p class="text-muted m-0">
          {{ inbox.ruleset.sender }}
          {{ inbox.ruleset.sender ? `(${inbox.number})` : inbox.number }}
        </p>
      </div>
      <div class="py-3" ref="parent">
        <MessagesAutoreply v-if="inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyModel'" />
        <MessagesAutoreplyMultiple v-if="inbox.ruleset.__typename === 'SmsInboxRulesetAutoreplyMultipleModel'" />
        <MessagesPaymentFixed v-else-if="inbox.ruleset.__typename === 'SmsInboxRulesetPaymentFixedModel'" />
        <MessagesPaymentDynamic v-else-if="inbox.ruleset.__typename === 'SmsInboxRulesetPaymentDynamicModel'" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { inject } from 'vue';
  import {
    SmsInboxModelResult,
    SmsInboxRulesetAutoreplyModel,
    SmsInboxRulesetAutoreplyMultipleModel,
    SmsInboxRulesetPaymentDynamicModel,
    SmsInboxRulesetPaymentFixedModel,
  } from '@/dto/graphql';
  import { useAutoAnimate } from '@formkit/auto-animate/vue';
  import { currentTheme, Theme } from '@/services/Theme';
  import MessagesAutoreply from './messages/MessagesAutoreply.vue';
  import MessagesAutoreplyMultiple from './messages/MessagesAutoreplyMultiple.vue';
  import MessagesPaymentFixed from './messages/MessagesPaymentFixed.vue';
  import MessagesPaymentDynamic from './messages/MessagesPaymentDynamic.vue';

  const [parent] = useAutoAnimate();

  type TInbox = SmsInboxModelResult & {
    ruleset:
      | SmsInboxRulesetAutoreplyModel
      | SmsInboxRulesetAutoreplyMultipleModel
      | SmsInboxRulesetPaymentDynamicModel
      | SmsInboxRulesetPaymentFixedModel;
  };

  const inbox = inject<TInbox>('inbox');
</script>

<style lang="scss">
  $blue: #0099ff;
  $gray: #ececec;
  $light-gray: whitesmoke;

  .phone {
    pointer-events: none;
    .screen {
      min-height: 440px;
      height: 100%;
      background: linear-gradient(to top, #f9fbfd, #fff 20%);
      align-self: center;
      &.dark {
        background: linear-gradient(to top, var(--bs-body-bg), var(--bs-chart-arc-border-color) 20%);
      }
    }
    .info {
      background: linear-gradient(to bottom, #f9fbfd, $light-gray 50%);
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin: 0;
        font-weight: bold;
      }
      &.dark {
        background: linear-gradient(to bottom, var(--bs-body-bg), var(--bs-chart-arc-border-color) 50%);
      }
    }
    .message {
      box-sizing: border-box;
      padding: 8px 10px;
      max-width: 200px;
      margin: 5px 10px;
      border-radius: 15px;
      position: relative;
      clear: both;
      font-size: 14px;
      width: initial !important;
    }
    .to {
      float: right;
      color: #fff;
      background-color: $blue;
      &::before {
        content: '';
        border: solid 10px;
        border-color: transparent transparent $blue;
        position: absolute;
        float: right;
        right: -7px;
        bottom: 2px;
      }
    }
    .from {
      float: left;
      background-color: $gray;
      &::after {
        content: '';
        border: solid 10px;
        border-color: transparent transparent $gray;
        position: absolute;
        z-index: 1;
        bottom: 2px;
        left: -7px;
      }
      &.dark {
        background-color: var(--bs-dark);
        &::after {
          border-color: transparent transparent var(--bs-dark);
        }
      }
    }
  }
</style>
