<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title me-auto">Sender ID</h4>
      <div class="d-inline-flex align-items-center">
        <span class="badge bg-light">{{ smsSender.length }} / {{ maxCharacters }}</span>
      </div>
    </div>
    <div class="card-body">
      <label for="senderName" class="mb-2">
        Sender ID <span class="text-muted">(optional)</span>
        <InfoTooltip
          tooltip="You may enter a maximum of 15 digits or 11 characters (eg. latin characters and/or numbers).<br><br>Custom Sender ID require pre-registration in some countries and others have no support at all. Contact us for assistance."
        ></InfoTooltip>
      </label>
      <input type="text" id="senderName" class="form-control" v-model="smsSender" :maxlength="maxCharacters" placeholder="Enter sender ID" />
      <div v-if="setup" class="d-flex justify-content-end mt-4">
        <button class="btn btn-primary" @click="emit('save')">Save changes</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';
  import { computed, watch } from 'vue';

  const props = defineProps({
    modelValue: {
      type: String,
      required: true,
    },
    setup: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['update:modelValue', 'save']);
  const smsSender = computed({
    get: () => props.modelValue,
    set: (value: string) => emit('update:modelValue', value),
  });

  const maxCharacters = computed(() => {
    return smsSender.value.match(/\D+/g) ? 11 : 15;
  });

  watch(maxCharacters, (c) => {
    smsSender.value = smsSender.value.slice(0, c);
  });

  watch(smsSender, (s) => {
    emit('update:modelValue', s);
  });
</script>
