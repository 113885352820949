<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title">Message class</h4>
    </div>
    <div class="card-body">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="gatewayapiClass"
          v-model="senderClass"
          :value="MessageClass.Standard"
          id="gatewayapiClassStandard"
        />
        <label class="form-check-label" for="gatewayapiClassStandard">
          Standard Class
          <Infobox tooltip="Standard is recommended for marketing and non time-critical information. This is the lowest price option."></Infobox>
        </label>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="gatewayapiClass"
          v-model="senderClass"
          :value="MessageClass.Premium"
          id="gatewayapiClassPremium"
        />
        <label class="form-check-label" for="gatewayapiClassPremium">
          Premium Class
          <Infobox tooltip="Premium is queued at a high priority. It has the least possible hops to the actual carriers."></Infobox>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { inject, Ref, ref, watch } from 'vue';
  import { MessageClass } from '@/dto/graphql';
  import Infobox from '@/components/shared/InfoTooltip.vue';
  import { QuickSend } from '@/services/QuickSend';

  const quickSend = inject('quickSend') as QuickSend;

  // refs for use in template
  const senderClass = quickSend.senderClass;
</script>
