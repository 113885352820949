<template>
  <div class="container-fluid vh-100 d-lg-flex flex-column">
    <!-- HEADER -->
    <div class="header">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h6 class="header-pretitle">SMS Campaign</h6>
            <h1 class="header-title py-2">List of Campaigns</h1>
          </div>

          <div class="col-auto">
            <button @click="router.push({ name: 'SmsCampaignCreate' })" class="btn btn-primary btn-lg d-flex align-items-center px-4">
              <i class="fe fe-edit-2 me-3"></i>
              Create campaign
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- HEADER -->

    <!-- BODY -->
    <div class="row flex-lg-fill">
      <div class="col-xl-3">
        <CampaignTags @reload="updateCampaignList" />
      </div>
      <div class="col-xl-9">
        <div class="card card-fill-lg">
          <div class="card-header">
            <h4 class="card-header-title d-flex align-items-center">Campaigns</h4>
          </div>
          <div class="position-relative mt-0 overflow-auto flex-grow-1 d-flex">
            <div class="inner position-absolute overflow-auto top-0 start-0 h-100 w-100">
              <div class="table-responsive-sm table-responsive-md">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 50%; min-width: 200px">Campaign name</th>
                      <th style="width: 20%; min-width: 140px">Recipients</th>
                      <th style="width: 25%; min-width: 140px">Status</th>
                      <th><!-- actions --></th>
                    </tr>
                  </thead>
                  <tbody v-if="campaigns?.length > 0">
                    <SmsCampaignListRow
                      v-for="campaign of campaigns"
                      :campaign="campaign"
                      :key="campaign.id"
                      @deleted="campaignDeleted(campaign)"
                      @update="updateCampaignList"
                    />
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4" class="text-center">
                        <Loading v-if="loadingCampaigns" />
                        <EmptyState v-else text="No campaigns found.">
                          <button @click="router.push({ name: 'SmsCampaignCreate' })" class="btn btn-primary px-4">Create campaign</button>
                        </EmptyState>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="totalCount > PER_PAGE" class="card-footer card-footer-boxed">
            <ListPagination
              :total-items="totalCount"
              :page-size="PER_PAGE"
              :current-page="currentPage"
              @page-change="(page) => (currentPage = page)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- BODY -->
  </div>
</template>

<script lang="ts" setup>
  import { CampaignList, CampaignTagList } from '@/services/GraphqlApi';
  import { onUnmounted, provide, Ref, ref, watch } from 'vue';
  import { CampaignModelResult, CampaignTagModelResult } from '@/dto/graphql';
  import SmsCampaignListRow from '@/components/sms-campaign-list/SmsCampaignListRow.vue';
  import CampaignTags from '@/components/sms-campaign-list/CampaignTags.vue';
  import Loading from '@/components/shared/Loading.vue';
  import EmptyState from '@/components/shared/EmptyState.vue';
  import ListPagination from '@/components/shared/ListPagination.vue';
  import _ from 'lodash';
  import { useRouter } from 'vue-router';

  const campaigns = ref() as Ref<CampaignModelResult[]>;
  const loadingCampaigns = ref(false) as Ref<boolean>;

  const currentPage = ref(0) as Ref<number>;
  const totalCount = ref(0) as Ref<number>;
  const totalPages = ref(0) as Ref<number>;

  const campaignTags = ref({}) as Ref<CampaignTagModelResult[]>;
  const currentTag = ref() as Ref<string>;

  const router = useRouter();

  // how many items per page?
  const PER_PAGE = 100;

  provide('campaignTags', campaignTags);
  provide('currentTag', currentTag);

  watch([currentPage, currentTag], () => {
    updateCampaignList();
  });

  const updateCampaignList = async () => {
    loadingCampaigns.value = true;
    CampaignList({
      ...(currentTag.value && { campaignTags: [currentTag.value] }),
      limit: PER_PAGE,
      page: currentPage.value,
    }).then((c) => {
      campaigns.value = c.results;
      totalCount.value = c.matchedCount;
      totalPages.value = Math.ceil(c.totalCount / PER_PAGE);
    });

    campaignTags.value = await CampaignTagList();
    loadingCampaigns.value = false;
  };

  const updateRef = setInterval(updateCampaignList, 5000);
  updateCampaignList();
  onUnmounted(() => {
    clearInterval(updateRef);
  });

  const campaignDeleted = (campaign: CampaignModelResult) => {
    _.remove(campaigns.value, (c: CampaignModelResult) => c.id === campaign.id);
  };
</script>

<style lang="scss" scoped>
  @media (max-width: 991px) {
    .card {
      height: 75vh;
    }
  }
</style>
