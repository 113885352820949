import punycode from 'punycode/';
import uniq from 'lodash/uniq';

export enum SmsEncoding {
  GSM = 'GSM 03.38',
  UCS2 = 'UCS2',
}

export type SmsStats = {
  maxCharacters: number;
  characters: number;
  remainingCharacters: number;
  messageParts: number;
  failedGsmChars: string[];
  encoding: SmsEncoding;
  links: string[];
};

const GSM_CHARS_ONE =
  ' !"#$%&\'()*+,-./0123456789:;<=>?@abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ£¥§¿_\n\rΔΦΓΛΩΠΨΣΘΞèéùìòÇØøÅåÆæßÉÄÖÑÜäöñüàäöñüà';
const GSM_CHARS_TWO = '^{}[]~|€\\';

// https://stackoverflow.com/questions/9760588/how-do-you-extract-a-url-from-a-string-using-python/31952097#31952097
const REGEXP_LINKS =
  /((?:(https?|s?ftp):\/\/)?(?:www\.)?((?:(?:[A-Z0-9][A-Z0-9-]{0,61}[A-Z0-9]\.)+)([A-Z]{2,6})|(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(?::(\d{1,5}))?(?:(\/\S+)*))/gi;

// RFC 5322 Official
const REGEXP_MAIL =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

let lastMessage: string | undefined;
let lastMessageStats: SmsStats | undefined;

/**
 * Get message stats
 */
export function getMessageStats(message: string): SmsStats {
  if (message === lastMessage && lastMessageStats) return lastMessageStats;

  const links = getAllLinks(message);
  const failedGsmChars = getMessageFailedGSM0338Chars(message);
  const encoding = failedGsmChars.length ? SmsEncoding.UCS2 : SmsEncoding.GSM;
  const stats = encoding === SmsEncoding.UCS2 ? getMessageCountUcs2(message) : getMessageCountGsm0338(message);

  return (lastMessageStats = {
    ...stats,
    links,
    failedGsmChars,
    encoding,
  });
}

export function getAllLinks(message: string): string[] {
  // replace all mails with nothing
  message = message.replace(REGEXP_MAIL, '');

  // extract urls
  const urls = message.match(REGEXP_LINKS);

  // any urls?
  if (!urls) return [];

  return uniq(urls.map((u) => u.toLowerCase()));
}

/**
 * Detect if there are any specialchars beyond the GSM 03.38 spec and return an array of failed
 * characters. Returns an empty array if nothing failed.
 */
function getMessageFailedGSM0338Chars(message: string) {
  const lookup = (GSM_CHARS_ONE + GSM_CHARS_TWO).split('');
  const chars = punycode.ucs2.decode(message);
  const failed_chars: string[] = [];
  chars.forEach((char_hex) => {
    const char = punycode.ucs2.encode([char_hex]);
    if (!lookup.includes(char)) {
      failed_chars.push(char);
    }
  });
  return uniq(failed_chars);
}

/**
 * Returns object with statistics about message, emulating GSM 03.38.
 *
 * @param message
 */
function getMessageCountGsm0338(message: string) {
  const chars = punycode.ucs2.decode(message);
  const lookup2 = GSM_CHARS_TWO.split('');
  let characters = 0;
  chars.forEach((char_hex) => {
    const char = punycode.ucs2.encode([char_hex]);
    characters++;
    if (lookup2.includes(char)) {
      characters++;
    }
  });
  const size = characters / 160 > 1 ? 153 : 160;
  const messageParts = characters / 160 > 1 ? Math.ceil(characters / 153) : 1;
  const maxCharacters = size * messageParts;
  let remainingCharacters = maxCharacters - characters;
  if (message.length === 0) {
    remainingCharacters = size;
  }
  return {
    maxCharacters,
    characters,
    remainingCharacters,
    messageParts,
  };
}

/**
 * Returns object with statistics about message, emulating UCS2.
 *
 * @param message
 */
function getMessageCountUcs2(message: string) {
  const chars = punycode.ucs2.decode(message);
  const characters = chars.length;
  const size = characters / 70 > 1 ? 67 : 70;
  // utf16 single message length is 70 and multi message length is 67
  const messageParts = characters / 70 > 1 ? Math.ceil(characters / 67) : 1;
  const maxCharacters = size * messageParts;
  let remainingCharacters = maxCharacters - characters;
  if (remainingCharacters === 0) {
    remainingCharacters = size;
  }
  return {
    maxCharacters,
    characters,
    remainingCharacters,
    messageParts,
  };
}

/**
 * Returns the total amount of messageparts
 *
 * @param messageParts The amount of messageparts
 * @param recipientCount The amount of recipients
 */
export function getTotalMessageParts(messageParts: number, recipientCount: number) {
  return messageParts * recipientCount;
}
