<template>
  <RuleSection
    title="Error: Initial SMS missing"
    index="C"
    type="danger"
    info="We can't find the initial SMS (maybe it's been too long, the user has sent multiple confirms or has never sent initial SMS?)"
    :margin="margin"
  >
    <div class="form-group row mb-0">
      <SmsMessage
        v-model="inbox.ruleset.errNoInitialContent"
        :placeholder="`Example: Thank you for your interest in our campaign. Unfortunately, we can't find your initial SMS.${
          inbox.keyword && `Please reply with ${inbox.keyword.toUpperCase()} to start the donation.`
        }`"
        label="Reply content:"
      ></SmsMessage>
    </div>
  </RuleSection>
</template>

<script setup lang="ts">
  import { inject } from 'vue';
  import RuleSection from './../ruleset/shared/RuleSection.vue';
  import SmsMessage from '@/components/shared/SmsMessage.vue';

  const props = defineProps({
    margin: {
      type: Boolean,
      default: true,
    },
  });

  const inbox = inject('inbox') as any;
</script>
