<template>
  <SingleMessage
    type="to"
    :text="`${inbox?.keyword == '*' ? '' : inbox?.keyword || 'SURVEY'} I loved the service! I'd like to give you a 5 star review.`"
  />
  <SingleMessage
    v-if="inbox?.ruleset.replyWithMessage"
    type="from"
    :text="`${(inbox?.ruleset.replyContent as unknown as TInbox) || `Thank you for your feedback! We really appreciate it.`}`"
  />
</template>

<script setup lang="ts">
  import { inject } from 'vue';
  import { SmsInboxModelResult, SmsInboxRulesetAutoreplyModel } from '@/dto/graphql';
  import SingleMessage from './SingleMessage.vue';

  type TInbox = SmsInboxModelResult & {
    ruleset: SmsInboxRulesetAutoreplyModel;
  };

  const inbox = inject<TInbox>('inbox');
</script>

<style scoped></style>
