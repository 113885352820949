<template>
  <template v-if="inbox?.ruleset.keywords?.length === 0">
    <SingleMessage type="to" :text="`${inbox?.keyword || 'RC'} WEBSITE`" />
    <SingleMessage type="from" text="You can find our website at https://relationcity.com" />
    <SingleMessage type="to" :text="`${inbox?.keyword || 'RC'} SURVEY What a nice platform!`" />
    <SingleMessage type="from" text="Thank you for your feedback! We really appreciate it." />
  </template>
  <template v-for="(keyword, idx) in inbox?.ruleset.keywords" :key="idx">
    <SingleMessage type="to" :text="`${inbox?.keyword || 'RC'} ${keyword.keyword || 'WEBSITE'}`" />
    <SingleMessage type="from" :text="`${keyword.replyContent || `You can find our website at https://relationcity.com`}`" />
  </template>
</template>

<script setup lang="ts">
  import { inject } from 'vue';
  import { SmsInboxModelResult, SmsInboxRulesetAutoreplyMultipleModel } from '@/dto/graphql';
  import SingleMessage from './SingleMessage.vue';

  type TInbox = SmsInboxModelResult & {
    ruleset: SmsInboxRulesetAutoreplyMultipleModel;
  };

  const inbox = inject<TInbox>('inbox');
</script>

<style scoped></style>
