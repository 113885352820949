<template>
  <Teleport to="body">
    <div ref="campaignSettingsModalEl" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="settingsModalLabel">Advanced campaign settings</h4>
            <button id="close" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-3 navbar-light">
                <ul class="navbar-nav">
                  <li></li>
                  <li class="nav-item" v-if="status !== 'Sending'">
                    <a href="#" class="nav-link" :class="{ active: currentPage === 'MessageClass' }" @click.prevent="currentPage = 'MessageClass'"
                      >Message class</a
                    >
                  </li>
                  <li class="nav-item">
                    <a href="#" class="nav-link" :class="{ active: currentPage === 'SendTime' }" @click.prevent="currentPage = 'SendTime'"
                      >Scheduling</a
                    >
                  </li>
                  <li class="nav-item">
                    <a href="#" class="nav-link" :class="{ active: currentPage === 'AllowedHours' }" @click.prevent="currentPage = 'AllowedHours'"
                      >Allowed hours</a
                    >
                  </li>
                  <li class="nav-item">
                    <a href="#" class="nav-link" :class="{ active: currentPage === 'SendSpeed' }" @click.prevent="currentPage = 'SendSpeed'"
                      >Send speed</a
                    >
                  </li>
                </ul>
              </div>

              <div class="col-9">
                <MessageClassComponent v-if="currentPage === 'MessageClass' && status !== 'Sending'" />
                <SendTime v-if="currentPage === 'SendTime'" v-model="sendTime" :timezone="accountSettings.settings?.timezone" />
                <AllowedHours v-if="currentPage === 'AllowedHours'" :timezone="accountSettings.settings?.timezone" />
                <SendSpeed v-model="throttle" v-if="currentPage === 'SendSpeed'" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="saveSettings">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
  import { Ref, ref, inject, onMounted, onBeforeUnmount } from 'vue';
  import { useToast } from 'vue-toastification';
  import { Modal } from 'bootstrap';
  import { AccountSettingsModel, CampaignAllowedHoursModel, MessageClass } from '@/dto/graphql';
  import MessageClassComponent from './MessageClass.vue';
  import SendTime from './SendTime.vue';
  import SendSpeed from './SendSpeed.vue';
  import AllowedHours from './AllowedHours.vue';
  import { CampaignUpsert, accountSettingsGet } from '@/services/GraphqlApi';
  import { zonedTimeToUtc } from 'date-fns-tz';
  import { useSettingsStore } from '@/stores/settings';
  const toast = useToast();
  const emit = defineEmits(['save', 'close']);
  const props = defineProps({
    campaignId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  });

  const campaignSettingsModalEl = ref(null) as any as Ref<HTMLDivElement>;
  const currentPage = ref<string>(props.status !== 'Sending' ? 'MessageClass' : 'SendTime');

  const messageClass = inject('messageClass') as Ref<MessageClass>;
  const sendTime = inject('sendTime') as Ref<Date>;
  const throttle = inject('throttle') as Ref<number>;
  const allowedHours = inject('allowedHours') as Ref<CampaignAllowedHoursModel[]>;

  const accountSettings = useSettingsStore();

  onMounted(() => {
    accountSettings.getSettings();

    const settingsModal = new Modal(campaignSettingsModalEl.value);
    settingsModal.show();

    onBeforeUnmount(async () => {
      settingsModal.hide();
    });

    campaignSettingsModalEl.value.addEventListener('hidden.bs.modal', () => {
      settingsModal.dispose();
      emit('close');
    });
  });

  const saveSettings = async () => {
    try {
      CampaignUpsert(
        {
          messageClass: messageClass.value,
          sendTime: sendTime.value,
          allowedHours: allowedHours.value.map((h) => {
            return {
              start: zonedTimeToUtc(h.start, accountSettings.settings?.timezone as string),
              end: zonedTimeToUtc(h.end, accountSettings.settings?.timezone as string),
            };
          }),
          throttle: throttle.value,
        },
        props.campaignId,
      );
      toast.success('Settings saved', { timeout: 1500 });
      emit('close');
    } catch (e) {
      toast.warning((e as Error).message);
    }
  };
</script>
