<template></template>

<style lang="scss">
  .chatlio-widget:not(.chatlio-open) {
    .chatlio-title-bar {
      display: none;
    }
  }
</style>

<script lang="ts">
  // @ts-nocheck
  import { defineComponent, watchEffect, ref } from 'vue';

  export const ChatlioReady = ref(false);
  const chatlioMustOpen = ref(false);
  export const ShowChatlio = function () {
    chatlioMustOpen.value = true;
  };

  export default defineComponent({
    setup() {
      window._chatlio = window._chatlio || [];
      !(function () {
        const t = document.getElementById('chatlio-widget-embed');
        if (t && window.ChatlioReact && _chatlio.init) return void _chatlio.init(t, ChatlioReact);
        for (
          let e = function (t) {
              return function () {
                _chatlio.push([t].concat(arguments));
              };
            },
            i = ['configure', 'identify', 'track', 'show', 'hide', 'isShown', 'isOnline', 'page', 'open', 'showOrHide'],
            a = 0;
          a < i.length;
          a++
        )
          _chatlio[i[a]] || (_chatlio[i[a]] = e(i[a]));
        const n = document.createElement('script'),
          c = document.getElementsByTagName('script')[0];
        (n.id = 'chatlio-widget-embed'),
          (n.src = 'https://w.chatlio.com/w.chatlio-widget.js'),
          (n.async = !0),
          n.setAttribute('data-embed-version', '2.3');
        n.setAttribute('data-widget-id', '5c69c12f-2814-466f-6e24-83e8cbd910b7');
        n.setAttribute('data-start-hidden', true);
        c.parentNode.insertBefore(n, c);

        document.addEventListener(
          'chatlio.ready',
          function (e) {
            const chatlioNode = e.target;

            // It's safe to use the API of course
            _chatlio.configure({
              noAnswerWithEmail: 'Oops! Sorry no one has responded yet. We have your email on file if you need to leave or you can continue to wait.',
              noAnswerWithoutEmail:
                'Oops! Sorry no one has responded yet. We have your email on file if you need to leave or you can continue to wait.',
              onlineTitle: 'Can we help?',
              offlineTitle: 'Contact Us',
              agentLabel: 'Support',
              onlineMessagePlaceholder: 'Type message here...',
              offlineGreeting: 'Sorry we are away, but we would love to hear from you and chat soon!',
              offlineEmailPlaceholder: 'Email',
              offlineMessagePlaceholder: 'Your message here',
              offlineNamePlaceholder: 'Name (optional but helpful)',
              offlineSendButton: 'Send',
              offlineThankYouMessage: 'Thanks for your message. We will be in touch soon!',
              requireInfoGreeting: 'Enter your name and email to start chatting!',
              requireInfoSubmitBtn: 'Start',
              requestScreenshotText: 'Operator would like to take a screenshot of your browser. Confirm below.',
              requestScreenshotAllowLabel: 'Take screenshot',
              requestScreenshotDeclineLabel: 'Decline',
              defaultOkBtnLabel: 'Ok',
              defaultSendBtnLabel: 'Send',
              defaultCancelBtnLabel: 'Cancel',
              defaultYesBtnLabel: 'Yes',
              defaultNoBtnLabel: 'No',
              conversationEndLabel: 'Your chat session has ended. Thanks for chatting!',
              conversationRatingLabel: 'How would you rate this chat?',
              conversationRatingThankYou: 'Thanks for rating your chat session!',
              conversationRatingPlaceholder: 'How can we improve?',
              conversationEndTranscriptPlaceholder: 'Email to send transcript of chat',
              conversationEndConfirmationQuestion: 'Are you sure you want to end this chat?',
              conversationTranscriptSentThankYou: 'Thanks! You will receive your transcript shortly.',
              conversationEndMenuLinkLabel: 'End Chat',
              autoResponseMessage: 'Question? Just type it below and we are online and ready to answer.',

              titleColor: '#152e4d',
            });
            ChatlioReady.value = true;

            watchEffect(() => {
              if (chatlioMustOpen.value) _chatlio.open();
              chatlioMustOpen.value = false;
            });
          },
          false,
        );
      })();
    },
  });
</script>
