import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { addMinutes, addHours, startOfDay } from 'date-fns';

/** Return two numbers in an array from a string with format HH:MM */
export function getHoursAndMinutes(time: string): number[] {
  const [hours, minutes] = time.split(':');
  return [Number(hours), Number(minutes)];
}

/** Return a string with format HH:MM from a ISOstring time */
export function getTimeFromISOString(time: string): string {
  const date = new Date(time);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  return `${hours}:${minutes}`;
}

/** Convert zoned time to UTC */
export function convertToUTC(userTime: string, userTimezone: string): string {
  const time = addMinutes(addHours(startOfDay(new Date()), getHoursAndMinutes(userTime)[0]), getHoursAndMinutes(userTime)[1]);
  const utcTime = zonedTimeToUtc(time, userTimezone);
  return getTimeFromISOString(utcTime.toISOString());
}

/** Convert UTC time to zoned time */
export function convertFromUTC(utcTime: string, userTimezone: string): string {
  const time = addMinutes(addHours(startOfDay(new Date()), getHoursAndMinutes(utcTime)[0]), getHoursAndMinutes(utcTime)[1]);
  const zonedTime = utcToZonedTime(time, userTimezone);
  return getTimeFromISOString(zonedTime.toISOString());
}
