<template>
  <div class="container mt-6">
    <div class="card">
      <div class="card-header">
        <h1 class="card-header-title">Create campaign</h1>
      </div>
      <div class="card-body">
        <button @click="createCampaign()" class="btn btn-primary btn-lg d-flex align-items-center px-4">
          <i class="fe fe-plus me-3"></i>
          Create from scratch
        </button>
        <div class="mt-5">
          <h3>Create from template</h3>
          <Loading v-if="loadingTemplates" />
          <div v-else-if="templates.length > 0">
            <div class="input-group input-group-merge mb-3">
              <input
                class="form-control"
                v-model="searchParam"
                placeholder="Search templates"
                type="text"
                aria-label="Input group appended"
                aria-describedby="inputGroup"
              />
              <div class="input-group-text" id="inputGroup">
                <span class="fe fe-search"></span>
              </div>
            </div>
            <div v-if="filteredTemplates.length > 0" class="mt-3 templates-grid">
              <div
                v-for="template in filteredTemplates"
                :key="template.id"
                @click="createCampaign(template)"
                class="template-grid-item card border my-0 shadow-none"
              >
                <div class="card-body">
                  <h4>{{ template.campaignName }}</h4>
                  <p class="max-3-lines m-0">
                    {{ template.message }}
                  </p>
                </div>
              </div>
            </div>
            <EmptyState v-else :text="`No templates found containing ${searchParam}`" />
          </div>
          <EmptyState v-else text="Tip: Campaign templates makes campaign creation even faster.">
            <button @click.prevent="router.push({ name: 'SmsTemplatesEditor', params: { id: 'new' } })" class="btn btn-outline-secondary px-4">
              Create first template
            </button>
          </EmptyState>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, Ref, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useToast } from 'vue-toastification';

  import { CampaignTemplateModelResult, CreateSendStatus, MessageClass } from '@/dto/graphql';
  import { CampaignTemplateList, CampaignUpsert, ConnectionGatewayapi } from '@/services/GraphqlApi';

  import EmptyState from '@/components/shared/EmptyState.vue';
  import Loading from '@/components/shared/Loading.vue';

  const router = useRouter();
  const toast = useToast();

  const templates = ref([]) as Ref<CampaignTemplateModelResult[]>;
  const loadingTemplates = ref(false) as Ref<boolean>;
  const searchParam = ref('') as Ref<string>;
  const senderName = ref('') as Ref<string>;

  ConnectionGatewayapi().then((res) => {
    if (res?.defaultSenderId) senderName.value = res?.defaultSenderId;
  });

  const filteredTemplates = computed(() => {
    return templates.value
      .filter((template) => {
        return (
          template.campaignName.toLowerCase().includes(searchParam.value.toLowerCase()) ||
          template.message.toLowerCase().includes(searchParam.value.toLowerCase())
        );
      })
      .sort((a, b) => {
        return a.campaignName.localeCompare(b.campaignName);
      })
      .slice(0, 10);
  });

  const updateTemplatesList = async () => {
    try {
      loadingTemplates.value = true;
      const { results } = await CampaignTemplateList({});
      templates.value = results;
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      loadingTemplates.value = false;
    }
  };

  updateTemplatesList();

  const createCampaign = async (campaign?: CampaignTemplateModelResult) => {
    try {
      const newCampaign = await CampaignUpsert({
        campaignName: campaign?.campaignName || '',
        message: campaign?.message ?? '',
        sendStatus: CreateSendStatus.Predraft,
        sendTime: new Date(),
        sender: campaign?.sender ?? senderName.value,
        messageClass: campaign?.messageClass ?? MessageClass.Standard,
      });
      await router.push({ name: 'SmsCampaignEditor', params: { id: newCampaign } });
    } catch (error: any) {
      toast.error(error.message);
    }
  };
</script>

<style scoped lang="scss">
  .max-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .templates-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    .template-grid-item {
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      &:hover {
        background-color: #f5f5f510;
      }
    }
  }
  @media (min-width: 1100px) {
    .templates-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
</style>
