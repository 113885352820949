<template>
  <div class="card" :class="{ 'bg-danger-soft pulse-animation': !allGood }">
    <div class="card-body">
      <div class="row align-items-center gx-0">
        <div class="col">
          <h6 class="text-uppercase mb-2" :class="{ 'text-muted': allGood, 'text-secondary': !allGood }">GatewayAPI Connection</h6>

          <div v-if="loading" class="h2 mb-0"><span class="spinner-border spinner-border-sm text-muted"></span> &nbsp;</div>

          <div v-else-if="!hasConnection">
            <div class="d-flex align-items-center justify-content-between">
              <span class="h2 mb-0">No API key</span>
            </div>
            <p class="mb-0 mt-2">A valid connection to GatewayAPI is required to be able to use RelationCity.</p>
            <router-link :to="{ name: 'SMSConfiguration' }" href="#" class="btn btn-sm btn-success d-inline-flex mt-3 px-4 py-1">
              <i class="fe fe-settings me-2"></i>Fix it now
            </router-link>
          </div>

          <div v-else-if="!status">
            <span class="h2 mb-0">API key invalid</span>
            <router-link :to="{ name: 'SMSConfiguration' }" href="#" class="btn btn-sm btn-danger d-inline-flex mt-n1 ms-3">
              <i class="fe fe-settings me-2"></i> Fix
            </router-link>
          </div>

          <div v-else>
            <span class="h2 mb-0">
              {{ status.credit.toLocaleString(undefined, { style: 'currency', currency: 'EUR', currencyDisplay: 'code' }).replace('EUR', '').trim() }}
            </span>
            <span class="badge bg-info-soft mt-n1 ms-3">
              {{ status.currency }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .gatewayapi-icon {
    height: 24px;
    width: 24px;
    display: inline-block;
    background-image: url('@/assets/gatewayapi-grey.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.5;
  }

  .pulse-animation {
    animation: pulse 2s infinite;

    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 #fad7dd;
      }

      70% {
        box-shadow: 0 0 0 5px transparent;
      }

      100% {
        box-shadow: 0 0 0 0 transparent;
      }
    }
  }
</style>

<script lang="ts" setup>
  import { computed, inject, ref, Ref, watchEffect } from 'vue';
  import { ConnectionGatewayapiStatusGet } from '@/services/GraphqlApi';
  import { ConnectionGatewayapiStatus } from '@/dto/graphql';

  const hasConnection = inject('hasConnection') as Ref<boolean>;
  const status = ref() as Ref<ConnectionGatewayapiStatus>;
  const allGood = computed(() => loading.value || (hasConnection.value && status.value));
  const loading = ref(true);

  watchEffect(() => {
    if (!hasConnection.value) {
      loading.value = false;
      return;
    }
    ConnectionGatewayapiStatusGet()
      .then((s) => {
        if (s) status.value = s;
      })
      .finally(() => {
        loading.value = false;
      });
  });
</script>
