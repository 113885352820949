<template>
  <input type="text" class="form-control" :id="field.name" v-model="value" :placeholder="field.placeholder ?? ''" />
</template>

<script setup lang="ts">
  import { PropType, watch, ref } from 'vue';
  import { ContactFieldTextModel } from '@/dto/graphql';

  const emit = defineEmits(['update:modelValue']);

  const props = defineProps({
    modelValue: {
      type: String,
      required: true,
    },
    field: {
      type: Object as PropType<ContactFieldTextModel>,
      required: true,
    },
  });

  const value = ref(props.modelValue);

  watch(value, (v) => {
    emit('update:modelValue', v);
  });
</script>

<style scoped></style>
