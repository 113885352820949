import { Tooltip } from 'bootstrap';

const elementMap = new WeakMap();

const vTooltip = {
  mounted: (el: HTMLElement, binding: { value: string }) => {
    const opts = { html: true } as Tooltip.Options;
    opts.title = binding.value ?? el.title;

    elementMap.set(el, new Tooltip(el, opts));
  },

  updated: function (el: HTMLElement, binding: { value: string; oldValue: string }) {
    vTooltip.beforeUnmount(el);
    vTooltip.mounted(el, binding);
  },

  beforeUnmount: (el: HTMLElement) => {
    const tooltip = elementMap.get(el) as Tooltip;
    tooltip.dispose();
    elementMap.delete(el);
  },
};

export default vTooltip;
