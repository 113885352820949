<template>
  <div class="card card-fill-lg">
    <form @submit.prevent="submit" class="d-flex flex-column flex-xl-fill">
      <div class="card-header">
        <h4 class="card-header-title d-flex align-items-center justify-content-between">
          <span v-if="currentContact">Edit contact</span>
          <span v-else>Create new contact</span>
          <div class="dropdown">
            <button
              class="btn btn-outline-secondary btn-rounded-circle dropdown-toggle me-n2"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></button>
            <ul class="dropdown-menu">
              <li>
                <a @click="blockContact()" class="dropdown-item list-group-item-action d-flex py-3 text-danger" href="#">
                  <i class="fe fe-x-circle me-3"></i>Block contact
                </a>
              </li>
              <li>
                <a @click.prevent="cancel" class="dropdown-item list-group-item-action d-flex py-3 text-secondary" href="#">
                  <i class="fe fe-x me-3"></i>Close
                </a>
              </li>
            </ul>
          </div>
        </h4>
      </div>
      <div class="card-body contact-editor-body position-relative mt-0 overflow-auto flex-grow-1 d-flex">
        <div class="inner position-absolute overflow-auto top-0 start-0 h-100 w-100 px-4 py-3">
          <div class="mb-3">
            <input
              v-focus="true"
              type="text"
              class="form-control form-control-flush h2"
              id="contactName"
              v-model.trim="name"
              placeholder="Enter name of contact"
            />
          </div>

          <div class="mb-3">
            <label class="form-label" for="isActive">
              Is active?
              <InfoTooltip tooltip="Inactive contacts cannot be contacted and are not counted in the tags list."></InfoTooltip>
            </label>
            <div class="form-check form-switch">
              <input class="form-check-input" v-model="isActive" type="checkbox" id="isActive" />
              <label class="form-check-label" for="isActive">
                <span v-if="isActive">Yes, contact is active</span>
                <span v-else>No, contact is inactive</span>
              </label>
            </div>
          </div>

          <div class="mb-3">
            <label class="form-label" for="mobileNumber">
              Country code & phone number
              <InfoTooltip tooltip="Enter full phone number (MSISDN), ie. country code + phone number."></InfoTooltip>
            </label>
            <PhoneNumberInput v-model="mobileNumber" id="mobileNumber"></PhoneNumberInput>
          </div>

          <ContactEditorTagEditor />
          <ContactCustomField v-for="field in contactStore.contactFields" :key="field.id" :field="field" />
          <Popper
            reference-is="span"
            popper-is="div"
            :popper-props="{ style: 'z-index: 2000' }"
            :teleport-props="{ to: 'body' }"
            :disabled="!addFieldDropdownOpen"
            :force-show="addFieldDropdownOpen"
          >
            <template #reference>
              <button class="btn btn-outline-secondary mt-3" type="button" @click="addFieldDropdownOpen = !addFieldDropdownOpen">
                <i class="me-2 fe fe-plus"></i>Add custom field
              </button>
            </template>
            <ContactEditorCustomFieldEditor v-if="addFieldDropdownOpen" @close="addFieldDropdownOpen = false" class="mt-4 mb-3" />
          </Popper>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <button v-if="currentContact" type="button" class="btn btn-outline-danger me-auto" @click.prevent="deleteMe">
          <i class="fe fe-trash"></i>
          Delete
        </button>
        <span v-tooltip="whyNotValid">
          <button type="submit" class="btn btn-primary" :disabled="!isValid">
            <span v-if="currentContact"><i class="fe fe-save me-2"></i> Save &amp; Close</span>
            <span v-else><i class="fe fe-plus me-2"></i> Create &amp; Close</span>
          </button>
        </span>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
  @media (max-width: 1199px) {
    .contact-editor-body {
      .inner {
        position: relative !important;
        height: auto;
        padding: 0 !important;
      }
    }
  }
</style>

<script lang="ts" setup>
  import { computed, ComputedRef, inject, onMounted, onUnmounted, provide, ref, Ref } from 'vue';
  import { ContactFieldTextValueModel, ContactFieldDateValueModel, ContactListModelResult, ContactModelResult, BlacklistTypes } from '@/dto/graphql';
  import PhoneNumberInput from '@/components/shared/PhoneNumberInput.vue';
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';
  import ContactEditorTagEditor from '@/components/contacts/ContactEditorTagEditor.vue';
  import ContactCustomField from '@/components/contacts/ContactCustomField.vue';
  import ContactEditorCustomFieldEditor from '@/components/contacts/ContactEditorCustomFieldEditor.vue';
  import { BlacklistUpsert, ContactDelete, ContactUpsert } from '@/services/GraphqlApi';
  import { getPhoneParts } from '@/services/PhoneNumber';
  import { useToast } from 'vue-toastification';
  import { useContactsStore } from '@/stores/contacts';
  import _ from 'lodash';
  import vFocus from '@/directives/vFocus';
  import vTooltip from '@/directives/vTooltip';
  import { Popper } from 'vue-use-popperjs';
  import { useDialog } from '@/services/Dialog';

  const createDialog = useDialog();

  const emit = defineEmits(['success']);
  const contactStore = useContactsStore();

  const contacts = inject('contacts') as Ref<ContactListModelResult>;
  const currentContact = inject('currentContact') as Ref<ContactModelResult | null>;
  const isCreatingNewContact = inject('isCreatingNewContact') as Ref<boolean>;
  const addFieldDropdownOpen = ref(false);

  const name = ref(currentContact.value?.name ?? '') as Ref<string>;
  const mobileNumber = ref(
    currentContact.value?.mobileNumber ? `${currentContact.value?.mobileCountry}${currentContact.value?.mobileNumber}` : '',
  ) as Ref<string>;
  const isActive = ref(currentContact.value?.isActive ?? true) as Ref<boolean>;
  const selectedTags = ref(currentContact.value?.tags.map((t) => t.id) ?? []) as Ref<string[]>;
  const isValid = computed(() => name.value && mobileNumber.value);
  const whyNotValid = computed(() => {
    if (!name.value) return `Name is required.`;
    if (!mobileNumber.value) return `Valid mobile number is required.`;
  }) as ComputedRef<string>;
  const textFields = ref(currentContact.value?.textFields ?? []) as Ref<ContactFieldTextValueModel[]>;
  const dateFields = ref(currentContact.value?.dateFields ?? []) as Ref<ContactFieldDateValueModel[]>;

  provide('selectedTags', selectedTags);

  const toast = useToast();

  const submit = async () => {
    const [cc, no] = getPhoneParts(mobileNumber.value);
    if (whyNotValid.value) return toast.error(whyNotValid.value);

    await ContactUpsert(
      {
        isActive: isActive.value,
        name: name.value,
        mobileCountry: cc,
        mobileNumber: no,
        tags: selectedTags.value,
        textFields: textFields.value,
        dateFields: dateFields.value,
      },
      currentContact.value?.id ?? undefined,
    )
      .then((newContact) => {
        if (!currentContact.value) {
          contacts.value.results.push(newContact);
          toast.success(`Contact '${name.value}' created.`, { timeout: 3000 });
        } else {
          const cIndex = contacts.value.results.findIndex((c) => c.id === currentContact.value?.id);
          if (cIndex !== -1) contacts.value.results[cIndex] = newContact;
          toast.success(`Changes on contact '${name.value}' saved.`, { timeout: 3000 });
        }

        currentContact.value = null;
        isCreatingNewContact.value = false;
        emit('success');
      })
      .catch((err) => {
        toast.error(`${err}`, { timeout: 3000 });
        console.error(err);
      });
  };

  const cancel = () => {
    isCreatingNewContact.value = false;
    currentContact.value = null;
  };

  type CustomFieldPayload = {
    id: string;
    type: 'ContactFieldTextModel' | 'ContactFieldDateModel';
    value: string;
  };

  onMounted(() => {
    window.scrollTo({ top: Number.MAX_SAFE_INTEGER });
  });

  onUnmounted(() => {
    emit('success');
    window.scrollTo({ top: 0 });
  });

  const deleteMe = async () => {
    const contact = currentContact.value;
    if (!contact) return;

    const res = await createDialog({
      title: `Delete ${_.escape(contact.name)}?`,
      content: `This action is <strong>irreversible</strong>.<br><br>Do you want to continue?`,
      confirmText: 'Delete contact',
      cancelText: 'Cancel',
    });

    if (!res) return;

    try {
      await ContactDelete(contact.id);
      currentContact.value = null;
      isCreatingNewContact.value = false;
      _.remove(contacts.value.results, (c) => c.id === contact.id);
      emit('success');
      toast.success(`Contact '${contact.name}' deleted.`, { timeout: 2000 });
    } catch (e) {
      toast.error(`Deletion of contact failed: ${(e as Error).message}`);
    }
  };

  const blockContact = async () => {
    if (!currentContact.value) return;
    const number = currentContact.value.mobileCountry + currentContact.value.mobileNumber;

    const res = await createDialog({
      title: `Block and delete ${_.escape(currentContact.value.name)}?`,
      content: `You are about to block and delete this contact. This action is <strong>irreversible</strong>.<br><br>Do you want to continue?`,
      confirmText: 'Block and delete contact',
      cancelText: 'Cancel',
      showInput: true,
      inputLabel: 'Note',
      inputPlaceholder: 'Enter a note for the block',
      inputValidator(value: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
          if (!value) {
            reject('Note cannot be empty.');
          }
          resolve(null);
        });
      },
    });

    if (!res) return;

    await BlacklistUpsert(number, BlacklistTypes.PhoneNumber, res as string)
      .then(() => {
        toast.success(`Contact '${currentContact.value?.name}' blocked.`, { timeout: 2000 });
        emit('success');
        currentContact.value = null;
      })
      .catch((e) => {
        toast.error(`Blocking of contact failed: ${(e as Error).message}`);
      });
  };
</script>
