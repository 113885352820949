<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title align-items-center me-auto">
        Recipients
        <InfoTooltip
          tooltip="You pick recipients by selecting one or more tags to target, ie. recipients who has at least one of the selected tags.<br><br>The number tells how many <em>active</em> contacts, who has that tag."
        />
      </h4>
      <div class="my-0 d-flex align-items-center">
        <span
          v-tooltip="'Total recipient count across all selected tags. Contacts are only included once, even if they have multiple tags.'"
          class="badge rounded-pill me-3"
          :class="{ 'bg-success': recipientCount > 0, 'bg-warning': recipientCount === 0 }"
        >
          {{ recipientCount.toLocaleString() }}
        </span>
        <div class="dropdown">
          <button class="btn btn-sm btn-outline-secondary rounded-pill" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fe fe-list m-0"></i>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a @click="sortBy = SortByKey.name" class="dropdown-item d-flex justify-content-between align-items-center" href="#">
                <span>Name</span>
                <i v-if="sortBy === SortByKey.name" class="fe fe-check text-primary ms-3"></i>
              </a>
            </li>
            <li>
              <a @click="sortBy = SortByKey.creation" class="dropdown-item d-flex justify-content-between align-items-center" href="#">
                <span>Creation date</span>
                <i v-if="sortBy === SortByKey.creation" class="fe fe-check text-primary ms-3"></i>
              </a>
            </li>
            <li>
              <a @click="sortBy = SortByKey.totalCount" class="dropdown-item d-flex justify-content-between align-items-center" href="#">
                <span>Contact count</span>
                <i v-if="sortBy === SortByKey.totalCount" class="fe fe-check text-primary ms-3"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="!allTags.length">
      <div class="text-muted">- No tags with active recipients found -</div>
    </div>
    <div class="scroll" v-else>
      <div class="list-group list-group-flush border-bottom-0">
        <label v-for="tag in allTagsSorted" class="list-group-item py-3 px-4 d-flex align-items-center" style="cursor: pointer" :key="tag.id">
          <input v-model="tags" :value="tag.id" class="form-check-input me-3 my-0" type="checkbox" />
          <div class="me-auto">
            {{ tag.name }}
          </div>
          <div class="badge rounded-pill bg-secondary ms-2">{{ tag.activeCount.toLocaleString() }}</div>
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .scroll {
    overflow-y: auto;
    max-height: 219px;
  }

  .flag {
    overflow: hidden;
    box-sizing: content-box;

    &,
    img {
      height: 32px;
      width: 32px;
    }
  }
</style>

<script lang="ts" setup>
  import { computed, inject, ref, Ref, watchEffect } from 'vue';
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';
  import { ContactList, ContactTagList } from '@/services/GraphqlApi';
  import { ContactModelResult, ContactTagModelResult } from '@/dto/graphql';
  import _ from 'lodash';
  import vTooltip from '@/directives/vTooltip';

  const recipientCount = inject('recipientCount') as Ref<number>;

  const tags = inject('tags') as Ref<string[]>;
  const allTags = ref([]) as Ref<ContactTagModelResult[]>;
  ContactTagList().then((tags) => {
    allTags.value = tags;
  });

  watchEffect(async () => {
    if (!tags.value.length) return (recipientCount.value = 0);

    const cl = await ContactList({ tags: tags.value, limit: 1 });
    recipientCount.value = cl.totalMatchedActive;
  });

  enum SortByKey {
    name = 'name',
    totalCount = 'totalCount',
    creation = 'id',
  }

  const sortBy = ref('name') as Ref<SortByKey>;

  const allTagsSorted = computed(() =>
    _.orderBy(
      allTags.value,
      [sortBy.value === SortByKey.name ? (t) => t.name.toLowerCase() : sortBy.value],
      [sortBy.value === SortByKey.name ? 'asc' : 'desc'],
    ),
  );
</script>
