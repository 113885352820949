export default function useSplash() {
  return {
    hideSplash() {
      const splashEl = document.getElementById('loadingSplash');
      splashEl?.classList?.add('fade-out');
      setTimeout(() => {
        splashEl?.classList?.add('d-none');
      }, 500);
    },

    showSplash() {
      const splashEl = document.getElementById('loadingSplash');
      splashEl?.classList?.remove('d-none');
      setTimeout(() => splashEl?.classList?.remove('fade-out'), 50);
    },
  };
}
