import { useContactsStore } from '@/stores/contacts';

export function useTagsValidation() {
  const contactsStore = useContactsStore();

  function validateTags(message: string) {
    // Check if tags in message exists
    const tagsInMessage = message.match(/%.*?%/g);

    if (tagsInMessage) {
      const tagsInContacts = tagsInMessage.map((tag) => tag.replace(/%/g, ''));
      const tagsInContactsStore = contactsStore.contactFields.map((field) => field.tagName);
      const tagsNotInContactsStore = tagsInContacts.filter((tag) => !(tag === 'NAME' || tagsInContactsStore.includes(tag)));
      // If we ever get other static tags than name, do something like ['NAME', 'HELLO', ...tagsInContacts].includes(tag) etc.

      if (tagsNotInContactsStore.length) {
        throw new Error(`The following tags are not in your contacts: ${tagsNotInContactsStore.join(', ')}`);
      }
    }

    return true;
  }

  return {
    validateTags,
  };
}
