<template>
  <div class="container-fluid vh-100 d-flex flex-column">
    <div class="header">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h6 class="header-pretitle">SMS Templates</h6>
            <h1 class="header-title py-2">List of Templates</h1>
          </div>

          <div class="col-auto">
            <button
              @click.prevent="router.push({ name: 'SmsTemplatesEditor', params: { id: 'new' } })"
              class="btn btn-primary btn-lg d-flex align-items-center px-4"
            >
              <i class="fe fe-edit-2 me-3"></i>
              Create template
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-fill">
      <div class="position-relative mt-0 overflow-auto flex-grow-1 d-flex">
        <div class="inner position-absolute overflow-auto top-0 start-0 h-100 w-100">
          <div class="table-responsive-sm table-responsive-md">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 35%; min-width: 200px">Template name</th>
                  <th style="width: 60%; min-width: 140px">Message</th>
                  <th><!-- actions --></th>
                </tr>
              </thead>
              <tbody v-if="templates?.length > 0">
                <SmsTemplateListRow v-for="template of templates" :template="template" :key="template.id" @update="updateTemplatesList" />
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4" class="text-center">
                    <Loading v-if="loadingTemplates" />
                    <EmptyState v-else text="No templates found.">
                      <button @click.prevent="router.push({ name: 'SmsTemplatesEditor', params: { id: 'new' } })" class="btn btn-primary px-4">
                        Create template
                      </button>
                    </EmptyState>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Ref, ref } from 'vue';
  import { CampaignTemplateModelResult } from '@/dto/graphql';
  import { CampaignTemplateList } from '@/services/GraphqlApi';
  import { useToast } from 'vue-toastification';
  import { useRouter } from 'vue-router';

  import SmsTemplateListRow from '@/components/sms-template-list/SmsTemplateListRow.vue';
  import Loading from '@/components/shared/Loading.vue';
  import EmptyState from '@/components/shared/EmptyState.vue';

  const toast = useToast();
  const router = useRouter();

  const templates = ref([]) as Ref<CampaignTemplateModelResult[]>;
  const loadingTemplates = ref(false) as Ref<boolean>;

  const updateTemplatesList = async () => {
    try {
      loadingTemplates.value = true;
      const { results } = await CampaignTemplateList({});
      templates.value = results;
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      loadingTemplates.value = false;
    }
  };

  updateTemplatesList();
</script>

<style scoped></style>
