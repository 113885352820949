<template>
  <div class="overflow-auto border rounded-3 p-3" style="max-height: 150px; height: auto">
    <ul class="list-group list-group-flush">
      <li class="list-group-item py-3" v-for="tag of tags" :key="tag.id">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" :id="tag.id" :value="tag.id" v-model="selectedIds" />
          <label :for="tag.id" class="form-check-label">{{ tag.name }}</label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
  import { ref, Ref, watch, PropType } from 'vue';
  import { ContactTagModelResult } from '@/dto/graphql';

  const props = defineProps({
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    tags: {
      type: Array as PropType<ContactTagModelResult[] | null>,
      required: true,
    },
  });

  const emit = defineEmits(['update:modelValue']);

  const selectedIds = ref(props.modelValue) as Ref<string[]>;

  watch(
    () => selectedIds.value,
    (v) => emit('update:modelValue', v),
  );
</script>
