<template>
  <label class="mb-2" for="sms-editor" v-if="label">{{ label }}</label>
  <div @click="focusTextarea()">
    <div class="input-container">
      <textarea
        ref="textarea"
        :value="modelValue"
        @input="$emit('update:modelValue', ($event.target as HTMLTextAreaElement).value)"
        class="form-control"
        :class="[currentTheme === Theme.Light && 'light', error && 'error']"
        name="sms-editor"
        :placeholder="placeholder ? placeholder : 'Enter message here'"
        id="sms-editor"
        cols="15"
        rows="10"
      >
      </textarea>
      <TagsSelector class="tags-selector" @insert="insertText" v-if="tagsSelector" />
    </div>
    <hr />
    <div class="d-flex align-items-center justify-content-between">
      <span class="mb-0" :class="[error && 'text-danger', warn && 'text-warning']">{{ error || warn || info }}</span>
      <small class="bg-light px-3 py-1 rounded">
        {{ stats.characters }} / {{ stats.maxCharacters }} =
        <span class="text-nowrap">{{ stats.messageParts }} messages</span>
      </small>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Ref, ref, watch, computed, onMounted } from 'vue';
  import { useTextareaAutosize } from '@vueuse/core';
  import { currentTheme, Theme } from '@/services/Theme';
  import { getMessageStats, SmsStats } from '@/services/SmsStats';
  import TagsSelector from '@/components/sms-campaign-editor/TagsSelector.vue';

  const props = defineProps<{
    modelValue: string;
    info?: string;
    error?: string;
    warn?: string;
    label?: string;
    tagsSelector?: boolean;
    placeholder?: string;
  }>();

  const { textarea } = useTextareaAutosize();
  const stats: Ref<SmsStats> = ref(getMessageStats(''));
  const message = computed(() => props.modelValue);

  watch(message, (m) => {
    stats.value = getMessageStats(m);
  });

  onMounted(() => {
    stats.value = getMessageStats(message.value);
  });

  const emit = defineEmits(['update:modelValue']);

  const focusTextarea = () => {
    textarea.value?.focus();
  };

  const insertText = (value: string) => {
    if (textarea.value) {
      const { selectionStart: start, selectionEnd: end, value: messageValue } = textarea.value;
      const before = messageValue.substring(0, start);
      const after = messageValue.substring(end, messageValue.length);
      emit('update:modelValue', `${before}${value}${after}`);
      textarea.value.selectionStart = start + value.length;
      textarea.value.selectionEnd = start + value.length;
      focusTextarea();
    }
  };
</script>

<style lang="scss" scoped>
  .input-container {
    position: relative;
    .tags-selector {
      opacity: 0;
      position: absolute;
      bottom: 10px;
      right: 0;
      z-index: 5;
      transition: opacity 0.1s ease-in-out;
    }

    &:hover {
      .tags-selector {
        opacity: 1;
      }
    }
  }

  textarea {
    resize: none;
    min-height: 100px;
    border: none; // Border breaks vueuse textarea autosize - using outline instead and imitate Dashkit behaviour
    outline: 1px solid transparent;
    transition: outline 0.15s ease-in-out;
    &.light {
      outline: 1px solid #d2ddec;
    }
    &:focus {
      outline: 1px solid #2c7be5;
    }
    &.error {
      outline: 1px solid rgb(230, 55, 87);
    }
  }
</style>
