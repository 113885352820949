<template>
  <tr>
    <td>
      {{ template.campaignName }}
    </td>
    <td>
      <span class="max-2-lines">
        {{ template.message }}
      </span>
    </td>
    <td>
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle rounded-pill" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" href="#" @click.prevent="router.push({ name: 'SmsTemplatesEditor', params: { id: template.id } })">
              <i class="fe fe-edit me-3"></i>
              <span>Edit</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click.prevent="createCampaignFromTemplate()">
              <i class="fe fe-send me-3"></i>
              <span>New campaign</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" href="#" @click.prevent="renameTemplate()">
              <i class="fe fe-edit-3 me-3"></i>
              <span>Rename</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item text-danger" href="#" @click.prevent="deleteTemplate()">
              <i class="fe fe-trash me-3"></i>
              <span>Delete</span>
            </a>
          </li>
        </ul>
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
  import { CampaignTemplateModelResult, CreateSendStatus } from '@/dto/graphql';
  import { CampaignTemplateDelete, CampaignTemplateUpsert, CampaignUpsert } from '@/services/GraphqlApi';

  import _ from 'lodash';

  import { PropType } from 'vue';
  import { useRouter } from 'vue-router';
  import { useToast } from 'vue-toastification';
  import { useDialog } from '@/services/Dialog';

  const createDialog = useDialog();

  const router = useRouter();
  const toast = useToast();

  const props = defineProps({
    template: {
      type: Object as PropType<CampaignTemplateModelResult>,
      required: true,
    },
  });

  const emit = defineEmits(['update']);

  const createCampaignFromTemplate = async () => {
    try {
      const newCampaign = await CampaignUpsert({
        campaignName: props.template.campaignName,
        message: props.template.message,
        sendStatus: CreateSendStatus.Predraft,
        sendTime: new Date(),
        sender: props.template.sender,
        messageClass: props.template.messageClass,
      });
      await router.push({ name: 'SmsCampaignEditor', params: { id: newCampaign } });
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const renameTemplate = async () => {
    const res = await createDialog({
      title: 'Change template name',
      content: 'Enter a template name',
      defaultInputValue: props.template.campaignName,
      showInput: true,
      inputLabel: 'Name',
      inputPlaceholder: 'Enter a template name',
      inputValidator(value: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
          if (!value) {
            reject('Template name cannot be empty.');
          }
          resolve(null);
        });
      },
    });

    if (!res) return;

    try {
      await CampaignTemplateUpsert(
        {
          campaignName: res as string,
          message: props.template.message,
          sender: props.template.sender,
          messageClass: props.template.messageClass,
          title: res as string, // TODO: Remove this when backend is fixed
        },
        props.template.id,
      );
      toast.success(`Template renamed to "${res}"`, { timeout: 1500 });
      emit('update');
    } catch (error) {
      console.error(error);
      toast.error(`Template could not be renamed.`, { timeout: 1500 });
    }
  };

  const deleteTemplate = async () => {
    try {
      const res = await createDialog({
        title: `Delete ${_.escape(props.template.campaignName)}?`,
        content: `This action is <strong>irreversible</strong>.<br><br>Do you want to continue?`,
        confirmText: 'Delete template',
      });

      if (!res) return;

      await CampaignTemplateDelete(props.template.id);
      toast.success(`Template deleted.`, { timeout: 1500 });
      emit('update');
    } catch (e) {
      toast.error(`Template could not be deleted.`);
    }
  };
</script>

<style scoped>
  .max-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>
