<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title me-auto">Recipients</h4>
      <div>
        <div class="btn btn-rounded-circle btn-outline-secondary me-2" @click.prevent="showImport = true">
          <span class="fe fe-upload"></span>
        </div>
        <button
          @click.prevent="deleteAll"
          class="btn btn-rounded-circle btn-outline-danger"
          :disabled="!recipients.length"
          v-tooltip="'Empty the list of recipients.'"
        >
          <span class="fe fe-trash"></span>
        </button>
      </div>
    </div>
    <div class="card-body" v-if="!recipients.length">
      <div class="text-muted">- No recipients added -</div>
    </div>
    <div class="scroll" v-else>
      <ul class="list-group list-group-flush border-bottom-0">
        <li class="list-group-item py-3 px-4" v-for="recipient in recipientsTop" key="recipient">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="flag overflow-hidden border rounded-circle mt-n2 mb-n2">
                <img :src="getFlagByPhoneNumber(recipient)" alt="" />
              </div>
            </div>
            <div class="col">
              (+{{ getPhoneParts(recipient)[0] }})
              {{ getPhoneParts(recipient)[1] }}
            </div>
            <div class="col-auto">
              <div @click="deleteMe(recipient)" class="btn btn-sm btn-rounded-circle btn-outline-danger">
                <span class="fe fe-trash"></span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="d-grid my-3 px-4">
        <button class="btn btn-outline-secondary" v-if="hasMore" @click.prevent="curPage++">Load more</button>
      </div>
    </div>

    <div class="card-footer">
      <PhoneNumberInputAdd @new-number="addRecipient"></PhoneNumberInputAdd>
    </div>
  </div>

  <PhoneImportModal v-if="showImport" @close="showImport = false"></PhoneImportModal>
</template>

<style lang="scss" scoped>
  .scroll {
    overflow-y: auto;
    max-height: 219px;
  }

  .flag {
    overflow: hidden;
    box-sizing: content-box;

    &,
    img {
      height: 32px;
      width: 32px;
    }
  }
</style>

<script lang="ts" setup>
  import { computed, inject, onBeforeUnmount, onMounted, onUnmounted, ref, Ref, watch } from 'vue';
  import PhoneNumberInputAdd from '@/components/shared/PhoneNumberInputAdd.vue';
  import { getFlagByPhoneNumber, getPhoneParts } from '@/services/PhoneNumber';
  import vTooltip from '@/directives/vTooltip';
  import PhoneImportModal from '@/components/sms-quick-send/PhoneImportModal.vue';
  import { QuickSend } from '@/services/QuickSend';
  import { useDialog } from '@/services/Dialog';

  const createDialog = useDialog();
  const recipients = (inject('quickSend') as QuickSend).recipients;
  const showImport = ref(false);

  const addRecipient = (number: string) => {
    const numberClean = number.replace(/\D+/, '');
    if (recipients.value.includes(numberClean)) return;
    recipients.value.push(numberClean);
  };

  const deleteMe = (number: string) => {
    recipients.value = recipients.value.filter((v) => v != number);
  };

  const curPage = ref(1);
  const recipientsTop = computed(() => {
    return recipients.value.slice(0, curPage.value * 100);
  });
  const hasMore = computed(() => {
    return recipients.value.length > curPage.value * 100;
  });

  const deleteAll = async () => {
    const res = await createDialog({
      title: 'Empty the list of recipients?',
      content: 'Your list of recipients will be emptied and you will have to add recipients all over.',
    });

    if (!res) return;
    recipients.value.length = 0;
    curPage.value = 1;
  };
</script>
