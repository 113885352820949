<template>
  <div>
    <h4>Message class</h4>
    <p>Premium guarantees the most direct route, amongst other features.</p>

    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="gatewayapiClass"
        v-model="messageClass"
        :value="MessageClass.Standard"
        id="gatewayapiClassStandard"
      />
      <label class="form-check-label" for="gatewayapiClassStandard">
        Standard Class
        <Infobox tooltip="Standard is recommended for marketing and non time-critical information. This is the lowest price option."></Infobox>
      </label>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="gatewayapiClass"
        v-model="messageClass"
        :value="MessageClass.Premium"
        id="gatewayapiClassPremium"
      />
      <label class="form-check-label" for="gatewayapiClassPremium">
        Premium Class
        <Infobox tooltip="Premium is queued at a high priority. It has the least possible hops to the actual carriers."></Infobox>
      </label>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { inject, Ref } from 'vue';
  import { MessageClass } from '@/dto/graphql';
  import Infobox from '@/components/shared/InfoTooltip.vue';

  // refs for use in template
  const messageClass = inject('messageClass') as Ref<MessageClass>;
</script>
