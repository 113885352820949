export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Timestamp: any;
};

export type AccountDataPolicyInput = {
  /** After how many months to anonymize logs */
  anonymizeLogsAfter?: Scalars['Int'];
  /** At what time each day the rules should be checked by cron jon */
  cronTime?: Scalars['String'];
  /** Whether to delete inactive contacts after inactivation */
  deleteInactiveContacts?: Scalars['Boolean'];
  /** After how many months to delete logs */
  deleteLogsAfter?: Scalars['Int'];
  /** The message to be sent upon deletion */
  deletedMessage?: InputMaybe<Scalars['String']>;
  /** An array of excluded contact tags from rules */
  excludedContactTags?: Array<Scalars['ID']>;
  /** The message to be sent upon inactivation */
  inactivatedMessage?: InputMaybe<Scalars['String']>;
  /** The number of months after which a contact is considered inactive */
  inactiveMonths?: Scalars['Int'];
  /** When a contact is considered inactive */
  inactiveStatus?: DataPolicyInactiveStatus;
  /** Whether an SMS should be sent upon deletion */
  sendSmsOnDeletion?: Scalars['Boolean'];
  /** Whether an SMS should be sent upon inactivation */
  sendSmsOnInactivation?: Scalars['Boolean'];
  /** The sender name of inactivation and deletions SMS */
  senderName?: InputMaybe<Scalars['String']>;
};

export type AccountDataPolicyModel = {
  __typename?: 'AccountDataPolicyModel';
  /** After how many months to anonymize logs */
  anonymizeLogsAfter: Scalars['Int'];
  /** At what time each day the rules should be checked by cron jon */
  cronTime: Scalars['String'];
  /** Whether to delete inactive contacts after inactivation */
  deleteInactiveContacts: Scalars['Boolean'];
  /** After how many months to delete logs */
  deleteLogsAfter: Scalars['Int'];
  /** The message to be sent upon deletion */
  deletedMessage?: Maybe<Scalars['String']>;
  /** An array of excluded contact tags from rules */
  excludedContactTags: Array<Scalars['ID']>;
  /** The message to be sent upon inactivation */
  inactivatedMessage?: Maybe<Scalars['String']>;
  /** The number of months after which a contact is considered inactive */
  inactiveMonths: Scalars['Int'];
  /** When a contact is considered inactive */
  inactiveStatus: DataPolicyInactiveStatus;
  /** Whether an SMS should be sent upon deletion */
  sendSmsOnDeletion: Scalars['Boolean'];
  /** Whether an SMS should be sent upon inactivation */
  sendSmsOnInactivation: Scalars['Boolean'];
  /** The sender name of inactivation and deletions SMS */
  senderName?: Maybe<Scalars['String']>;
};

export type AccountSettingsInput = {
  /** The timezone of the account */
  timezone?: Scalars['String'];
};

export type AccountSettingsModel = {
  __typename?: 'AccountSettingsModel';
  /** The timezone of the account */
  timezone: Scalars['String'];
};

export type AuthorizeAccountResponse = {
  __typename?: 'AuthorizeAccountResponse';
  /** If authenticated then an access token is returned. */
  accessToken: Scalars['String'];
  /** The ID of the account that was authorized. */
  accountId: Scalars['String'];
};

export type AuthorizeResponse = {
  __typename?: 'AuthorizeResponse';
  /** If authenticated then an token is returned. */
  authenticationToken: Scalars['String'];
};

export type BlacklistListModelResult = {
  __typename?: 'BlacklistListModelResult';
  results: Array<BlacklistModelResult>;
  totalCount: Scalars['Int'];
};

export type BlacklistModelResult = {
  __typename?: 'BlacklistModelResult';
  _id: Scalars['ID'];
  blockedAt?: Maybe<Scalars['Timestamp']>;
  note?: Maybe<Scalars['String']>;
  recipient: Scalars['String'];
  type: BlacklistTypes;
};

export enum BlacklistTypes {
  Email = 'EMAIL',
  PhoneNumber = 'PHONE_NUMBER'
}

export type CampaignAllowedHoursInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type CampaignAllowedHoursModel = {
  __typename?: 'CampaignAllowedHoursModel';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type CampaignBaseModel = {
  allowedHours: Array<CampaignAllowedHoursModel>;
  campaignName: Scalars['String'];
  message: Scalars['String'];
  messageClass: MessageClass;
  sender: Scalars['String'];
  throttle: Scalars['Int'];
};

export type CampaignListModelResult = {
  __typename?: 'CampaignListModelResult';
  matchedCount: Scalars['Int'];
  results: Array<CampaignModelResult>;
  totalCount: Scalars['Int'];
};

export type CampaignModelInput = {
  allowedHours?: InputMaybe<Array<CampaignAllowedHoursInput>>;
  campaignName?: InputMaybe<Scalars['String']>;
  campaignTags?: InputMaybe<Array<Scalars['ID']>>;
  message?: InputMaybe<Scalars['String']>;
  messageClass?: InputMaybe<MessageClass>;
  messageEncoding?: InputMaybe<MessageEncoding>;
  sendStatus?: InputMaybe<CreateSendStatus>;
  sendTime?: InputMaybe<Scalars['Timestamp']>;
  sender?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['ID']>>;
  throttle?: InputMaybe<Scalars['Int']>;
};

export type CampaignModelResult = CampaignBaseModel & {
  __typename?: 'CampaignModelResult';
  allowedHours: Array<CampaignAllowedHoursModel>;
  campaignName: Scalars['String'];
  campaignTags: Array<CampaignTagModelResult>;
  estimatedSendTime: Scalars['Int'];
  id: Scalars['ID'];
  isWithinAllowedHours: Scalars['Boolean'];
  message: Scalars['String'];
  messageClass: MessageClass;
  messageEncoding: MessageEncoding;
  paused: Scalars['Boolean'];
  recipientCount: Scalars['Int'];
  sendStatus: SendStatus;
  sendTime: Scalars['Timestamp'];
  sender: Scalars['String'];
  statusFailed: Scalars['Int'];
  statusReceived: Scalars['Int'];
  statusSent: Scalars['Int'];
  tags: Array<ContactTagModelResult>;
  throttle: Scalars['Int'];
};

export type CampaignTagModel = {
  name: Scalars['String'];
};

export type CampaignTagModelResult = {
  __typename?: 'CampaignTagModelResult';
  id: Scalars['ID'];
  name: Scalars['String'];
  totalCount: Scalars['Int'];
  totalDraftCount: Scalars['Int'];
  totalScheduledCount: Scalars['Int'];
  totalSentCount: Scalars['Int'];
};

export type CampaignTemplateListModelResult = {
  __typename?: 'CampaignTemplateListModelResult';
  results: Array<CampaignTemplateModelResult>;
  totalCount: Scalars['Int'];
};

export type CampaignTemplateModel = {
  allowedHours?: Array<CampaignAllowedHoursInput>;
  campaignName: Scalars['String'];
  message: Scalars['String'];
  messageClass: MessageClass;
  sender: Scalars['String'];
  throttle?: Scalars['Int'];
  title: Scalars['String'];
};

export type CampaignTemplateModelResult = CampaignBaseModel & {
  __typename?: 'CampaignTemplateModelResult';
  allowedHours: Array<CampaignAllowedHoursModel>;
  campaignName: Scalars['String'];
  id: Scalars['ID'];
  message: Scalars['String'];
  messageClass: MessageClass;
  sender: Scalars['String'];
  throttle: Scalars['Int'];
  title: Scalars['String'];
};

export enum Capabilities {
  OcAdmin = 'oc_admin',
  RcDefault = 'rc_default'
}

export type ConnectionGatewayapiStatus = {
  __typename?: 'ConnectionGatewayapiStatus';
  credit: Scalars['Float'];
  currency: Scalars['String'];
  id: Scalars['Int'];
};

export type ConnectionModelResult = {
  __typename?: 'ConnectionModelResult';
  account: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  defaultSenderId: Scalars['String'];
  id: Scalars['ID'];
  lastUsedAt: Scalars['Timestamp'];
  provider: Providers;
};

export type ContactFieldBaseModel = {
  id: Scalars['ID'];
  name: Scalars['String'];
  tagName: Scalars['String'];
};

export type ContactFieldDateModel = ContactFieldBaseModel & {
  __typename?: 'ContactFieldDateModel';
  id: Scalars['ID'];
  name: Scalars['String'];
  tagName: Scalars['String'];
};

export type ContactFieldDateModelInput = {
  name: Scalars['String'];
};

export type ContactFieldDateValueInput = {
  id: Scalars['ID'];
  value: Scalars['DateTime'];
};

export type ContactFieldDateValueModel = {
  __typename?: 'ContactFieldDateValueModel';
  id: Scalars['ID'];
  value: Scalars['DateTime'];
};

export type ContactFieldTextModel = ContactFieldBaseModel & {
  __typename?: 'ContactFieldTextModel';
  id: Scalars['ID'];
  name: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  tagName: Scalars['String'];
};

export type ContactFieldTextModelInput = {
  name: Scalars['String'];
  placeholder: Scalars['String'];
};

export type ContactFieldTextValueInput = {
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type ContactFieldTextValueModel = {
  __typename?: 'ContactFieldTextValueModel';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type ContactListModelResult = {
  __typename?: 'ContactListModelResult';
  results: Array<ContactModelResult>;
  /** Total amount of contacts for this account. */
  totalCount: Scalars['Int'];
  /** Amount of active contacts, which match the tags */
  totalMatchedActive: Scalars['Int'];
};

export type ContactModel = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  dateFields?: InputMaybe<Array<ContactFieldDateValueInput>>;
  isActive: Scalars['Boolean'];
  latestInteractionAt?: InputMaybe<Scalars['Timestamp']>;
  mobileCountry: Scalars['String'];
  mobileNumber: Scalars['String'];
  name: Scalars['String'];
  systemDeactivatedAt?: InputMaybe<Scalars['Timestamp']>;
  tags: Array<Scalars['ID']>;
  textFields?: InputMaybe<Array<ContactFieldTextValueInput>>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ContactModelResult = {
  __typename?: 'ContactModelResult';
  createdAt?: Maybe<Scalars['Timestamp']>;
  dateFields?: Maybe<Array<ContactFieldDateValueModel>>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  latestInteractionAt?: Maybe<Scalars['Timestamp']>;
  mobileCountry: Scalars['String'];
  mobileNumber: Scalars['String'];
  name: Scalars['String'];
  systemDeactivatedAt?: Maybe<Scalars['Timestamp']>;
  tags: Array<ContactTagModelResult>;
  textFields?: Maybe<Array<ContactFieldTextValueModel>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ContactTagModel = {
  name: Scalars['String'];
};

export type ContactTagModelResult = {
  __typename?: 'ContactTagModelResult';
  activeCount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  totalCount: Scalars['Int'];
};

export enum CreateSendStatus {
  Draft = 'Draft',
  Predraft = 'Predraft',
  Queued = 'Queued'
}

export type DataCampaigns = {
  __typename?: 'DataCampaigns';
  campaignName: Scalars['String'];
  recipientCount: Scalars['Int'];
  statusFailure: Scalars['Int'];
  statusSuccess: Scalars['Int'];
  statusWaiting: Scalars['Int'];
};

export type DataContactCountriesModel = {
  __typename?: 'DataContactCountriesModel';
  contactCount: Scalars['Int'];
  countryPrefix: Scalars['String'];
};

export type DataDailyContactsModel = {
  __typename?: 'DataDailyContactsModel';
  contactCount: Scalars['Int'];
  day: Scalars['String'];
};

export type DataDailyRecipientsModel = {
  __typename?: 'DataDailyRecipientsModel';
  day: Scalars['String'];
  recipientCount: Scalars['Int'];
  statusFailure: Scalars['Int'];
  statusSuccess: Scalars['Int'];
  statusWaiting: Scalars['Int'];
};

export enum DataPolicyInactiveStatus {
  ReceivedCampaigns = 'RECEIVED_CAMPAIGNS',
  UpdatedEdited = 'UPDATED_EDITED'
}

export type DpaModelInput = {
  dpaVersion?: InputMaybe<Scalars['String']>;
  signedDate?: InputMaybe<Scalars['String']>;
  signeeCompanyName?: InputMaybe<Scalars['String']>;
  signeeEmail?: InputMaybe<Scalars['String']>;
  signeeName?: InputMaybe<Scalars['String']>;
  signeeVatNo?: InputMaybe<Scalars['String']>;
  signingStatus: Scalars['Boolean'];
};

export type DpaModelResult = {
  __typename?: 'DpaModelResult';
  dpaVersion?: Maybe<Scalars['String']>;
  signedDate?: Maybe<Scalars['String']>;
  signeeCompanyName?: Maybe<Scalars['String']>;
  signeeEmail?: Maybe<Scalars['String']>;
  signeeName?: Maybe<Scalars['String']>;
  signeeVatNo?: Maybe<Scalars['String']>;
  signingStatus: Scalars['Boolean'];
};

export type MeModel = {
  __typename?: 'MeModel';
  /** The email address used when signing up */
  email: Scalars['String'];
  /** The accounts the user is associated with */
  myAccounts: Array<MyAccountModel>;
  /** The full name of the user */
  name: Scalars['String'];
};

export enum MessageClass {
  Charge = 'Charge',
  Premium = 'Premium',
  Secret = 'Secret',
  Standard = 'Standard'
}

export enum MessageEncoding {
  Ucs2 = 'UCS2',
  Utf8 = 'UTF8'
}

export type MessageModel = {
  __typename?: 'MessageModel';
  context?: Maybe<Scalars['String']>;
  date: Scalars['Timestamp'];
  from: Scalars['String'];
  gatewayapiId: Scalars['Float'];
  id: Scalars['ID'];
  message: Scalars['String'];
  type: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** To create an account in both OCiD and RelationCity. Return an access token */
  accountCreate: AuthorizeAccountResponse;
  /** To update a data policy on an account. Return true if successfully updated */
  accountDataPolicyUpdate: Scalars['Boolean'];
  /** To update the DPA on an account. Return true if successfully updated */
  accountDpaUpdate: Scalars['Boolean'];
  /** To update settings on an account. Return true if successfully updated */
  accountSettingsUpdate: Scalars['Boolean'];
  /** To authorize an authentication token. Returns an access token with stage ACCESS */
  authorize: AuthorizeResponse;
  /** To authorize the selected account. Returns an access token */
  authorizeAccount: AuthorizeAccountResponse;
  /** To delete a blacklist recipient */
  blacklistDelete: Scalars['Boolean'];
  /** To add a blacklist recipient */
  blacklistUpsert: Scalars['Boolean'];
  /** To delete a campaign. Return true for successful document deletion */
  campaignDelete: Scalars['Boolean'];
  /** To update a campaigns sending status (pause, throttle or send time). Returns the id of the document */
  campaignSendingStatusUpdate: Scalars['ID'];
  /** To delete a campaign tag. Return true for successful document deletion */
  campaignTagDelete: Scalars['Boolean'];
  /** To create or update a campaign tag. Returns the id of the document */
  campaignTagUpsert: Scalars['ID'];
  /** To delete a campaign template. Return true for successful document deletion */
  campaignTemplateDelete: Scalars['Boolean'];
  /** To create or update a campaign template. Returns the id of the document */
  campaignTemplateUpsert: Scalars['ID'];
  /** To create or update a campaign. Returns the id of the document */
  campaignUpsert: Scalars['ID'];
  /** Returns success boolean */
  connectionDefaultsSave: Scalars['Boolean'];
  /** Returns id */
  connectionGatewayapiSave: Scalars['String'];
  /** To create or update a bulk of contacts. Returns state of import. */
  contactBulkInsert: Scalars['Boolean'];
  /** To delete a contact. Return true for successful document deletion'  */
  contactDelete: Scalars['Boolean'];
  /** To create or update a contact date field and will return the document */
  contactFieldDateUpsert: Scalars['String'];
  /** To delete a contact field. Return true for successful sub document deletion */
  contactFieldDelete: Scalars['Boolean'];
  /** To create or update a contact text field and will return the document */
  contactFieldTextUpsert: Scalars['String'];
  /** Deletes a tag - also removes it from any contacts who had this tag. */
  contactTagDelete: Scalars['Boolean'];
  /** To create or update a contact tag and will return the id of the document */
  contactTagUpsert: Scalars['String'];
  /** To create or update a contact and will return the id of the document */
  contactUpsert: ContactModelResult;
  /** To remove authentication- and accesstokens from database. */
  removeTokens: Scalars['Boolean'];
  /** To send a list of sms to the specified recipients through GatewayAPI */
  smsBulkSend: SmsBulkSendResponse;
  /** To create or update a multiple autoreply ruleset and will afterwards return the document */
  smsInboxRulesetAutoreplyMultipleUpsert: Scalars['String'];
  /** To create or update a ruleset and will afterwards return the document */
  smsInboxRulesetAutoreplyUpsert: Scalars['String'];
  /** To create or update a ruleset and will afterwards return the document */
  smsInboxRulesetPaymentDynamicUpsert: Scalars['String'];
  /** To create or update a ruleset and will afterwards return the document */
  smsInboxRulesetPaymentFixedUpsert: Scalars['String'];
  /** To create or update a sms inbox and will afterwards return the document */
  smsInboxUpsert: Scalars['String'];
  /** To send a single sms to the specified recipient through GatewayAPI */
  smsSend: SmsSendResponse;
};


export type MutationAccountCreateArgs = {
  accountName: Scalars['String'];
  dpa: DpaModelInput;
  timezone: Scalars['String'];
};


export type MutationAccountDataPolicyUpdateArgs = {
  accountDataPolicy: AccountDataPolicyInput;
};


export type MutationAccountDpaUpdateArgs = {
  dpa: DpaModelInput;
};


export type MutationAccountSettingsUpdateArgs = {
  accountSettings: AccountSettingsInput;
};


export type MutationAuthorizeArgs = {
  authenticationToken: Scalars['String'];
};


export type MutationAuthorizeAccountArgs = {
  accountId: Scalars['String'];
};


export type MutationBlacklistDeleteArgs = {
  blacklistId: Scalars['String'];
};


export type MutationBlacklistUpsertArgs = {
  blacklistId?: InputMaybe<Scalars['String']>;
  blockedAt?: InputMaybe<Scalars['Timestamp']>;
  note?: InputMaybe<Scalars['String']>;
  recipient: Scalars['String'];
  type: BlacklistTypes;
};


export type MutationCampaignDeleteArgs = {
  campaignId: Scalars['String'];
};


export type MutationCampaignSendingStatusUpdateArgs = {
  campaignId: Scalars['ID'];
  paused?: InputMaybe<Scalars['Boolean']>;
  sendTime?: InputMaybe<Scalars['Timestamp']>;
  throttle?: InputMaybe<Scalars['Int']>;
};


export type MutationCampaignTagDeleteArgs = {
  campaignTagId: Scalars['String'];
};


export type MutationCampaignTagUpsertArgs = {
  campaignTag: CampaignTagModel;
  campaignTagId?: InputMaybe<Scalars['ID']>;
};


export type MutationCampaignTemplateDeleteArgs = {
  campaignTemplateId: Scalars['String'];
};


export type MutationCampaignTemplateUpsertArgs = {
  campaignTemplate: CampaignTemplateModel;
  campaignTemplateId?: InputMaybe<Scalars['ID']>;
};


export type MutationCampaignUpsertArgs = {
  campaign: CampaignModelInput;
  campaignId?: InputMaybe<Scalars['ID']>;
};


export type MutationConnectionDefaultsSaveArgs = {
  defaultSenderId?: InputMaybe<Scalars['String']>;
};


export type MutationConnectionGatewayapiSaveArgs = {
  key: Scalars['String'];
  provider: Providers;
};


export type MutationContactBulkInsertArgs = {
  contacts: Array<ContactModel>;
};


export type MutationContactDeleteArgs = {
  contactId: Scalars['String'];
};


export type MutationContactFieldDateUpsertArgs = {
  contactDateField: ContactFieldDateModelInput;
  contactFieldId?: InputMaybe<Scalars['String']>;
};


export type MutationContactFieldDeleteArgs = {
  contactFieldId: Scalars['String'];
};


export type MutationContactFieldTextUpsertArgs = {
  contactFieldId?: InputMaybe<Scalars['String']>;
  contactTextField: ContactFieldTextModelInput;
};


export type MutationContactTagDeleteArgs = {
  contactTagId?: InputMaybe<Scalars['String']>;
  deleteContactsToo?: InputMaybe<Scalars['Boolean']>;
};


export type MutationContactTagUpsertArgs = {
  contactTag: ContactTagModel;
  contactTagId?: InputMaybe<Scalars['String']>;
};


export type MutationContactUpsertArgs = {
  contact: ContactModel;
  contactId?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveTokensArgs = {
  accessToken: Scalars['String'];
  authenticationToken: Scalars['String'];
};


export type MutationSmsBulkSendArgs = {
  encoding: MessageEncoding;
  message: Scalars['String'];
  messageClass: MessageClass;
  recipients: Array<Scalars['String']>;
  sender: Scalars['String'];
};


export type MutationSmsInboxRulesetAutoreplyMultipleUpsertArgs = {
  rulesetId?: InputMaybe<Scalars['String']>;
  smsInboxRuleset: SmsInboxRulesetAutoreplyMultipleModelInput;
};


export type MutationSmsInboxRulesetAutoreplyUpsertArgs = {
  rulesetId?: InputMaybe<Scalars['String']>;
  smsInboxRuleset: SmsInboxRulesetAutoreplyModelInput;
};


export type MutationSmsInboxRulesetPaymentDynamicUpsertArgs = {
  rulesetId?: InputMaybe<Scalars['String']>;
  smsInboxRuleset: SmsInboxRulesetPaymentDynamicModelInput;
};


export type MutationSmsInboxRulesetPaymentFixedUpsertArgs = {
  rulesetId?: InputMaybe<Scalars['String']>;
  smsInboxRuleset: SmsInboxRulesetPaymentFixedModelInput;
};


export type MutationSmsInboxUpsertArgs = {
  smsInbox: SmsInboxModel;
  smsInboxId?: InputMaybe<Scalars['String']>;
};


export type MutationSmsSendArgs = {
  encoding: MessageEncoding;
  message: Scalars['String'];
  messageClass: MessageClass;
  recipient: Scalars['String'];
  sender: Scalars['String'];
};

export type MyAccountModel = {
  __typename?: 'MyAccountModel';
  /** The whitelisted email domains */
  emailDomainWhitelist?: Maybe<Array<Scalars['String']>>;
  /** Whether email domain whitelisting is enabled on this account */
  emailDomainWhitelistEnabled: Scalars['Boolean'];
  /** The ObjectID of the account */
  id: Scalars['ID'];
  /** The result document of the role */
  myRole: RoleModel;
  /** The name of the account */
  name: Scalars['String'];
  /** The platform this account belongs to */
  platform: Scalars['String'];
};

export enum Providers {
  GatewayApi = 'GatewayApi',
  GatewayApiEu = 'GatewayApiEu'
}

export type Query = {
  __typename?: 'Query';
  /** To get the data policy of an account. Returns an account data policy */
  accountDataPolicyGet: AccountDataPolicyModel;
  /** To get the dpa of an account */
  accountDpaGet: DpaModelResult;
  /** To get the settings of an account. Returns an account settings */
  accountSettingsGet: AccountSettingsModel;
  /** To list all blacklisted recipients based on filters and pagination */
  blacklistList: BlacklistListModelResult;
  /** To get a campaign by campaign id */
  campaignGet: CampaignModelResult;
  /** To list all campaigns based on filters and pagination */
  campaignList: CampaignListModelResult;
  /** To list all campaign tags based and their usage */
  campaignTagList: Array<CampaignTagModelResult>;
  /** To get a single campaign template by account and id */
  campaignTemplateGet: CampaignTemplateModelResult;
  /** To list all campaign templates based on filters and pagination */
  campaignTemplateList: CampaignTemplateListModelResult;
  /** To get information about the GatewayAPI connection. */
  connectionGatewayapi: ConnectionModelResult;
  /** Returns information about API key connected to account. */
  connectionGatewayapiStatus: ConnectionGatewayapiStatus;
  /** To list all contact fields */
  contactFieldList: Array<ContactFieldBaseModel>;
  /** To get a single contacts by account and contact id */
  contactGet: ContactModelResult;
  /** To list all contacts based on filters and pagination */
  contactList: ContactListModelResult;
  /** To list all contacts tags based and their usage */
  contactTagList: Array<ContactTagModelResult>;
  /** To get basic information about the current session and accounts from OCiD. */
  me: MeModel;
  /** To get a single sms inbox by account and sms inbox id */
  smsInboxGet: SmsInboxModelResult;
  /** To list all sms inboxes with pagination */
  smsInboxList: SmsInboxListModelResult;
  /** To list all incoming sms with pagination */
  smsIncomingList: SmsIncomingListModelResult;
  /** To get statistics about completed campaigns */
  statisticsCampaigns: StatisticCampaigns;
  /** To get statistics about the countries of contacts */
  statisticsContactsCountries: StatisticContactCountriesModel;
  /** To get statistics about the daily created contact */
  statisticsContactsCreated: StatisticDailyContactsModel;
  /** To get statistics about the daily created recipients */
  statisticsRecipientsDaily: StatisticDailyRecipientsModel;
  /** To get a single by phone number */
  threadGet: ThreadModelResult;
};


export type QueryBlacklistListArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
};


export type QueryCampaignGetArgs = {
  campaignId: Scalars['String'];
};


export type QueryCampaignListArgs = {
  campaignTags?: InputMaybe<Array<Scalars['ID']>>;
  contactTags?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
};


export type QueryCampaignTemplateGetArgs = {
  campaignTemplateId: Scalars['String'];
};


export type QueryCampaignTemplateListArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
};


export type QueryContactGetArgs = {
  contactId: Scalars['String'];
};


export type QueryContactListArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerySmsInboxGetArgs = {
  smsInboxId: Scalars['String'];
};


export type QuerySmsInboxListArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<Scalars['String']>;
};


export type QuerySmsIncomingListArgs = {
  inboxId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<Scalars['String']>;
};


export type QueryStatisticsCampaignsArgs = {
  sampleSize: Scalars['Float'];
};


export type QueryStatisticsContactsCreatedArgs = {
  dateFrom: Scalars['Timestamp'];
  dateTo: Scalars['Timestamp'];
};


export type QueryStatisticsRecipientsDailyArgs = {
  dateFrom: Scalars['Timestamp'];
  dateTo: Scalars['Timestamp'];
};


export type QueryThreadGetArgs = {
  MSISDN: Scalars['String'];
};

export type RoleModel = {
  __typename?: 'RoleModel';
  /** The ObjectID of the account */
  account?: Maybe<Scalars['ID']>;
  /** Whether the role is built-in by the system and locked from changes */
  builtIn: Scalars['Boolean'];
  /** The capabilities that defines the permissions a role grants */
  capabilities: Array<Capabilities>;
  /** The description of the role */
  description: Scalars['String'];
  /** The name of the role */
  name: Scalars['String'];
};

export enum SendStatus {
  Completed = 'Completed',
  Draft = 'Draft',
  Failed = 'Failed',
  Predraft = 'Predraft',
  Queued = 'Queued',
  Sending = 'Sending'
}

export type SmsBulkSendResponse = {
  __typename?: 'SmsBulkSendResponse';
  /** The unit of the credit */
  creditUnit: Scalars['String'];
  /** The credit used when the sms is successfully sent */
  creditUsed: Scalars['String'];
  /** The id from GatewayAPI when the sms is successfully sent */
  gatewayapiIds: Array<Scalars['String']>;
};

export type SmsInboxListModelResult = {
  __typename?: 'SmsInboxListModelResult';
  results: Array<SmsInboxModelResult>;
  totalCount: Scalars['Int'];
};

export type SmsInboxModel = {
  keyword: Scalars['String'];
  number: Scalars['String'];
  ruleset: Scalars['ID'];
};

export type SmsInboxModelResult = {
  __typename?: 'SmsInboxModelResult';
  gatewayapiToken: Scalars['String'];
  id: Scalars['ID'];
  keyword: Scalars['String'];
  number: Scalars['String'];
  ruleset: SmsInboxRulesetBaseModel;
};

export type SmsInboxRulesetAutoreplyModel = SmsInboxRulesetBaseModel & {
  __typename?: 'SmsInboxRulesetAutoreplyModel';
  active: Scalars['Boolean'];
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  replyContent: Scalars['String'];
  replyWithMessage: Scalars['Boolean'];
  sender: Scalars['String'];
};

export type SmsInboxRulesetAutoreplyModelInput = {
  /** Is the ruleset active or not on the inbox */
  active: Scalars['Boolean'];
  /** Date the ruleset is active from */
  activeFrom?: InputMaybe<Scalars['Timestamp']>;
  /** Date the ruleset is active to */
  activeTo?: InputMaybe<Scalars['Timestamp']>;
  /** Text message to be sent in a normal reply sms */
  replyContent?: InputMaybe<Scalars['String']>;
  /** Whether to reply with a message or not */
  replyWithMessage: Scalars['Boolean'];
  /** Text shown as sender of all SMS sent out (max. 11 chars) */
  sender: Scalars['String'];
};

export type SmsInboxRulesetAutoreplyMultipleKeywordModel = {
  __typename?: 'SmsInboxRulesetAutoreplyMultipleKeywordModel';
  keyword: Scalars['String'];
  replyContent: Scalars['String'];
};

export type SmsInboxRulesetAutoreplyMultipleKeywordModelInput = {
  keyword: Scalars['String'];
  replyContent: Scalars['String'];
};

export type SmsInboxRulesetAutoreplyMultipleModel = SmsInboxRulesetBaseModel & {
  __typename?: 'SmsInboxRulesetAutoreplyMultipleModel';
  active: Scalars['Boolean'];
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  errorMessage: Scalars['String'];
  id: Scalars['ID'];
  keywords?: Maybe<Array<SmsInboxRulesetAutoreplyMultipleKeywordModel>>;
  sendErrorMessage: Scalars['Boolean'];
  sender: Scalars['String'];
};

export type SmsInboxRulesetAutoreplyMultipleModelInput = {
  /** Is the ruleset active or not on the inbox */
  active: Scalars['Boolean'];
  /** Date the ruleset is active from */
  activeFrom?: InputMaybe<Scalars['Timestamp']>;
  /** Date the ruleset is active to */
  activeTo?: InputMaybe<Scalars['Timestamp']>;
  errorMessage: Scalars['String'];
  keywords?: InputMaybe<Array<SmsInboxRulesetAutoreplyMultipleKeywordModelInput>>;
  sendErrorMessage: Scalars['Boolean'];
  /** Text shown as sender of all SMS sent out (max. 11 chars) */
  sender: Scalars['String'];
};

export type SmsInboxRulesetBaseModel = {
  active: Scalars['Boolean'];
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  sender: Scalars['String'];
};

export type SmsInboxRulesetPaymentDynamicModel = SmsInboxRulesetBaseModel & {
  __typename?: 'SmsInboxRulesetPaymentDynamicModel';
  active: Scalars['Boolean'];
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  errChargeContent: Scalars['String'];
  errInvalidContent: Scalars['String'];
  errNoConfirmationContent?: Maybe<Scalars['String']>;
  errNoConfirmationTime?: Maybe<Scalars['Int']>;
  errNoInitialContent?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initialContent?: Maybe<Scalars['String']>;
  maxTimeCompletion: Scalars['Int'];
  paymentConfirmWord?: Maybe<Scalars['String']>;
  paymentContent: Scalars['String'];
  paymentPriceMax: Scalars['Int'];
  paymentPriceMin: Scalars['Int'];
  requireConfirmation: Scalars['Boolean'];
  sender: Scalars['String'];
};

export type SmsInboxRulesetPaymentDynamicModelInput = {
  /** Is the ruleset active or not on the inbox */
  active: Scalars['Boolean'];
  /** Date the ruleset is active from */
  activeFrom?: InputMaybe<Scalars['Timestamp']>;
  /** Date the ruleset is active to */
  activeTo?: InputMaybe<Scalars['Timestamp']>;
  /** Text error to be sent if a overcharge sms fails */
  errChargeContent: Scalars['String'];
  /** Text error to be sent if sms content is invalid */
  errInvalidContent: Scalars['String'];
  /** Text error to be sent if confirmation time have been passed */
  errNoConfirmationContent?: InputMaybe<Scalars['String']>;
  /** How long a confirmation session is active in minutes */
  errNoConfirmationTime?: InputMaybe<Scalars['Int']>;
  /** Text error to be sent if the initial step is skipped */
  errNoInitialContent?: InputMaybe<Scalars['String']>;
  /** Message to be sent in a initial sms */
  initialContent?: InputMaybe<Scalars['String']>;
  /** How long a payment session is active in minutes */
  maxTimeCompletion?: InputMaybe<Scalars['Int']>;
  /** Word used for confirming a payment */
  paymentConfirmWord?: InputMaybe<Scalars['String']>;
  /** Text message to be sent in a payment sms */
  paymentContent: Scalars['String'];
  /** Maximum price in a dynamic payment price range */
  paymentPriceMax: Scalars['Int'];
  /** Minimum price in a dynamic payment price range */
  paymentPriceMin: Scalars['Int'];
  /** Is confirmation required to complete a payment */
  requireConfirmation: Scalars['Boolean'];
  /** Text shown as sender of all SMS sent out (max. 11 chars) */
  sender: Scalars['String'];
};

export type SmsInboxRulesetPaymentFixedModel = SmsInboxRulesetBaseModel & {
  __typename?: 'SmsInboxRulesetPaymentFixedModel';
  active: Scalars['Boolean'];
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  errChargeContent: Scalars['String'];
  errInvalidContent: Scalars['String'];
  errNoConfirmationContent?: Maybe<Scalars['String']>;
  errNoConfirmationTime?: Maybe<Scalars['Int']>;
  errNoInitialContent?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initialContent?: Maybe<Scalars['String']>;
  maxTimeCompletion?: Maybe<Scalars['Int']>;
  paymentConfirmWord?: Maybe<Scalars['String']>;
  paymentContent: Scalars['String'];
  paymentPrice: Scalars['Int'];
  requireConfirmation: Scalars['Boolean'];
  sender: Scalars['String'];
};

export type SmsInboxRulesetPaymentFixedModelInput = {
  /** Is the ruleset active or not on the inbox */
  active: Scalars['Boolean'];
  /** Date the ruleset is active from */
  activeFrom?: InputMaybe<Scalars['Timestamp']>;
  /** Date the ruleset is active to */
  activeTo?: InputMaybe<Scalars['Timestamp']>;
  /** Text error to be sent if a overcharge sms fails */
  errChargeContent: Scalars['String'];
  /** Text error to be sent if sms content is invalid */
  errInvalidContent: Scalars['String'];
  /** Text error to be sent if confirmation time have been passed */
  errNoConfirmationContent?: InputMaybe<Scalars['String']>;
  /** How long a confirmation session is active in minutes */
  errNoConfirmationTime?: InputMaybe<Scalars['Int']>;
  /** Text error to be sent if the initial step is skipped */
  errNoInitialContent?: InputMaybe<Scalars['String']>;
  /** Message to be sent in a initial sms */
  initialContent?: InputMaybe<Scalars['String']>;
  /** How long a payment session is active in minutes */
  maxTimeCompletion?: InputMaybe<Scalars['Int']>;
  /** Word used for confirming a payment */
  paymentConfirmWord?: InputMaybe<Scalars['String']>;
  /** Text message to be sent in a payment sms */
  paymentContent: Scalars['String'];
  /** Price in a fixed payment */
  paymentPrice: Scalars['Int'];
  /** Is confirmation required to complete a payment */
  requireConfirmation: Scalars['Boolean'];
  /** Text shown as sender of all SMS sent out (max. 11 chars) */
  sender: Scalars['String'];
};

export type SmsIncomingListModelResult = {
  __typename?: 'SmsIncomingListModelResult';
  results: Array<SmsIncomingModelResult>;
  totalCount: Scalars['Int'];
};

export type SmsIncomingModelResult = {
  __typename?: 'SmsIncomingModelResult';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  message: Scalars['String'];
  senderNumber: Scalars['String'];
};

export type SmsOutgoingModelResult = {
  __typename?: 'SmsOutgoingModelResult';
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type SmsSendResponse = {
  __typename?: 'SmsSendResponse';
  /** The unit of the credit */
  creditUnit: Scalars['String'];
  /** The credit used when the sms is successfully sent */
  creditUsed: Scalars['String'];
  /** The id from GatewayAPI when the sms is successfully sent */
  gatewayapiId: Scalars['String'];
};

export type StatisticCampaigns = {
  __typename?: 'StatisticCampaigns';
  data: Array<DataCampaigns>;
};

export type StatisticContactCountriesModel = {
  __typename?: 'StatisticContactCountriesModel';
  contactTotalCount: Scalars['Int'];
  countryTotalCount: Scalars['Int'];
  data: Array<DataContactCountriesModel>;
};

export type StatisticDailyContactsModel = {
  __typename?: 'StatisticDailyContactsModel';
  contactTotalCount: Scalars['Int'];
  data: Array<DataDailyContactsModel>;
};

export type StatisticDailyRecipientsModel = {
  __typename?: 'StatisticDailyRecipientsModel';
  data: Array<DataDailyRecipientsModel>;
  recipientTotalCount: Scalars['Int'];
  statusTotalFailure: Scalars['Int'];
  statusTotalSuccess: Scalars['Int'];
  statusTotalWaiting: Scalars['Int'];
};

export type ThreadModelResult = {
  __typename?: 'ThreadModelResult';
  MSISDN: Scalars['String'];
  contact?: Maybe<ContactModelResult>;
  messages?: Maybe<Array<MessageModel>>;
};
