import { Authorize, AuthorizeAccount, Me, RemoveTokens } from '@/services/GraphqlApi';
import { getOcidUrl } from '@/services/Env';
import { MeModel, MyAccountModel } from '@/dto/graphql';
import router from '@/router';
import { once } from 'lodash';

export let user = null as MeModel | null;
export let userAccounts = [] as MyAccountModel[];
export let currentAccount = null as MyAccountModel | null;

export const getAuthenticationToken = async () => {
  const authenticationToken = localStorage.getItem('authentication-token');
  if (authenticationToken) {
    return authenticationToken;
  }
  return null;
};

export const getCurrentUser = once(async () => {
  const me = await Me();
  user = me;
  userAccounts = me?.myAccounts ?? [];
  if (localStorage.getItem('currentAccountID')) {
    const foundAccount = userAccounts.find((account) => account.id === localStorage.getItem('currentAccountID'));
    if (foundAccount) {
      await selectAccount(foundAccount.id);
    } else {
      if (userAccounts.length > 0) {
        await selectAccount(userAccounts[0].id);
      }
    }
  } else {
    if (userAccounts.length > 0) {
      await selectAccount(userAccounts[0].id);
    }
  }

  return me;
});

export const selectAccount = async (id: string) => {
  const token = await AuthorizeAccount(id);
  if (token.accessToken) {
    localStorage.setItem('access-token', token.accessToken);
    localStorage.setItem('currentAccountID', id);
    currentAccount = userAccounts.find((a) => a.id === id) ?? null;
  }
};

const authorize = once(async (token?: string) => {
  const authToken = token ?? (await authenticate());
  const authenticationToken = (await Authorize(authToken as string)).authenticationToken;
  localStorage.setItem('authentication-token', authenticationToken);
});

const authenticate = once(async () => {
  const paseto = await generatePaseto();
  const authReq = await fetch('/api/login/authenticate', {
    body: JSON.stringify({ token: paseto }),
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
  }).then(async (res) => {
    return await res.json();
  });

  return authReq.authenticationToken;
});

const generatePaseto = async () => {
  const devAuthTokenRes = await fetch('/api/generate-paseto');
  return await devAuthTokenRes.text();
};

let sessionWithAuthToken = false;
export const getAccessToken = async () => {
  if (window.location.hash.startsWith('#authtoken=')) {
    sessionWithAuthToken = true;
    localStorage.removeItem('authentication-token');
    localStorage.removeItem('access-token');
  }

  const accessToken = localStorage.getItem('access-token');

  if (accessToken) {
    try {
      await getCurrentUser();
      return accessToken;
    } catch (error) {
      return OcidRedirect(error);
    }
  }

  // if in dev and no authtoken in URL and no authentication token in localstorage: generate a fake authentication token
  if (process.env.NODE_ENV === 'development' && !window.location.hash.startsWith('#authtoken=') && !localStorage.getItem('authentication-token')) {
    await authorize();
    await getCurrentUser();
    window.location.hash = '';
    return accessToken;
  }

  if (!window.location.hash.startsWith('#authtoken=')) {
    return OcidRedirect('missing auth token');
  }

  const authenticationToken = window.location.hash.substring(11);
  try {
    await authorize(authenticationToken);
    await getCurrentUser();
    window.location.hash = '';
    return accessToken;
  } catch (error) {
    return OcidRedirect(error);
  }
};

function OcidRedirect(error: any) {
  if (sessionWithAuthToken) return;

  window.location.href = getOcidUrl();
}

const ocidUrl = getOcidUrl();

export async function logout() {
  await RemoveTokens(localStorage.getItem('authentication-token') ?? '', localStorage.getItem('access-token') ?? '');
  localStorage.removeItem('authentication-token');
  localStorage.removeItem('access-token');
  user = null;
  userAccounts = [];
  currentAccount = null;
  window.location.href = `${ocidUrl}/logout`;
}
