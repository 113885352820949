<template>
  <div class="input-group">
    <span class="input-group-text">
      <div class="flag overflow-hidden border rounded-circle">
        <img
          :src="phoneFlag"
          alt=""
          v-tooltip="
            phoneDetails
              ? phoneDetails.country
                ? `Country ${phoneDetails.country} (+${phoneDetails.countryCallingCode}) detected.`
                : `Invalid number for +${phoneDetails.countryCallingCode}.`
              : 'Enter a phone number, starting with country calling code.'
          "
        />
      </div>
    </span>

    <input
      v-model="phoneNumber"
      class="form-control"
      type="text"
      name="phonenumber"
      inputmode="numeric"
      placeholder="Enter full phone number"
      :id="props.id"
    />
  </div>
</template>

<style lang="scss" scoped>
  .flag {
    overflow: hidden;
    box-sizing: content-box;

    &,
    img {
      height: 32px;
      width: 32px;
    }
  }
</style>

<script lang="ts" setup>
  import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
  import { Ref, ref, watch, watchEffect } from 'vue';
  import vTooltip from '@/directives/vTooltip';
  import { getFlagByPhoneNumber } from '@/services/PhoneNumber';

  const emit = defineEmits(['update:modelValue']);
  const phoneDetails = ref(parsePhoneNumberFromString('')) as Ref<PhoneNumber | undefined>;
  const phoneFlag = ref('');

  const props = defineProps({
    modelValue: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
  });

  const phoneNumber = ref(props.modelValue) as Ref<string>;
  watch(
    () => props.modelValue,
    (v) => (phoneNumber.value = v),
  );

  watchEffect((e) => {
    phoneFlag.value = 'flags-48/flag-default.svg';
    if (!phoneNumber.value) return;

    const phoneParseable = phoneNumber.value.replace(/\D+/, '');
    phoneDetails.value = parsePhoneNumberFromString(`+${phoneParseable}`);
    phoneFlag.value = getFlagByPhoneNumber(phoneParseable);

    if (phoneDetails.value?.country) emit('update:modelValue', phoneParseable);
  });
</script>
