<template>
  <RuleSection
    v-if="inbox.ruleset.requireConfirmation"
    title="Payment confirmation SMS"
    index="2"
    type="success"
    :info="
      inbox.keyword && inbox.ruleset.paymentConfirmWord
        ? `When the user sends ${inbox.keyword} ${inbox.ruleset.paymentConfirmWord}, a charged SMS is sent, which confirms the charge.`
        : `When the user sends the specified keyword and confirmation word, a charged SMS is sent, which confirms the charge.`
    "
  >
    <div class="form-group row">
      <label for="confirmationWord" class="col-sm-3 col-form-label">Confirmation word:</label>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control text-uppercase"
          id="confirmationWord"
          placeholder="Confirmation word"
          v-model="confirmationWord"
          @keydown.space.prevent
        />
      </div>
    </div>
    <div v-if="inbox.ruleset.__typename == 'SmsInboxRulesetPaymentFixedModel'" class="form-group row">
      <label for="price" class="col-sm-3 col-form-label">Price:</label>
      <div class="col-sm-9">
        <input
          type="number"
          class="form-control"
          id="price"
          placeholder="Price"
          v-model="inbox.ruleset.paymentPrice"
          @keypress="isNumber($event)"
          @keypress.space.prevent
        />
      </div>
    </div>
    <div class="form-group row mb-0">
      <SmsMessage
        v-model="inbox.ruleset.paymentContent"
        placeholder="Example: Thank you for your donation to x! We really appreciate your support."
        label="Reply content:"
      ></SmsMessage>
    </div>
  </RuleSection>
</template>

<script setup lang="ts">
  import { computed, inject } from 'vue';
  import { SmsInboxRulesetPaymentDynamicModel, SmsInboxRulesetPaymentFixedModel, SmsInboxModel } from '@/dto/graphql';
  import RuleSection from './../ruleset/shared/RuleSection.vue';
  import SmsMessage from '@/components/shared/SmsMessage.vue';

  type TInbox = SmsInboxModel & {
    ruleset: SmsInboxRulesetPaymentFixedModel | SmsInboxRulesetPaymentDynamicModel;
  };

  const inbox = inject('inbox') as any;

  const isNumber = (event: KeyboardEvent) => {
    if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
  };

  const confirmationWord = computed({
    get: () => inbox.value.ruleset.paymentConfirmWord,
    set: (value: string) => {
      inbox.value.ruleset.paymentConfirmWord = value.replace(/\s/g, '').toLocaleUpperCase();
    },
  });
</script>
