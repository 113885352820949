export default [
  {
    id: 'AD',
    name: 'Andorra',
    prefix: 376,
  },
  {
    id: 'AE',
    name: 'United Arab Emirates',
    prefix: 971,
  },
  {
    id: 'AF',
    name: 'Afghanistan',
    prefix: 93,
  },
  {
    id: 'AG',
    name: 'Antigua and Barbuda',
    prefix: 1268,
  },
  {
    id: 'AI',
    name: 'Anguilla',
    prefix: 1264,
  },
  {
    id: 'AL',
    name: 'Albania',
    prefix: 355,
  },
  {
    id: 'AM',
    name: 'Armenia',
    prefix: 374,
  },
  {
    id: 'AO',
    name: 'Angola',
    prefix: 244,
  },
  {
    id: 'AR',
    name: 'Argentina',
    prefix: 54,
  },
  {
    id: 'AT',
    name: 'Austria',
    prefix: 43,
  },
  {
    id: 'AU',
    name: 'Australia',
    prefix: 61,
  },
  {
    id: 'AW',
    name: 'Aruba',
    prefix: 297,
  },
  {
    id: 'AZ',
    name: 'Azerbaijan',
    prefix: 994,
  },
  {
    id: 'BA',
    name: 'Bosnia and Herzegovina',
    prefix: 387,
  },
  {
    id: 'BB',
    name: 'Barbados',
    prefix: 1246,
  },
  {
    id: 'BD',
    name: 'Bangladesh',
    prefix: 880,
  },
  {
    id: 'BE',
    name: 'Belgium',
    prefix: 32,
  },
  {
    id: 'BF',
    name: 'Burkina Faso',
    prefix: 226,
  },
  {
    id: 'BG',
    name: 'Bulgaria',
    prefix: 359,
  },
  {
    id: 'BH',
    name: 'Bahrain',
    prefix: 973,
  },
  {
    id: 'BI',
    name: 'Burundi',
    prefix: 257,
  },
  {
    id: 'BJ',
    name: 'Benin',
    prefix: 229,
  },
  {
    id: 'BM',
    name: 'Bermuda',
    prefix: 1441,
  },
  {
    id: 'BN',
    name: 'Brunei Darussalam',
    prefix: 673,
  },
  {
    id: 'BO',
    name: 'Bolivia, Plurinational State of',
    prefix: 591,
  },
  {
    id: 'BR',
    name: 'Brazil',
    prefix: 55,
  },
  {
    id: 'BS',
    name: 'Bahamas',
    prefix: 1242,
  },
  {
    id: 'BT',
    name: 'Bhutan',
    prefix: 975,
  },
  {
    id: 'BW',
    name: 'Botswana',
    prefix: 267,
  },
  {
    id: 'BY',
    name: 'Belarus',
    prefix: 375,
  },
  {
    id: 'BZ',
    name: 'Belize',
    prefix: 501,
  },
  {
    id: 'CD',
    name: 'Congo, The Democratic Republic of the',
    prefix: 243,
  },
  {
    id: 'CF',
    name: 'Central African Republic',
    prefix: 236,
  },
  {
    id: 'CG',
    name: 'Congo',
    prefix: 242,
  },
  {
    id: 'CH',
    name: 'Switzerland',
    prefix: 41,
  },
  {
    id: 'CI',
    name: "Côte d'Ivoire",
    prefix: 225,
  },
  {
    id: 'CK',
    name: 'Cook Islands',
    prefix: 682,
  },
  {
    id: 'CL',
    name: 'Chile',
    prefix: 56,
  },
  {
    id: 'CM',
    name: 'Cameroon',
    prefix: 237,
  },
  {
    id: 'CN',
    name: 'China',
    prefix: 86,
  },
  {
    id: 'CO',
    name: 'Colombia',
    prefix: 57,
  },
  {
    id: 'CR',
    name: 'Costa Rica',
    prefix: 506,
  },
  {
    id: 'CU',
    name: 'Cuba',
    prefix: 53,
  },
  {
    id: 'CV',
    name: 'Cabo Verde',
    prefix: 238,
  },
  {
    id: 'CW',
    name: 'Curaçao',
    prefix: 599,
  },
  {
    id: 'CY',
    name: 'Cyprus',
    prefix: 357,
  },
  {
    id: 'CZ',
    name: 'Czechia',
    prefix: 420,
  },
  {
    id: 'DE',
    name: 'Germany',
    prefix: 49,
  },
  {
    id: 'DJ',
    name: 'Djibouti',
    prefix: 253,
  },
  {
    id: 'DK',
    name: 'Denmark',
    prefix: 45,
  },
  {
    id: 'DM',
    name: 'Dominica',
    prefix: 1767,
  },
  {
    id: 'DO',
    name: 'Dominican Republic',
    prefix: 1849,
  },
  {
    id: 'DZ',
    name: 'Algeria',
    prefix: 213,
  },
  {
    id: 'EC',
    name: 'Ecuador',
    prefix: 593,
  },
  {
    id: 'EE',
    name: 'Estonia',
    prefix: 372,
  },
  {
    id: 'EG',
    name: 'Egypt',
    prefix: 20,
  },
  {
    id: 'ER',
    name: 'Eritrea',
    prefix: 291,
  },
  {
    id: 'ES',
    name: 'Spain',
    prefix: 34,
  },
  {
    id: 'ET',
    name: 'Ethiopia',
    prefix: 251,
  },
  {
    id: 'FI',
    name: 'Finland',
    prefix: 358,
  },
  {
    id: 'FJ',
    name: 'Fiji',
    prefix: 679,
  },
  {
    id: 'FK',
    name: 'Falkland Islands (Malvinas)',
    prefix: 500,
  },
  {
    id: 'FM',
    name: 'Micronesia, Federated States of',
    prefix: 691,
  },
  {
    id: 'FO',
    name: 'Faroe Islands',
    prefix: 298,
  },
  {
    id: 'FR',
    name: 'France',
    prefix: 33,
  },
  {
    id: 'GA',
    name: 'Gabon',
    prefix: 241,
  },
  {
    id: 'GB',
    name: 'Great Britain',
    prefix: 44,
  },
  {
    id: 'EN',
    name: 'United Kingdom',
    prefix: 44,
  },
  // {
  //   'id': 'UK',
  //   'name': 'United Kingdom',
  //   'prefix': 44,
  // },
  {
    id: 'GD',
    name: 'Grenada',
    prefix: 1473,
  },
  {
    id: 'GE',
    name: 'Georgia',
    prefix: 995,
  },
  {
    id: 'GH',
    name: 'Ghana',
    prefix: 233,
  },
  {
    id: 'GI',
    name: 'Gibraltar',
    prefix: 350,
  },
  {
    id: 'GL',
    name: 'Greenland',
    prefix: 299,
  },
  {
    id: 'GM',
    name: 'Gambia',
    prefix: 220,
  },
  {
    id: 'GN',
    name: 'Guinea',
    prefix: 224,
  },
  {
    id: 'GP',
    name: 'Guadeloupe',
    prefix: 590,
  },
  {
    id: 'GQ',
    name: 'Equatorial Guinea',
    prefix: 240,
  },
  {
    id: 'GR',
    name: 'Greece',
    prefix: 30,
  },
  {
    id: 'GT',
    name: 'Guatemala',
    prefix: 502,
  },
  {
    id: 'GW',
    name: 'Guinea-Bissau',
    prefix: 245,
  },
  {
    id: 'GY',
    name: 'Guyana',
    prefix: 592,
  },
  {
    id: 'HK',
    name: 'Hong Kong',
    prefix: 852,
  },
  {
    id: 'HN',
    name: 'Honduras',
    prefix: 504,
  },
  {
    id: 'HR',
    name: 'Croatia',
    prefix: 385,
  },
  {
    id: 'HT',
    name: 'Haiti',
    prefix: 509,
  },
  {
    id: 'HU',
    name: 'Hungary',
    prefix: 36,
  },
  {
    id: 'ID',
    name: 'Indonesia',
    prefix: 62,
  },
  {
    id: 'IE',
    name: 'Ireland',
    prefix: 353,
  },
  {
    id: 'IL',
    name: 'Israel',
    prefix: 972,
  },
  {
    id: 'IN',
    name: 'India',
    prefix: 91,
  },
  {
    id: 'IQ',
    name: 'Iraq',
    prefix: 964,
  },
  {
    id: 'IR',
    name: 'Iran, Islamic Republic of',
    prefix: 98,
  },
  {
    id: 'IS',
    name: 'Iceland',
    prefix: 354,
  },
  {
    id: 'IT',
    name: 'Italy',
    prefix: 39,
  },
  {
    id: 'JM',
    name: 'Jamaica',
    prefix: 1876,
  },
  {
    id: 'JO',
    name: 'Jordan',
    prefix: 962,
  },
  {
    id: 'JP',
    name: 'Japan',
    prefix: 81,
  },
  {
    id: 'KE',
    name: 'Kenya',
    prefix: 254,
  },
  {
    id: 'KG',
    name: 'Kyrgyzstan',
    prefix: 996,
  },
  {
    id: 'KH',
    name: 'Cambodia',
    prefix: 855,
  },
  {
    id: 'KI',
    name: 'Kiribati',
    prefix: 686,
  },
  {
    id: 'KM',
    name: 'Comoros',
    prefix: 269,
  },
  {
    id: 'KN',
    name: 'Saint Kitts and Nevis',
    prefix: 1869,
  },
  {
    id: 'KO',
    name: "Korea, Democratic People's Republic of",
    prefix: 850,
  },
  {
    id: 'KR',
    name: 'Korea, Republic of',
    prefix: 82,
  },
  {
    id: 'KW',
    name: 'Kuwait',
    prefix: 965,
  },
  {
    id: 'KY',
    name: 'Cayman Islands',
    prefix: 1345,
  },
  {
    id: 'KZ',
    name: 'Kazakhstan',
    prefix: 7,
  },
  {
    id: 'LA',
    name: "Lao People's Democratic Republic",
    prefix: 856,
  },
  {
    id: 'LB',
    name: 'Lebanon',
    prefix: 961,
  },
  {
    id: 'LC',
    name: 'Saint Lucia',
    prefix: 1758,
  },
  {
    id: 'LI',
    name: 'Liechtenstein',
    prefix: 423,
  },
  {
    id: 'LK',
    name: 'Sri Lanka',
    prefix: 94,
  },
  {
    id: 'LR',
    name: 'Liberia',
    prefix: 231,
  },
  {
    id: 'LS',
    name: 'Lesotho',
    prefix: 266,
  },
  {
    id: 'LT',
    name: 'Lithuania',
    prefix: 370,
  },
  {
    id: 'LU',
    name: 'Luxembourg',
    prefix: 352,
  },
  {
    id: 'LV',
    name: 'Latvia',
    prefix: 371,
  },
  {
    id: 'LY',
    name: 'Libya',
    prefix: 218,
  },
  {
    id: 'MA',
    name: 'Morocco',
    prefix: 212,
  },
  {
    id: 'MC',
    name: 'Monaco',
    prefix: 377,
  },
  {
    id: 'MD',
    name: 'Moldova, Republic of',
    prefix: 373,
  },
  {
    id: 'ME',
    name: 'Montenegro',
    prefix: 382,
  },
  {
    id: 'MG',
    name: 'Madagascar',
    prefix: 261,
  },
  {
    id: 'MH',
    name: 'Marshall Islands',
    prefix: 692,
  },
  {
    id: 'MK',
    name: 'Macedonia, Republic of',
    prefix: 389,
  },
  {
    id: 'ML',
    name: 'Mali',
    prefix: 223,
  },
  {
    id: 'MM',
    name: 'Myanmar',
    prefix: 95,
  },
  {
    id: 'MN',
    name: 'Mongolia',
    prefix: 976,
  },
  {
    id: 'MO',
    name: 'Macao',
    prefix: 853,
  },
  {
    id: 'MQ',
    name: 'Martinique',
    prefix: 596,
  },
  {
    id: 'MR',
    name: 'Mauritania',
    prefix: 222,
  },
  {
    id: 'MS',
    name: 'Montserrat',
    prefix: 1664,
  },
  {
    id: 'MT',
    name: 'Malta',
    prefix: 356,
  },
  {
    id: 'MU',
    name: 'Mauritius',
    prefix: 230,
  },
  {
    id: 'MV',
    name: 'Maldives',
    prefix: 960,
  },
  {
    id: 'MW',
    name: 'Malawi',
    prefix: 265,
  },
  {
    id: 'MX',
    name: 'Mexico',
    prefix: 52,
  },
  {
    id: 'MY',
    name: 'Malaysia',
    prefix: 60,
  },
  {
    id: 'MZ',
    name: 'Mozambique',
    prefix: 258,
  },
  {
    id: 'NA',
    name: 'Namibia',
    prefix: 264,
  },
  {
    id: 'NC',
    name: 'New Caledonia',
    prefix: 687,
  },
  {
    id: 'NE',
    name: 'Niger',
    prefix: 227,
  },
  {
    id: 'NG',
    name: 'Nigeria',
    prefix: 234,
  },
  {
    id: 'NI',
    name: 'Nicaragua',
    prefix: 505,
  },
  {
    id: 'NL',
    name: 'Netherlands',
    prefix: 31,
  },
  {
    id: 'NO',
    name: 'Norway',
    prefix: 47,
  },
  {
    id: 'NP',
    name: 'Nepal',
    prefix: 977,
  },
  {
    id: 'NR',
    name: 'Nauru',
    prefix: 674,
  },
  {
    id: 'NZ',
    name: 'New Zealand',
    prefix: 64,
  },
  {
    id: 'OM',
    name: 'Oman',
    prefix: 968,
  },
  {
    id: 'PA',
    name: 'Panama',
    prefix: 507,
  },
  {
    id: 'PE',
    name: 'Peru',
    prefix: 51,
  },
  {
    id: 'PF',
    name: 'French Polynesia',
    prefix: 689,
  },
  {
    id: 'PG',
    name: 'Papua New Guinea',
    prefix: 675,
  },
  {
    id: 'PH',
    name: 'Philippines',
    prefix: 63,
  },
  {
    id: 'PK',
    name: 'Pakistan',
    prefix: 92,
  },
  {
    id: 'PL',
    name: 'Poland',
    prefix: 48,
  },
  {
    id: 'PM',
    name: 'Saint Pierre and Miquelon',
    prefix: 508,
  },
  {
    id: 'PR',
    name: 'Puerto Rico',
    prefix: 1939,
  },
  {
    id: 'PS',
    name: 'Palestine',
    prefix: 970,
  },
  {
    id: 'PT',
    name: 'Portugal',
    prefix: 351,
  },
  {
    id: 'PW',
    name: 'Palau',
    prefix: 680,
  },
  {
    id: 'PY',
    name: 'Paraguay',
    prefix: 595,
  },
  {
    id: 'QA',
    name: 'Qatar',
    prefix: 974,
  },
  {
    id: 'RE',
    name: 'Réunion',
    prefix: 262,
  },
  {
    id: 'RO',
    name: 'Romania',
    prefix: 40,
  },
  {
    id: 'RS',
    name: 'Serbia',
    prefix: 381,
  },
  {
    id: 'RU',
    name: 'Russian Federation',
    prefix: 7,
  },
  {
    id: 'RW',
    name: 'Rwanda',
    prefix: 250,
  },
  {
    id: 'SA',
    name: 'Saudi Arabia',
    prefix: 966,
  },
  {
    id: 'SB',
    name: 'Solomon Islands',
    prefix: 677,
  },
  {
    id: 'SC',
    name: 'Seychelles',
    prefix: 248,
  },
  {
    id: 'SD',
    name: 'Sudan',
    prefix: 249,
  },
  {
    id: 'SE',
    name: 'Sweden',
    prefix: 46,
  },
  {
    id: 'SG',
    name: 'Singapore',
    prefix: 65,
  },
  {
    id: 'SI',
    name: 'Slovenia',
    prefix: 386,
  },
  {
    id: 'SK',
    name: 'Slovakia',
    prefix: 421,
  },
  {
    id: 'SL',
    name: 'Sierra Leone',
    prefix: 232,
  },
  {
    id: 'SM',
    name: 'San Marino',
    prefix: 378,
  },
  {
    id: 'SN',
    name: 'Senegal',
    prefix: 221,
  },
  {
    id: 'SO',
    name: 'Somalia',
    prefix: 252,
  },
  {
    id: 'SR',
    name: 'Suriname',
    prefix: 597,
  },
  {
    id: 'ST',
    name: 'Sao Tome and Principe',
    prefix: 239,
  },
  {
    id: 'SV',
    name: 'El Salvador',
    prefix: 503,
  },
  {
    id: 'SY',
    name: 'Syrian Arab Republic',
    prefix: 963,
  },
  {
    id: 'SZ',
    name: 'Swaziland',
    prefix: 268,
  },
  {
    id: 'TC',
    name: 'Turks and Caicos Islands',
    prefix: 1649,
  },
  {
    id: 'TD',
    name: 'Chad',
    prefix: 235,
  },
  {
    id: 'TG',
    name: 'Togo',
    prefix: 228,
  },
  {
    id: 'TH',
    name: 'Thailand',
    prefix: 66,
  },
  {
    id: 'TJ',
    name: 'Tajikistan',
    prefix: 992,
  },
  {
    id: 'TL',
    name: 'Timor-Leste',
    prefix: 670,
  },
  {
    id: 'TM',
    name: 'Turkmenistan',
    prefix: 993,
  },
  {
    id: 'TN',
    name: 'Tunisia',
    prefix: 216,
  },
  {
    id: 'TO',
    name: 'Tonga',
    prefix: 676,
  },
  {
    id: 'TR',
    name: 'Turkey',
    prefix: 90,
  },
  {
    id: 'TT',
    name: 'Trinidad and Tobago',
    prefix: 1868,
  },
  {
    id: 'TW',
    name: 'Taiwan, Province of China',
    prefix: 886,
  },
  {
    id: 'TZ',
    name: 'Tanzania, United Republic of',
    prefix: 255,
  },
  {
    id: 'UA',
    name: 'Ukraine',
    prefix: 380,
  },
  {
    id: 'UG',
    name: 'Uganda',
    prefix: 256,
  },
  {
    id: 'US',
    name: 'United States',
    prefix: 1,
  },
  {
    id: 'UY',
    name: 'Uruguay',
    prefix: 598,
  },
  {
    id: 'UZ',
    name: 'Uzbekistan',
    prefix: 998,
  },
  {
    id: 'VC',
    name: 'Saint Vincent and the Grenadines',
    prefix: 1784,
  },
  {
    id: 'VE',
    name: 'Venezuela, Bolivarian Republic of',
    prefix: 58,
  },
  {
    id: 'VG',
    name: 'Virgin Islands, British',
    prefix: 1284,
  },
  {
    id: 'VI',
    name: 'Virgin Islands, U.S.',
    prefix: 1340,
  },
  {
    id: 'VN',
    name: 'Vietnam',
    prefix: 84,
  },
  {
    id: 'VU',
    name: 'Vanuatu',
    prefix: 678,
  },
  {
    id: 'WS',
    name: 'Samoa',
    prefix: 685,
  },
  {
    id: 'XK',
    name: 'Kosovo',
    prefix: 383,
  },
  {
    id: 'YE',
    name: 'Yemen',
    prefix: 967,
  },
  {
    id: 'ZA',
    name: 'South Africa',
    prefix: 27,
  },
  {
    id: 'ZM',
    name: 'Zambia',
    prefix: 260,
  },
  {
    id: 'ZW',
    name: 'Zimbabwe',
    prefix: 263,
  },
];
