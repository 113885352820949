<template>
  <RuleSection title="Reply SMS" index="1" type="success" info="What should the user receive upon sending a text to this endpoint?">
    <label class="form-check mb-4">
      <input class="form-check-input" type="checkbox" v-model="inbox.ruleset.replyWithMessage" id="autoreply" />
      <label class="form-check-label" for="autoreply">Send reply?</label>
    </label>
    <div class="form-group row mb-0" v-if="inbox.ruleset.replyWithMessage">
      <SmsMessage v-model="inbox.ruleset.replyContent" label="Reply content:"></SmsMessage>
    </div>
  </RuleSection>
</template>

<script setup lang="ts">
  import { inject, Ref, watch, watchEffect } from 'vue';
  import { SmsInboxRulesetAutoreplyModel } from '@/dto/graphql';
  import RuleSection from './shared/RuleSection.vue';
  import SmsMessage from '@/components/shared/SmsMessage.vue';

  type TInbox = {
    ruleset: SmsInboxRulesetAutoreplyModel;
  };
  const inbox = inject('inbox') as Ref<TInbox>;

  watchEffect(() => {
    if (!inbox.value.ruleset.replyWithMessage) {
      inbox.value.ruleset.replyContent = '';
    }
  });
</script>
