<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title me-auto">Status</h4>
      <h3 class="my-0">
        <span class="badge rounded-pill bg-secondary">Draft</span>
      </h3>
    </div>
    <div class="card-body">
      <div class="row text-truncate">
        <dt class="col-6 text-truncate">Recipients</dt>
        <dd class="col-6 text-truncate text-end">{{ recipientCount.toLocaleString() }}</dd>
      </div>
      <div class="row text-truncate">
        <dt class="col-6 text-truncate">
          Send speed
          <InfoTooltip
            tooltip="This number tells how many SMS is sent per second. Can be configured by clicking the Settings-button on the top right."
          ></InfoTooltip>
        </dt>
        <dd class="col-6 text-truncate text-end">{{ throttle }}</dd>
      </div>
      <div class="row text-truncate">
        <dt class="col-6 text-truncate">
          Delivery ETA
          <InfoTooltip tooltip="An estimation on how long it will take to send the campaign to all recipients."></InfoTooltip>
        </dt>
        <dd class="col-6 text-truncate text-end">{{ calculatedEta }}</dd>
      </div>
      <div class="row" v-if="stats.encoding === SmsEncoding.UCS2">
        <dt class="col-6 text-truncate">UCS2 Characters</dt>
        <dd class="col-6 text-truncate text-end">
          {{ stats.failedGsmChars.join('') }}
        </dd>
      </div>
      <div class="row text-truncate">
        <dt class="col-6 text-truncate">
          Message length
          <InfoTooltip tooltip="This summarizes how many characters the SMS takes up."></InfoTooltip>
        </dt>
        <dd class="col-6 text-truncate text-end">{{ stats.characters }} / {{ stats.maxCharacters }}</dd>
      </div>
      <div class="row text-truncate">
        <dt class="col-6 text-truncate">
          SMS parts
          <InfoTooltip
            tooltip="In case of long SMS'es, these are automatically split into multiple parts. Recipients only see a single SMS, but technically multiple SMS'es are in fact sent and the receiving device combine all parts into one SMS automatically.<br><br>Therefore you pay the regular SMS price, for each SMS part."
          ></InfoTooltip>
        </dt>
        <dd class="col-6 text-truncate text-end">{{ stats.messageParts }}</dd>
      </div>
      <div class="row text-truncate">
        <dt class="col-6 text-truncate">
          Encoding
          <InfoTooltip
            tooltip="GSM 03.38 is the default encoding. It handles up to 160 characters before splitting into multiple SMS-parts, and supports the most common western characters.<br><br>If you utilize symbols outside of GSM 03.38's range, we switch to UCS2 automatically, at the cost of fewer characters per SMS part."
          ></InfoTooltip>
        </dt>
        <dd class="col-6 text-truncate text-end">{{ stats.encoding }}</dd>
      </div>
      <div class="row text-truncate">
        <dt class="col-6 text-truncate">
          Total SMS parts
          <InfoTooltip tooltip="This is simply the calculation:<br>Recipients × SMS Parts"></InfoTooltip>
        </dt>
        <dd class="col-6 text-truncate text-end">
          {{ getTotalMessageParts(stats.messageParts, recipientCount).toLocaleString() }}
        </dd>
      </div>
      <div class="row">
        <dt class="col-6 text-truncate">
          Links?
          <InfoTooltip tooltip="Links must be whitelisted beforehand. Visit 'Settings > URL Whitelist' on GatewayAPI to configure."></InfoTooltip>
        </dt>
        <dd class="col-6 text-truncate text-end text-warning" v-if="stats.links.length">Yes <span class="ms-1 fe fe-alert-circle"></span></dd>
        <dd class="col-6 text-truncate text-end text-success" v-else>No <span class="ms-1 fe fe-check-circle"></span></dd>
      </div>

      <div class="mt-3 pt-3 border-top border-muted text-muted small">
        Calculations above are approximations. For instance, merge tags causes variation in message length.
        <a href="https://gatewayapi.com/docs/glossary/#gsm-0338" target="_blank" class="text-muted text-decoration-underline">Specifications here</a>.
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ComputedRef, inject, Ref } from 'vue';
  import { getMessageStats, SmsEncoding, SmsStats, getTotalMessageParts } from '@/services/SmsStats';
  import InfoTooltip from '@/components/shared/InfoTooltip.vue';
  import { addSeconds, formatDistanceToNow } from 'date-fns';

  const recipientCount = inject('recipientCount') as Ref<number>;
  const throttle = inject('throttle') as Ref<number>;
  const message = inject('message') as Ref<string>;

  const stats = computed(() => getMessageStats(message.value)) as ComputedRef<SmsStats>;

  const calculatedEta = computed(() => {
    const seconds = Math.ceil(recipientCount.value / throttle.value);
    return formatDistanceToNow(addSeconds(new Date(), seconds));
  });
</script>
