<template>
  <form @submit="createAccount" @keydown.enter="$event.preventDefault()" class="content">
    <div class="card-body relative">
      <CreateAccountStep1 v-if="currentStep === 1" v-model:name="name" :error-message="nameErrorMessage" />
      <CreateAccountStep2 v-if="currentStep === 2" v-model:timezone="timezone" :error-message="timezoneErrorMessage" />
      <CreateAccountStep3
        v-if="currentStep === 3"
        v-model:signee-company-name="signeeCompanyName"
        v-model:signee-vat-no="signeeVatNo"
        v-model:signee-name="signeeName"
        v-model:confirmation="confirmation"
        v-model:agreement-read="agreementRead"
        v-model:signing-status="signingStatus"
        :signee-company-name-error-message="signeeCompanyNameErrorMessage"
        :signee-vat-no-error-message="signeeVatNoErrorMessage"
        :signee-name-error-message="signeeNameErrorMessage"
        :confirmation-error-message="confirmationErrorMessage"
      />
    </div>
    <div class="card-footer d-flex" :class="[currentStep === 1 ? 'justify-content-end' : 'justify-content-between']">
      <button v-if="currentStep > 1" type="button" class="btn btn-outline-secondary" @click="prevStep()">Back</button>
      <button v-if="currentStep < 3" type="button" class="btn btn-primary" @click="nextStep()">Next</button>
      <button v-if="currentStep == 3" type="submit" :disabled="signingStatus && !agreementRead" class="btn btn-primary">Create account</button>
    </div>
  </form>
</template>

<script setup lang="ts">
  import { useForm, useField } from 'vee-validate';
  import { AccountCreate } from '@/services/GraphqlApi';
  import * as yup from 'yup';
  import { useToast } from 'vue-toastification';
  import { ref } from 'vue';
  import { getCurrentUser, selectAccount, user } from '@/services/Authentication';
  import useSplash from '@/composables/useSplash';
  import CreateAccountStep1 from '@/components/account/CreateAccountStep1.vue';
  import CreateAccountStep2 from '@/components/account/CreateAccountStep2.vue';
  import CreateAccountStep3 from '@/components/account/CreateAccountStep3.vue';

  const { handleSubmit } = useForm({
    initialValues: {
      name: '',
      timezone: 'Europe/Copenhagen',
      signingStatus: false,
      signeeCompanyName: '',
      signeeVatNo: '',
      signeeName: '',
      confirmation: false,
    },
  });
  const toast = useToast();
  const dpaVersion = '1.0';
  const currentStep = ref(1);
  const agreementRead = ref(false);

  const splash = useSplash();
  const emit = defineEmits(['step-change', 'close']);

  const { value: name, errorMessage: nameErrorMessage, validate: validateNameField } = useField('name', yup.string().required().label('Name'));
  const { value: timezone, errorMessage: timezoneErrorMessage } = useField('timezone', yup.string().required().label('Timezone'));
  const { value: signingStatus } = useField('signingStatus', yup.boolean().label('Signing status'));
  const { value: signeeCompanyName, errorMessage: signeeCompanyNameErrorMessage } = useField(
    'signeeCompanyName',
    yup
      .string()
      .label('Company name')
      .when([], {
        is: () => signingStatus.value,
        then: yup.string().required('Please enter company name'),
      }),
  );
  const { value: signeeVatNo, errorMessage: signeeVatNoErrorMessage } = useField(
    'signeeVatNo',
    yup
      .string()
      .label('VAT ID')
      .when([], {
        is: () => signingStatus.value,
        then: yup.string().required('Please enter VAT ID'),
      }),
  );
  const { value: signeeName, errorMessage: signeeNameErrorMessage } = useField(
    'signeeName',
    yup
      .string()
      .label('Contact name')
      .when([], {
        is: () => signingStatus.value,
        then: yup.string().required('Please enter your name'),
      }),
  );
  const { value: confirmation, errorMessage: confirmationErrorMessage } = useField(
    'confirmation',
    yup
      .boolean()
      .label('Confirmation')
      .when([], {
        is: () => signingStatus.value,
        then: yup.boolean().label('You must confirm that you have read the agreement').required().oneOf([true]),
      }),
  );

  const selectNewAccount = async (accountId: string) => {
    await getCurrentUser();
    await selectAccount(accountId);
  };

  const prevStep = () => {
    currentStep.value = currentStep.value - 1;
    emit('step-change', currentStep.value);
  };

  const nextStep = async () => {
    // validate current step
    if (currentStep.value === 1) {
      await validateNameField();
      if (nameErrorMessage.value) return;
    }
    currentStep.value = currentStep.value + 1;
  };

  const createAccount = handleSubmit(async (values) => {
    if (currentStep.value === 3 && signingStatus.value && !agreementRead.value) {
      toast.error('You must read the agreement first. Please click the link to read the DPA.');
      return;
    }

    const dpa = {
      signeeCompanyName: values.signeeCompanyName,
      signeeVatNo: values.signeeVatNo,
      signeeName: values.signeeName,
      signingStatus: values.signingStatus,
      dpaVersion,
      signeeEmail: values.signingStatus ? user?.email ?? '' : '',
      signedDate: new Date().toISOString(),
    };

    try {
      const account = await AccountCreate(values.name, dpa, values.timezone);
      localStorage.setItem('access-token', account.accessToken);
      await selectNewAccount(account.accountId);
      splash.showSplash();
      setTimeout(() => (window.location.href = '/'), 500);
    } catch (error) {
      toast.error('Could not create account');
      console.error(error);
    }
  });
</script>

<style scoped>
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 0.75rem;
    margin: 0.5rem;
  }
</style>
